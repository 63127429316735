import React from 'react';
import propTypes from 'prop-types';

import '../../styles/components/NotificationsContainer.scss';

const Notification = props => {
  const { className, children } = props;

  return (
    <div className={`notification ${className}`} role="alert">
      {children}
    </div>
  );
};

Notification.propTypes = {
  children: propTypes.func.isRequired,
  className: propTypes.string,
};

Notification.defaultProps = {
  className: '',
};

export default Notification;
