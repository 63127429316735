import { TEXT_NULL_VALUE } from '../../../utils/constants';

import {
  dateFullShortMonthNoTime,
  numberComma,
  numberMagnitude,
  numberMagnitudeSigned,
  numberSignedName,
  percent,
} from '../../../utils/format';

export default unparsedData => {
  const {
    drsOutstanding,
    issuedLast1D,
    cancelledLast1D,
    netChangeLast1D,
    issuedLimit,
    dateEndOfDay,
    totalIssued,
  } = unparsedData;

  const issueAndTotal =
    !issuedLimit && !totalIssued
      ? TEXT_NULL_VALUE
      : `${percent(issuedLimit / 100, 0)} / ${numberComma(totalIssued, 0)}`;

  return {
    drsOutstanding: numberComma(drsOutstanding, 0),
    issuedLast1D: numberMagnitude(issuedLast1D, 0),
    cancelledLast1D: numberMagnitude(cancelledLast1D, 0),
    netChangeLast1D: numberMagnitudeSigned(netChangeLast1D, 0),
    netChangeLast1DColor: numberSignedName(netChangeLast1D, 2),
    issueAndTotal,
    dateEndOfDay: dateEndOfDay
      ? dateFullShortMonthNoTime(dateEndOfDay)
      : TEXT_NULL_VALUE,
  };
};
