import '../../styles/components/Glossary.scss';
import React, { Component } from 'react';

import {
  ActionableText,
  NoDataAvailable,
  OutlineHandler,
  SearchBox,
  Tabs,
} from '@jpm-adr/pattern-library';

import GlossaryData from './GlossaryData.json';

class Glossary extends Component {
  constructor() {
    super();
    this.state = { GlossaryData, searchString: '' };
    this.createSections = this.createSections.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.findString = this.findString.bind(this);
    this.resetString = this.resetString.bind(this);
  }

  onSearch(searchString) {
    this.setState({
      searchString,
    });
  }

  findString(term) {
    const { searchString } = this.state;
    if (searchString === '') return true;
    return term.term.toLowerCase().indexOf(searchString.toLowerCase()) > -1;
  }
  resetString() {
    this.setState({ searchString: '' });
  }

  createSections() {
    const { GlossaryData, searchString } = this.state;
    const sections = GlossaryData || [];

    const insideTabs = sections.map(section => {
      const terms = section.terms.filter(this.findString).map(term => (
        <div className="glossaryjpm_termcontainer" key={`${term.term}`}>
          <OutlineHandler>
            <strong role="textbox" tabIndex="0">
              {term.term}
            </strong>
          </OutlineHandler>
          <OutlineHandler>
            <div role="textbox" tabIndex="0">
              {term.definition}
            </div>
          </OutlineHandler>
        </div>
      ));
      return (
        <div tabId={section.text} label={section.text} key={`${section.text}`} role='tabpanel' aria-labelledby={section.text}>
          {terms.length !== 0 ? (
            terms
          ) : (
            <NoDataAvailable
              message="No Results Found"
              messageStyles
              action={(
                <ActionableText onClick={this.resetString}>
                  Clear Entry
                </ActionableText>
              )}
            />
          )}
        </div>
      );
    });

    const SearchInput = (
      <SearchBox
        onChange={this.onSearch}
        placeholder="Filter Terms (By Name)"
        onSubmit={() => {}}
        size={300}
        styles="glossaryjpm__tabs__search"
        key="glossaryjpm__tabs__search"
        clickable={false}
        input={searchString}
      />
    );
    return (
      <Tabs
        tabsId="glossaryjpm__tabs"
        optionalText="View Terms"
        rightContent={SearchInput}
        onChangeTab={this.resetString}
      >
        {insideTabs}
      </Tabs>
    );
  }

  render() {
    return (
      <div className="glossaryjpm">
        <section className="glossaryjpm__header columns">
          <div className="column side-margis">&nbsp;</div>
          <div className="column main-content-wrapper">
            <h1 className="glossaryjpm__header__title">Glossary of Terms</h1>
          </div>
          <div className="column side-margis">&nbsp;</div>
        </section>

        <section className="glossaryjpm__tabs columns">
          <div className="glossaryjpm__graybar" />
          <div className="column side-margis">&nbsp;</div>
          <div className="column main-content-wrapper">
            {this.createSections()}
          </div>
          <div className="column side-margis">&nbsp;</div>
        </section>
      </div>
    );
  }
}

export default Glossary;
