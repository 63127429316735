import React from 'react';

export const ModalRegs = () => (
  <p>
    Access to information regarding the issuer is only made available to
    Non-U.S. persons outside of the United States within the meaning of
    Regulation S promulgated under the Securities Act of 1933, as amended (the
    “Securities Act”). NOTHING CONTAINED HEREIN CONSTITUTES AN OFFER TO SELL
    SECURITIES OR THE SOLICITATION OF AN OFFER TO PURCHASE ANY SECURITIES IN ANY
    JURISDICTION. Neither the depositary receipts nor the underlying securities
    of the issuer to which this certification relates have been or will be
    registered under the Securities Act or any United States state securities
    laws. <br />
    <br />
    By clicking “I Accept” below and accessing the information on this website,
    you are certifying and agreeing to the following: (a) you have read,
    understood and agree to this disclaimer, (b) you have independently informed
    yourself of any legal restrictions and represent to JPMorgan Chase Bank,
    N.A. and each of its affiliated entities, including, without limitation,
    J.P. Morgan AG, that by accessing the information contained on this website,
    you are not in violation of, and will not be causing the issuer of the
    underlying securities, JPMorgan Chase Bank, N.A. or any of J.P. Morgan Chase
    Bank, N.A.&apos;s affiliated entities, including, without limitation, J.P.
    Morgan AG, to violate, any applicable laws and, as a result, you are legally
    authorized to access such information, (c) you understand and acknowledge
    that the information you are about to see relates to a security or
    securities that has/have not been registered under the Securities Act, and
    may not be offered or sold within the United States (within the meaning of
    Regulation S under the Securities Act) or to, or for the account or benefit
    of, U.S. persons (as such term is defined in Regulation S under the
    Securities Act), (d) you are a Non-U.S. person (as defined in Regulation S
    under the Securities Act) located outside of the United States (within the
    meaning of Regulation S under the Securities Act) and (e) access to this
    website has been limited so that it shall not constitute directed selling
    efforts (as defined in Regulation S under the Securities Act) in the United
    States.
  </p>
);

export const Modal114A = () => (
  <p>
    Access to information regarding the issuer is only made available to
    “qualified institutional buyers” within the meaning of Rule 144A promulgated
    under the Securities Act of 1933, as amended (the “Securities Act”). NOTHING
    CONTAINED HEREIN CONSTITUTES AN OFFER TO SELL SECURITIES OR THE SOLICITATION
    OF AN OFFER TO PURCHASE ANY SECURITIES IN ANY JURISDICTION. Neither the
    depositary receipts nor the underlying securities of the issuer to which
    this certification relates have been or will be registered under the
    Securities Act or any United States state securities laws.
    <br />
    <br /> By clicking “I Accept” below and accessing the information on this
    website, you are certifying and agreeing to the following: (a) you have
    read, understood and agree to this disclaimer, (b) you have independently
    informed yourself of any legal restrictions and represent to JPMorgan Chase
    Bank, N.A. each of its affiliated entities, including, without limitation,
    J.P. Morgan AG, that by accessing the information contained on this website,
    you are not in violation of, and will not be causing the issuer of the
    underlying securities, JPMorgan Chase Bank, N.A. or any of J.P. Morgan Chase
    Bank, N.A.&apos;s affiliated entities, including, without limitation, J.P. Morgan
    AG, to violate, any applicable laws and, as a result, you are legally
    authorized to access such information, (c) you understand and acknowledge
    that the information you are about to see relates to a security or
    securities that has/have not been registered under the Securities Act, and
    may only be offered and sold to qualified institutional buyers (as such term
    if defined in Rule 144A under the Securities Act), (d) you are a qualified
    institutional buyer (as defined in Rule 144A under the Securities Act)
    acting for your own account or the accounts of other qualified institutional
    buyers, and (e) access to this website has been limited so that it shall not
    constitute general advertising or general solicitation (within the meaning
    of Regulation D under the Securities Act).
  </p>
);
