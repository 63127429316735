import '../../styles/components/DisclosureTabs.scss';
import PropTypes from 'prop-types';
import React from 'react';

import { NavigationTabs } from '@jpm-adr/pattern-library';

import DisclosureMain from './disclosure/main';
import PrivacyPolicy from './privacyPolicy/PrivacyPolicy';
import TermsConditions from './termConditions/TermConditions';


const getTitle = ({path = ''}) => {
  let title = ''

  switch(path.match('[^/]+$')[0]) {
    case 'privacy-policy':
      title = "Privacy Policy";
      break;
    case 'terms-conditions':
      title = "Terms & Conditions";
      break;
    default:
      title = "Disclosures";
      break;
  }

  return title;
};

const Disclosure = ({match}) => {
  return (
    <div className="disclosurejpmorgan core">
      <section className="columns section-header">
        <div className="column is-hidden-touch column-hidden side-margis margin-1">
          &nbsp;
        </div>
        <div className="column main-content-wrapper disclosure-header">
          <div className="margin-mobile">
            <h1 className="core__t3">{getTitle(match)}</h1>
          </div>
        </div>
        <div className="column is-hidden-touch column-hidden side-margis margin-2">
          &nbsp;
        </div>
      </section>
      <section className="columns margin-mobile">
        <div className="column is-hidden-touch column-hidden side-margis margin-1">
          &nbsp;
        </div>
        <div className="column main-content-wrapper disclosure-main-wrapper">
          <section className="disclosure-section-wrapper">
            <NavigationTabs
              hasButton={false}
              customClass="disclosure-main-tabs"
            >
              <div
                label="DISCLOSURES"
                key="DISCLOSURES"
                url="/disclosure/disclosures"
                renderContent={() => <DisclosureMain />}
              />
              <div
                label="PRIVACY POLICY"
                key="PRIVACY_POLICY"
                url="/disclosure/privacy-policy"
                renderContent={() => <PrivacyPolicy />}
              />
              <div
                label="TERMS & CONDITIONS"
                key="TERMS_CONDITIONS"
                url="/disclosure/terms-conditions"
                renderContent={() => <TermsConditions />}
              />
            </NavigationTabs>
          </section>
        </div>
        <div className="column is-hidden-touch column-hidden side-margis margin-2">
          &nbsp;
        </div>
      </section>
    </div>
  );
};

Disclosure.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired
  }).isRequired
};

export default Disclosure;
