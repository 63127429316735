import React from 'react';

const clotheSpinIcon = (
  <svg className="icon clothespin-icon">
    <path
      className="path1"
      d="M82.2 61.9c-0.4-0.2-23.4-13.2-31.3-17.6v-4.4h-1.8v4.4c-7.8 4.4-30.9 17.4-31.3 17.6 -2.4 1.7-3.9 4.4-3.9 7.4 0 4.9 4 9 9 9h54.3c5 0 9-4 9-9C86.1 66.3 84.6 63.6 82.2 61.9ZM77.1 76.4H22.9c-3.9 0-7.1-3.2-7.1-7.1 0-2.4 1.2-4.6 3.1-5.9C19.4 63 39.2 51.9 50 45.8c10.8 6.1 30.6 17.2 31.2 17.6 1.9 1.3 3.1 3.5 3.1 5.9C84.3 73.2 81.1 76.4 77.1 76.4Z" //NOSONAR
    />
    <path
      className="path2"
      d="M77.1 79.3H22.9c-5.5 0-10-4.5-10-10 0-3.3 1.6-6.4 4.3-8.2 0.4-0.2 16.9-9.5 30.9-17.4v-4.8h3.8v4.8c26.8 15.1 30.6 17.3 30.8 17.4 2.7 1.9 4.3 5 4.3 8.2C87.1 74.8 82.6 79.3 77.1 79.3ZM50 47c-13.9 7.8-30.1 16.9-30.7 17.3 -1.6 1.1-2.6 3-2.6 5 0 3.4 2.8 6.1 6.1 6.1h54.3c3.4 0 6.1-2.8 6.1-6.1 0-2-1-3.9-2.7-5.1C80.3 64.1 74.8 60.9 50 47Z" //NOSONAR
    />
    <path
      className="path3"
      d="M50 41.1c-1.1 0-2-0.9-2-2s0.9-2 2-2c3.7 0 6.8-3 6.8-6.8s-3-6.8-6.8-6.8 -6.8 3-6.8 6.8c0 1.1-0.9 2-2 2s-2-0.9-2-2c0-6 4.8-10.8 10.8-10.8 6 0 10.8 4.8 10.8 10.8S56 41.1 50 41.1Z" //NOSONAR
    />
  </svg>
);
const microphoneIcon = (
  <svg className="icon microphone-icon">
    <path
      d="M76 32c-1.1 0-2 0.9-2 2v6 2 7c0 13.2-10.8 24-24 24 -13.2 0-24-10.8-24-24v-7 -2 -6c0-1.1-0.9-2-2-2s-2 0.9-2 2v6 2 7c0 14.8 11.5 26.9 26 27.9V85H32c-1.1 0-2 0.9-2 2s0.9 2 2 2h35c1.1 0 2-0.9 2-2s-0.9-2-2-2H52v-8.1c14.5-1 26-13.2 26-27.9v-7 -2 -6C78 32.9 77.1 32 76 32Z" //NOSONAR
      className="a path2"
    />
    <path
      d="M50 66c9.4 0 17-7.6 17-17V27c0-9.4-7.6-17-17-17s-17 7.6-17 17v22C33 58.4 40.6 66 50 66ZM37 27c0-7.2 5.8-13 13-13 7.2 0 13 5.8 13 13v22c0 7.2-5.8 13-13 13 -7.2 0-13-5.8-13-13V27Z" //NOSONAR
      className="a path2"
    />
  </svg>
);
const cartIcon = (
  <svg className="icon cart-icon">
    <path
      className="cart-upper"
      d="M78.2 64.9H37.4L27.2 25.5l-0.1-0.3 -14.3-7.9c-0.3-0.2-0.6-0.2-0.9-0.2 -0.7 0-1.4 0.4-1.7 1 -0.3 0.5-0.3 1-0.2 1.5 0.1 0.5 0.5 0.9 0.9 1.2l12.8 7 10.6 41h43.9c1.1 0 2-0.9 2-2C80.2 65.8 79.3 64.9 78.2 64.9Z" //NOSONAR
    />
    <path
      d="M69.5 69.8c-3.6 0-6.6 2.9-6.6 6.6 0 3.6 2.9 6.6 6.6 6.6 3.6 0 6.6-2.9 6.6-6.6C76 72.7 73.1 69.8 69.5 69.8ZM69.5 78.9c-1.4 0-2.6-1.2-2.6-2.6 0-1.4 1.2-2.6 2.6-2.6 1.4 0 2.6 1.2 2.6 2.6C72.1 77.8 70.9 78.9 69.5 78.9Z" //NOSONAR
      className="a path3"
    />
    <path
      d="M43.7 69.8c-3.6 0-6.6 2.9-6.6 6.6 0 3.6 2.9 6.6 6.6 6.6 3.6 0 6.6-2.9 6.6-6.6C50.3 72.7 47.3 69.8 43.7 69.8ZM43.7 78.9c-1.4 0-2.6-1.2-2.6-2.6 0-1.4 1.2-2.6 2.6-2.6 1.4 0 2.6 1.2 2.6 2.6C46.3 77.8 45.2 78.9 43.7 78.9Z" //NOSONAR
      className="a path3"
    />
    <path
      className="cart-body"
      d="M89.7 31.4c-0.4-0.5-1-0.8-1.6-0.8H34.6c-0.2 0-0.3 0-0.5 0.1 0 0 0 0 0 0 0 0-0.1 0-0.1 0 -0.1 0-0.1 0-0.2 0.1 -0.9 0.4-1.4 1.4-1.2 2.3l6.5 25.7c0.2 0.9 1 1.4 1.8 1.5 0.1 0 0.3 0.1 0.5 0.1h38.5c0.9 0 1.6-0.6 1.9-1.4L90 33.2C90.2 32.6 90.1 31.9 89.7 31.4ZM78.3 56.4H42.6l-5.5-21.9h48.3L78.3 56.4Z" //NOSONAR
    />
  </svg>
);
const carIcon = (
  <svg className="icon car-icon">
    <path
      d="M59 60H41c-0.9 0-1.6-0.7-1.6-1.6 0-0.9 0.7-1.6 1.6-1.6h18c0.9 0 1.6 0.7 1.6 1.6S59.9 60 59 60Z"
      className="a path4"
    />
    <path
      d="M60.9 65.1H39.1c-0.9 0-1.6-0.7-1.6-1.6s0.7-1.6 1.6-1.6h21.8c0.9 0 1.6 0.7 1.6 1.6C62.5 64.4 61.8 65.1 60.9 65.1Z"
      className="a path4"
    />
    <path
      d="M59 70.5H41c-0.9 0-1.6-0.7-1.6-1.6s0.7-1.6 1.6-1.6h18c0.9 0 1.6 0.7 1.6 1.6S59.9 70.5 59 70.5Z"
      className="a path4"
    />
    <path
      d="M28.6 69.8c-3.9 0-7.1-3.2-7.1-7.1s3.2-7.1 7.1-7.1 7.1 3.2 7.1 7.1S32.5 69.8 28.6 69.8ZM28.6 58.8c-2.1 0-3.9 1.7-3.9 3.9 0 2.1 1.7 3.9 3.9 3.9 2.1 0 3.9-1.7 3.9-3.9C32.5 60.6 30.8 58.8 28.6 58.8Z" //NOSONAR
      className="b path4"
    />
    <path
      d="M72.2 69.8c-3.9 0-7.1-3.2-7.1-7.1s3.2-7.1 7.1-7.1 7.1 3.2 7.1 7.1C79.3 66.6 76.1 69.8 72.2 69.8ZM72.2 58.8c-2.1 0-3.9 1.7-3.9 3.9 0 2.1 1.7 3.9 3.9 3.9 2.1 0 3.9-1.7 3.9-3.9C76.1 60.6 74.3 58.8 72.2 58.8Z" //NOSONAR
      className="b path4"
    />
    <path
      className="path4"
      d="M90 47.5v-1.6h-7.1c0-0.1 0-0.2 0-0.2L80 25.6c-0.1-0.8-0.3-1.9-0.3-2.4l-0.1-1.1 -1.9-0.5c-4.1-1.1-11.1-1.7-17-2l-3.2-0.2c-2.6-0.1-2.7-0.1-4.8-0.2l-3.8 0c-2.8 0.1-2.7 0-5.3 0.1l-3.1 0.2c-6.1 0.3-13.5 0.9-17.8 2.1l-1.9 0.5 -0.2 1.1c-0.1 0.5-0.2 1.6-0.3 2.4l-2.9 20.1c0 0.1 0 0.2 0 0.2H10v1.6c-0.1 5.7 3.2 7.5 5.1 8 -0.3 2.3-0.4 4.7-0.4 7.1 0 3.9 0.7 8.8 0.7 9 0.1 0.7 0.2 1.3 0.2 1.4v0.1c0 0.1 0 0.2 0.1 0.3v11.6c0 0.9 0.7 1.6 1.6 1.6h9.5c0.9 0 1.6-0.7 1.6-1.6v-5c0.9 0.3 1.8 0.5 2.6 0.5h37.7l0 0c0.7 0 1.7-0.2 2.6-0.4v5c0 0.9 0.7 1.6 1.6 1.6h9.5c0.9 0 1.6-0.7 1.6-1.6V74.5c0.1-0.2 0.1-0.4 0.1-0.5 0-0.2 0.2-1.5 0.3-2.3 0-0.2 0.7-5.2 0.7-9.1 0-2.5-0.1-4.9-0.4-7.2C86.9 54.9 90 53.1 90 47.5ZM15.2 52.1c-0.7-0.4-1.5-1.3-1.8-2.9h2.1L15.2 52.1ZM23.4 26c0.1-0.4 0.1-0.9 0.2-1.4 6.9-1.8 23.5-2.2 26.4-2.2 3 0 19.4 0.4 26.4 2.2 0.1 0.5 0.1 0.9 0.2 1.4l2.9 20H48.9l13.2-4.9c0.8-0.3 1.3-1.2 1-2.1 -0.3-0.8-1.2-1.3-2.1-1l-21.1 7.9c-0.1 0-0.1 0.1-0.2 0.1H20.5L23.4 26ZM25.3 83.6H19v-7.9l0.7 0.3 5.6 2.8C25.3 78.8 25.3 83.6 25.3 83.6ZM68.9 77.4H31.2c-0.6 0-1.6-0.2-2.1-0.5l-8.4-4.1 -1.8-0.9c0-0.2-0.1-0.4-0.1-0.6 0 0-0.7-5-0.7-8.6 0-4.5 0.5-8.8 1.4-12.2l0 0c0.1-0.4 0.2-0.8 0.3-1.1h60.7c0.1 0.1 0.1 0.3 0.2 0.5l0.2 0.7c0.9 3.4 1.4 7.7 1.4 12.2 0 3.6-0.7 8.6-0.7 8.6 -0.1 0.5-0.2 1.2-0.2 1.8 -0.1 0.1-0.3 0.1-0.5 0.2L71.2 77C70.6 77.2 69.5 77.4 68.9 77.4ZM81 83.7h-6.3v-4.6l6.3-2.5V83.7ZM84.8 52.1l-0.3-2.9h2.1C86.3 50.8 85.5 51.7 84.8 52.1Z" //NOSONAR
    />
  </svg>
);
const pcIcon = (
  <svg className="icon pc-icon">
    <path
      className="element"
      d="M85.2 16.3h-70c-2.8 0-5 2.3-5 5v47.7c0 2.8 2.3 5 5 5h33.2V80H32.7c-1 0-1.8 0.8-1.8 1.8 0 1 0.8 1.8 1.8 1.8h35c1 0 1.8-0.8 1.8-1.8 0-1-0.8-1.8-1.8-1.8H52.1v-5.9h33.2c2.8 0 5-2.3 5-5V21.4C90.3 18.6 88 16.3 85.2 16.3ZM13.9 64.6h72.7v4.5c0 0.7-0.6 1.3-1.3 1.3h-70c-0.7 0-1.3-0.6-1.3-1.3V64.6ZM15.2 20h70c0.7 0 1.3 0.6 1.3 1.3v39.5H13.9V21.4C13.9 20.6 14.5 20 15.2 20Z" //NOSONAR
    />
  </svg>
);
const messageIcon = (
  <svg className="icon message-icon">
    <path
      className="start"
      d="M89.8 31.7c0-6-4.9-10.9-10.9-10.9H51.5c-5.2 0-9.7 3.7-10.7 8.8l-0.1 0.6H44l0.1-0.4c0.9-3.4 3.9-5.8 7.4-5.8h27.4c4.2 0 7.7 3.4 7.7 7.7v12.4c0 3.7-2.6 6.8-6.2 7.5 -0.5 0.1-1 0.1-1.5 0.1h-1.5l1.2 4.6 -4.4-4.6h-7.5v3.3h5.6l8.3 8.7c0.6 0.6 1.4 0.6 2 0.1 0.6-0.5 0.8-1.3 0.6-2.1l-1.8-7 0.2-0.1c4.7-1.3 8-5.6 8-10.6V31.7Z" //NOSONAR
    />
    <path
      className="path9"
      d="M20.1 78.7l9.1-9.6h22.9c6.6 0 12-5.4 12-12V43.5c0-0.5 0-0.9-0.1-1.5 -0.2-1.8-0.8-3.4-1.8-4.9 -2.2-3.5-6-5.6-10.1-5.6h-30c-6.6 0-12 5.4-12 12v13.6c0 5.4 3.6 10.1 8.9 11.6l0.2 0.1 -2 7.8c-0.2 0.9 0.1 1.8 0.7 2.3C18.6 79.4 19.5 79.3 20.1 78.7ZM13.6 57.2V43.5c0-4.7 3.8-8.5 8.5-8.5h30c4.7 0 8.5 3.8 8.5 8.5v13.6c0 4.7-3.8 8.5-8.5 8.5H27.3l-4.9 5.2 1.3-5.2h-1.5c-0.5 0-1-0.1-1.6-0.2C16.5 64.7 13.6 61.2 13.6 57.2Z" //NOSONAR
    />
    <path
      d="M49.8 47.2c-2 0-3.6 1.6-3.6 3.6 0 2 1.6 3.6 3.6 3.6 2 0 3.6-1.6 3.6-3.6C53.4 48.8 51.8 47.2 49.8 47.2Z"
      className="a path9"
    />
    <path
      d="M37.1 47.2c-2 0-3.6 1.6-3.6 3.6 0 2 1.6 3.6 3.6 3.6 2 0 3.6-1.6 3.6-3.6C40.8 48.8 39.1 47.2 37.1 47.2Z"
      className="a path9"
    />
    <path
      d="M24.5 47.2c-2 0-3.6 1.6-3.6 3.6 0 2 1.6 3.6 3.6 3.6 2 0 3.6-1.6 3.6-3.6C28.2 48.8 26.5 47.2 24.5 47.2Z"
      className="a path9"
    />
    <path
      d="M69.8 39c0-1.8-1.4-3.2-3.2-3.2 -0.5 0-1 0.1-1.5 0.4l-0.5 0.2 0.3 0.4c0.9 1.5 1.5 3.1 1.7 4.8l0.1 0.5 0.5-0.1C68.8 41.8 69.8 40.5 69.8 39Z"
      className="a path9"
    />
    <path
      d="M77.4 35.9c-1.8 0-3.2 1.4-3.2 3.2 0 1.8 1.4 3.2 3.2 3.2 1.8 0 3.2-1.4 3.2-3.2C80.6 37.3 79.2 35.9 77.4 35.9Z"
      className="a path9"
    />
  </svg>
);
const textIcon = (
  <svg className="icon text-icon">
    <path
      d="M81 51H18c-1.1 0-2-0.9-2-2s0.9-2 2-2h63c1.1 0 2 0.9 2 2S82.1 51 81 51Z"
      className="a path5"
    />
    <path
      d="M81 58H18c-1.1 0-2-0.9-2-2s0.9-2 2-2h63c1.1 0 2 0.9 2 2S82.1 58 81 58Z"
      className="a path5"
    />
    <path
      className="upper"
      d="M43 68H18c-1.1 0-2-0.9-2-2s0.9-2 2-2h25c1.1 0 2 0.9 2 2S44.1 68 43 68Z"
    />
    <path
      className="middle"
      d="M80.7 36.4H67.1c-1.3 1.2-2.2 2.2-3.6 3.5h17.1c2.9 0 5.2 2.4 5.2 5.2v21c0 2.9-2.4 5.2-5.2 5.2H18.5c-2.9 0-5.2-2.4-5.2-5.2v-21c0-2.9 2.4-5.2 5.2-5.2h31.9c0.8-1.3 1.8-2.5 2.4-3.5l-34.3 0c-4.8 0-8.8 3.9-8.8 8.8v21c0 4.8 3.9 8.8 8.8 8.8h62.2c4.8 0 8.8-3.9 8.8-8.8v-21C89.4 40.3 85.5 36.4 80.7 36.4Z" //NOSONAR
    />
    <path
      className="lower"
      d="M85.1 11.9l-0.5-0.6c-0.7-0.7-1.7-1.3-2.8-1.3 0 0 0 0 0 0 -1 0-2 0.5-2.8 1.3L58.8 31.4c-3.2 3.1-6 6.6-8.5 10.4 -0.4 0.7-0.4 1.6 0.2 2.2l0.3 0.3 1.1 1.1 0.3 0.3c0.3 0.3 0.6 0.4 1 0.5 0.1 0 0.2 0 0.2 0 0.3 0 0.7-0.1 0.9-0.3 3.7-2.4 7.2-5.3 10.4-8.4l20.3-20.1c0.7-0.7 1.2-1.7 1.2-2.8C86.3 13.6 85.9 12.6 85.1 11.9ZM81.8 14C81.8 14 81.8 14 81.8 14c0.1 0 0.3-0.1 0.4 0l0.5 0.4c0.1 0.1 0.1 0.2 0.1 0.3 0 0.1-0.1 0.2-0.2 0.3L72.6 25l-1.2-1.2L81.5 14C81.6 13.9 81.7 14 81.8 14ZM69 26.2l1.2 1.2 -7.8 7.7c-2.5 2.4-5.1 4.7-8 6.7 2-2.8 4.3-5.5 6.8-7.9L69 26.2Z" //NOSONAR
    />
  </svg>
);
const leafIcon = (
  <svg className="icon leaf-icon">
    <path
      d="M50 28.5c2.9 0 5.9-2.4 5.9-7.9 0-4.4-4-9.4-4.5-10 -0.7-0.8-2.1-0.8-2.8 0 -0.5 0.6-4.4 5.5-4.4 10C44.1 26.1 47.1 28.5 50 28.5ZM50 24.9c-1.3 0-2.2-1.8-2.2-4.3 0-1.9 1.2-4.1 2.2-5.7 1 1.6 2.2 3.8 2.2 5.7C52.2 23.1 51.3 24.9 50 24.9Z" //NOSONAR
      className="a path6"
    />
    <path
      d="M52.6 34.8c-0.2 1.7 0.3 3.2 1.5 4.4 1 1 2.3 1.5 3.7 1.5 1.4 0 3.6-0.5 6-3 3.1-3.1 3.8-9.5 3.9-10.2 0.1-0.5-0.1-1.1-0.5-1.5 -0.3-0.3-0.8-0.5-1.3-0.5l-0.2 0c-0.7 0.1-7.1 0.8-10.2 3.9C53.8 31.2 52.8 33 52.6 34.8ZM63.7 29.6c-0.4 1.8-1.1 4.3-2.5 5.6 -1.7 1.7-3.6 2.4-4.6 1.5 -0.9-0.9-0.3-2.8 1.5-4.6C59.4 30.7 61.9 30 63.7 29.6Z" //NOSONAR
      className="a path6"
    />
    <path
      d="M65.9 39.5l-0.2 0c-0.7 0.1-7.1 0.8-10.2 3.9 -1.7 1.7-2.7 3.6-2.9 5.4 -0.2 1.7 0.3 3.2 1.5 4.4 1 1 2.3 1.5 3.7 1.5 1.4 0 3.6-0.5 6-3 3.1-3.1 3.8-9.5 3.9-10.2 0.1-0.5-0.1-1.1-0.5-1.5C66.9 39.7 66.4 39.5 65.9 39.5ZM63.7 43.6c-0.4 1.8-1.1 4.3-2.5 5.6 -1.7 1.7-3.6 2.4-4.6 1.5 -0.9-0.9-0.3-2.8 1.5-4.6C59.4 44.7 61.9 44 63.7 43.6Z" //NOSONAR
      className="a path6"
    />
    <path
      d="M65.9 53.5l-0.2 0c-0.7 0.1-7.1 0.8-10.2 3.9 -1.7 1.7-2.7 3.6-2.9 5.4 -0.2 1.7 0.3 3.2 1.5 4.4 1 1 2.3 1.5 3.7 1.5 1.4 0 3.6-0.5 6-3 3.1-3.1 3.8-9.5 3.9-10.2 0.1-0.5-0.1-1.1-0.5-1.5C66.9 53.7 66.4 53.5 65.9 53.5ZM63.7 57.5c-0.4 1.8-1.1 4.3-2.5 5.6 -1.7 1.7-3.6 2.4-4.6 1.5 -0.4-0.4-0.5-1-0.4-1.7 0.2-0.9 0.9-1.9 1.8-2.9C59.4 58.7 61.9 57.9 63.7 57.5Z" //NOSONAR
      className="a path6"
    />
    <path
      d="M65.9 67.5l-0.2 0c-0.7 0.1-7.1 0.8-10.2 3.9 -3.9 3.9-3.5 7.7-1.5 9.7 1 1 2.3 1.5 3.7 1.5 1.4 0 3.6-0.5 6-3 3.1-3.1 3.8-9.5 3.9-10.2 0.1-0.5-0.1-1.1-0.5-1.5C66.9 67.7 66.4 67.5 65.9 67.5ZM63.7 71.5c-0.4 1.8-1.1 4.3-2.5 5.6 -1.7 1.7-3.6 2.4-4.6 1.5 -0.4-0.4-0.5-1-0.4-1.7 0.2-0.9 0.9-1.9 1.8-2.9C59.4 72.7 61.9 71.9 63.7 71.5Z" //NOSONAR
      className="a path6"
    />
    <path
      d="M42.2 40.7c1.4 0 2.7-0.5 3.7-1.5 2.1-2.1 2.4-5.9-1.5-9.7 -3.1-3.1-9.5-3.8-10.2-3.9l-0.2 0c-0.5 0-0.9 0.2-1.3 0.5 -0.4 0.4-0.6 0.9-0.5 1.5 0 0.3 0.7 7 3.9 10.2C38.6 40.2 40.8 40.7 42.2 40.7ZM41.9 32.1c1.8 1.8 2.4 3.7 1.5 4.6 -0.9 0.9-2.8 0.3-4.6-1.5 -1.3-1.3-2.1-3.8-2.5-5.6C38.1 30 40.6 30.7 41.9 32.1Z" //NOSONAR
      className="a path6"
    />
    <path
      d="M34.3 39.5l-0.2 0c-0.5 0-0.9 0.2-1.3 0.5 -0.4 0.4-0.6 0.9-0.5 1.5 0 0.3 0.7 7 3.9 10.2 2.4 2.4 4.6 3 6 3 1.4 0 2.7-0.5 3.7-1.5 2.1-2.1 2.4-5.9-1.5-9.7C41.3 40.3 35 39.6 34.3 39.5ZM41.9 46c1.8 1.8 2.4 3.7 1.5 4.6 -0.9 0.9-2.8 0.3-4.6-1.5 -1.3-1.3-2.1-3.8-2.5-5.6C38.1 44 40.6 44.7 41.9 46Z" //NOSONAR
      className="a path6"
    />
    <path
      d="M34.3 53.5l-0.2 0c-0.5 0-0.9 0.2-1.3 0.5 -0.4 0.4-0.6 0.9-0.5 1.5 0 0.3 0.7 7 3.9 10.2 2.4 2.4 4.6 3 6 3 1.4 0 2.7-0.5 3.7-1.5 2.1-2.1 2.4-5.9-1.5-9.7C41.3 54.3 35 53.6 34.3 53.5ZM41.9 60c1 1 1.6 2 1.8 2.9 0.2 0.7 0.1 1.3-0.4 1.7 -0.9 0.9-2.8 0.3-4.6-1.5 -1.3-1.3-2.1-3.8-2.5-5.6C38.1 57.9 40.6 58.7 41.9 60Z" //NOSONAR
      className="a path6"
    />
    <path
      d="M34.3 67.5l-0.2 0c-0.5 0-0.9 0.2-1.3 0.5 -0.4 0.4-0.6 0.9-0.5 1.5 0 0.3 0.7 7 3.9 10.2 2.4 2.4 4.6 3 6 3 1.4 0 2.7-0.5 3.7-1.5 2.1-2.1 2.4-5.9-1.5-9.7C41.3 68.2 35 67.6 34.3 67.5ZM41.9 74c1 1 1.6 2 1.8 2.9 0.2 0.7 0.1 1.3-0.4 1.7 -0.9 0.9-2.8 0.3-4.6-1.5 -1.3-1.3-2.1-3.8-2.5-5.6C38.1 71.9 40.6 72.7 41.9 74Z" //NOSONAR
      className="a path6"
    />
    <path
      className="bottom"
      d="M50 29.5c-1 0-1.8 0.8-1.8 1.8v56.8c0 1 0.8 1.8 1.8 1.8 1 0 1.8-0.8 1.8-1.8V31.3C51.8 30.3 51 29.5 50 29.5Z"
    />
  </svg>
);

const factoryIcon = (
  <svg className="icon factory-icon">
    <path
      d="M44.8 15.5c-7.8 0-14.8 3.5-19.4 9.1 0.3 1.1 0.5 2.4 1.2 3.3 4.1-5.7 10.7-9.4 18.3-9.4 12.4 0 22.5 10.1 22.5 22.5 0 12.4-10.1 22.5-22.5 22.5 -5.7 0-10.9-2.2-14.9-5.7 -0.2 1.2-0.3 2.3-0.4 3.5 4.3 3.2 9.6 5.1 15.3 5.1 14 0 25.4-11.4 25.4-25.4C70.2 26.9 58.8 15.5 44.8 15.5Z" //NOSONAR
      className="a path8"
    />
    <path
      d="M44.5 21.6c-6.2 0-11.7 2.9-15.3 7.4 0.1 1.6 0.3 3.2 0.5 4.9 2.7-5.4 8.3-9.2 14.7-9.2 9.1 0 16.4 7.4 16.4 16.4 0 9.1-7.4 16.4-16.4 16.4 -5.5 0-10.4-2.7-13.3-6.9 -0.4 1.1-0.9 2.2-1.3 3.3 3.6 4.1 8.8 6.7 14.7 6.7 10.8 0 19.5-8.8 19.5-19.5C64 30.3 55.2 21.6 44.5 21.6Z" //NOSONAR
      className="a path8"
    />
    <path
      className="path8"
      d="M44.8 46.6c-3.1 0-5.6-2.5-5.6-5.6 0-3.1 2.5-5.6 5.6-5.6 3.1 0 5.6 2.5 5.6 5.6C50.4 44.1 47.9 46.6 44.8 46.6ZM44.8 38.4c-1.4 0-2.6 1.1-2.6 2.6s1.1 2.6 2.6 2.6 2.6-1.1 2.6-2.6S46.2 38.4 44.8 38.4Z" //NOSONAR
    />
    <path
      d="M66.7 69.8c-0.5 0-0.9-0.2-1.2-0.6l-19-24c-0.5-0.7-0.4-1.6 0.2-2.2 0.7-0.5 1.6-0.4 2.2 0.3l19 24c0.5 0.7 0.4 1.6-0.2 2.2C67.4 69.7 67.1 69.8 66.7 69.8Z" //NOSONAR
      className="b path8"
    />
    <path
      d="M41.7 43.8c-0.7-0.5-1.6-0.4-2.2 0.3l-7 8.8c0.8 0.7 1.4 1.5 2.3 2l7.1-9C42.5 45.3 42.4 44.3 41.7 43.8Z"
      className="c path8"
    />
    <path
      d="M20.8 39.5l0 1.5 -0.6 0c-0.8 0-1.5 0.7-1.5 1.5 0 0.8 0.7 1.5 1.5 1.5h2.1c0.4 0 0.7-0.1 1-0.3 0.1 0 0.1-0.1 0.2-0.2 0.3-0.3 0.4-0.7 0.4-1.1l0-3c0-0.8-0.7-1.5-1.5-1.5C21.6 38 20.8 38.7 20.8 39.5Z" //NOSONAR
      className="d path8"
    />
    <path
      className="path8"
      d="M13.4 52.4h0.3v7.8c0 0 0 0 0 0.1v11.2h3.4V60.2c0 0 0 0 0 0v-7.8h4.2c-0.5 0.3-0.9 0.8-0.9 1.5v8.5c0 0 0 0.1 0 0.1v8.9h3.4V59c0 0 0 0 0 0v-5.1c0-0.6-0.4-1.2-0.9-1.5h4.2v7.8c0 0.1 0 0.1 0 0.2v11h3.4V59c0 0 0-0.1 0-0.1v-6.5h0.4c1.2 0 2.2-1 2.2-2.2V32.7c0 0 0-0.1 0-0.1 0 0 0-0.1 0-0.1V30c0-0.9-0.8-1.7-1.7-1.7 -0.9 0-1.6 0.7-1.7 1.6l-1.5-3.4 0-4.2c0-0.3-0.1-0.6-0.2-0.8l-4.4-8.7c-0.3-0.6-0.9-0.9-1.5-0.9 -0.6 0-1.2 0.4-1.5 0.9l-4.4 8.7c-0.1 0.2-0.2 0.5-0.2 0.8v4.2l-1.5 3.4c-0.1-0.9-0.8-1.6-1.7-1.6 -0.9 0-1.7 0.8-1.7 1.7v2.4c0 0 0 0.1 0 0.1 0 0 0 0.1 0 0.1v17.5C11.2 51.4 12.2 52.4 13.4 52.4ZM22.2 17.4l1.6 3.2h-3.3L22.2 17.4ZM19.5 24.1h5.4v1.1h-5.4V24.1ZM18.9 28.6h6.6l0.8 1.9h-8.2L18.9 28.6ZM14.6 33.9h15.1V49H14.6V33.9Z" //NOSONAR
    />
    <path
      d="M22.2 34.2c-4 0-7.3 3.3-7.3 7.3s3.3 7.3 7.3 7.3c4 0 7.3-3.3 7.3-7.3S26.2 34.2 22.2 34.2ZM22.2 45.3c-2.1 0-3.9-1.7-3.9-3.9 0-2.1 1.7-3.9 3.9-3.9s3.9 1.7 3.9 3.9C26.1 43.6 24.3 45.3 22.2 45.3Z" //NOSONAR
      className="b path8"
    />
    <path
      d="M87.1 88.9H16.9c-0.9 0-1.7-0.7-1.7-1.7 0-0.9 0.7-1.7 1.7-1.7h70.2c0.9 0 1.7 0.7 1.7 1.7C88.8 88.1 88 88.9 87.1 88.9Z"
      className="a path8"
    />
    <path
      d="M86.7 73.3H13.7c-0.9 0-1.7-0.7-1.7-1.7 0-0.9 0.7-1.7 1.7-1.7h73.1c0.9 0 1.7 0.7 1.7 1.7C88.4 72.6 87.6 73.3 86.7 73.3Z"
      className="a path8"
    />
    <path
      d="M86.7 80H13.7c-0.9 0-1.7-0.7-1.7-1.7 0-0.9 0.7-1.7 1.7-1.7h73.1c0.9 0 1.7 0.7 1.7 1.7C88.4 79.3 87.6 80 86.7 80Z"
      className="a path8"
    />
    <path
      d="M13.4 79.8c-0.9 0-1.7-0.7-1.7-1.7v-6.5c0-0.9 0.7-1.7 1.7-1.7s1.7 0.7 1.7 1.7v6.5C15.1 79 14.3 79.8 13.4 79.8Z"
      className="d path8"
    />
    <path
      d="M19.2 79.8c-0.9 0-1.7-0.7-1.7-1.7v-6.5c0-0.9 0.7-1.7 1.7-1.7s1.7 0.7 1.7 1.7v6.5C20.8 79 20.1 79.8 19.2 79.8Z"
      className="d path8"
    />
    <path
      d="M24.9 79.8c-0.9 0-1.7-0.7-1.7-1.7v-6.5c0-0.9 0.7-1.7 1.7-1.7s1.7 0.7 1.7 1.7v6.5C26.6 79 25.8 79.8 24.9 79.8Z"
      className="d path8"
    />
    <path
      d="M30.6 79.8c-0.9 0-1.7-0.7-1.7-1.7v-6.5c0-0.9 0.7-1.7 1.7-1.7s1.7 0.7 1.7 1.7v6.5C32.3 79 31.6 79.8 30.6 79.8Z"
      className="d path8"
    />
    <path
      d="M36.4 79.8c-0.9 0-1.7-0.7-1.7-1.7v-6.5c0-0.9 0.7-1.7 1.7-1.7 0.9 0 1.7 0.7 1.7 1.7v6.5C38.1 79 37.3 79.8 36.4 79.8Z"
      className="d path8"
    />
    <path
      d="M42.1 79.8c-0.9 0-1.7-0.7-1.7-1.7v-6.5c0-0.9 0.7-1.7 1.7-1.7 0.9 0 1.7 0.7 1.7 1.7v6.5C43.8 79 43 79.8 42.1 79.8Z"
      className="d path8"
    />
    <path
      d="M47.9 79.8c-0.9 0-1.7-0.7-1.7-1.7v-6.5c0-0.9 0.7-1.7 1.7-1.7 0.9 0 1.7 0.7 1.7 1.7v6.5C49.5 79 48.8 79.8 47.9 79.8Z"
      className="d path8"
    />
    <path
      d="M53.6 79.8c-0.9 0-1.7-0.7-1.7-1.7v-6.5c0-0.9 0.7-1.7 1.7-1.7 0.9 0 1.7 0.7 1.7 1.7v6.5C55.3 79 54.5 79.8 53.6 79.8Z"
      className="d path8"
    />
    <path
      d="M53.6 79.8c-0.9 0-1.7-0.7-1.7-1.7v-6.5c0-0.9 0.7-1.7 1.7-1.7 0.9 0 1.7 0.7 1.7 1.7v6.5C55.3 79 54.5 79.8 53.6 79.8Z"
      className="d path8"
    />
    <path
      d="M59.3 79.8c-0.9 0-1.7-0.7-1.7-1.7v-6.5c0-0.9 0.7-1.7 1.7-1.7s1.7 0.7 1.7 1.7v6.5C61 79 60.3 79.8 59.3 79.8Z"
      className="d path8"
    />
    <path
      d="M65.1 79.8c-0.9 0-1.7-0.7-1.7-1.7v-6.5c0-0.9 0.7-1.7 1.7-1.7 0.9 0 1.7 0.7 1.7 1.7v6.5C66.8 79 66 79.8 65.1 79.8Z"
      className="d path8"
    />
    <path
      d="M70.8 79.8c-0.9 0-1.7-0.7-1.7-1.7v-6.5c0-0.9 0.7-1.7 1.7-1.7 0.9 0 1.7 0.7 1.7 1.7v6.5C72.5 79 71.7 79.8 70.8 79.8Z"
      className="d path8"
    />
    <path
      d="M70.8 87.9c-0.9 0-1.7-0.7-1.7-1.7V57.3c0-0.9 0.7-1.7 1.7-1.7 0.9 0 1.7 0.7 1.7 1.7v28.9C72.5 87.2 71.7 87.9 70.8 87.9Z"
      className="b path8"
    />
    <path
      d="M87.1 88.3c-0.9 0-1.7-0.7-1.7-1.7V57.2c0-0.9 0.7-1.7 1.7-1.7 0.9 0 1.7 0.7 1.7 1.7v29.4C88.8 87.5 88 88.3 87.1 88.3Z"
      className="b path8"
    />
    <path
      d="M87.1 58.2H70.8c-0.9 0-1.7-0.7-1.7-1.7s0.7-1.7 1.7-1.7h16.3c0.9 0 1.7 0.7 1.7 1.7S88 58.2 87.1 58.2Z"
      className="e path8"
    />
    <path
      d="M70.8 79.8c-0.9 0-1.7-0.7-1.7-1.7v-6.5c0-0.9 0.7-1.7 1.7-1.7 0.9 0 1.7 0.7 1.7 1.7v6.5C72.5 79 71.7 79.8 70.8 79.8Z"
      className="d path8"
    />
    <path
      d="M76.6 79.8c-0.9 0-1.7-0.7-1.7-1.7v-6.5c0-0.9 0.7-1.7 1.7-1.7 0.9 0 1.7 0.7 1.7 1.7v6.5C78.2 79 77.5 79.8 76.6 79.8Z"
      className="d path8"
    />
    <path
      d="M82.3 79.8c-0.9 0-1.7-0.7-1.7-1.7v-6.5c0-0.9 0.7-1.7 1.7-1.7 0.9 0 1.7 0.7 1.7 1.7v6.5C84 79 83.2 79.8 82.3 79.8Z"
      className="d path8"
    />
    <path
      d="M70.8 58.2c-0.3 0-0.6-0.1-0.8-0.2 -0.8-0.5-1.1-1.5-0.6-2.3l8-14.2c0.5-0.8 1.5-1.1 2.3-0.6 0.8 0.5 1.1 1.5 0.6 2.3l-8 14.2C72 57.9 71.4 58.2 70.8 58.2Z"
      className="e path8"
    />
    <path
      d="M87 58.2c-0.6 0-1.2-0.3-1.5-0.9l-8-14.2c-0.5-0.8-0.2-1.8 0.6-2.3 0.8-0.5 1.8-0.2 2.3 0.6l8 14.2c0.5 0.8 0.2 1.8-0.6 2.3C87.5 58.1 87.2 58.2 87 58.2Z"
      className="e path8"
    />
    <path
      d="M83.1 52.9h-8.2c-0.9 0-1.7-0.7-1.7-1.7 0-0.9 0.7-1.7 1.7-1.7h8.2c0.9 0 1.7 0.7 1.7 1.7C84.8 52.1 84.1 52.9 83.1 52.9Z"
      className="c path8"
    />
    <path
      d="M84 52.6c-0.9 0-1.7-0.7-1.7-1.7v-6.3c0-0.9 0.7-1.7 1.7-1.7 0.9 0 1.7 0.7 1.7 1.7V51C85.6 51.9 84.9 52.6 84 52.6Z"
      className="d path8"
    />
    <path
      d="M73.4 52.6c-0.9 0-1.7-0.7-1.7-1.7v-6.3c0-0.9 0.7-1.7 1.7-1.7 0.9 0 1.7 0.7 1.7 1.7V51C75.1 51.9 74.4 52.6 73.4 52.6Z"
      className="d path8"
    />
    <path
      d="M75.5 69.4c-0.9 0-1.7-0.7-1.7-1.7v-7.1c0-0.9 0.7-1.7 1.7-1.7 0.9 0 1.7 0.7 1.7 1.7v7.1C77.2 68.6 76.4 69.4 75.5 69.4Z"
      className="d path8"
    />
    <path
      d="M82.2 69.4c-0.9 0-1.7-0.7-1.7-1.7v-7.1c0-0.9 0.7-1.7 1.7-1.7 0.9 0 1.7 0.7 1.7 1.7v7.1C83.8 68.6 83.1 69.4 82.2 69.4Z"
      className="d path8"
    />
    <path
      d="M27.3 78.7v7.9c0 0.9 0.7 1.7 1.7 1.7s1.7-0.7 1.7-1.7v-7.9H27.3Z"
      className="d path8"
    />
  </svg>
);

export default {
  'clothespin-icon': clotheSpinIcon,
  'microphone-icon': microphoneIcon,
  'cart-icon': cartIcon,
  'car-icon': carIcon,
  'pc-icon': pcIcon,
  'message-icon': messageIcon,
  'text-icon': textIcon,
  'leaf-icon': leafIcon,
  'factory-icon': factoryIcon,
};
