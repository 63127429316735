import React, { Component } from 'react';
import propTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import '../../styles/components/ModalRegs.scss';

class modal extends Component {
  constructor(props) {
    super(props);
    this.refModal = React.createRef();
    this.acceptButton = React.createRef();
    this.closeModal = this.closeModal.bind(this);
    this.updateState = this.updateState.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);

    this.state = {
      isAccept: false,
    };
  }

  componentDidUpdate() {
    this.buttonFocus();
  }

  onKeyDown() {
    return event => {
      if (event.key === 'Escape') {
        this.closeModal();
      }
    };
  }

  buttonFocus() {
    this.acceptButton.current && this.acceptButton.current.focus();
  }

  closeModal() {
    const $modal = this.refModal.current;
    const { history } = this.props;
    $modal.classList.remove('is-active');
    history.goBack();
  }

  updateState() {
    const { isAccept } = this.state;
    const { onClickBtn } = this.props;
    this.setState({ isAccept: true });
    onClickBtn(isAccept);
  }

  render() {
    const { children, title } = this.props;
    return (
      <div className="modal modal-regs is-active" ref={this.refModal} aria-live="assertive">
        <div className="modal-background" />
        <div className="modal-card" role="dialog" aria-modal="true" aria-labelledby="modal-title">
          <header className="modal-card-head">
            <p className="modal-card-title" id="modal-title">{title}</p>
            <button
              title="Pressing ESC, modal will be closed and and go back to the previous page."
              className="btn-modal"
              aria-label="close"
              type="button"
              onClick={this.closeModal}
            >
              <p className="modal-card-title has-text-right is-hidden-touch">
                Close
              </p>
            </button>

            <button
              className="delete"
              aria-label="close"
              type="button"
              onClick={this.closeModal}
            />
          </header>
          <section className="modal-card-body">{children}</section>
          <footer className="modal-card-foot">
            <button
              title="Pressing ENTER/SPACEBAR, Disclaimer modal will be closed."
              onKeyDown={this.onKeyDown()}
              ref={this.acceptButton}
              name="I ACCEPT"
              className="button"
              type="button"
              onClick={this.updateState}
            >
              <span>I ACCEPT</span>
            </button>
          </footer>
        </div>
      </div>
    );
  }
}

modal.propTypes = {
  history: propTypes.shape().isRequired,
  onClickBtn: propTypes.func.isRequired,
  children: propTypes.node.isRequired,
  title: propTypes.string.isRequired,
};

export default withRouter(modal);
