import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';

import './styles/components/css-module/componentes.scss';
import './styles/fonts/fonts.scss';
import API from './utils/apiPublic';
import BrokerForms from './pages/brokerInfo/BrokerForms';
import ClientAdvisory from './pages/about/ClientAdvisory';
import Disclosure from './components/disclosures/Disclosure';
import DisclosureHome from './pages/disclosure/index';
import DownloadRedirectPage from './pages/errorPages/DownloadRedirectPage';
import DrDirectory from './pages/drDirectory/DrDirectory';
import DrProfile from './pages/drProfile/DrProfile';
import Footer from './components/footer/Footer';
import Glossary from './pages/about/Glossary';
import Header from './components/header/Header';
import Headroom from './pages/brokerInfo/Headroom';
import Home from './components/home/Home';
import JpMorgan from './pages/contact/JpMorgan';
import Navigation from './components/navigation/Navigation';
import Page404 from './pages/errorPages/Page404';
import ScrollToTop from './ScrollToTop';
import WhyJpMorgan from './pages/about/WhyJpMorgan';

class AppPublic extends React.Component {
  constructor(props) {
    super(props);
    this.loaded = false
    this.state = { env: 'production' };
    API.getEnv().then(env => this.setState({ env }));
  }

  componentDidUpdate () {
    const pathname = window &&
      window.location &&
      window.location.pathname;

    if (!pathname) return
    
    this.sendGoogleAnalyticsData(pathname);
  }

  sendGoogleAnalyticsData (pathname) {
    // We can send custom data through this object
    let gtmData = {
      event: 'pageview',
      page: {
        url: pathname
      }
    };
    
    if (!this.loaded) {
      this.loaded = true;
      return;
    }

    window.dataLayer.push(gtmData);
  }

  render() {
    const { env } = this.state;
    return (
      <ScrollToTop>
        <Fragment>
          <div className="is-sticky-header">
            <Header env={env} />
            <Navigation env={env} />
          </div>
          <div className="container-sticky">
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/about/whyjpmorgan" component={WhyJpMorgan} />
              <Route
                exact
                path="/about/clientadvisory"
                component={ClientAdvisory}
              />
              <Route exact path="/about/glossary" component={Glossary} />

              <Route
                exact
                path="/dr/drdirectory/:section(drUniverse|corporateActions|bookClosures|fees)"
                component={DrDirectory}
              />

              <Route
                exact
                path="/drprofile/:cusip([0-9a-zA-Z]{1,9})"
                component={DrProfile}
              />

              <Route exact path="/broker/brokerforms" component={BrokerForms} />
              <Route exact path="/broker/headroom" component={Headroom} />

              <Route exact path="/contact/jpmorgan" component={JpMorgan} />
              <Route
                exact
                path="/disclosure/disclosures"
                component={DisclosureHome}
              />
              <Route
                exact
                path="/disclosure/privacy-policy"
                component={DisclosureHome}
              />
              <Route
                exact
                path="/disclosure/terms-conditions"
                component={DisclosureHome}
              />
              <Route
                exact
                path="/error/documentNotFound"
                component={DownloadRedirectPage}
              />
              <Route component={Page404} />
            </Switch>
          </div>
          <Footer env={env} />
          <Disclosure />
        </Fragment>
      </ScrollToTop>
    );
  }
}

export default AppPublic;
