import PropTypes from 'prop-types';
import React from 'react';
import { Redirect } from 'react-router-dom';

import { getUserInfo } from '../../utils/apiCalls';

import { prefix, subSections } from '../../utils/constants';

class NoCusip extends React.Component {
  constructor () {
    super();

    this.state = {
      cusip: null,
      fetching: true
    };
  }

  componentDidMount () {
    getUserInfo().then(data => this.setState({
      cusip: data.defaultDr || null,
      fetching: false
    }));
  }

  render () {
    const {
      cusip,
      fetching
    } = this.state;
    const defaultSection = Object.keys(subSections)[0];

    if (cusip) {
      const {
        match: {
          params: { section = defaultSection },
        },
      } = this.props;
      return <Redirect push to={`${prefix}/${section}/${cusip}`} />;
    }

    if (!cusip && !fetching) {
      return <Redirect push to={`${prefix}/noCusipError`} />;
    }

    return null;
  }
}
NoCusip.propTypes = {
  match: PropTypes.shape({}).isRequired,
};

export default NoCusip;
