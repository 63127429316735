import '../../../styles/components/PrivacyPolicy.scss';
import React from 'react';

const PrivacyPolicy = () => (
  <div className="jpmprivacypolicy">
    <h3>
      <a href="https://www.jpmorgan.com/privacy" target="_blank" rel="noreferrer">Privacy Policy</a>
    </h3>
  </div>
);

export default PrivacyPolicy;
