import PropTypes from 'prop-types';
import React from 'react';

import '../../../styles/IssuerServices/components/ProgressBar.scss';

const ProgressBar = props => {
  const { containerClass, percentage, width } = props;
  return (
    <div
      className={`progressbar progressbar__container ${containerClass}`}
      style={{ width }}
    >
      <div
        className="progressbar__progress"
        style={{ width: `${percentage}%` }}
      />
    </div>
  );
};

ProgressBar.defaultProps = {
  containerClass: '',
  width: '100%',
};

ProgressBar.propTypes = {
  containerClass: PropTypes.string,
  percentage: PropTypes.number.isRequired,
  width: PropTypes.string,
};

export default ProgressBar;
