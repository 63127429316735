import React, { Component } from 'react';
import propTypes from 'prop-types';

import {
  LoadMoreButton,
  NoDataAvailable,
  Table,
} from '@jpm-adr/pattern-library';

import { DECIMAL_DIGITS , URLbyDomain } from '../../utils/constants';
import { dateFullShortMonthNoTime, number } from '../../utils/format';
import { getFees } from '../../utils/api';

import '../../styles/components/Fees.scss';

class Fees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feesTable: [],
      loadMore: true,
      isLoading: true,
    };
    this.getFeesPagedData = this.getFeesPagedData.bind(this);
    this.feesTitles = [
      {
        id: 1,
        title: 'Announcement Date',
        customCSSClass: 'margin-table-fees',
      },
      {
        id: 2,
        title: 'Fee Type',
        customCSSClass: 'margin-table-fees',
      },
      {
        id: 3,
        title: 'Fee Amount (USD)',
        customCSSClass: 'margin-table-fees',
      },
      {
        id: 4,
        title: 'Record Date',
        customCSSClass: 'margin-table-fees',
      },
      {
        id: 5,
        title: 'Notices',
        customCSSClass: 'margin-table-fees',
      },
    ];

    this.getFeesPagedData({ currentPosition: 0, pageSize: 25 });
    this.LoadingWithState = (
      <LoadMoreButton
        className="dr-loading-button"
        fetchFunction={this.getFeesPagedData}
        position={0}
        pageSize={25}
      />
    );
  }

  getFeesPagedData({ currentPosition, pageSize }) {
   
    const { xid } = this.props;
    const { feesTable } = this.state;
    return getFees(xid, currentPosition, pageSize)
      .then(response => {
        const { items = [] } = response;
        const parsedItems = items.map(item => ({
          ...item,
          feeAmount: number(item.feeAmount, DECIMAL_DIGITS)
        }));

        this.setState({
          feesTable: feesTable.concat(parsedItems),
          loadMore: response.pagination.hasMore,
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          isLoading: false,
        });
      });
  }

  adapter(apiData) {
    const mapObject = (obj, field, customCSS) => {
      let newObj = {};
      if (field in obj) {
        if (field === 'notices') {
          const noticesData = obj[field];
          const url = `${URLbyDomain}/cms/document?cmsId=`;
          newObj.hasComponents = true;
          newObj.hasNotices = true;
          newObj.pdf = noticesData.pdf || '';
          newObj.xbrl = noticesData.xbrl || '';
          newObj.pdfLink = url + newObj.pdf;
          newObj.xbrlLink = url + newObj.xbrl;
        } else if (field === 'announcementDate' || field === 'recordDate') {
          newObj.data = dateFullShortMonthNoTime(obj[field]);
        } else {
          newObj.data = String(obj[field]);
        }
        newObj.customCSSClass = customCSS;
      } else {
        newObj.data = '--';
        newObj.customCSSClass = customCSS;
      }
      return newObj;
    };
    let newDataStructure = [];

    apiData.map(obj => {
      let announcement = mapObject(obj, 'announcementDate', 'fee-type-margin'),
        feeType = mapObject(obj, 'feeType', 'fee-type-margin'),
        feeAmount = mapObject(obj, 'feeAmount', 'fee-type-margin'),
        recordDate = mapObject(obj, 'recordDate', 'fee-type-margin'),
        notices = mapObject(obj, 'notices', 'fee-type-margin');
      newDataStructure.push([
        announcement,
        feeType,
        feeAmount,
        recordDate,
        notices,
      ]);
    });
    return newDataStructure;
  }

  render() {
    const { isLoading, feesTable, loadMore } = this.state;

    if (!isLoading && feesTable.length === 0) {
      return (
        <section className="Fees__Table">
          <NoDataAvailable
            messageTitle="No data available"
            customMessageTitleClass="Data__Not__Found-Title"
            customWrapperCSS="No__Data__Available-Custom-Wrapper"
            message="There is no data available for this specific program."
            messageStyles="Data__Not__Found-Message"
            wrapperClass="Data__Not__Found"
          />
        </section>
      );
    }

    const apiData = this.adapter(feesTable);

    return (
      <React.Fragment>
        <Table
          isLoading={isLoading}
          customStyles={{ height: '440px' }}
          values={apiData}
          tableId="fees-table"
          tableCustomCss="fees-table table-component table is-striped is-narrow is-fullwidth"
          tableTitles={this.feesTitles}
        />
        {isLoading || !loadMore ? null : this.LoadingWithState}
      </React.Fragment>
    );
  }
}

Fees.defaultProps = {
  xid: null,
};

Fees.propTypes = {
  xid: propTypes.oneOfType([propTypes.string, propTypes.number]),
};

export default Fees;
