import React from 'react';
import { components } from 'react-select';

const showPlaceHolder = ({ isMulti, menuIsOpen, placeholder }) => {
  if (!isMulti || !menuIsOpen) {
    return placeholder;
  }
  return false;
};

const Placeholder = props => {
  const { selectProps } = props

  return (
    components.Placeholder && (
      <components.Placeholder {...props}>
        {showPlaceHolder(selectProps)}
      </components.Placeholder>
    )
  );
};

export default Placeholder;
