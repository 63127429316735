import PropTypes from 'prop-types';
import React from 'react';

import TableAutoPopulate from './TableAutoPopulate';
import {
  SharedStateConsumer,
  SharedStateProvider,
} from './SharedState.context';

import {
  DatePickerComponent,
  DownloadButtonComponent,
  LoadMore,
  SimpleButton,
  SimpleCallbackProps,
  SingleSelectorFilter,
  YearAndMonthSelectorFilter,
} from '../filtersFactory/FiltersFactory';

import '../../../styles/IssuerServices/components/MonthlyReportsList.scss';

const TableLoader = props => {
  const {
    cusip,
    bottomFilters,
    filters,
    filtersClassName,
    getFocusableElement,
    getFirstFocusableElement,
    error,
    offset,
    configTable,
    disabledLoading,
    disabledErrorBoundary,
    disabledEmptyHandler,
    immediateRequest,
    requestApi,
    requestApiConfig,
    xid,
    headerInfo,
  } = props;
  // Define table props
  const tableProps = {
    error,
    configTable,
    disabledLoading,
    disabledErrorBoundary,
    disabledEmptyHandler,
    immediateRequest,
    requestApi,
    requestApiConfig,
    xid,
    headerInfo,
  };
  // Here are all the filter that can be showed according to
  //  the TableLoader "filter || bottomFilters" configuration
  const filtersAvailable = {
    singleSelect: function singleSelecteWrapper(filterProps) {
      return <SingleSelectorFilter {...filterProps} />;
    },
    loadMore: function loadMoreWrapper(filterProps) {
      const { sharedState } = filterProps;
      const { pagination = {} } = sharedState;
      const { hasMore, totalItems, offset, limit } = pagination;
      if (hasMore === false || !totalItems || offset + limit > totalItems)
        return '';
      return <LoadMore {...filterProps} {...sharedState} />;
    },
    datePicker: function datePickerWrapper(filterProps) {
      return <DatePickerComponent {...filterProps} />;
    },
    simpleCallbackProps: function simpleCallbackProps(filterProps) {
      return <SimpleCallbackProps {...filterProps} />;
    },
    selectYearAndMonth: function selectYearAndMonth(filterProps) {
      return <YearAndMonthSelectorFilter {...filterProps} />;
    },
    exportButton: function exportButton(filterProps) {
      return <DownloadButtonComponent {...filterProps} {...{ cusip }} />;
    },
    simpleButton: function simpleButton(filterProps) {
      return <SimpleButton {...filterProps} />;
    },
  };
  // Make focus in the next available record
  const makeFocus = ({ pagination }) =>
    (getFirstFocusableElement && getFirstFocusableElement()) ||
    (pagination.offset &&
      pagination.offset > 0 &&
      pagination.offset === offset &&
      getFocusableElement());

  const showTopFilters = sharedState => {
    if (filters.length > 0) {
      return (
        <div className={`filtersContainer ${filtersClassName || ''}`}>
          {filters.map(
            filter =>
              filtersAvailable[filter.id] &&
              filtersAvailable[filter.id]({
                ...filter,
                ...{ sharedState },
              })
          )}
        </div>
      );
    }
    return '';
  };

  const showBottomFilters = sharedState => {
    if (bottomFilters.length > 0) {
      return bottomFilters.map(
        filter =>
          filtersAvailable[filter.id] &&
          filtersAvailable[filter.id]({
            ...filter,
            ...props,
            ...{ sharedState },
          })
      );
    }
    return '';
  };
  // Table should be populated passed the configTable and the rest of the props to "TableAutoPopulate"
  const drawTable = () => {
    return (
      <SharedStateProvider>
        <SharedStateConsumer>
          {sharedState => {
            makeFocus(sharedState);
            return (
              <React.Fragment>
                {showTopFilters(sharedState)}
                <TableAutoPopulate
                  {...tableProps}
                  updateSharedState={sharedState.updateSharedState}
                />
                {showBottomFilters(sharedState)}
              </React.Fragment>
            );
          }}
        </SharedStateConsumer>
      </SharedStateProvider>
    );
  };

  return drawTable();
};

TableLoader.defaultProps = {
  filters: [],
  bottomFilters: [],
};

TableLoader.propTypes = {
  xid: PropTypes.number.isRequired,
  apiRequest: PropTypes.func.isRequired,
  configTable: PropTypes.objectOf.isRequired,
  filters: PropTypes.arrayOf(),
  bottomFilters: PropTypes.arrayOf(),
};

export default TableLoader;
