import React from 'react';

import CardsContainer from '../../components/CardsContainer/CardsContainer';
import sections from './contactData.json';

import '../../styles/components/JpMorgan.scss';

const JpMorgan = () => {
  return (
    <div className="contactjpmorgan core">
      <section className="columns section-header">
        <div className="column is-hidden-touch column-hidden side-margis margin-1">
          &nbsp;
        </div>
        <div className="column main-content-wrapper">
          <div className="margin-mobile">
            <h1 className="core__t3">Contact J.P. Morgan</h1>
          </div>
        </div>
        <div className="column is-hidden-touch column-hidden side-margis margin-2">
          &nbsp;
        </div>
      </section>
      <section className="columns margin-mobile">
        <div className="column is-hidden-touch column-hidden side-margis margin-1">
          &nbsp;
        </div>
        <div className="column main-content-wrapper">
          <div className="items">
            <div className="item">
              <div className="core__t5">Registered ADR Holders</div>
              {sections[0].map(section => (
                <CardsContainer {...section} />
              ))}
            </div>
            <div className="item">
              <div className="core__t5">Brokers & Institutions</div>
              {sections[1].map(section => (
                <CardsContainer {...section} />
              ))}
              <div className="core__t5">
                Client Services & Product Management
              </div>
              {sections[2].map(section => (
                <CardsContainer {...section} />
              ))}
            </div>
            <div className="item">
              <div className="core__t5">Corporate Clients</div>
              {sections[3].map(section => (
                <CardsContainer {...section} />
              ))}
            </div>
          </div>
        </div>
        <div className="column is-hidden-touch column-hidden side-margis margin-2">
          &nbsp;
        </div>
      </section>
    </div>
  );
};

export default JpMorgan;
