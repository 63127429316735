import AccessTokenService from './access-token-service';
import ApiBase from '../../utils/apiBase';
import { defaultApiAlertsURL } from './constants';

class ApiAlerts extends ApiBase {
  constructor() {
    if (!ApiAlerts.instance) {
      super();
      this.logOffCallBack = null;
      this.gettingDomain = false;
      this.callbacksQueue = [];
      this.domain = null;
      this.token = AccessTokenService.retrieve();
      ApiAlerts.instance = this;
    }
    return ApiAlerts.instance;
  }

  setDomain(data = {}) {
    const { jpmAlerts } = data;
    this.domain = jpmAlerts || defaultApiAlertsURL;
  }
}

export default new ApiAlerts();
