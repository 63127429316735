import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import IHSComponent from './components/baseComponent/BaseComponent';

class ScrollToTop extends IHSComponent {
  constructor() {
    super();
  }

  componentDidUpdate({ location }) {
    const { location: newLocation } = this.props;

    if (newLocation !== location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

ScrollToTop.propTypes = {
  children: PropTypes.element, // sometimes is null
  location: PropTypes.shape({}).isRequired,
};

export default withRouter(ScrollToTop);
