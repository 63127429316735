import React from 'react';
import { Link } from 'react-router-dom';

import '../../styles/components/Disclosure.scss';
import Copyright from './Copyright'

const Disclosure = () => (
  <div className="disclosure-background" role="region">
    <div className="disclosure columns is-multiline">
      <div className="column is-hidden-touch" />
      <div className="main-content-wrapper">
        <ul className="column is-two-fifths-desktop is-12-mobile is-centered">
          <li>
            <Link
              to="/disclosure/disclosures"
              id="investor-disclosure-disclosure"
            >
              Disclosures
            </Link>
          </li>
          <li className="separator">|</li>
          <li>
            <Link to="/disclosure/privacy-policy" id="privacy-policy-disclosure">
              Privacy Policy
            </Link>
          </li>
          <li className="separator">|</li>
          <li>
            <Link
              to="/disclosure/terms-conditions"
              id="terms-conditions-disclosure"
            >
              Terms & Conditions
            </Link>
          </li>
          <Copyright />
        </ul>
        <div className="column is-two-fifths-desktop is-8-mobile is-offset-2-mobile">
          <div className="disclosure-statement">
            The data on the adr.com website is delayed by at least 15 minutes.
            Benchmark prices are indicative only and provided for informational
            purposes. All information is subject to market conditions and may
            change at any time and without notice. J.P. Morgan does not undertake
            to update any prices or information provided herein. Information is
            not intended as a recommendation nor an offer or solicitation for the
            purchase or sale of any financial instrument.
          </div>
        </div>
      </div>
      <div className="column is-hidden-touch" />
    </div>
  </div>
);
export default Disclosure;
