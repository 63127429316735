export default [
  {
    label: 'CORPORATE ACTION TYPE',
    name: 'type',
    type: 'select',
    filter: 'corporateActionEvents',
    defaultValue: '',
    options: [],
    placeholder: 'All Corporate Action Types',
    isMulti: true,
    showClearLabel: true,
  },
  {
    label: 'ANNOUNCEMENT DATE RANGE',
    type: 'datePicker',
    name: 'datePicker',
    defaultValue: '',
    showClearLabel: true,
  },
  {
    label: 'COUNTRY',
    name: 'country',
    type: 'select',
    filter: 'countries',
    defaultValue: '',
    options: [],
    placeholder: 'All Countries',
    isMulti: true,
    showClearLabel: true,
  },
  {
    label: 'SECTOR',
    name: 'sector',
    type: 'select',
    filter: 'sectors',
    defaultValue: '',
    options: [],
    placeholder: 'All Sectors',
    isMulti: true,
    showClearLabel: true,
  },
  {
    name: 'q',
    defaultValue: ''
  },
  {
    name: 'startDate',
    defaultValue: ''
  },
  {
    name: 'endDate',
    defaultValue: ''
  },
];
