import '../../styles/components/ClientAdvisory.scss';

import React, { Fragment } from 'react';
import propTypes from 'prop-types';
import { Button, FileButtons } from '@jpm-adr/pattern-library';
import { URLbyDomain } from '../../utils/constants';

import Data from './ClientAdvisoryData.json';

const ClientAdvisory = () => {
  const WrapperJPM = props => {
    const { children, className } = props;
    return (
      <section className={`${className} columns wrapperJPM`}>
        <div className="column side-margis" />
        <div className="column main-content-wrapper">{children}</div>{' '}
        <div className="column side-margis" />
      </section>
    );
  };
  WrapperJPM.propTypes = {
    children: propTypes.node.isRequired,
    className: propTypes.string.isRequired,
  };

  const { pictureHeader, cards } = Data;
  const pictureHeaderRender = (
    <WrapperJPM className="clientadvisory__header">
      <h1 className="clientadvisory__header__title">{pictureHeader.title}</h1>
      <h2 className="clientadvisory__header__subtitle">
        {pictureHeader.subtitle}
      </h2>
    </WrapperJPM>
  );

  const abstractRender = ()=> {


    return(
      <WrapperJPM className="clientadvisory__abstract">
        <div className="clientadvisory__abstract__content">
          A primary goal of our team is to connect you to the resources available within the bank.
          <br />
          {`J.P. Morgan is a global leader in financial services, offering solutions to the world's most important corporations
          , governments and institutions in more than 100 countries.`}
        </div>
      </WrapperJPM>
  )};

  const cardsRender = (
    <WrapperJPM className="clientadvisory__cards columns">
      <div className="clientadvisory__cards__container">
        {cards.map(card => (
          <div className="clientadvisory__cards__card">
            <div className="card__title">{card.title}</div>
            <div className="card__description">{card.description}</div>
            {card.button && (
              <a
                href={card.button.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button title={card.button.text} border={2} style={card.button.style ? card.button.style : ''}>
                  {card.button.text}
                </Button>
              </a>
            )}
            {card.buttonFile && (
              <Fragment>
                <FileButtons
                  advisoryButton
                  buttonsInfo={{
                    pdf: true,
                    pdfLink:`${URLbyDomain}${card.buttonFile.link}`,
                    title: 'U.S. T+1: Impact to DR Issuers and Investors',
                    xbrl: '',
                  }}
                  buttonText='U.S. T+1: Impact to DR Issuers and Investors'
                />
              </Fragment>
            )}
          </div>
        ))}
      </div>
    </WrapperJPM>
  );
  return (
    <div classNama="clientadvisory">
      {pictureHeaderRender}
      {abstractRender()}
      {cardsRender}
    </div>
  );
};

export default ClientAdvisory;
