/* eslint-disable sort-imports-es6-autofix/sort-imports-es6 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../../styles/components/DrUniverse.scss';
import { getDrUniverse, getExportData } from '../../utils/api';
import drUniverseFilters from './DrUniverseFilters';
import exportData from '../../utils/exportData';
import {
  labelSponsorshipDropdown,
  labelListingsDropdown,
  optionsListingsDropdown,
  optionsSponsorshipDropdown,
  stringForCancelAPI,
} from '../../utils/constants';
import DrUniverseTableAdapter, {
  createDrUniverseTitles,
  SORT_OPTIONS_MAP,
} from './DrUniverseTableAdapter';
import { reduceToValues } from '../../utils/urlMethods';
import TableViewPageContainer from '../../components/common/TableViewPage/TableViewPageContainer';
/* eslint-enable sort-imports-es6-autofix/sort-imports-es6 */

export default class DrUniverse extends Component {
  constructor () {
    super();
    this.fetchParameters = {
      filters: null,
      limit: 25,
      offset: 0,
      sortBy: '',
      sortOrder: 'asc'
    };
    this.cancelToken = [];
    this.export = this.export.bind(this);
    this.extraExportFunction = null;
    this.fetchFilteredData = this.fetchFilteredData.bind(this);
    this.setExtraExportFunction = this.setExtraExportFunction.bind(this);
    this.tableRef = React.createRef();
  }

  setExtraExportFunction (extraFunction) {
    this.extraExportFunction = extraFunction;
  }

  handleExportClickOption (isFullDownload = false) {
    const infoFromChild =
      this.extraExportFunction && this.extraExportFunction();
    const { data, filtersValues, offset,
      sortBy,
      sortOrder } = infoFromChild;
    delete infoFromChild.data;
    let args
    if(isFullDownload) {
      args = {
        limit: null,
        offset,
        sortBy,
        sortOrder,
        full: isFullDownload
      }
    } else {
      const params = {...filtersValues}
      if (
        params.listings &&
        params.listings ===
          optionsListingsDropdown[labelListingsDropdown.All].length + 1
      ) {
        delete params.listings;
      }

      if (
        params.sponsorship &&
        params.sponsorship ===
          optionsSponsorshipDropdown[labelSponsorshipDropdown.All].length + 1
      ) {
        delete params.sponsorship;
      }

      args = {
        ...reduceToValues(params),
        limit: data.length
      };
    }

    return getExportData('drUniverse', args).then(response => {
        const fileName = 'dr_universe.xlsx';
        exportData(fileName, response.data);
    });
  }

  fetchFilteredData (filters) {
    const { q, depositary, listings, sponsorship, ...otherFilters } = filters;
    let params = {
      q: q,
      ...otherFilters,
    };

    if (depositary) {
      params.depositary = depositary.reduce((acc, item) => {
        if (item.label !== 'Other') {
          acc.push(item);
        } else {
          const values = item.value.split('|');
          values.map(item => acc.push({ label: 'Other', value: item }));
        }
        return acc;
      }, []);
    }

    if (listings) {
      if (optionsListingsDropdown[listings.label]) {
        params = { ...params };
      } else {
        params = { ...params, listings };
      }
    }

    if (sponsorship) {
      if (optionsSponsorshipDropdown[sponsorship.label]) {
        params = { ...params };
      } else {
        params = { ...params, sponsorship };
      }
    }

    if (this.cancelToken && this.cancelToken[0]) {
      this.cancelToken[0].cancel(stringForCancelAPI);
    }
    params.cancelToken = this.cancelToken;
    return getDrUniverse(params).then(response => {
      const { items = [], pagination } = response;
      const parameters = {
        data: DrUniverseTableAdapter(items),
        pagination,
      };
      this.fetchParameters = Object.assign(this.fetchParameters, {
        ...otherFilters,
      });

      return parameters;
    });
  }

  export () {
    if (this.tableRef.current) {
      this.tableRef.current.handleExport();
    }
  }

  canResetFilters (filtersValues) {
    return (
      (filtersValues.region && filtersValues.region.length > 0) ||
      (filtersValues.sector && filtersValues.sector.length > 0) ||
      (filtersValues.exchange && filtersValues.exchange.length > 0) ||
      (filtersValues.country && filtersValues.country.length > 0) ||
      (filtersValues.depositary && filtersValues.depositary.length > 0) ||
      (filtersValues.listings &&
        filtersValues.listings.value !==
          optionsListingsDropdown[labelListingsDropdown.All].length + 1) ||
      (filtersValues.sponsorship &&
        filtersValues.sponsorship.value !==
          optionsSponsorshipDropdown[labelSponsorshipDropdown.All].length + 1)
    );
  }

  render () {
    const { customButton } = this.props
    return (
      <TableViewPageContainer
        canResetFunc={this.canResetFilters}
        classModifier="dr-universe-table-view"
        customExportButton={customButton}
        defaultSortBy={SORT_OPTIONS_MAP.name}
        defaultSortOrder="asc"
        extraExportFunction={this.setExtraExportFunction}
        fields={drUniverseFilters}
        headerColumns={createDrUniverseTitles}
        heading="DR UNIVERSE"
        onFetchData={this.fetchFilteredData}
        ref={this.tableRef}
        searchInputPlaceholder="Filter Results (Name, Ticker, or CUSIP)"
      />
    );
  }
}

DrUniverse.defaultProps = {
  customButton: null
}

DrUniverse.propTypes = {
  customButton: PropTypes.func
};
