import PropTypes from 'prop-types';
import React from 'react';

import '../../../styles/IssuerServices/pages/Ownership.scss';

import TopFiftyBar from '../../components/TopFiftyBar/TopFiftyBar';
import TopFiftyTable from './TopFiftyTable';

const Ownership = props => {
  const { cusip } = props;
  return (
    <div className="ownership ownership__content">
      <h2 className="owenership ownership__title">
        Top 50 Institutional Holders
      </h2>
      <TopFiftyBar {...props} immediateRequest={cusip} />
      <TopFiftyTable {...props} immediateRequest={cusip} />
    </div>
  );
};

Ownership.propTypes = {
  cusip: PropTypes.string.isRequired,
  match: PropTypes.shape({}).isRequired,
};

export default Ownership;
