import PropTypes from 'prop-types';
import React from 'react';

import '../../../../styles/IssuerServices/components/FieldsFactory.scss';

const setForClass = 'setFor';
const setForId = setForClass;

const ErrorMessage = props => {
  const { message, hasError } = props;
  return hasError ? <p className="errorMessage">{message}</p> : '';
};

ErrorMessage.propTypes = {
  hasError: PropTypes.bool,
  message: PropTypes.string.isRequired,
};
ErrorMessage.defaultProps = {
  hasError: false,
};

export const SetFor = props => {
  const {
    onChange,
    labelText,
    placeholder,
    type,
    hasError,
    errorMessage,
    showApiError,
    value,
  } = props;
  return (
    <div className={setForClass}>
      <div className="setForHeader">
        <p className="setFor__title">Set Alerts For</p>
        <ErrorMessage
          {...{
            hasError: showApiError,
            message:
              "Either something went wrong or the data doesn't exist. Try again.",
          }}
        />
      </div>
      <div className="setFor__container">
        <label aria-controls={setForId} htmlFor={setForId}>
          <p>{labelText}</p>
          <input
            id={type}
            type="email"
            placeholder={placeholder}
            onChange={onChange}
            value={value}
          />
        </label>
        <ErrorMessage {...{ hasError, message: errorMessage }} />
      </div>
    </div>
  );
};

SetFor.propTypes = {
  onChange: PropTypes.func,
  labelText: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  hasError: PropTypes.string,
  errorMessage: PropTypes.string,
};
SetFor.defaultProps = {
  onChange: () => '',
  labelText: '',
  placeholder: '',
  type: '',
  hasError: '',
  errorMessage: '',
};

export const Separator = props => {
  const { className = '', text = '', customElement = '' } = props;
  return (
    <div className={`separatorFieldsForm ${className}`}>
      {text ? <p>{text}</p> : ''}
      {customElement}
    </div>
  );
};

Separator.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  customElement: PropTypes.string,
};
Separator.defaultProps = {
  className: '',
  text: '',
  customElement: '',
};

const toggleCheckOnEnter = (e, onChange) => {
  if (e.key === 'Enter') {
    e.target.checked = !e.target.checked;
    onChange(e);
  }
};
const CheckBox = props => {
  const { onChange, id } = props;
  return (
    <label htmlFor={id}>
      <input
        id={id}
        className="checkboxField"
        type="checkbox"
        {...{ onChange }}
        onKeyPress={e => {
          toggleCheckOnEnter(e, onChange);
        }}
        {...props}
      />
    </label>
  );
};

export const CheckBoxAndInputAndTextAndText = props => {
  const {
    checkboxProps,
    hasError,
    hasCheckedBox,
    errorMessage,
    className,
    firstText,
    secondText,
    inputProps,
    labelText,
    disabledInput,
    type,
  } = props;
  const resetValue = hasCheckedBox ? '' : 'value';
  const inputValue = { [resetValue]: '' };
  return (
    <div className={`CheckBoxAndInputAndTextAndText ${className}`}>
      <div className="CheckBoxAndInputAndTextAndText__container">
        <CheckBox
          {...checkboxProps}
          id={`checkbox-${type}`}
          checked={hasCheckedBox}
        />
        {disabledInput ? (
          <p className="labelText">{labelText}</p>
        ) : (
          <label htmlFor={labelText}>
            <p className="labelText">{labelText}</p>
            <input
              maxLength="20"
              disabled={!hasCheckedBox}
              id={labelText}
              className="inputText"
              {...inputValue}
              {...inputProps}
            />
          </label>
        )}
        {[firstText, secondText].map(
          text => (text ? <p className="textAfterInput">{text}</p> : '')
        )}
      </div>
      <ErrorMessage {...{ hasError, message: errorMessage }} />
    </div>
  );
};
