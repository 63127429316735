import Axios from 'axios';
import get from 'lodash/get';
import * as AppConstants from '../../utils/appConstants';

const AxiosWrapper = Axios.create();

AxiosWrapper.interceptors.response.use(
  response => {
    const showDebugInfo = localStorage.getItem(
      AppConstants.KEY_LOCAL_STORAGE_SHOW_DEBUG_INFO
    );

    if (!response || !showDebugInfo || showDebugInfo !== 'true') {
      return response;
    }

    if (get(response, 'data.replace')) {
      // Remove Debug.url statement from API response (Debug URL set by Caddy response)
      response.data = response.data.replace(
        /Debug.url = ".*;/,
        ''
      );

      // API responses with debug data contain scripts that must be executed (showdebuginfo)
      const now = Date.now();
      // eslint-disable-next-line no-eval
      eval(`window[${now}] = ${response.data}`); // NOSONAR [disable sonar; Peding to refactor for avoid the eval]
      response.data = window[now];
      delete window[now];
    }
    return response;
  },
  error => Promise.reject(error)
);

AxiosWrapper.CancelToken = Axios.CancelToken;
AxiosWrapper.isCancel = Axios.isCancel;

export default AxiosWrapper;
