import React from 'react';
import propTypes from 'prop-types';
import {
  NoDataAvailable,
  TabularData,
  withLoadingText,
} from '@jpm-adr/pattern-library';
import { executeDataRequest } from '../../utils/dataRequestMethods';
import { withApiRequest } from '../../utils/apiWithHOC';
import { withErrorBoundary } from '../../utils/errorBoundary';

import '../../styles/components/Custodian.scss';

import Address from '../custodian/Address';
import Contact from '../custodian/Contact';

const CustodianData = props => {
  const { isLoading, data } = props;
  const {
    name,
    location,
    bicCode,
    accountNumber,
    safeKeepingAccount,
    mainContact,
    alternativeContact,
    address,
  } = data || {};

  const list = [
    {
        id: 1, 
        title: 'Location', 
        value: location 
    },
    {
        id: 2, 
        title: 'BIC Code', 
        value: bicCode 
    },
    {
        id: 3, 
        title: 'Account Number', 
        value: accountNumber
    },
    {
        id: 4, 
        title: 'Safe Keeping Account', 
        value: safeKeepingAccount
    },
    {
      id: 5,
      title: 'Main Contact',
      value: mainContact ? <Contact hasPhoneAndFax data={mainContact} /> : '--',
    },
    {
      id: 6,
      title: 'Alternate Contact',
      value: alternativeContact ? <Contact data={alternativeContact} /> : '--',
    },
    {
      id: 7,
      title: 'Address',
      value: address ? <Address data={address} /> : '--',
    },
  ];

  const Content = () => {
    if (!data || (data && Object.keys(data).length === 0)) {
      return (
        <NoDataAvailable
          messageTitle="No data available"
          customMessageTitleClass="Data__Not__Found-Title"
          customWrapperCSS="No__Data__Available-Custom-Wrapper"
          message="There is no data available for this specific program."
          messageStyles="Data__Not__Found-Message"
          wrapperClass="Data__Not__Found"
        />
      );
    }

    return (
      <React.Fragment>
        <h4 className="custodian-subtitle column is-12">{name || '--'}</h4>
        <div className="column">
          <TabularData data={list} />
        </div>
      </React.Fragment>
    );
  };

  const ContentWithLoadingData = withLoadingText(Content);

  return (
    <ContentWithLoadingData
      isLoading={isLoading}
      customStyles={{
        height: '440px',
        marginBottom: '20px',
        borderWidth: '0px',
      }}
    />
  );
};

CustodianData.defaultProps = {
  isLoading: false,
  data: {
    name: '--',
    location: '--',
    bicCode: '--',
    accountNumber: '--',
    safeKeepingAccount: '--',
    mainContact: {
      name: '--',
    },
    alternativeContact: {
      name: '--',
    },
    address: {
      line1: '--',
    },
  },
};

CustodianData.propTypes = {
  isLoading: propTypes.bool,
  data: propTypes.shape({
    name: propTypes.string,
    location: propTypes.string,
    bicCode: propTypes.string,
    accountNumber: propTypes.string,
    safeKeepingAccount: propTypes.string,
    mainContact: propTypes.shape({
      name: propTypes.string,
    }),
    alternativeContact: propTypes.shape({
      name: propTypes.string,
    }),
    address: propTypes.shape({
      line1: propTypes.string,
    }),
  }),
};

export default withErrorBoundary(
  withApiRequest(
    CustodianData,
    ({ cusip }) =>
      cusip ? executeDataRequest(`dr/custodian/${cusip}`, 'GET') : null
  )
);
