const BOOK_STATUS_OPTIONS = [
  {
    label: 'Closed',
    value: 1,
    'aria-label': 'Closed',
  },
];

export default [
  {
    label: 'Current Book Status',
    name: 'bookStatus',
    type: 'select',
    filter: 'bookStatuses',
    defaultValue: BOOK_STATUS_OPTIONS[0],
    placeholder: 'Status',
  },
  {
    label: 'Books Closed Reason',
    name: 'bookClosedReason',
    type: 'select',
    filter: 'bookClosedReasons',
    defaultValue: [],
    placeholder: 'All Books Closed Reason',
    isMulti: true,
    showClearLabel: true,
  },
  {
    label: 'Books Closed Type',
    name: 'bookClosedType',
    type: 'select',
    filter: 'bookClosedTypes',
    defaultValue: [],
    placeholder: 'All Books Closed Types',
    isMulti: true,
    showClearLabel: true,
  },
  {
    label: 'Country',
    name: 'country',
    type: 'select',
    filter: 'countries',
    defaultValue: [],
    placeholder: 'All Countries',
    isMulti: true,
    showClearLabel: true,
  },
  {
    name: 'q',
    defaultValue: ''
  }
];
