import React, { Component, Fragment } from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { debounce } from 'lodash';

import {
  NoResultsSearch,
  SearchBox,
  SearchResults,
} from '@jpm-adr/pattern-library';

import '../../styles/components/Header.scss';
import ApiConsumer from '../apiConsumer/ApiConsumer';
import Login from '../login/Login';
import Logo from '../../asssets/jpm-logo-white.svg';

const MobileSearchIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#FFFFFF"
  >
    <path
      d="M16.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" //NOSONAR
    />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);

export const closeIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="white"
  >
    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);

class Header extends Component {
  constructor() {
    super();
    this.searchInput = null;
    this.mobileSearchInput = null;
    this.mobileSearchButton = null;
    this.setSearchInputRef = this.setSearchInputRef.bind(this);
    this.setMobileSearchInputRef = this.setMobileSearchInputRef.bind(this);
    this.setMobileSearchButtonRef = this.setMobileSearchButtonRef.bind(this);
    this.clearAndFocus = this.clearAndFocus.bind(this);
    this.clear = this.clear.bind(this);
    this.displayAndFocus = this.displayAndFocus.bind(this);
    this.displayAndFocusMobile = this.displayAndFocusMobile.bind(this);
    this.handleChange = debounce(this.handleChange, 250);
    this.handleDocumentClick = this.handleDocumentClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.state = {
      querySearch: '',
      focus: false,
    };
  }
  componentDidMount() {
    const input = document.getElementsByClassName('search-input');
    document.addEventListener('click', this.handleDocumentClick);
    document.addEventListener('keydown', this.handleKeyDown);
    input[0].addEventListener('focus', this.handleFocus);
  }

  setSearchInputRef(element) {
    this.searchInput = element;
  }

  setMobileSearchInputRef(element) {
    this.mobileSearchInput = element;
  }

  setMobileSearchButtonRef(element) {
    this.mobileSearchButton = element;
  }

  clearAndFocus() {
    const isMobile =
      this.mobileSearchInput &&
      this.mobileSearchInput.parentElement.className.match(/animated-input/g);
    this.clear();

    if (isMobile) {
      this.mobileSearchInput.focus();
    } else {
      this.searchInput.focus();
    }
  }

  clear() {
    this.setState({ querySearch: '' });
  }

  animateInputField(nodeToAnimate, inputField) {
    if (nodeToAnimate) {
      if (nodeToAnimate.className.match(/animated-input/g)) {
        nodeToAnimate.classList.remove('animated-input');
      } else {
        nodeToAnimate.classList.add('animated-input');
        inputField.focus();
      }
    }
  }

  displayAndFocus() {
    this.animateInputField(
      this.searchInput.parentElement.parentElement,
      this.searchInput
    );
  }

  displayAndFocusMobile() {
    const element = this.mobileSearchInput;
    const parent = element.parentElement;
    this.clear();
    this.mobileSearchButton.classList.toggle('is-hidden-mobile');
    this.animateInputField(parent, element);
  }

  handleDocumentClick() {
    const { querySearch, focus } = this.state;
    if (focus === true) {
      this.setState({ focus: false });
    }
    if (querySearch !== '' && focus === false) {
      const results = document.getElementsByClassName('results');
      results[0].classList.add('hidden');
    }
  }
  handleKeyDown(e) {
    if (e.which === 27) {
      const { querySearch } = this.state;
      if (querySearch !== '') {
        const results = document.getElementsByClassName('results');
        results[0].classList.add('hidden');
      }
    }
  }
  handleFocus() {
    const { querySearch } = this.state;
    if (querySearch !== '') {
      this.setState({ focus: true });
      const results = document.getElementsByClassName('results');
      if (results !== null && results[0]) {
        results[0].classList.remove('hidden');
      }
    }
  }

  handleChange(query) {
    this.setState({ querySearch: query });
  }

  render() {
    const { querySearch } = this.state;
    const { env } = this.props;
    return (
      <Fragment>
        <header>
          <div className="header columns navigation-column">
            <div className="column is-inline-flex-touch center">
              <div
                className="burguer is-hidden-desktop"
                data-target="navbarMenuMarkit"
              />
              <h2 className="is-hidden-mobile">Depositary Receipts</h2>
            </div>
            <div className="column has-text-centered logo-center">
              <Link to="/">
                <img src={Logo} alt="Logo" />
              </Link>
            </div>
            <div className="Search column search-center">
              <button
                type="button"
                onClick={this.displayAndFocusMobile}
                className="header-mobile-search-button is-hidden-tablet"
                ref={this.setMobileSearchButtonRef}
                aria-label="Search Button"
              >
                {MobileSearchIcon}
              </button>
              <SearchBox
                classModifier="header-desktop-search is-hidden-mobile"
                placeholder="Find DR (Name, Ticker or CUSIP)"
                clickable
                colorIcon="white"
                onClick={this.displayAndFocus}
                onChange={query => {
                  this.handleChange(query);
                }}
                onSubmit={query => {
                    this.setState({ querySearch: query });
                }}
                inputRef={this.setSearchInputRef}
                styles="search-input is-hidden-mobile"
                input={querySearch}
              />
            </div>
            <Login env={env} />
            <ApiConsumer query={querySearch}>
              {({ data }) => {
              if (Object.keys(data).length === 0 && querySearch !== '') {
                  return (
                    <NoResultsSearch
                      styles="results"
                      onClick={this.clearAndFocus}
                    />
                  );
              }
              if (querySearch === '') {
                  return '';
              }
              return (
                <div>
                  <SearchResults
                    data={data}
                    path="/drprofile/"
                    styles="results"
                    stringBold={`${querySearch}`}
                    clear={this.displayAndFocusMobile}
                  />
                </div>
              );
              }}
            </ApiConsumer>
          </div>
          <SearchBox
            classModifier="header-mobile-search is-hidden-tablet"
            placeholder="Find DR (Name, Ticker or CUSIP)"
            clickable
            colorIcon="white"
            customIcon={closeIcon}
            onClick={this.displayAndFocusMobile}
            onChange={query => {
                this.handleChange(query);
            }}
            onSubmit={query => {
                this.setState({ querySearch: query });
            }}
            inputRef={this.setMobileSearchInputRef}
            styles="input-mobile is-hidden-tablet"
            input={querySearch}
          />
        </header>
      </Fragment>
    );
  }
}

Header.propTypes = {
  env: propTypes.string.isRequired,
};

export default Header;
