import { numberComma, numberMagnitude } from '../../utils/format';

export default function adapter(drUniverse) {
  return drUniverse.map(obj => {
    let name = mapObjectHasLink(
        obj,
        'name',
        'books-name ellipsis regular is-bold'
      ),
      ticker = mapObject(
        obj,
        'ticker',
        'DR-Universe-Table__Ticker is-hidden-touch is-bold'
      ),
      exchange = mapObject(
        obj,
        'exchange',
        'DrUniverse__exchange__Content is-hidden-mobile'
      ),
      country = mapObject(
        obj,
        'country',
        'DrUniverse__country__Content is-hidden-mobile'
      ),
      sector = mapObject(
        obj,
        'sector',
        'DrUniverse__sector__Content is-hidden-mobile'
      ),
      tradingValue = mapObject(
        obj,
        'tradingValue',
        'DrUniverse__tradingValue__Content Trading-Value__Values'
      ),
      depositary = mapObject(
        obj,
        'depositary',
        'DrUniverse__depositary__Content is-hidden-touch'
      ),
      globalMarketCap = mapObject(
        obj,
        'globalMarketCap',
        'Global-Market-Cap is-hidden-mobile'
      ),
      price = mapObject(
        obj,
        'price',
        'DrUniverse__price__Content is-hidden-mobile'
      );

    return [
      name,
      ticker,
      exchange,
      country,
      sector,
      tradingValue,
      depositary,
      globalMarketCap,
      price
    ];
  });
}

function mapObject(obj, field, customCSS) {
  let newObj = {};

  if (field in obj) {
    if (
      field === 'tradingValue' ||
      field === 'globalMarketCap' ||
      field === 'price'
    ) {
      newObj.data = numberMagnitude(obj[field], 2);
    } else {
      newObj.data = String(obj[field]);
    }
    newObj.customCSSClass = customCSS;
  } else {
    newObj.data = '--';
    newObj.customCSSClass = customCSS;
  }
  return newObj;
}

function mapObjectHasLink(obj, field, customCSS) {
  let newObj = {};

  if (field in obj) {
    newObj.data = String(obj[field]);
    newObj.hasLink = true;
    newObj.contentLink = '/drprofile/' + obj.cusip;
    newObj.customCSSClass = customCSS;
  }
  return newObj;
}

export const POSSIBLE_SORT_VALUES = {
  asc: 'asc', // Ascending
  desc: 'desc', // Descending
  unsorted: 'unsorted',
};

export const SORT_OPTIONS_MAP = {
  name: 'name',
  ticker: 'ticker',
  exchange: 'exchange',
  country: 'country',
  sector: 'sector',
  tradingValue: 'tradingValue',
  depositary: 'depositary',
  globalMarketCap: 'globalMarketCap',
  price: 'price',
  drOutstanding: 'drOutstanding',
};

export const getSortDirectionForName = (
  name,
  currentlySortedName,
  sortedDirection
) => {
  if (name === currentlySortedName) {
    return POSSIBLE_SORT_VALUES[sortedDirection];
  }

  return POSSIBLE_SORT_VALUES.unsorted;
};

export const createDrUniverseTitles = (
  currentlySortedName,
  sortedDirection,
  handleSort
) => {
  let index = 1;

  const createHeader = (title, customCSSClass, sortName, width) => {
    index++;

    return {
      id: index,
      title,
      customCSSClass,
      sortable: true,
      handleSort,
      sortName,
      sortDirection: getSortDirectionForName(
        sortName,
        currentlySortedName,
        sortedDirection
      ),
      width,
      minWidth: width,
    };
  };

  return [
    createHeader('DR Name', 'DR-Universe-Table-Title', SORT_OPTIONS_MAP.name),
    createHeader(
      'Ticker',
      'DR-Universe-Table__Ticker is-hidden-touch',
      SORT_OPTIONS_MAP.ticker,
      '110px'
    ),
    createHeader(
      'Exchange',
      'DR-Universe-Table__Exchange is-hidden-mobile',
      SORT_OPTIONS_MAP.exchange,
      '110px'
    ),
    createHeader(
      'Country',
      'DR-Universe-Table__Country is-hidden-mobile',
      SORT_OPTIONS_MAP.country,
      '145px'
    ),
    createHeader(
      'Sector',
      'DR-Universe-Table__Sector is-hidden-mobile',
      SORT_OPTIONS_MAP.sector,
      '195px'
    ),
    createHeader(
      'Trading Value (USD)',
      'DR-Universe-Table__Trading-Value table-title-align-right',
      SORT_OPTIONS_MAP.tradingValue,
      '119px'
    ),
    createHeader(
      'Depositary',
      'DR-Universe-Table__Depositary is-hidden-touch',
      SORT_OPTIONS_MAP.depositary,
      '115px'
    ),
    createHeader(
      'Company Market Cap (USD)',
      'DR-Universe-Table__G-M-C is-hidden-mobile table-title-align-right',
      SORT_OPTIONS_MAP.globalMarketCap,
      '160px'
    ),
    createHeader(
      'Price (USD)',
      'DR-Universe-Table__Price is-hidden-mobile',
      SORT_OPTIONS_MAP.price,
      '80px'
    )
  ];
};
