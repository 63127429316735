import React, { Component } from 'react';
import propTypes from 'prop-types';

import '../../styles/components/Navigation.scss';
import Menu from '../menu/Menu';

import DataMenu from '../common/DataMenu';

class Navigation extends Component {
  constructor() {
    super();
  }

  componentDidMount() {
    document.addEventListener('DOMContentLoaded', function() {
      const $opacity = document.getElementsByClassName('container-sticky');

      // Get all "navbar-burger" elements
      var $navbarBurgers = Array.prototype.slice.call(
        document.querySelectorAll('.navbar-burger, .burguer'),
        0
      );
      // Check if there are any nav burgers
      if ($navbarBurgers.length > 0) {
        // Add a click event on each of them
        $navbarBurgers.forEach(function($el) {
          $el.addEventListener('click', function() {
            // Get the target from the "data-target" attribute
            var target = $el.dataset.target;
            var $target = document.getElementById(target);
            const body = document.getElementsByTagName('body')[0];
            // Toggle the class on both the "navbar-burger" and the "navbar-menu"
            $el.classList.toggle('is-active');
            $target.classList.toggle('is-active');
            $el.classList.toggle('close-burger');
            $opacity[0].classList.toggle('opacity');
            body.classList.toggle('no-scroll');
          });
        });
      }
    });
  }

  spaceMenu() {
    return (
      <span className="navbar-item menu-principal menu-empty column has-dropdown is-hoverable side-margins">
        &nbsp;
      </span>
    );
  }

  render() {
    const { env } = this.props;
    return (
      <React.Fragment>
        <div className="navbar-panel">
          {this.spaceMenu()}
          <nav
            className="navbar main-content-wrapper"
            aria-label="main navigation"
          >
            <ul id="navbarMenuMarkit" className="navbar-menu">
              {DataMenu(env).map(menu => (
                <Menu
                  name={menu.name}
                  items={menu.submenu}
                  key={menu.id}
                  id={menu.id}
                />
              ))}
            </ul>
          </nav>
          {this.spaceMenu()}
        </div>
      </React.Fragment>
    );
  }
}

Navigation.propTypes = {
  env: propTypes.string.isRequired,
};

export default Navigation;
