import React, { Component } from 'react';

import '../../styles/components/TopTradingVolumes.scss';

import { NoDataAvailable, Table, Tabs } from '@jpm-adr/pattern-library';

import { getTopTradingVolumes } from '../../utils/api';
import { numberMagnitude, priceMagnitude } from '../../utils/format';

class TopTradingVolumes  extends Component {

  constructor() {
    super();
    this.state = {
      dataNyse: [],
      dataOtc: [],
      dataUnsponsored: []
    };

    this.topTradingTitles = [
      {
        id: 1,
        title: 'DR Name',
        customCSSClass: 'ellipsis',
      },
      {
        id: 2,
        title: 'Ticker',
        customCSSClass: 'is-hidden-mobile',
      },
      {
        id: 3,
        title: 'Exchange',
        customCSSClass: 'is-hidden-mobile',
      },
      {
        id: 4,
        title: 'Sector',
        customCSSClass: 'is-hidden-touch',
      },
      {
        id: 5,
        title: 'Region',
        customCSSClass: 'is-hidden-touch is-hidden-desktop-only',
      },
      {
        id: 6,
        title: ['Prior Day',<br />,'Trading Value'],
        customCSSClass: 'has-text-right',
      },
      {
        id: 7,
        title: ['Prior Day',<br />,'Trading Volume'],
        customCSSClass: 'has-text-right is-hidden-mobile',
      },
      {
        id: 8,
        title: ['30-Day Avg.',<br />,'Volume'],
        customCSSClass: 'has-text-right is-hidden-mobile',
      },
    ];
  }

  componentDidMount() {

    const getTopTradingVolumeHelper=(name,key)=>(
      getTopTradingVolumes(name)
      .then(response => {
        const tradingTopData = this.adapter(response.items);
        this.setState({[key]:tradingTopData});
      })
    )
    getTopTradingVolumeHelper("nyse","dataNyse");
    getTopTradingVolumeHelper("otc","dataOtc");
    getTopTradingVolumeHelper("unsponsored","dataUnsponsored");

  }

  mapObject(obj, field, customCSS) {
    let newObj = {};
    if(field in obj){
      if(field === 'priorDayTradingVolume' || field === 'avg30DayVolume') {
        newObj.data = numberMagnitude(obj[field], 2);
        newObj.customCSSClass = 'has-text-right has-format-number';
      } else if (field === 'priorDayTradingValue') {
        newObj.data = priceMagnitude(obj[field], 2);
      } else {
        newObj.data = String(obj[field]);
      }
    } else {
      newObj.data = "--"
    }
    if (customCSS){
      newObj.customCSSClass += ' ' + customCSS;
    }
    return newObj;
  }

  mapObjectHasLink(obj, field, customCSS) {
    let newObj = {};
    if(field in obj){
      newObj.data = obj[field];
      newObj.hasLink = true;
      newObj.contentLink = '/drprofile/' + obj.cusip;
      newObj.customCSSClass = customCSS;
    }
    return newObj;
  }

  adapter(apiData = []){
    let newDataStructure = [];
    apiData.map( obj => {
      let
        name = this.mapObjectHasLink(obj, 'name', 'field-name ellipsis'),
        ticker = this.mapObject(obj, 'ticker', 'field-ticker is-hidden-mobile'),
        exchange = this.mapObject(obj, 'exchange', 'field-exchange is-hidden-mobile'),
        sector = this.mapObject(obj, 'sector', 'field-sector is-hidden-touch ellipsis'),
        region = this.mapObject(obj, 'region', 'field-sector is-hidden-touch is-hidden-desktop-only ellipsis'),
        priorDayTradingValue = this.mapObject(obj, 'priorDayTradingValue', 'field-prior-value'),
        priorDayTradingVolume = this.mapObject(obj, 'priorDayTradingVolume', 'field-prior-volume is-hidden-mobile'),
        avg30DayVolume = this.mapObject(obj, 'avg30DayVolume', 'field-avg-volume is-hidden-mobile')

      newDataStructure.push([name, ticker, exchange, sector, region, priorDayTradingValue, priorDayTradingVolume, avg30DayVolume]);
    })
    return newDataStructure
  }

  tableRender(dataSource) {
    if (dataSource.length === 0) {
      return (
        <NoDataAvailable
          messageTitle="No data available"
          customMessageTitleClass="Data__Not__Found-Title"
          customWrapperCSS="No__Data__Available-Custom-Wrapper"
          message="There is no data available for this specific program."
          messageStyles="Data__Not__Found-Message"
          wrapperClass="Data__Not__Found Top__Trading__Volumes"
        />
      );
    }
    return (
      <Table
        values={dataSource}
        tableId="top-trading-table"
        tableCustomCss="top-trading-table table-component table is-striped is-narrow is-hoverable is-fullwidth"
        tableTitles={this.topTradingTitles}
      />
    );
  }

  render () {
    const { lastTradeDate } = this.props;
    const { dataNyse, dataOtc, dataUnsponsored } = this.state;
    return (
      <section className="columns is-multiline margin-mobile top-trading ">
        <div className="column is-hidden-touch column-hidden side-margins"> &nbsp;</div>
        <div className="is-flex-tablet top-trading-container is-paddingless main-content-wrapper">
          <div className="column columns is-multiline ">
            <div className="column is-12 top-trading-container-title is-paddingless-top">
              <span className="top-trading-title"><h2>TOP TRADING VOLUMES</h2></span>
            </div>
            <div className="column is-12 is-paddingless-top">
              <Tabs>
                <div label="NYSE/NASDAQ" tabId="NYSE/NASDAQ">
                  {this.tableRender(dataNyse || [])}
                </div>
                <div label="OTC" tabId="OTC">
                  {this.tableRender(dataOtc || [])}
                </div>
                <div label="UNSPONSORED" tabId="UNSPONSORED">
                  {this.tableRender(dataUnsponsored || [])}
                </div>
              </Tabs>
              <p className="is-hidden-mobile last-trade-text">* All Trading Values as of End of Day {lastTradeDate}</p>
            </div>
          </div>
        </div>
        <div className="column is-hidden-touch column-hidden side-margins"> &nbsp;</div>
      </section>
    );
  }
}
export default TopTradingVolumes;
