import '../../styles/components/DrProfile.scss';
import '../../styles/components/DrProfileReduce.scss';
import React, { Component, Fragment } from 'react';
import propTypes from 'prop-types';
import { DownloadButton, Tabs, Title } from '@jpm-adr/pattern-library';
import { camelCase } from 'lodash';

import API from '../../utils/apiPublic';
import AllEvents from '../../components/drprofile/AllEvents';
import BrokerForm from '../../components/drprofile/BrokerForm';
import Custodian from '../../components/drprofile/Custodian';
import Dividends from '../../components/drprofile/DividendsPublic';
import DividendsFinalAnnouncements from '../../components/drprofile/DividendsFinalAnnouncementsPublic';
import Fees from '../../components/drprofile/Fees';
import HeaderProfile from '../../components/drprofile/HeaderProfile';
import Modal from '../../components/modals/modal';
import Notifications from '../../components/drprofile/NotificationsContainer';
import OpenCloseTable from '../../components/drprofile/OpenClosed';
import OtherCorporateActions from '../../components/drprofile/OtherCorporateActions';
import OtherCorporateActionsFullHistory from '../../components/drprofile/OtherCorporateActionsFullHistory';
import Ownerships from '../../components/drprofile/Ownerships';

import ProgramDetail from '../../components/drprofile/ProgramDetail';
import QuoteSection from '../../components/drprofile/QuoteSection';
import RelatedSecurities from '../../components/drprofile/RelatedSecurities';
import TradingVolume from '../../components/drprofile/TradingVolume';
import UsefulLinks from '../../components/drprofile/UsefulLinks';
import { Modal114A, ModalRegs } from '../../components/modals/dataModals';
import { TABSCUSIP } from '../../utils/constants';
import { getTypeDr, getXID } from '../../utils/api';


class DrProfile extends Component {
  constructor() {
    super();
    this.state = {
      xid: null,
      currentTab: 'allEvents',
      isRegs: null,
      isAccepted: false,
      URL: null,
    };
    this.tabRef = React.createRef();
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props;

    getTypeDr(params.cusip).then(response => {
      this.setState({
        isRegs: response,
      });
    });
    getXID(params.cusip).then(response1 => {
      this.setState({
        xid: response1 && response1.xid,
      });
    });
    API.getAPIEndpoint().then(URL => this.setState({ URL }));
  }

  componentWillReceiveProps(nextprops) {
    const {
      match: { params },
    } = this.props;
    const {
      match: { params: nextparams },
    } = nextprops;
    if (params.cusip !== nextparams.cusip) {
      getXID(nextparams.cusip).then(response1 => {
        this.setState(
          {
            xid: response1 && response1.xid,
          },
          () =>
            this.tabRef &&
            this.tabRef.current &&
            this.tabRef.current.onClickTabItem('ALL EVENTS', 'allEvents')
        );
      });
      getTypeDr(nextparams.cusip).then(response => {
        this.setState({
          isRegs: response,
          isAccepted: false,
        });
      });
    }
  }

  DrProfileRender() {
    const { xid } = this.state;
    const {
      match: { params },
    } = this.props;
    const { cusip } = params;

    return (
      <Fragment>
        <div id="exportPDF">
          <section className="Header_Profile__Wrapper">
            <HeaderProfile headerCusip={params.cusip} xid={xid} />
          </section>
          <section className="columns margin-mobile drprofile-section">
            <div className="column is-hidden-touch column-hidden side-margins">
              &nbsp;
            </div>
            <div className="column is-four-fifths-desktop is-12-tablet detail-margin dr-profile-content main-content-wrapper">
              <div className="columns is-wrapping is-multiline">
                <div className="column is-8-widescreen is-wide-screen is-8-desktop is-12-tablet drprofile-margin drprofile-main-content">
                  <Notifications cusip={cusip} />
                  <QuoteSection cusip={cusip} />
                  <div className="notice-links-margins">
                    <RelatedSecurities cusip={params.cusip} />
                  </div>
                  <ProgramDetail xid={xid} />
                </div>
                <div className="column is-4-widescreen is-4-desktop is-12-tablet drprofile-sidebar">
                  <div className="custodian-wrapper">
                    <Custodian custodianCusip={params.cusip} />
                  </div>
                  <div className="brokerform-wrapper">
                    <BrokerForm xid={xid} />
                  </div>
                </div>
              </div>
            </div>
            <div className="column is-hidden-touch column-hidden side-margins">
              &nbsp;
            </div>
          </section>
          <section className="columns margin-mobile program-activity-section">
            <div className="column is-hidden-touch column-hidden side-margins">
              &nbsp;
            </div>
            <div className="column is-four-fifths-desktop is-12-tablet dividend-margins no-padding dr-profile-content main-content-wrapper">
              <div className="columns is-wrapping">
                <div className="column program-activity-tabs">
                  <div className="level program-activity">
                    <div className="level-left ">
                      <Title
                        sectionTitle="Program Activity"
                        className="program-activity"
                      />
                    </div>
                    <div className="level-right is-hidden-mobile is-hidden-desktop">
                      <DownloadButton
                        customClass="export-table-tablet btn"
                        title="Export Table"
                        icon="Download"
                        border="0"
                        onClick={() => {
                          return this.tableExportHandler();
                        }}
                      >
                        Export Table
                      </DownloadButton>
                    </div>
                  </div>
                  <Tabs
                    ref={this.tabRef}
                    tabsId="program-activity-tabs"
                    customClass="program-activity-tabs"
                    tabState={(e, a) => {
                      this.setState({ currentTab: a });
                    }}
                    btnContent="Export Table"
                    hasButton
                    onClickBtn={() => {
                      return this.tableExportHandler();
                    }}
                    customBtnClass="is-hidden-touch export-table-button"
                  >
                    <div tabId="allEvents" label="ALL EVENTS">
                      <AllEvents cusip={params.cusip} />
                    </div>
                    <div tabId="Dividends" label="DIVIDENDS">
                      <Tabs
                        tabsId="dividends-buttons"
                        customClass="dividends-buttons"
                        tabState={(e, a) => {
                          this.setState({ currentTab: a });
                        }}
                      >
                        <div tabId="Dividends" label="Final Announcement">
                          <DividendsFinalAnnouncements cusip={params.cusip} />
                        </div>
                        <div tabId="dividends" label="Full History">
                          <Dividends cusip={params.cusip} />
                        </div>
                      </Tabs>
                    </div>
                    <div tabId="fees" label="FEES">
                      <Fees xid={xid} />
                    </div>
                    <div
                      tabId="OtherCorporateActions"
                      label="OTHER CORPORATE ACTIONS"
                    >
                      <Tabs
                        tabsId="other-corporate-actions-buttons"
                        customClass="dividends-buttons"
                        tabState={(e, a) => {
                          this.setState({ currentTab: a });
                        }}
                      >
                        <div
                          tabId="OtherCorporateActions"
                          label="Final Announcement"
                        >
                          <OtherCorporateActions
                            cusip={params.cusip}
                            query="final"
                          />
                        </div>
                        <div tabId="otherCorporateActions" label="Full History">
                          <OtherCorporateActionsFullHistory
                            cusip={params.cusip}
                          />
                        </div>
                      </Tabs>
                    </div>
                    <div tabId="booksOpenClosed" label="BOOKS OPEN/CLOSED">
                      <OpenCloseTable cusip={params.cusip} />
                    </div>
                    <div tabId="tradingVolume" label="TRADING VOLUME">
                      <TradingVolume cusip={params.cusip} />
                    </div>
                    <div tabId="ownership" label="OWNERSHIP">
                      <Ownerships cusip={params.cusip} />
                    </div>
                  </Tabs>
                </div>
              </div>
            </div>
            <div className="column is-hidden-touch column-hidden side-margins">
              &nbsp;
            </div>
          </section>
          <section className="columns margin-mobile is-hidden-mobile">
            <div className="column is-hidden-touch column-hidden side-margins">
              &nbsp;
            </div>
            <div className="is-four-fifths-desktop is-12-tablet detail-margin dr-profile-content main-content-wrapper">
              <UsefulLinks />
            </div>
            <div className="column is-hidden-touch column-hidden side-margins">
              &nbsp;
            </div>
          </section>
        </div>
      </Fragment>
    );
  }

  tableExportHandler() {
    const { xid, currentTab, URL } = this.state;

    const {
      match: { params },
    } = this.props;
    const currentCamelCase = camelCase(currentTab);
    const id = TABSCUSIP.indexOf(currentCamelCase) > -1 ? params.cusip : xid;
    const finalAnnouncement =
      currentTab === 'Dividends' || currentTab === 'OtherCorporateActions'
        ? '?announcementFilter=final'
        : '';
    const tableUrl =
      URL +
      '/dr/' +
      currentCamelCase +
      '/' +
      id +
      '/exportTable' +
      finalAnnouncement;
    return { url: tableUrl, title: currentTab, type: 'xlsx' };
  }

  renderRegulationSDisclaimerModal() {
    return (
      <Modal
        title="Regulation S Disclaimer"
        onClickBtn={() => {
          this.setState({ isAccepted: true });
        }}
      >
        <ModalRegs />
      </Modal>
    );
  }

  renderRule144DisclaimerModal() {
    return (
      <Modal
        title="Rule 144A Disclaimer"
        onClickBtn={() => {
          this.setState({ isAccepted: true });
        }}
      >
        <Modal114A />
      </Modal>
    );
  }

  renderModal() {
    const { isRegs, isAccepted } = this.state;
    if (isRegs && isRegs.level === 'REGS' && !isAccepted) {
      return this.renderRegulationSDisclaimerModal();
    } else if (isRegs && isRegs.level === '144A' && !isAccepted) {
      return this.renderRule144DisclaimerModal();
    } else if (isAccepted) {
      return this.DrProfileRender();
    } else {
      return this.DrProfileRender();
    }
  }

  render() {
    return <Fragment>{this.renderModal()}</Fragment>;
  }
}

DrProfile.propTypes = {
  match: propTypes.shape({
    cusip: propTypes.string,
  }).isRequired,
};

export default DrProfile;
