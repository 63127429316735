export default function exportData(fileName, data, fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE variant
        window.navigator.msSaveOrOpenBlob(
        new Blob([data], {type: fileType}),
        fileName
        );
      } else {
        const url = window.URL.createObjectURL(new Blob([data], {type: fileType}));
        const link = document.createElement('a');
        link.id='downloadlink'
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        link.remove();
      }
}
