import PropTypes from 'prop-types';
import React from 'react';

import FilterField from './FilterField';

import '../../../styles/components/Filters.scss';

export default function Filters(props) {
  const { fields, onChange, onClear, values } = props;

  return (
    <div className="Filters">
      {fields.map(field => {
        const { label, name, type, filter, ...other } = field;

        if(label !== undefined){
          return (
            <div key={name} className="Filters__field">
              <FilterField
                {...other}
                type={type}
                filter={filter}
                label={label}
                name={name}
                onChange={(optionalName, value) => {
                  onChange(optionalName || name, value)
                }}
                onClear={optionalName => {
                  onClear(optionalName || name)
                }}
                value={values[name]}
                startDate={values['startDate']}
                endDate={values['endDate']}
              />
            </div>
          );
        }

        return <span />;
      })}
    </div>
  );
}

Filters.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['select']),
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  values: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    'aria-label': PropTypes.string,
  }).isRequired,
};
