import PropTypes from 'prop-types';
import React from 'react';
import {
  LoadingText,
  NoDataAvailable,
  TabularData,
} from '@jpm-adr/pattern-library';

import {
  endOfCurrentMonth,
  lastMonthOfCurrentMonth,
} from '../../../utils/format';
import { getMonthlySummary, getProgramDetails } from '../../utils/apiCalls';
import { monthlyParser, programDetailsParser } from '../../components/tables/formater';

import '../../../styles/IssuerServices/components/SummaryTables.scss';

class SummaryTables extends React.Component {
  constructor() {
    super();
    this.state = {
      cusipCached: '',
      loadings: {
        monthly: 'isLoading',
        programDetails: 'isLoading',
      },
      errors: {
        monthly: false,
        programDetails: false,
      },
      monthlyData: [],
      programDetailsData: [],
      xidCached: '',
    };
    this.getMonthlyData = this.getMonthlyData.bind(this);
    this.getProgramDetails = this.getProgramDetails.bind(this);
  }

  componentDidMount() {
    const { xid, cusip } = this.props;
    this.getMonthlyData(xid);
    this.getProgramDetails(cusip);
  }

  componentDidUpdate() {
    const { xid, cusip } = this.props;
    this.getMonthlyData(xid);
    this.getProgramDetails(cusip);
  }

  getUniqueKey() {
    return Date.now() | (0 * Math.random() * (5 - 1) + 1);
  }

  getMonthlyData(xid) {
    const { errors, xidCached, loadings } = this.state;
    if (!xid) return;

    if (xid === xidCached || errors.monthly === 'error') return;
    getMonthlySummary(xid)
      .then(monthlyData => {
        loadings.monthly = false;
        this.setState({
          loadings,
          monthlyData: this.parseData('monthlyDataParser', monthlyData),
          xidCached: xid,
        });
      })
      .catch(() => {
        errors.monthly = 'error';
        this.setState({ errors });
      });
  }

  getProgramDetails(cusip) {
    if (!cusip) return;
    const { errors, cusipCached, loadings } = this.state;
    if (cusipCached === cusip || errors.programDetails === 'error') return;
    getProgramDetails(cusip)
      .then(programDetailsData => {
        loadings.programDetails = false;
        this.setState({
          loadings,
          programDetailsData: this.parseData(
            'ProgramDetailsDataParser',
            programDetailsData
          ),
          cusipCached: cusip,
        });
      })
      .catch(() => {
        errors.programDetails = 'error';
        this.setState({ errors });
      });
  }

  parseData(type, data) {
    return this[type] && this[type](data);
  }

  monthlyDataParser(data) {
    return monthlyParser(data);
  }

  ProgramDetailsDataParser(data) {
    return programDetailsParser(data);
  }

  showError(options = {}) {
    const {
      title = 'Data not found',
      message = "Either something went wrong or the data doesn't exist.",
    } = options;
    return (
      <NoDataAvailable
        messageTitle={title}
        customMessageTitleClass="Data__Not__Found-Title"
        customWrapperCSS="No__Data__Available-Custom-Wrapper"
        message={message}
        messageStyles="Data__Not__Found-Message"
        wrapperClass="Data__Not__Found"
      />
    );
  }

  showLoading() {
    return (
      <LoadingText aria-live="polite" aria-busy="true" withGreyBackground />
    );
  }

  showTable(type) {
    let {
      errors,
      loadings,
      monthlyData = [],
      programDetailsData = [],
    } = this.state;

    const { error } = this.props;
    if (errors[type] === 'error' || error) {
      return this.showError();
    }

    if (loadings[type] === 'isLoading') {
      return this.showLoading();
    }

    const dataToShow = {
      monthlyData,
      programDetailsData,
    };

    const dataByType = dataToShow[`${type}Data`];
    if (dataByType.length === 0) {
      return this.showError({
        title: 'No data available',
        message: "There is no data available for this specific program.",
      });
    }

    return <TabularData data={dataToShow[`${type}Data`]} />;
  }

  render() {
    const {
      errors: { monthly, programDetails },
      loadings,
    } = this.state;

    const hideDates = monthly === 'error' && programDetails === 'error';
    return (
      <div className="summary__tables">
        <React.Fragment>
          <div className="table__container">
            <h3 className="table__title">
              Monthly summary{' '}
              {hideDates ? '' : `- ${lastMonthOfCurrentMonth()}`}
            </h3>
            {this.showTable('monthly')}
            <div className="summary__asof">
              {hideDates || (loadings.monthly || loadings.getProgramDetails)
                ? ''
                : `As of ${endOfCurrentMonth()}`}
            </div>
          </div>
          <div className="table__container">
            <h3 className="table__title">Program details</h3>
            {this.showTable('programDetails')}
          </div>
        </React.Fragment>
      </div>
    );
  }
}

SummaryTables.defaultProps = {
  xid: '',
  cusip: '',
  error: false,
};

SummaryTables.propTypes = {
  match: PropTypes.shape({}).isRequired,
  xid: PropTypes.number,
  cusip: PropTypes.number,
  error: PropTypes.bool,
};

export default SummaryTables;
