import React from 'react';
import { Table } from '@jpm-adr/pattern-library';
import Tables from './InvestorDisclosureTable.json';

const { InputTable } = Tables

const InvestorDisclosure = () => {
    return (
      <div className="investor-disclosure">
        <p className="core__t15 disclosure-tab-text">
          J.P. Morgan acting as depositary for depositary receipt programs may collect fees from holders of depositary receipts.
          J.P. Morgan may (i) reimburse the company for certain expenses incurred by the issuer in connection with the depositary
          receipts program or (ii) share with the issuer revenue derived by J.P. Morgan from the program. J.P. Morgan may use
          brokers, dealers or other service providers that are affiliates or other divisions of J.P. Morgan and may earn or share
          fees and commissions.
        </p>
        <p className="core__t15 disclosure-tab-text">
          From time to time, J.P. Morgan may pay a rebate to brokers in connection with
          the deposit of shares for the issuance of unsponsored depositary receipts. J.P.
          Morgan disclaims all liability arising out of, or relating to, such rebates.
          In particular, J.P. Morgan disclaims all responsibility regarding whether such broker passes all
          or a portion of such rebate to beneficial holders of such depositary receipts.
        </p>
        <p className="core__t15">
          A cancellation transaction fee of $15.00 will be charged per cancellation request, except for depositary
          receipt programs listed on the HKEX Exchange, for which the cancellation transaction fee will be HK$115.00.
        </p>
        <p className="core__t15 disclosure-tab-text">
          <b><u>Fee Schedule for the Provision of Depositary Services for Unsponsored American Depositary Receipt (ADR) Programs</u></b>
        </p>
        <p className="core__t15 disclosure-tab-text">
          <i>All fees in U.S. dollars</i>
        </p>
        <p className="core__t15 disclosure-tab-text">
          Fees quoted in this schedule (this &quot;<strong>Fee Schedule</strong>&quot;) are subject to the terms and conditions of the applicable ADR and are incorporated thereto. 
          Capitalized terms used herein and not otherwise defined shall have the meanings assigned to them in the applicable ADR. The fees and charges 
          herein may differ from those of other depositaries. JPMorgan Chase Bank, N.A., acting as depositary (&quot;<strong>J.P. Morgan</strong>&quot; or the &quot;<strong>Depositary</strong>&quot;), 
          reserves the right to modify, reduce or increase these fees upon thirty (30) days’ notice to the Registered Holders of ADRs. 
          The current list of J.P. Morgan’s unsponsored ADR programs is available at www.adr.com. 
        </p>
        <p className="core__t15 disclosure-tab-text">
          <i>Applicability of this Fee Schedule</i>
        </p>
        <p className="core__t15 disclosure-tab-text">
          The Depositary may charge the following parties, the fees and charges in this Fee Schedule, as applicable: 
        </p>
        <ol className="roman-list">
          <li>holders of American Depositary Shares (&quot;<strong>ADSs</strong>&quot;);</li>
          <li>any party depositing or withdrawing shares;</li>
          <li>any party transferring or surrendering ADRs;</li>
          <li>any party to whom ADRs are issued (including issuance pursuant to a stock dividend or stock split or an exchange of stock or distribution or a change in the number of shares represented by each ADS); and</li>
          <li>holders of ADRs</li>
        </ol>
        <p className="core__t15 disclosure-tab-text">
          <i>Schedule of Fees and Charges</i>
        </p>
        <p className="core__t15 disclosure-tab-text">
          Actual fees to be charged are dependent on the ADR that the fee relates to and will be determined by J.P. Morgan at the relevant time. 
        </p>
        <p className="core__t15 disclosure-tab-text">
          The amounts in the table below relate to fees for: 
        </p>
        <ol className="alphabet-list">
          <li>the delivery or surrender of American Depositary Shares and/or Receipts and deposit or withdrawal of Shares;</li>
          <li>the distribution of cash, Shares or other property received in respect of deposited Securities;</li>
          <li>depositary services; and</li>
          <li>the issuance and transfer of ADR certificates</li>
        </ol>
        <p className="core__t15 disclosure-tab-text">
          <Table
            tableId="investor-disclosure-table"
            values={InputTable}
            tableTitles={InputTable[0].map((element, index) => {
                return {
                index,
                title: element.title
                };
            })}
          />
        </p>
        <p className="core__t15 disclosure-tab-text">
          Other applicable fees and charges to be charged in addition to the relevant fee(s) from the above table: 
        </p>
        <ol className="alphabet-list custom-start">
          <li>taxes (including applicable interest and penalties) and other governmental charges;</li>
          <li>registration or custodial fees or charges relating to the Shares.</li>
          <li>cancellation transaction, SWIFT, telex and facsimile transmission fees and expenses and delivery charges relating to the Shares.</li>
          <li>foreign currency conversion expenses and fees: To facilitate the administration of various depositary receipts transactions; including disbursement of dividends or other cash distributions and other corporate actions, the Depositary may engage the foreign exchange desk within the Bank and/or its affiliates in order to enter into spot foreign exchange transactions to convert currency into U.S. dollars (&quot;FX Transactions&quot;). For further details relating to the applicable foreign exchange rate, any applicable spread, and the execution of FX Transactions, see the &quot;Disclosure&quot; page (or successor page) of www.adr.com (as changed by the Depositary from time to time).</li>
          <li>any other fees, charges and expenses as are incurred by the Depositary and/or any of its agents (including, without limitation, the Custodian and expenses incurred on behalf of Holders in connection with compliance with foreign exchange control regulations or any law or regulation relating to foreign investment) in connection with the Receipt program, the servicing of the Shares or other Deposited Securities, the sale of securities (including, without limitation, Deposited Securities), the delivery of Deposited Securities or otherwise in connection with the Depositary’s or its Custodian’s compliance with applicable laws, rules or regulations.</li>
          <li>fees or expenses payable by or due to J.P. Morgan or its agents, including any custodian, in connection with the tax reclaim process, or to tax authorities or regulators (which fees, charges or expenses may be deducted from the dividend or any other distribution or by billing or otherwise in J.P. Morgan’s discretion).</li>
          <li>a fee for the distribution or sale of securities, such fee being in an amount equal to the fee for the execution and delivery of ADSs which would have been charged as a result of the deposit of such securities (for purposes of this Fee Schedule treating all such securities as if they were Shares) but which securities or the net cash proceeds from the sale thereof are instead distributed by the Depositary to Holders.</li>
        </ol>


















        <p className="core__t15 disclosure-tab-text">
          <b><u>Tax Disclaimer for Portuguese Residents Only</u></b>
        </p>
        <p className="core__t15 disclosure-tab-text">
          Stamp duty tax might be due by Portuguese resident holders of depositary receipts on any commission or fee charged by JPMCB NA 
          for the issuance, cancellation or sale of depositary receipts. In such case, the Portuguese resident holder to whom the 
          commissions and/or fee is charged will be legally responsible for the payment of stamp duty tax due or owing to the Portuguese 
          tax authorities. JPMCB NA shall bear no responsibility for the payment of any tax due by the holders.
        </p>
        <p className="core__t15 disclosure-tab-text">
          JPMorgan Chase & Co. and its affiliates do not provide tax, legal or accounting advice. This material has been prepared for informational 
          purposes only and is not intended to provide, and should not be relied on for, tax, legal or accounting advice. You should consult your own 
          tax, legal and accounting advisors before engaging in any transaction. JPMorgan Chase Bank, N.A. is a national banking association organized 
          under the laws of the U.S.A. with limited liability.
        </p>
      </div>
    )
};

export default InvestorDisclosure;
