import '../../../styles/IssuerServices/pages/Activity.scss';
import PropTypes from 'prop-types';
import React from 'react';

import DailyTable from './DailyTable';
import MonthlyTable from './MonthlyTable';
import SubSectionsComponent from '../../components/subSections/SubSections';

import { subSections } from '../../utils/constants';

const sections = subSections['activity'];
const subSectionsComponentConfig = { config: { sections } };

const Activity = props => {
  sections.subNavigations = sections.subNavigations.map(section => {
    if (section.id === 'monthly') {
      section.element = <MonthlyTable {...props} />;
    }
    if (section.id === 'daily') {
      section.element = <DailyTable {...props} />;
    }
    return section;
  });

  return (
    <div className="activity activity__content">
      <h2 className="activity activity__title">
        PROGRAM ISSUANCE AND CANCELLATION
      </h2>
      <SubSectionsComponent {...subSectionsComponentConfig} />
    </div>
  );
};

Activity.propTypes = {
  cusip: PropTypes.string.isRequired,
  headerInfo: PropTypes.objectOf.isRequired,
};

export default Activity;
