import '../../styles/components/BrokerForms.scss';
import React, { Component } from 'react';
import { NoDataAvailable, Table } from '@jpm-adr/pattern-library';

import GenericFormsAdapter, {
  SORT_OPTIONS_MAP,
  createProgramSpecificTitles,
} from './GenericFormsAdapter';

import { getBrockerForms } from '../../utils/api';

const DEFAULT_FILTER = {
  sortBy: SORT_OPTIONS_MAP.name,
  sortOrder: 'asc',
};

class GenericForms extends Component{
  constructor(props){
    super(props);
    this.state={
      GenericFormsTable: [],
      isLoading: true,
      filters: {
        ...DEFAULT_FILTER,
      },
    };
    this.fetchData = this.fetchData.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.getBrockerFormsData = this.getBrockerFormsData.bind(this);
  }

  componentDidMount() {
    this.getBrockerFormsData('generic');
  }

  getBrockerFormsData(query){
    const {GenericFormsTable=[], filters}=this.state;
    return getBrockerForms(query, filters)
    .then(response=>{
      const {items=[]}= response;
      this.setState({
        GenericFormsTable: GenericFormsTable.concat(items),
        isLoading: false,
      })
    })
  }

  handleSort(sortBy, sortOrder) {
    const { filters } = this.state;
    this.setState(
      {
        isLoading: true,
        filters: {
          ...filters,
          sortBy,
          sortOrder,
        },
      },
      this.fetchData
    );
  }

  fetchData(){
    const {filters} = this.state;
    return getBrockerForms('generic', filters)
      .then(response => {
        const { items = [] } = response;
        this.setState({
          isLoading: false,
          GenericFormsTable: items,
        })
      })
  }

  render(){
    const {filters, isLoading, GenericFormsTable} = this.state;
    const {sortBy, sortOrder} = filters;
    if(!isLoading && GenericFormsTable.length === 0){
      return (
        <section>
          <NoDataAvailable />
        </section>
      )
    }
    return(
      <React.Fragment>
        <Table
          isLoading={isLoading}
          values={GenericFormsAdapter(GenericFormsTable)}
          tableId="BrokerForms__Generic-Forms"
          tableCustomCss="BrokerForms__table table-component table is-striped is-narrow is-fullwidth"
          theadCustomCss="BrokerForms__table-titles"
          tbodyCustomCss="BrokerForms__table-body"
          tableWrapperClass="BrokerForms__table"
          tableTitles={createProgramSpecificTitles(
            sortBy,
            sortOrder,
            this.handleSort
          )}
        />
        {(isLoading)? null : this.LoadingWithState}
      </React.Fragment>
    )
  }
}

export default GenericForms
