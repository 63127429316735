import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {ButtonWithOptions, NavigationTabs } from '@jpm-adr/pattern-library';
import {withRouter} from 'react-router';
import {EXPORT_OPTION_CURRENT_VIEW, EXPORT_OPTION_FULL_UNIVERSE} from '../../utils/constants';
import FeeBrowser from './FeeBrowser';
import BookClosures from './BookClosures';

import '../../styles/components/DrDirectory.scss';
import CorporateActions from './CorporateActions';
import DrUniverse from './DrUniverse';

class DrDirectory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      exportFunction: () => {},
      isDownloadingExcel: false
    };

    this.setExportFunction = this.setExportFunction.bind(this);
    this.handleExportClickOption = this.handleExportClickOption.bind(this);
  }

  setExportFunction(exportFunction) {
    this.setState({
      exportFunction,
    });
  }

  getIdFromURL() {
    const { location } = this.props;
    const locationUrl = location.pathname;
    return locationUrl.substring(16);
  }

  exportWithOptions () {
    const cssClass = 'level-right dr-directory-download-button';
    const { isDownloadingExcel } = this.state;
    return (
      <div
        className={cssClass}
        style={{
          backgroundColor: 'white',
          height: '35px',
          margin: '7px 15px',
          width: '217px'
        }}
      >
        <ButtonWithOptions
          icon="Download"
          isDownloadingExcel={isDownloadingExcel}
          onOptionClick={this.handleExportClickOption}
          options={[EXPORT_OPTION_CURRENT_VIEW, EXPORT_OPTION_FULL_UNIVERSE]}
          text="Export Options"
        />
      </div>
    );
  }

  handleExportClickOption (option) {
    const isFullDownload = option === EXPORT_OPTION_FULL_UNIVERSE;
    const path = this.getIdFromURL();
    this.setState({ isDownloadingExcel: true });
    let ref = null;
    switch(path) {
      case 'drUniverse':
        ref = this.drUniverseElement;
        break;
      case 'corporateActions':
        ref = this.corporateActionsElement;
        break;
      case 'bookClosures':
        ref = this.bookClosuresElement;
        break;
      case 'fees': 
        ref = this.feesElement;
        break;
    }
    if(ref) {
      ref.handleExportClickOption(isFullDownload)
        .finally(() => this.setState({ isDownloadingExcel: false }));
    } else {
      this.setState({ isDownloadingExcel: false });
    }
  }

  render() {
    const { exportFunction } = this.state;
    return (
      <section className='dr-directory-main-wrapper'>
        <NavigationTabs
          hasButton
          customBtnClass='dr-directory-download-button'
          onClickBtn={exportFunction}
          customButton={this.exportWithOptions()}
          customClass='Dr-Directory-Main-Tabs'
        >
          <div
            label='DR UNIVERSE'
            url='/dr/drdirectory/drUniverse'
            tabId='DR_UNIVERSE'
            aria-labelledby='DR_UNIVERSE'
            role='tabpanel'
            key='DrUniverse'
            renderContent={() => (
              <DrUniverse
                key='DrUniverse+'
                ref={ref => { this.drUniverseElement = ref; }}
                customButton={this.exportWithOptions()}
              />
            )}
          />

          <div
            label='CORPORATE ACTIONS'
            url='/dr/drdirectory/corporateActions'
            tabId='CORPORATE_ACTIONS'
            aria-labelledby='CORPORATE_ACTIONS'
            role='tabpanel'
            key='CORPORATE_ACTIONS'
            renderContent={() => (
              <CorporateActions
                key='CORPORATE_ACTIONS+'
                ref={ref => { this.corporateActionsElement = ref; }}
                customButton={this.exportWithOptions()}
              />
            )}
          />

          <div
            label='BOOK CLOSURES'
            url='/dr/drdirectory/bookClosures'
            tabId='BOOK_CLOSURES'
            aria-labelledby='BOOK_CLOSURES'
            role='tabpanel'
            key='BOOK_CLOSURES'
            renderContent={() => (
              <BookClosures
                key='BOOK_CLOSURES+'
                ref={ref => { this.bookClosuresElement = ref; }}
                customButton={this.exportWithOptions()}
              />
            )}
          />

          <div
            label='FEES'
            url='/dr/drdirectory/fees'
            tabId='FEES'
            aria-labelledby='FEES'
            role='tabpanel'
            key='FEES'
            renderContent={() => (
              <FeeBrowser
                key='FEES+'
                ref={ref => { this.feesElement = ref; }}
                customButton={this.exportWithOptions}
              />
            )}
          />
        </NavigationTabs>
      </section>
    );
  }
}

DrDirectory.propTypes = {
  // eslint-disable-next-line
  location: PropTypes.object,
};

export default withRouter(DrDirectory);
