import '../../styles/components/ContactCard.scss';

import React from 'react';

import PropTypes from 'prop-types';

import ContactCard from '../contactCard/ContactCard';


const CardsContainer = props => {
  const { title, address, contacts } = props;
  const contasctsRender = contacts.map(contact => <ContactCard {...contact} />);
  const addressRender = address.map(adr => (
    <div className="core__t15">{adr}</div>
  ));

  return (
    <div className="cardsContainer__container">
      <div
        className={`core__t6 cardContainer__title ${title ? '' : 'notitle'}`}
      >
        {title.split('<br />').map((st, idx) => (
          <React.Fragment>
            {idx === 0 ? '' : <br />}
            {st}
          </React.Fragment>
        ))}
      </div>
      {addressRender}
      {contasctsRender}
    </div>
  );
};

CardsContainer.propTypes = {
  title: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  contacts: PropTypes.arrayOf(PropTypes.object).isRequired,
};

CardsContainer.propTypes = {
  title: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  contacts: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default CardsContainer;
