import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { isPathAbsolute } from '../../utils/urlMethods';

const Submenu = props => {
  let { name, id, path, onCloseDropDown } = props;
  return isPathAbsolute(path) ? (
    <a
      aria-label={`${name}. Pressing ENTER or SPACEBAR key,
        ${name} page will be open and data on the screen will get change.
      `}
      className="navbar-item"
      href={path}
      target="_blank"
      rel="noopener noreferrer"
    >
      {name}
    </a>
  ) : (
    <Link
      aria-label={`${name}. Pressing ENTER or SPACEBAR key,
        ${name} page will be open and data on the screen will get change.
      `}
      className="navbar-item"
      id={id}
      to={path}
      onClick={onCloseDropDown}
    >
      {name}
    </Link>
  );
};

Submenu.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  onCloseDropDown: PropTypes.func.isRequired,
};

export default Submenu;
