import PropTypes from 'prop-types';
import React from 'react';

import { FileButtons } from '@jpm-adr/pattern-library';

import {
  dateMonthYearSpacing,
  dateMonthYearSpacingWithComma,
  formatDate,
  substract,
  yearMonthWithUnderscore,
  yearMonthWithoutSpaces,
} from '../../../utils/format';

import TableLoader from '../../components/tables/TableLoader';
import { downloadFile, getLibraryMonthlyReports } from '../../utils/apiCalls';

class MonthlyTable extends React.Component {
  constructor() {
    super();
    this.state = {
      filterBy: this.getYearsFilter()[0],
    };
    this.prepareConfigTable = this.prepareConfigTable.bind(this);
    this.prepareApiRequest = this.prepareApiRequest.bind(this);
    this.onSelectYear = this.onSelectYear.bind(this);
  }

  onSelectYear() {
    return (error, yearSelected) => {
      this.setState({
        filterBy: yearSelected,
      });
    };
  }

  getYearsFilter() {
    return [0, 1, 2, 3, 4, 5].map(year => {
      const yearConfig = substract({ year }, 'YYYY');
      return { label: yearConfig, value: yearConfig };
    });
  }

  prepareConfigTable() {
    const { headerInfo = {}, xid } = this.props;
    const { name = '' } = headerInfo;

    return {
      columns: [
        {
          text: 'Report Type',
        },
        {
          text: 'Title',
        },
        {
          text: 'Publish Date',
        },
      ],
      rows: [
        {
          text: () => <div className="report-type">Monthly Board Report</div>,
        },
        {
          className: 'title-with-download-pdf',
          text: itemData => (
            <FileButtons
              buttonsInfo={{
                labels: {
                  label: `${dateMonthYearSpacing(
                    itemData.documentTag.split('_')[1]
                  )} Monthly Board Report`,
                },
                pdf: true,
                pdfLink: `/dm/document?feedId=${itemData.feedId}&docTag=${
                  itemData.documentTag
                }&versionTag=${itemData.versionTag}`,
                title: `${name.replace(
                  ' ',
                  '_'
                )}_DR_${xid}_MonthlyBoardReport_${yearMonthWithUnderscore(
                  formatDate(itemData.documentTag.split('_')[1], 'YYYYMM')
                )}_AsOf_${yearMonthWithoutSpaces(itemData.date)}`,
                newFetch: downloadFile,
              }}
            />
          ),
        },
        {
          text: itemData => dateMonthYearSpacingWithComma(itemData.date),
        },
      ],
    };
  }

  prepareApiRequest() {
    const { xid } = this.props;
    const { filterBy } = this.state;

    return {
      requestApi: xid ? getLibraryMonthlyReports : null,
      requestApiConfig: { xid, year: filterBy.label },
    };
  }

  render() {
    const {
      props,
      prepareApiRequest,
      prepareConfigTable,
      state: { filterBy },
      getYearsFilter,
      onSelectYear,
    } = this;
    const configs = {
      configTable: prepareConfigTable(),
      filters: [
        {
          id: 'singleSelect',
          options: getYearsFilter(),
          value: filterBy,
          onChange: onSelectYear(),
          label: 'Select year with up and down key, ',
          isSearchable: false,
        },
      ],
      immediateRequest: true,
    };
    const tableLoaderConfig = { ...configs, ...props, ...prepareApiRequest() };
    return <TableLoader {...tableLoaderConfig} />;
  }
}

MonthlyTable.propTypes = {
  xid: PropTypes.string.isRequired,
  headerInfo: PropTypes.objectOf.isRequired,
};

export default MonthlyTable;
