import '../../../styles/components/RelatedSecurities.scss';
import React from 'react';
import propTypes from 'prop-types';
import { NoticeLinks } from '@jpm-adr/pattern-library';
import { getRelatedSecurities } from '../../utils/apiCalls';
import { withApiRequest } from '../../../utils/apiWithHOC';
import { withErrorBoundary } from '../../../utils/errorBoundary';

const RelatedSecurities = props => {
  const { data } = props;

  if (!data || data.length === 0 || !data.map) {
    return <span />;
  }

  const relatedSecuritiesData = data.map((security, index) => {
    const currentPosition = security.position || index;

    return {
      href: `/issuerservices/summary/${security.cusip}`,
      text: `${security.name} (${security.ticker})`,
      position: currentPosition,
    };
  });

  return (
    <NoticeLinks
      label="Related Securities: "
      noticeClass="notice-links column is-vcentered is-desktop"
      anchors={relatedSecuritiesData}
    />
  );
};

RelatedSecurities.propTypes = {
  data: propTypes.arrayOf(
    propTypes.shape({
      xid: propTypes.number,
      cusip: propTypes.string,
      name: propTypes.string,
      ticker: propTypes.string,
    })
  ).isRequired,
};

export default withErrorBoundary(
  withApiRequest(
    RelatedSecurities,
    ({ cusip }) => (cusip ? getRelatedSecurities(cusip) : null)
  )
);
