/**
 * dataRequestMethods.js defines common methods used throughout the react app
 */

import Axios from '../components/common/AxiosWrapper';
import * as Constants from './constants';
import * as UrlMethods from './urlMethods';

import ApiPublic from './apiPublic';

export const getDataRequestUrl = () => {
  if (UrlMethods.isLocalHost()) {
    const url = Constants.URLbyDomain;
    return Promise.resolve({ data: { jpmAdr: url } });
  }

  return Axios.get('/api/endpoints')
    .then(responseEndpoints => Promise.resolve(responseEndpoints))
    .catch(err => Promise.reject(err));
};

/**
 * Method used to execute a data request
 * @param {String} path [Fully qualified URL for a data request]
 * @param {String} method [Request method, defaults to 'GET']
 * @param {Function} callback [Callback to which a response will be passed]
 * @param {Object} params [URL parameters as key value pairs]
 */
export const executeDataRequest = (path, method, params) => {
  return ApiPublic.makeCall({
    method,
    endpoint: path,
    params,
  });
};

/**
 * Method used to execute a data download request
 * @param {String} path [Fully qualified URL for a data request]
 */
export const executeDataDownloadRequest = path => {
  return ApiPublic.makeCall({
    endpoint: path,
    method: 'GET',
    responseType: 'blob'
  });
};
