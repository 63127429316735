import React from 'react';
import { Tabs } from '@jpm-adr/pattern-library';

import ExecutionDisclosure from './ExecutionDisclosure';
import FxDisclosure from './FxDisclosure3';
import InvestorDisclosure from './InvestorDisclosure';

const DisclosureMain = () => {
  return (
    <div>
      <Tabs tabsId="Investor-tabs" customClass="investor-disclosure-tabs">
        <div tabId="Depositary-tab" label="DEPOSITARY RECEIPT DISCLOSURES">
          <p className="core__t15 disclosure-tab-text">
            The information on this site may inform you of certain investment
            products and services offered by the depositary receipts
            business&nbsp; of JPMorgan Chase Bank, N.A. or any of its affiliates
            (together, &quot;
            <strong>J.P. Morgan</strong>
            &quot;), part of JPMorgan Chase & Co. No information&nbsp; published
            on this site is intended as a recommendation or an offer or
            solicitation for the purchase or sale of any security or
            financial&nbsp; instrument, or to enter into a transaction involving
            any financial instrument, or as an official confirmation or official
            valuation of any&nbsp; transaction mentioned herein. The products,
            services, information and/or materials referenced within these web
            pages may not be available for&nbsp; all individuals or residents of
            certain jurisdictions.
          </p>
          <p className="core__t15 disclosure-tab-text">
            Data may be delayed. Data is provided for information purposes only
            and does not bind J.P. Morgan in any way. Any&nbsp; pricing
            information provided is indicative only and does not reflect a level
            where J.P. Morgan is prepared to execute a trade.&nbsp; Nothing in
            this material should be construed as investment, tax, legal,
            accounting, regulatory or other advice or as creating a&nbsp;
            fiduciary relationship.
          </p>
          <p className="core__t15 disclosure-tab-text">
            All market prices, data and other information are not warranted as
            to completeness or accuracy and are subject to change without
            notice. J.P.&nbsp; Morgan disclaims any responsibility or liability
            to the fullest extent permitted by applicable law, for any loss or
            damage arising from any&nbsp; reliance on our use of the data in any
            way.
          </p>
          <p className="core__t15 disclosure-tab-text">
            Data or information acquired by you through access this site may not
            be reproduced, redistributed or transmitted, in whole or in
            part,&nbsp; without J.P. Morgan’s consent. Any unauthorized use is
            strictly prohibited.
          </p>
          <p className="core__t15 disclosure-tab-text">
            Please consult the following additional disclosures and information:
          </p>
          <ul className="core__t15 disclosure-tab-text">
            <li>Investors Disclosure</li>
            <li>Depositary Receipts FX Disclosure</li>
            <li>Execution Disclosure</li>
            <li>
              For important disclosures in respect of JPMM material prepared by
              J.P. Morgan Sales and Trading personnel, please consult:&nbsp;
              <a
                href="//www.jpmorgan.com/salesandtradingdisclaimer"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.jpmorgan.com/salesandtradingdisclaimer
              </a>
            </li>
            <li>
              For disclosures relating to specific companies referenced in J.P.
              Morgan Research, please consult:&nbsp;
              <a
                href="//www.jpmm.com/research/disclosures"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.jpmm.com/research/disclosures
              </a>
            </li>
          </ul>
          <p className="core__t15">
            J.P. Morgan is the global brand name for JPMorgan Chase & Co. and
            its subsidiaries and affiliates worldwide.
          </p>
        </div>
        <div tabId="Investor-tab" label="INVESTOR DISCLOSURE">
          <InvestorDisclosure />
        </div>
        <div tabId="FX-tab" label="FX DISCLOSURE">
          <FxDisclosure />
        </div>
        <div tabId="Execution-tab" label="EXECUTION DISCLOSURE">
          <ExecutionDisclosure />
        </div>
      </Tabs>
    </div>
  );
};

export default DisclosureMain;
