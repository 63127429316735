import * as DataRequestMethods from './dataRequestMethods';
import { createUrlQuery, reduceToValues } from './urlMethods';

export function getBookClosure({ offset = 0, limit = 25, ...args }) {
  const query = createUrlQuery({
    offset,
    limit,
    ...reduceToValues(args),
  });

  return DataRequestMethods.executeDataRequest(`bookClosures?${query}`, 'GET');
}

export function getDrUniverse({ offset = 0, limit = 25, ...args }) {
  const query = createUrlQuery({
    offset,
    limit,
    ...reduceToValues(args),
  });
  const { cancelToken } = args;

  return DataRequestMethods.executeDataRequest(`drUniverse?${query}`, 'GET', {
    cancelToken,
  });
}


export function getExportData(screenType, args) {
  const query = createUrlQuery({ ...args });
  return DataRequestMethods.executeDataDownloadRequest(`${screenType}/exportTable?${query}`);
}

export function getCorporateActions({ offset = 0, limit = 25, ...args }) {
  const query = createUrlQuery({
    offset,
    limit,
    ...reduceToValues(args),
  });
  return DataRequestMethods.executeDataRequest(
    `corporateActions?${query}`,
    'GET'
  );
}

export function getFeesBrowser({ offset = 0, limit = 25, ...args }) {
  const query = createUrlQuery({
    offset,
    limit,
    ...reduceToValues(args),
  });
  return DataRequestMethods.executeDataRequest(`fees?${query}`, 'GET');
}

export function getDataProgramDetail(xid) {
  return DataRequestMethods.executeDataRequest(
    `dr/programDetails/${xid}`,
    'GET'
  );
}

export function getFilterData(type) {
  return DataRequestMethods.executeDataRequest(`filters?type=${type}`, 'GET');
}
export function getXID(cusip) {
  return DataRequestMethods.executeDataRequest(`dr/xid/${cusip}`, 'GET');
}

export function getOpenClose(cusip) {
  return DataRequestMethods.executeDataRequest(`dr/openClose/${cusip}`, 'GET');
}

export function getCustodian(xid) {
  return DataRequestMethods.executeDataRequest(`dr/custodian/${xid}`, 'GET');
}

export function getQuote(xid) {
  return DataRequestMethods.executeDataRequest(`dr/quotes/${xid}`, 'GET');
}

export function getDividends(xid, start = 0, length = 25) {
  return DataRequestMethods.executeDataRequest(
    `dr/dividends/${xid}?limit=${length}&offset=${start}&announcementFilter=full`,
    'GET'
  );
}

export function getDividendsFinalAnouncements(xid, start = 0, length = 25) {
  return DataRequestMethods.executeDataRequest(
    `dr/dividends/${xid}?limit=${length}&offset=${start}&announcementFilter=final`,
    'GET'
  );
}

export function getSearch(query) {
  return DataRequestMethods.executeDataRequest(`search?q=${query}`, 'GET');
}

export function getBrokerForm(xid) {
  return DataRequestMethods.executeDataRequest(`dr/brokerForms/${xid}`, 'GET');
}

export function getFees(xid, start = 0, length = 25) {
  return DataRequestMethods.executeDataRequest(
    `dr/fees/${xid}?limit=${length}&offset=${start}`,
    'GET'
  );
}

export function getAllEvents(cusip, start = 0, length = 25) {
  return DataRequestMethods.executeDataRequest(
    `dr/allEvents/${cusip}?limit=${length}&offset=${start}`,
    'GET'
  );
}

export function getTopTradingVolumes(query) {
  return DataRequestMethods.executeDataRequest(
    `index/topTradeVolume?exchange=${query}`,
    'GET'
  );
}

export function getOtherCorporateActions(
  cusip,
  start = 0,
  length = 25,
  isFinal = false
) {
  return DataRequestMethods.executeDataRequest(
    `dr/otherCorporateActions/${cusip}?limit=${length}&offset=${start}${
      isFinal ? '&announcementFilter=final' : ''
    }`,
    'GET'
  );
}

export function getRelatedSecurities(xid) {
  return DataRequestMethods.executeDataRequest(
    `dr/relatedSecurities/${xid}`,
    'GET'
  );
}

export function getTypeDr(cusip) {
  return DataRequestMethods.executeDataRequest(`dr/level/${cusip}`);
}

export function getOpenClosedBook(cusip) {
  return DataRequestMethods.executeDataRequest(
    `dr/booksOpenClosed/${cusip}`,
    'GET'
  );
}
export function getTradingVolume(cusip) {
  return DataRequestMethods.executeDataRequest(
    `dr/tradingVolume/${cusip}`,
    'GET'
  );
}

export function getOwnerships(cusip) {
  return DataRequestMethods.executeDataRequest(`dr/ownership/${cusip}`, 'GET');
}

export function getOtherCorporateActionsFullHistory(cusip) {
  return DataRequestMethods.executeDataRequest(
    `dr/otherCorporateActions/${cusip}`,
    'GET'
  );
}

export function getBrockerForms(type, args) {
  const query = createUrlQuery({
    ...reduceToValues(args),
  });
  return DataRequestMethods.executeDataRequest(
    `brokerForms?type=${type}&${query}`,
    'GET'
  );
}

export function getHeadroomFile() {
  return DataRequestMethods.executeDataRequest(`headroom`, 'GET');
}

export function getDrNotifications(cusip) {
  return DataRequestMethods.executeDataRequest(
    `dr/notifications/${cusip}`,
    'GET'
  );
}

export function getLadingStats() {
  return DataRequestMethods.executeDataRequest('index/stats', 'GET');
}

export function getYear() {
  return DataRequestMethods.executeDataRequest('getDate', 'GET');
}
