import PropTypes from 'prop-types';
import React from 'react';

import {DatePicker} from '@jpm-adr/pattern-library';

import Field from './Field';
import Select from '../SelectTest/Select';

const componetMap = { select: Select, datePicker: DatePicker};

export default function FilterField(props) {
  const { label, onClear, type, filter, ...other } = props;

  const Component = componetMap[type];

  return (
    <Field htmlFor={other.name} label={label}>
      <Component label={label} {...other} id={other.name} type={filter} clearValue={onClear} />
    </Field>
  );
}

FilterField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  filter: PropTypes.string.isRequired,
  onClear: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['select']).isRequired,
};
