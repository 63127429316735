import PropTypes from 'prop-types';
import React from 'react';

import Layout from './Layout';

const SectionDisplay = ({ match }) => {
  return (
    <div>
      <Layout match={match} />
    </div>
  );
};

SectionDisplay.propTypes = {
  match: PropTypes.shape({}).isRequired,
};
export default SectionDisplay;
