import React from 'react';
import propTypes from 'prop-types';

import HeaderProfileData from './HeaderProfileData';
import PDFButton from './PrintPDFButton';

import '../../styles/components/HeaderProfile.scss';

const HeaderProfile = props => {
  const { headerCusip, xid } = props;
  return (
    <section className="columns section-header">
      <div className="column is-hidden-touch column-hidden side-margis margin-1">
        &nbsp;
      </div>
      <div className="main-content-wrapper">
        <HeaderProfileData cusip={headerCusip} />
        <PDFButton xid={xid} />
      </div>
      <div className="column is-hidden-touch column-hidden side-margis margin-2">
        &nbsp;
      </div>
    </section>
  );
};

HeaderProfile.propTypes = {
  headerCusip: propTypes.string.isRequired,
  xid: propTypes.string.isRequired,
};

export default HeaderProfile;
