import React from 'react';

import {Error404} from '@jpm-adr/pattern-library';

import '../../styles/components/Page404.scss'

const DownloadRedirectPage = ()=>{
  const focusInput = component =>{
    if(component){
      component.focus()
    }
  };
  const closeMethod = ()=>{
    window.close()
  };
  return(
    <section className="Page404-wrapper">
      <Error404
        customCSS=" Dark__Message-Subtitle"
        errorTitle=""
        errorSubtitle="Document Not Found"
        errorMessage="Either something went wrong or the document doesn´t exist."
        action={
          (
            <button
              type="button"
              ref={focusInput}
              tabIndex="0"
              className="Error404__Button"
              Aria-label="Close Page"
              onClick={closeMethod}
            >
              Close
            </button>
          )
        }
      />
    </section>
  )
}

export default DownloadRedirectPage;
