import '../../styles/components/HeadingLayout.scss';
import PropTypes from 'prop-types';
import React from 'react';

const HeadingLayout = ({children, title}) => {
  return (
    <div className="heading-layout core">
      <section className="columns section-header">
        <div className="column is-hidden-touch column-hidden side-margis margin-1">
          &nbsp;
        </div>
        <div className="column main-content-wrapper heading-layout-header">
          <div className="margin-mobile">
            {title}
          </div>
        </div>
        <div className="column is-hidden-touch column-hidden side-margis margin-2">
          &nbsp;
        </div>
      </section>
      <section className="columns margin-mobile">
        <div className="column is-hidden-touch column-hidden side-margis margin-1">
          &nbsp;
        </div>
        <div className="column main-content-wrapper heading-layout-main-wrapper">
          {children}
        </div>
        <div className="column is-hidden-touch column-hidden side-margis margin-2">
          &nbsp;
        </div>
      </section>
    </div>
  );
};

HeadingLayout.defaultProps = {
  children: <span />,
}

HeadingLayout.propTypes = {
  children: PropTypes.element,
  title: PropTypes.element.isRequired
};

export default HeadingLayout;
