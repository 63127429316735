export const prefix = '/issuerservices';
export const subSections = {
  summary: {
    subNavigations: [],
  },
  activity: {
    subNavigations: [
      {
        id: 'monthly',
        sectionName: 'Monthly',
      },
      {
        id: 'daily',
        sectionName: 'Daily',
      },
    ],
  },
  ownership: {
    subNavigations: [],
  },
  dividends: {
    subNavigations: [
      {
        id: 'finalAnnouncement',
        sectionName: 'Final Announcement',
      },
      {
        id: 'fullHistory',
        sectionName: 'Full History',
      },
    ],
  },
  library: {
    subNavigations: [
      {
        id: 'monthlyReports',
        sectionName: 'Monthly Reports',
      },
      {
        id: 'dailyReports',
        sectionName: 'Daily Reports',
      },
    ],
  },
  alerts: {
    subNavigations: [
      {
        id: 'sentAlertsHistory',
        sectionName: 'Sent Alerts History',
      },
      {
        id: 'manageRecipients',
        sectionName: 'Manage Recipients',
      },
    ],
  },
};
export const defaultCusip = '055622104';

export const defaultApiURL =
  'https://qa-api.markitdigital.com/jpmadr-private/v1';

export const defaultApiAlertsURL = 'https://qa-api.markitdigital.com/alerts/v1';

export const SAMLURL = 'https://sso-mod.dev1.mdgapp.net/sp/startSSO.ping?PartnerIdpId=cmc20asfb7:SAML20:Janus&TargetResource=';

export const alertDescriptions = {
  8: 'End of Month Summary',
  16: 'End of Week Summary',
  32: 'End of Day Summary'
};
