import '../../styles/components/HeadroomFile.scss';
import React from 'react';
import { FileButtons } from '@jpm-adr/pattern-library';

import IHSComponent from '../baseComponent/BaseComponent';
import { getHeadroomFile } from '../../utils/api';

import { URLbyDomain } from '../../utils/constants';
import { dateFullShortMonthNoTime } from '../../utils/format';

class HeadroomFile extends IHSComponent {
  constructor() {
    super();
    this.state = {
      loading: true,
      available: false,
      headroomInfo: { items: [] },
    };
    this.getHeadroomFile = this.getHeadroomFile.bind(this);
    this.fileItem = this.fileItem.bind(this);
  }

  componentDidMount() {
    this.getHeadroomFile();
  }

  getHeadroomFile() {
    getHeadroomFile()
      .then(data =>
        this.setState({
          available: true,
          loading: false,
          headroomInfo: data,
        })
      )
      .catch(() => this.setState({ available: false, loading: false }));
  }

  fileItem(item) {
    const { title, date, type = 'pdf', docTag } = item;

    if (!docTag) return <p>Not Available</p>

    const buttonsInfo = {};
    
    let downloadTitle = docTag.match(/HeadroomAvailability([0-9]*)/g);
    downloadTitle = downloadTitle
      ? downloadTitle[0]
      : docTag.replace('adr.com.', '');
    buttonsInfo[type] = true;
    buttonsInfo[`${type}Link`] = `${URLbyDomain}/dm/document?docTag=${docTag}&fileName=${downloadTitle}`;
    buttonsInfo.title = downloadTitle;
    

    return (
      <div className="document">
        <div className="left-align">
          <a
            href={`${URLbyDomain}/dm/document?docTag=${docTag}&fileName=${downloadTitle}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {title || 'JPM Headroom Availability Report'}
          </a>
          {
            date && (
            <div className="file-date">
              {dateFullShortMonthNoTime(date)}
            </div>
          )}          
        </div>
        <div className="right-align">
          <FileButtons buttonsInfo={buttonsInfo} />
        </div>
      </div>
    );
  }

  render() {
    let {
      loading,
      headroomInfo: { items },
    } = this.state;

    if (loading) return null;

    if (!items || items.length < 1) items.push('No Data');
    
    return (
      <div className="headroom-download-section">
        {items.map(item => this.fileItem(item))}
      </div>
    );
  }
}

export default HeadroomFile;
