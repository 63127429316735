export default {
  toAPI: (unParsedParams = {}, xid = null, destinationId = null) => {
    // Parse data from component, saved in the state;
    // Keys are the parameter name that the API receives

    const symbolCriteria = {
      'Symbols.Type': 'symbol',
      'Symbols.Symbol': xid,
    };
    const destinations = [destinationId];

    const items = [
      { id: 573, string: 'sharedFlow' }, // sharedFlow = shared alert for issued, cancelled, low and high
      { id: 599, string: 'endOfDaySummary' },
      { id: 574, period: 16, string: 'endOfWeekSummary' },
      { id: 574, period: 8, string: 'endOfMonthSummary' },
    ];
    let criteria573 = {
      'Shares.Issued': 'drIssuance',
      'Shares.Cancelled': 'drCancellation',
      'Limit.Low': 'drLowLimit',
      'Limit.High': 'drHighLimit',
    };
    criteria573 = Object.keys(criteria573).reduce(
      (obj, key) => {
        obj[key] = parseFloat(
          (unParsedParams[`checkbox.${criteria573[key]}`] &&
            unParsedParams[`input.${criteria573[key]}`]) ||
            0
        );
        unParsedParams[`checkbox.sharedFlow`] =
          unParsedParams[`checkbox.sharedFlow`] ||
          unParsedParams[`checkbox.${criteria573[key]}`];
        return obj;
      },
      { Period: 32 }
    );

    return items.map(item => ({
      item: item.id,
      destinations,
      criteria: {
        ...(item.id === 573 ? { ...criteria573 } : {}),
        ...(item.id === 574 ? { Period: item.period } : {}),
        ...symbolCriteria,
      },
      itemId: unParsedParams[`itemId.${item.string}`],
      send: unParsedParams[`checkbox.${item.string}`],
    }));
  },
  fromArray: items =>
    items.reduce((result, item) => {
      const { criteria = {}, itemId } = item;
      const { limit = {}, shares = {} } = criteria;
      const { high = 0, low = 0 } = limit;
      const { cancelled = 0, issued = 0 } = shares;
      switch (item.item) {
        case 573:
          if (low) {
            result['checkbox.drLowLimit'] = true;
            result['input.drLowLimit'] = low;
          }
          if (high) {
            result['checkbox.drHighLimit'] = true;
            result['input.drHighLimit'] = high;
          }
          if (cancelled) {
            result['checkbox.drCancellation'] = true;
            result['input.drCancellation'] = cancelled;
          }
          if (issued) {
            result['checkbox.drIssuance'] = true;
            result['input.drIssuance'] = issued;
          }
          result['itemId.sharedFlow'] = itemId;
          break;
        case 599:
          result['checkbox.endOfDaySummary'] = true;
          result['itemId.endOfDaySummary'] = itemId;
          break;
        case 574:
          if(criteria.period === 32 ) {
              result['checkbox.endOfDaySummary'] = true;
              result['itemId.endOfDaySummary'] = itemId;
          } else if(criteria.period === 16) {
              result['checkbox.endOfWeekSummary'] = true;
              result['itemId.endOfWeekSummary'] = itemId;
          } else if(criteria.period === 8) {
              result['checkbox.endOfMonthSummary'] = true;
              result['itemId.endOfMonthSummary'] = itemId;
          }
      }
      return result;
    }, {}),
};
