import PropTypes from 'prop-types';
import React from 'react';

import ProgressBar from '../progressBar/ProgressBar';

import '../../../styles/IssuerServices/components/TopFiftyBar.scss';

import ApiStates from '../../../utils/apiStatesWrapper';
import { getOwnershipTotals } from '../../utils/apiCalls';
import {
  numberComma,
  numberMagnitude,
  numberSignedName,
} from '../../../utils/format';

const TopFiftyBar = props => {
  const {
    data: {
      drsOutstanding,
      drsTotals,
      marketValue,
      percentHeld,
      positionChange
    }
  } = props;

  const percentage = Math.min(100, percentHeld | 0);
  return (
    <div className="ownership__bar">
      <div className="top-50-totals" tabIndex="0" onMouseDown={e => e.preventDefault()}>{/*eslint-disable-line*/}{/*accesibility needed focusable area*/}
        <h3 className="ownership__subtitle">Top 50 Totals</h3>
        <div className="comparation-container">
          <div className="quote__details holdings">
            <p className="quote__number">
              <span className="quote__cyphers quote__cyphers--comparative">
                {numberComma(drsTotals)}
              </span>
            </p>
            <div className="quote__text">Total DRs</div>
          </div>

          <div className="quote__details position">
            <p className="quote__number">
              <span
                className={`quote__cyphers position-change-${numberSignedName(
                  positionChange
                )}`}
              >
                {numberComma(positionChange)}
              </span>
            </p>
            <div className="quote__text">Position Change</div>
          </div>

          <div className="quote__details market">
            <p className="quote__number">
              <span className="quote__cyphers">
                {numberMagnitude(marketValue, 2)} USD
              </span>
            </p>
            <div className="quote__text">Market Value</div>
          </div>
        </div>
      </div>
      <div className="top-50-vs-outstanding" tabIndex="0" onMouseDown={e => e.preventDefault()}>{/*eslint-disable-line*/}{/*accesibility needed focusable area*/}
        <h3 className="ownership__subtitle">Top 50 Vs. Outstanding</h3>
        <div className="comparation-container">
          <div className="quote__details holdings">
            <p className="quote__number">
              <span className="quote__cyphers quote__cyphers--comparative">
                {percentage}% / {numberComma(drsTotals)}
              </span>
            </p>
            <div className="quote__text">Top 50 Reported DR Holdings</div>
          </div>

          <ProgressBar percentage={percentage} width="340px" />

          <div className="quote__details outstandings">
            <p className="quote__number">
              <span className="quote__cyphers">
                {numberComma(drsOutstanding)}
              </span>
            </p>
            <div className="quote__text">DRs Outstanding</div>
          </div>
        </div>
      </div>
    </div>
  );
};

TopFiftyBar.defaultProps = {
  data: {
    drsTotals: null,
    positionChange: null,
    marketValue: null,
    drsOutstanding: null,
  },
};

TopFiftyBar.propTypes = {
  data: {
    drsTotals: PropTypes.string,
    positionChange: PropTypes.string,
    marketValue: PropTypes.string,
    drsOutstanding: PropTypes.string,
  },
};

const config = {
  WrappedComponent: TopFiftyBar,
  requestApi: ({ cusip }) => getOwnershipTotals(cusip),
  custom: { containerClass: 'TopFiftyBar' },
};

export default ApiStates(config);
