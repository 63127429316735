import React from 'react';
import propTypes from 'prop-types';
import {
  FileButtons,
  NoDataAvailable,
  withLoadingText,
} from '@jpm-adr/pattern-library';
import { URLbyDomain } from '../../utils/constants';
import { executeDataRequest } from '../../utils/dataRequestMethods';
import { withApiRequest } from '../../utils/apiWithHOC';
import { withErrorBoundary } from '../../utils/errorBoundary';

import '../../styles/components/BrokerForm.scss';

const BrokerForm = props => {
  const { isLoading, data, xid } = props;

  const Content = () => {
    if (!data || (data && Object.keys(data).length === 0)) {
      return (
        <NoDataAvailable
          messageTitle=''
          customMessageTitleClass="Data__Not__Found-Title"
          customWrapperCSS="No__Data__Available-Custom-Wrapper"
          message="There are no applicable forms for this DR program."
          messageStyles="Data__Not__Found-Message"
          wrapperClass="Data__Not__Found"
        />
      );
    }

    return (
      <React.Fragment>
        {data.map(item => {
          const { cmsId, feedId, title, documentTag } = item;
          const fullURL=`${URLbyDomain}${cmsId?'/cms/document?cmsId='+cmsId:'/dm/document?feedId='+feedId+'&docTag='+documentTag}`
          return (
            <div className="column broker-form" key={cmsId || feedId}>
              <a
                href={fullURL}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>{title}</span>
              </a>
              <FileButtons
                buttonsInfo={{
                  pdf: true,
                  pdfLink:fullURL,
                  xbrl: '',
                }}
              />
            </div>
          );
        })}
      </React.Fragment>
    );
  };

  const ContentWithLoadingData = withLoadingText(Content);

  return (
    <section className="custodian broker is-multiline is-hidden-mobile">
      <h2 className="column is-12">Broker Forms</h2>
      <ContentWithLoadingData
        isLoading={isLoading && xid}
        customStyles={{ height: '230px', width: '467px', borderWidth: '0px' }}
      />
    </section>
  );
};

BrokerForm.defaultProps = {
  isLoading: true,
  data: [
    {
      title: 'Limited Cancellation  2018',
      document: '1',
    },
    {
      title: 'Withdrawal Certification Form',
      document: '2',
    },
    {
      title: 'Limited Cancellation  2018',
      document: '3',
    },
  ],
  xid: null,
};

BrokerForm.propTypes = {
  isLoading: propTypes.bool,
  data: propTypes.arrayOf(
    propTypes.shape({
      title: propTypes.string,
      document: propTypes.string,
    })
  ),
  xid: propTypes.string,
};

export default withErrorBoundary(
  withApiRequest(
    BrokerForm,
    ({ xid }) =>
      xid ? executeDataRequest(`dr/brokerForms/${xid}`, 'GET') : null
  )
);
