import PropTypes from 'prop-types';
import React from 'react';

import {
  numberComma,
  numberCommaSigned,
  numberSignedName,
  percent,
  price,
  shortMonthYear,
  substract,
} from '../../../utils/format';

import TableLoader from '../../components/tables/TableLoader';
import { getActivityMonthlyReports } from '../../utils/apiCalls';

class MonthlyTable extends React.Component {
  constructor() {
    super();
    this.state = {
      filterBy: this.getYearsFilter()[0],
    };
    this.prepareConfigTable = this.prepareConfigTable.bind(this);
    this.prepareApiRequest = this.prepareApiRequest.bind(this);
    this.onSelectYear = this.onSelectYear.bind(this);
  }

  onSelectYear() {
    return (error, yearSelected) => {
      this.setState({
        filterBy: yearSelected,
      });
    };
  }

  getYearsFilter() {
    return [0, 1, 2, 3, 4, 5].map(year => {
      const yearConfig = substract({ year }, 'YYYY');
      return { label: yearConfig, value: yearConfig };
    });
  }

  prepareConfigTable() {
    return {
      columns: [
        {
          text: <div>Month</div>,
        },
        {
          text: (
            <React.Fragment>
              <div>DRs</div> <div>Outstanding</div>
            </React.Fragment>
          ),
        },
        {
          text: <div>Issuance</div>,
        },
        {
          text: <div>Cancellation</div>,
        },
        {
          text: <div>Net Change</div>,
        },
        {
          text: <div>Total Activity</div>,
        },
        {
          text: <div>Volume</div>,
        },
        {
          text: (
            <React.Fragment>
              <div>DR Price at </div>
              <div>Month&apos;s Close</div>
              <div>(USD)</div>{' '}
            </React.Fragment>
          ),
        },
        {
          text: <div>DR Value (USD)</div>,
        },
        {
          text: (
            <React.Fragment>
              <div>Underlying </div>
              <div>Value (USD)</div>
            </React.Fragment>
          ),
        },
        {
          text: (
            <React.Fragment>
              <div>DR % of</div>
              <div> Market Cap</div>
            </React.Fragment>
          ),
        },
      ],
      rows: [
        {
          text: data => shortMonthYear(data.date),
        },
        {
          text: data => numberComma(data.drsOutstanding),
        },
        {
          text: data => numberComma(data.issuance),
        },
        {
          text: data => numberComma(data.cancellation),
        },
        {
          text: data => (
            <div
              className={`position-change-${numberSignedName(data.netChange)}`}
            >
              {numberCommaSigned(data.netChange)}
            </div>
          ),
        },
        {
          text: data => numberComma(data.totalActivity),
        },
        {
          text: data => numberComma(data.volume),
        },

        {
          text: data => price(data.drPrice),
        },
        {
          text: data => numberComma(data.drValue),
        },
        {
          text: data => numberComma(data.underlyingValue),
        },
        {
          text: data => percent(data.drPercentMarketCap, 1),
        },
      ],
    };
  }

  prepareApiRequest() {
    const { cusip } = this.props;
    const { filterBy } = this.state;

    return {
      requestApi: cusip ? getActivityMonthlyReports : null,
      requestApiConfig: { cusip, year: filterBy.label },
    };
  }

  render() {
    const {
      props,
      prepareApiRequest,
      prepareConfigTable,
      state: { filterBy },
      getYearsFilter,
      onSelectYear,
    } = this;
    const configs = {
      configTable: prepareConfigTable(),
      filters: [
        {
          id: 'singleSelect',
          options: getYearsFilter(),
          value: filterBy,
          onChange: onSelectYear(),
          label: 'Select year with up and down key, ',
          isSearchable: false,
        },
        {
          id: 'exportButton',
          hasModal: true,
        },
      ],
      immediateRequest: true,
    };
    const tableLoaderConfig = { ...configs, ...props, ...prepareApiRequest() };
    return <TableLoader {...tableLoaderConfig} />;
  }
}

MonthlyTable.propTypes = {
  cusip: PropTypes.string.isRequired,
  headerInfo: PropTypes.objectOf.isRequired,
};

export default MonthlyTable;
