import React, { Fragment } from 'react';

import { numberMagnitude, priceMagnitude } from '../../utils/format';

import '../../styles/components/Hero.scss';

const Hero = ({ error, loading, totalPreviousDayDrsTraded, totalPreviousDayVolume }) => (
  <Fragment>
    <section className="hero">
      <div className="hero-body">
        <div className="container has-text-centered">
          <h1 className="title">
            Your Passport to<br />Global Markets
          </h1>
          <ul className={`hero-content is-hidden-mobile ${loading ? 'visibility-hidden' : ''}`}>
            <li>
              <p className="title">
                {
                  error
                    ? '--'
                    : priceMagnitude(totalPreviousDayDrsTraded, 2)
                }
              </p>
              <p className="title-key">Value of DRs Traded*</p>
            </li>
            <li>
              <p className="title">
                {
                  error
                    ? '--'
                    : numberMagnitude(totalPreviousDayVolume, 2)
                }
              </p>
              <p className="title-key">Total DRs Traded*</p>
            </li>
          </ul>
          <h2 className="subtitle is-hidden-mobile">
            One of J.P. Morgan&apos;s core principles is &ldquo;first class business in a first<br />
            class way.&rdquo; DR issuers have relied on us for over 90 years for the trusted<br />
            advice and long-term partnership unique to J.P. Morgan.
          </h2>
        </div>
      </div>
    </section>
  </Fragment>
);

export default Hero;
