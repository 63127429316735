import React, { Component } from 'react';
import propTypes from 'prop-types';
import {
  LoadMoreButton,
  NoDataAvailable,
  Table,
} from '@jpm-adr/pattern-library';
import { URLbyDomain } from '../../utils/constants';
import { dateFullShortMonthNoTime } from '../../utils/format';
import { getOtherCorporateActions } from '../../utils/api';
import { withErrorBoundary } from '../../utils/errorBoundary';

import '../../styles/components/OtherCorporateActions.scss';

let workIdGroups = {};

const stampGroups = obj => {
  obj.isLastWorkIdMember = !obj.workId ||
    workIdGroups[obj.workId] &&
    obj.sortOrder === workIdGroups[obj.workId].sortOrder;
  obj.customCSSClass =
    `${obj.isLastWorkIdMember ? `${obj.customCSSClass} isLastWorkIdMember` : obj.customCSSClass}`;
  return obj;
};

const notices = (noticesData, sequenceNo) => {
  const url = URLbyDomain + '/cms/document?cmsId=';
  const pdf = noticesData.pdf || '';
  const xbrl = noticesData.xbrl || '';
  const sequenceNoParam = sequenceNo ? `&sequenceNo=${sequenceNo}` : '';
  return {
    hasComponents: true,
    hasNotices: true,
    pdf,
    xbrl,
    pdfLink: `${url}${pdf}${sequenceNoParam}`,
    xbrlLink: `${url}${xbrl}${sequenceNoParam}`,
  };
};
const datesNotices = datesData => ({
  data: dateFullShortMonthNoTime(datesData),
});

const emptyField = obj => ({
  data: String(obj),
});

const undefinedField = () => ({
  data: '--',
});

const objectTransormtionsMappedFunctions = new Map();
objectTransormtionsMappedFunctions.set(undefined, undefinedField);
objectTransormtionsMappedFunctions.set(null, undefinedField);
objectTransormtionsMappedFunctions.set('notices', notices);
objectTransormtionsMappedFunctions.set('announcementDate', datesNotices);
objectTransormtionsMappedFunctions.set('eventDate', datesNotices);

const objectTransormation = obj => (field, customCSS) => {
  let newObj = {};
  const sequenceNo = obj.sequenceNo;
  if (obj[field] === undefined || obj[field] === "") {
    newObj = undefinedField();
  } else {
    newObj = objectTransormtionsMappedFunctions.has(field)
      ? objectTransormtionsMappedFunctions.get(field)(obj[field], sequenceNo)
      : emptyField(obj[field]);
  }
  newObj.customClass = customCSS;
  newObj = stampGroups(newObj);
  return newObj;
};

class OtherCorporateActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loadMore: true,
      isLoading: true,
    };
    this.getData = this.getData.bind(this);
    this.getData({ currentPosition: 0, pageSize: 25 });
    this.LoadingWithState = (
      <LoadMoreButton
        className="dr-loading-button"
        fetchFunction={this.getData}
        position={0}
        pageSize={25}
      />
    );
  }
  getData({ currentPosition, pageSize }) {
    const { cusip } = this.props;
    const { data } = this.state;
    return getOtherCorporateActions(cusip, currentPosition, pageSize, true)
      .then(response => {
        const { items = [] } = response;
        this.setState({
          data: data.concat(items),
          loadMore: response.pagination.hasMore,
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          isLoading: false,
        });
      });
  }

  render() {
    const { isLoading, data, loadMore } = this.state;
    if (
      !isLoading &&
      (data === null || (data && Object.keys(data).length === 0))
    ) {
      return (
        <section>
          <NoDataAvailable
            messageTitle="No data available"
            customMessageTitleClass="Data__Not__Found-Title"
            customWrapperCSS="No__Data__Available-Custom-Wrapper"
            message="There is no data available for this specific program."
            messageStyles="Data__Not__Found-Message"
            wrapperClass="Data__Not__Found"
          />
        </section>
      );
    }

    function adapter(apiData = []) {
      return apiData
        .map(current => {
          workIdGroups = {};
          const { sortOrder, workId } = current;
          const isLastValue = workIdGroups[workId] ? true : false;
          workIdGroups[workId] = {
            isLastValue,
            sortOrder
          };
          return current;
        })
        .map(obj => {
          const fieldTransformation = objectTransormation(obj);
          return [
            fieldTransformation(
              'eventDate',
              'other-corporate-actions-td gutter-40'
            ),
            fieldTransformation('action', 'other-corporate-actions-td gutter-40'),
            fieldTransformation(
              'announcementDate',
              'other-corporate-actions-td gutter-40'
            ),
            fieldTransformation('status', 'other-corporate-actions-td gutter-40'),
            fieldTransformation('notices', 'other-corporate-actions-notices'),
          ];
        });
    }

    const OtherCorporateActionsData = adapter(data || []);
    const customCSSClass = ' otherCorporateActions margin-table-corpotate-actions-full';
    const OtherCorporateActionsTitles = [
      {
        id: 'OCA-Event-Date',
        title: 'Event Date',
        customCSSClass,
      },
      {
        id: 'OCA-Action',
        title: 'Action',
        customCSSClass,
      },
      {
        id: 'OCA-Announcement-Date',
        title: 'Announcement Date',
        customCSSClass,
      },
      {
        id: 'OCA-Status',
        title: 'Status',
        customCSSClass: `${customCSSClass} status`,
      },
      {
        id: 'OCA-Notices',
        title: 'Notices',
        customCSSClass,
      },
    ];

    return (
      <React.Fragment>
        <Table
          isLoading={isLoading}
          customStyles={{ height: '440px' }}
          values={OtherCorporateActionsData}
          tableId="other-corporate-actions-table"
          tableCustomCss="fees-table other-corporate-actions-table table-component table is-striped is-narrow is-fullwidth"
          tableTitles={OtherCorporateActionsTitles}
          tableWrapperClass="table-wrapper-dividends"
        />
        {isLoading || !loadMore ? null : this.LoadingWithState}
      </React.Fragment>
    );
  }
}

OtherCorporateActions.propTypes = {
  cusip: propTypes.string.isRequired,
};

export default withErrorBoundary(OtherCorporateActions);
