import React from 'react';

import {Error404} from '@jpm-adr/pattern-library';

import '../../styles/components/Page404.scss'

const Page404 = ()=>{
  const focusInput = component =>{
    if(component){
      component.focus()
    }
  };
  return(
    <section className="Page404-wrapper">
      <Error404
        action={
          (
            <a
              href="/"
              ref={focusInput}
              tabIndex="0"
              className="Error404__Button"
              Aria-label="Return to ADR.com Home Page"
            >
              Return to ADR.com Home Page
            </a>
          )
        }
      />
    </section>
  )
}

export default Page404;
