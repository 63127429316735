import PropTypes from 'prop-types';
import React from 'react';

import { Button } from '@jpm-adr/pattern-library';

import ModalExport from '../modalExport/ModalExport';
import newAlertsConfig from './fields/config/newAlerts';
import newAlertsParser from './fields/parser/newAlerts';
import newAlertsValidations from './fields/validations/newAlerts';
import utils from '../../utils/utils';
import {
  CheckBoxAndInputAndTextAndText,
  Separator,
  SetFor,
} from './fields/fieldsFactory';

import {
  deleteAlertsSubscriptions,
  getAlertsConfigurations,
  getAlertsProfile,
  getAlertsSubscriptions,
  postAlertsDestination,
  postNewAlert,
  putAlertsSubscriptions,
} from '../../utils/apiCalls';

import '../../../styles/IssuerServices/components/NewAlerts.scss';

const defaultState = {
  showApiError: false,
  openModal: false,
  foundError: true,
  fields: {
    setFor: null,
    'input.drIssuance': null,
    'input.drCancellation': null,
    'input.drLowLimit': null,
    'input.drHighLimit': null,
  },
  validationErrors: {},
};

class NewAlerts extends React.Component {
  constructor(props) {
    super(props);
    this.getDefaultState = this.getDefaultState.bind(this);
    this.state = this.getDefaultState();
    this.getFooter = this.getFooter.bind(this);
    this.captureField = this.captureField.bind(this);
    this.validateFields = this.validateFields.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.getModal = this.getModal.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.getProfile = this.getProfile.bind(this);
    this.foundErrors = this.foundErrors.bind(this);
    this.getDestinationIndex = this.getDestinationIndex.bind(this);
    this.apiCalls = this.apiCalls.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { openModal } = this.state;
    if (prevState.openModal !== openModal) {
      this.getProfile();
    }
  }

  onSuccess(data) {
    let [configs, profile, subscriptions] = data;
    let items = subscriptions.items.map(info => ({
      email: profile.addresses.find(
        address => address.destinationId === info.destinationId
      ).address,
      date: info.insertDate,
      description: utils.getAlertDescription(
        info.item,
        info.criteria.period,
        configs.items.find(config => config.item === info.item).description
      ),
      destinationId: info.destinationId,
    }));

    const emails = items.reduce((obj, item) => {
      obj[item.email] = item.destinationId;
      return obj;
    }, {});
    const maxIndex = items.reduce((max, item) => {
      const v = parseInt(item.destinationId.split('_')[1]);
      return v > max ? v : max;
    }, -1);
    this.setState({ emails, maxIndex });
  }

  getDefaultState() {
    const { data } = this.props;
    const email = data && data.email;

    const newDefault = data ? newAlertsParser.fromArray(data.sameEmail) : {};
    return {
      ...defaultState,
      fields: { ...defaultState.fields, ...newDefault, setFor: email },
    };
  }

  getFooter() {
    const { validationErrors } = this.foundErrors();
    return () => (
      <div className="actionButtonContainer">
        <Button
          aria-label="Pressing enter or spacebar key to cancel alerts modal."
          onClick={() => this.closeModal()}
          customClass="btn cancelButton"
        >
          <p>Cancel</p>
        </Button>
        <Button
          aria-label="Pressing enter or spacebar key to save set alerts."
          disabled={validationErrors.setFor}
          customClass="btn saveButton"
          onClick={() => this.validateFields()}
        >
          <p>Save</p>
        </Button>
      </div>
    );
  }

  getModal() {
    const {
      validationErrors = {},
      showApiError,
      fields: { setFor },
    } = this.state;
    const { data = {} } = this.props;
    return (
      <ModalExport
        title={data.title || 'Set New Alerts'}
        customClassName="newAlerts"
        footer={this.getFooter()}
        handleCloseModal={this.closeModal}
      >
        <SetFor
          {...newAlertsConfig.setFor}
          hasError={validationErrors[newAlertsConfig.setFor.type]}
          onChange={this.captureField('setFor')}
          showApiError={showApiError}
          value={setFor}
        />
        <Separator text="Choose alert types" />
        {newAlertsConfig.fields.map(rowFields => {
          const { inputProps, checkboxProps, type } = rowFields;
          const { [`input.${type}`]: hasInputError } = validationErrors;
          const {
            fields: {
              [`checkbox.${type}`]: hasCheckedBox,
              [`input.${type}`]: value,
            },
          } = this.state;
          const hasError = inputProps || !hasCheckedBox ? hasInputError : false;
          return (
            <CheckBoxAndInputAndTextAndText
              hasError={hasError}
              hasCheckedBox={hasCheckedBox}
              {...rowFields}
              inputProps={{
                ...inputProps,
                onChange: this.captureField(`input.${type}`),
                value,
              }}
              checkboxProps={{
                ...checkboxProps,
                onChange: this.captureField(`checkbox.${type}`, 'checked'),
              }}
            />
          );
        })}
        <Separator className="bottomSeparator" />
      </ModalExport>
    );
  }

  getProfile() {
    const { xid } = this.props;
    return Promise.all([
      getAlertsConfigurations(),
      getAlertsProfile(),
      getAlertsSubscriptions(xid),
    ]).then(this.onSuccess);
  }

  getDestinationIndex() {
    const {
      emails = {},
      maxIndex = 0,
      fields: { setFor },
    } = this.state;
    const index = emails[setFor] && emails[setFor].split('_')[1];
    return index || maxIndex + 1;
  }

  captureField(field, property = 'value') {
    const { fields: currentFields } = this.state;
    return evt => {
      const fields = { ...currentFields, [field]: evt.target[property] };
      this.setState({ fields, validationErrors: {} });
    };
  }

  openModal(e) {
    if (e) {
      if (e.type === 'keydown' && e.key !== 'Enter') return null;
      e.preventDefault();
    }
    return this.setState({ openModal: true });
  }

  closeModal() {
    const { emails, maxIndex } = this.state;
    const draftState = {
      openModal: false,
      ...this.getDefaultState(),
      emails,
      maxIndex,
    };
    this.setState(draftState);
  }

  foundErrors() {
    const validations = Object.keys(newAlertsValidations);
    const validationErrors = {};
    let foundError = false;
    validations.map(field => {
      const {
        fields: {
          [field]: fieldToValidate,
          [`checkbox.${field.replace('input.', '')}`]: checkboxToValidate,
        },
      } = this.state;
      const hasError = newAlertsValidations[field](
        fieldToValidate,
        checkboxToValidate
      );
      validationErrors[field] = hasError;
      foundError = foundError ? foundError : hasError;
      return field;
    });
    return { foundError, validationErrors };
  }

  apiCalls() {
    const index = this.getDestinationIndex();
    const { xid, onSave } = this.props;
    const { fields } = this.state;

    const destinationId = `${xid}_${index}`;
    const allSubscriptions = newAlertsParser.toAPI(fields, xid, destinationId);

    const newSubscriptions = allSubscriptions.filter(
      item => !item.itemId && item.send
    );
    const updateSubscriptions = allSubscriptions.filter(item => item.itemId);
    const calls = [];
    if (newSubscriptions.length > 0) calls.push(postNewAlert(newSubscriptions));
    updateSubscriptions.map(subs =>
      calls.push(
        subs.send
          ? putAlertsSubscriptions({ data: subs, itemId: subs.itemId })
          : deleteAlertsSubscriptions({ itemId: subs.itemId })
      )
    );
    Promise.all(calls)
      .then(() => {
        this.setState({ openModal: false }, () => {
          this.closeModal();
          onSave && onSave();
        });
      })
      .catch(() => {
        this.setState({ showApiError: true });
      });
  }
  validateFields() {
    const index = this.getDestinationIndex();
    const { xid } = this.props;
    const { fields } = this.state;
    const { foundError, validationErrors } = this.foundErrors();

    if (foundError) {
      return this.setState({ validationErrors });
    }

    const destinationId = `${xid}_${index}`;
    return postAlertsDestination({
      destinationId,
      data: {
        address: fields.setFor,
        format: 'TEXT',
      },
    })
      .then(this.apiCalls)
      .catch(() => {
        this.setState({ showApiError: true });
      });
  }

  render() {
    const { openModal } = this.state;
    const { actionItem } = this.props;
    return (
      <React.Fragment>
        {openModal && this.getModal()}
        {actionItem ? (
          actionItem({ onClick: this.openModal })
        ) : (
          <Button
            onClick={() => this.openModal()}
            customClass="btn saveButton openModalButton"
          >
            <p>Set New Alert</p>
          </Button>
        )}
      </React.Fragment>
    );
  }
}

NewAlerts.propTypes = {
  cusip: PropTypes.string.isRequired,
  xid: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default NewAlerts;
