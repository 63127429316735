import React from 'react';

const ExecutionDisclosure = () => {
  return (
    <div className="execution-disclosure">
      <h3>Execution Disclosure</h3>
      <p>
        J.P. Morgan acting as depositary for depositary receipt (“
        <strong>DR</strong>
        ”) programs may facilitate the sale and purchase of securities in
        accordance with the applicable deposit agreement or the terms and
        condition of an unsponsored depositary receipts program. J.P. Morgan
        also acts as registrar and dividend disbursing agent for certain New
        York Registered Share (“
        <strong>NYRS</strong>
        ”) programs of issuers incorporated in the Netherlands. The procedures
        set forth below apply to any sales/purchases required under sponsored or
        unsponsored DR programs or a NYRS appointment to the extent such
        sales/purchases are contemplated in the terms of such appointment. Sales
        or purchases may be executed in the jurisdiction of incorporation of a
        DR issuer or in the market where the DRs or NYRSs are listed and/or
        traded. Examples include but are not limited to:
      </p>
      <ul>
        <li>
          a sale in the local market of rights to subscribe to underlying equity
          shares of an Issuer, where such rights are not registered in the US
          (or other jurisdiction if registration is required);
        </li>
        <li>
          a sale of shares issued as a result of the acquisition of a DR issuer;
        </li>
        <li>
          a sale of corporate action-related entitlements that are not
          distributed to DR holders;
        </li>
        <li>
          the purchase of local shares with a foreign currency cash remittance
          that cannot be converted to US dollars; and
        </li>
        <li>
          a sale of securities underlying DRs that remain outstanding subsequent
          to the termination of a DR program.
        </li>
      </ul>
      <p>
        Any exceptions to these procedures will be made in accordance with J.P.
        Morgan’s policies.
      </p>
      <h3>Execution of sale or purchase of securities</h3>
      <p>
        If J.P. Morgan is required to endeavor to sell or purchase securities
        for the benefit of DR or NYRS holders, it may instruct the J.P. Morgan
        Equity Trading Desk (“
        <strong>JPM trading desk</strong>
        ”) acting as principal or agent to execute the transaction. JPM trading
        desk may charge a commission of up to 5 basis points (unless otherwise
        disclosed) calculated on the execution price (subject to rounding). If
        the JPM trading desk does not have the capability to buy or sell
        securities in the relevant market or is unable to perform the trade for
        any reason, J.P. Morgan may instruct its appointed local market
        custodian or a local broker to execute the sale or purchase, as the case
        may be. Any such local market custodian or local broker may charge a
        commission in relation to the execution of any such sale or purchase
        transaction. Sales or purchases executed through agents other than a JPM
        trading desk or J.P. Morgan’s local market custodian will only be made
        in accordance with the policies of J.P. Morgan.
      </p>
      <h3>Execution Period</h3>
      <p>
        J.P. Morgan will endeavor to execute the full sale/purchase during the
        applicable trading period and in the applicable markets of the security
        to the best of its ability. Sales transactions will only commence
        subsequent to confirmation by J.P. Morgan of receipt of the security to
        be sold.
      </p>
      <p>
        Absent a specific trading period, J.P. Morgan will instruct the JPM
        trading desk, the local custodian or the local broker, as the case may
        be to execute a sale or purchase as soon as reasonably practicable as
        market conditions will permit, endeavoring to complete such sale or
        purchase within five (5) business days. The execution price for the
        securities bought or sold will depend on market conditions, including
        but not limited to:
      </p>
      <ol type="a">
        <li>volume of securities subject to the trade;</li>
        <li>influence of the trade on market value of securities;</li>
        <li>historic trading volumes of the securities; and</li>
        <li>number of counterparties interested in the trade.</li>
      </ol>
      <p>
        J.P. Morgan does not guarantee that the securities will be purchased or
        sold or at any particular price.
      </p>
      <h3>Final Price</h3>
      <p>
        J.P. Morgan will base the final price (“
        <strong>Final Price</strong>
        ”) on the actual execution price for the sale or purchase as adjusted
        for costs and commissions as described below.
      </p>
      <p>
        The Final Price for executions of trades over a trading period of more
        than one day will be determined based on the weighted average of the
        final prices of all the sales/purchases of securities carried out on
        behalf of J.P. Morgan and on its instructions that occurred over each
        day of the trading period taking into account the highest and lowest
        price traded on all days of the trading period during normal trading
        hours of the exchange or market where the security trades. Any
        conversion of foreign currency into US dollars that may be required will
        be undertaken in accordance with the foreign exchange policies of J.P.
        Morgan in place at that time. Please consult the additional disclosure
        for more information: [Depositary Receipts FX Disclosure]
      </p>
      <p>
        The Final Price will be determined net of certain incurred costs or
        commissions, including but not limited to: (i) commission paid to a JPM
        trading desk, local custodian, local broker or other agent; (ii) stock
        exchange fees; (iii) applicable taxes and governmental charges; and (iv)
        any sale coordination fee of J.P. Morgan of up to 20 basis points
        calculated on the execution price (subject to rounding), with such fee
        to be assessed only in accordance with the terms and conditions of the
        applicable program and to the extent it would not cause the Final Price
        to fall outside of the range of prices for the security on that day
        during normal trading hours of the exchange or market where the security
        trades.
      </p>
      <h3>Disclaimer</h3>
      <p>
        J.P. Morgan reserves the right to change any of the above execution
        policies at any time as deemed necessary or appropriate and it may cease
        to offer any of the services referenced herein or add new services over
        time. This document is provided solely for informational purposes and
        provides a summary of the information contained therein. It is not a
        complete description of the transactions, processes and procedures
        referred to herein. Processes and procedures described in, and any other
        content of, this document are subject to change without notice. J.P.
        Morgan does not accept any obligation to update this document on time or
        at all whether such processes and procedures have changed or otherwise.
      </p>
    </div>
  );
};

export default ExecutionDisclosure;
