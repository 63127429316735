import ApiAlerts from './apiAlerts';
import ApiAuth from './apiAuth';

export const getInitialCusip = params =>
  ApiAuth.makeCall({
    method: 'get',
    endpoint: '/library/58724',
    params: { reportType: 'monthly', ...params },
  });

export const getUser = () =>
  ApiAuth.makeCall({
    method: 'get',
    endpoint: '/getUser',
  });

export const getXID = cusip =>
  ApiAuth.makeCall({
    endpoint: `/dr/xid/${cusip}`,
  }).then(response => {
    const { xid } = response;
    return xid;
  });

export const getLatestsReports = xid =>
  ApiAuth.makeCall({
    endpoint: `/summary/latestsReports/${xid}`,
  }).then(response => response.items || []);

export const getMonthlySummary = xid =>
  ApiAuth.makeCall({
    endpoint: `/summary/monthlySummary/${xid}`,
  });

export const getProgramDetails = cusip =>
  ApiAuth.makeCall({
    endpoint: `/summary/programDetails/${cusip}`,
  });

export const getRelationshipManager = xid =>
  ApiAuth.makeCall({
    endpoint: `/summary/relationshipManager/${xid}`,
  });

export const getRelatedSecurities = cusip =>
  ApiAuth.makeCall({
    endpoint: `/summary/relatedSecurities/${cusip}`,
  });

export const getQuoteBar = xid =>
  ApiAuth.makeCall({
    endpoint: `/summary/quotes/${xid}`,
  });

export const getF6 = xid =>
  ApiAuth.makeCall({
    endpoint: `/summary/f6Data/${xid}`,
  });

export const getHeader = cusip =>
  ApiAuth.makeCall({
    endpoint: `/dr/header/${cusip}`,
  });

export const getLibraryMonthlyReports = paramsRequest => {
  const { xid } = paramsRequest;
  const params = { ...paramsRequest };
  params.reportType = 'monthly';
  delete params.xid;
  return ApiAuth.makeCall({
    endpoint: `/library/${xid}`,
    params,
  });
};

export const getActivityMonthlyReports = paramsRequest => {
  const { cusip, ...params } = paramsRequest;
  params.activityType = 'monthly';
  return ApiAuth.makeCall({
    endpoint: `/activity/${cusip}`,
    params,
  });
};

export const getActivityDailyReports = paramsRequest => {
  const { cusip, ...params } = paramsRequest;
  params.activityType = 'daily';
  return ApiAuth.makeCall({
    endpoint: `/activity/${cusip}`,
    params,
  });
};

export const getLibraryDailyReports = paramsRequest => {
  const { xid } = paramsRequest;
  const params = { ...paramsRequest };
  params.reportType = 'daily';
  return ApiAuth.makeCall({
    endpoint: `/library/${xid}`,
    params,
  });
};

export const getDividensFull = (cusip, currentPosition, pageSize) => {
  const params = { currentPosition, pageSize };
  params.announcementFilter = 'full';
  return ApiAuth.makeCall({
    endpoint: `/dr/dividends/${cusip}`,
    params,
  });
};

export const getDividensFinal = (cusip, currentPosition, pageSize) => {
  const params = { currentPosition, pageSize };
  params.announcementFilter = 'final';
  return ApiAuth.makeCall({
    endpoint: `/dr/dividends/${cusip}`,
    params,
  });
};

export const getOwnershipTop = (cusip, params) => {
  return ApiAuth.makeCall({
    endpoint: `/ownership/top/${cusip}`,
    params,
  });
};

export const getOwnershipTotals = cusip => {
  return ApiAuth.makeCall({
    endpoint: `/ownership/totals/${cusip}`,
  });
};

export const getUserInfo = () => {
  return ApiAuth.makeCall({
    endpoint: `/user/info`,
  });
};

export const downloadFile = endpoint => {
  return ApiAuth.makeCall({
    endpoint,
    responseType: 'blob',
  });
};

///Alerts Calls

export const getHistoryAlerts = paramsRequest => {
  const { ...params } = paramsRequest;
  return ApiAlerts.makeCall({
    endpoint: `/history`,
    params,
  });
};

export const getAlertsProfile = () => {
  return ApiAlerts.makeCall({
    endpoint: `/profile`,
  });
};

export const getAlertsConfigurations = () => {
  return ApiAlerts.makeCall({
    endpoint: `/configurations`,
  });
};
export const getAlertsSubscriptions = xid => {
  return ApiAlerts.makeCall({
    endpoint: `/subscriptions/`,
    params: { venueXid: xid, limit: 1000 },
  });
};

export const postNewAlert = data => {
  return ApiAlerts.makeCall({
    method: 'post',
    endpoint: `/subscriptions`,
    data,
  });
};

export const putAlertsSubscriptions = ({data,itemId}) => {
  return ApiAlerts.makeCall({
    method: 'put',
    endpoint: `/subscriptions/${itemId}`,
    data,
  });
};

export const deleteAlertsSubscriptions = ({itemId}) => {
  return ApiAlerts.makeCall({
    method: 'delete',
    endpoint: `/subscriptions/${itemId}`,
  });
};

export const postAlertsDestination = ({ destinationId, data }) => {
  return ApiAlerts.makeCall({
    method: 'put',
    endpoint: `/profile/destinations/${destinationId}`,
    data,
  });
};

export const deleteAlertsDestination = destinationId => {
  return ApiAlerts.makeCall({
    method: 'delete',
    endpoint: `/profile/destinations/${destinationId}`,
  });
};

export const makeSearch = q => {
  return ApiAuth.makeCall({
    endpoint: `/search?q=${q}`,
  });
};
