import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { debounce } from 'lodash';

import {
  Button,
  NoResultsSearch,
  OutlineHandler,
  SearchBox,
  SearchResults,
} from '@jpm-adr/pattern-library';

import AccessTokenService from '../../utils/access-token-service';
import ApiConsumer from '../../../components/apiConsumer/ApiConsumer';
import SsoForm from '../ssoForm/SsoForm';
import { closeIcon } from '../../../components/header/Header';
import { getUserInfo, makeSearch } from '../../utils/apiCalls';
import { prefix, subSections } from '../../utils/constants';

const navigationPaths = Object.keys(subSections);

const MobileSearchIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 24 24"
    fill="#FFFFFF"
  >
    <path
      d="M16.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" //NOSONAR
    />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);

class Header extends React.Component {
  constructor() {
    super();
    this.state = {
      userInfo: {},
      querySearch: '',
      renderSSOForm: false
    };

    this.clear = this.clear.bind(this);
    this.clearAndFocus = this.clearAndFocus.bind(this);
    this.displayAndFocus = this.displayAndFocus.bind(this);
    this.displayAndFocusMobile = this.displayAndFocusMobile.bind(this);
    this.handleChange = debounce(this.handleChange, 250);
    this.setMobileSearchInputRef = this.setMobileSearchInputRef.bind(this);
    this.setMobileSearchButtonRef = this.setMobileSearchButtonRef.bind(this);
    this.setSearchInputRef = this.setSearchInputRef.bind(this);
    this.submitSSO = this.submitSSO.bind(this);
  }
  componentDidMount() {
    getUserInfo()
      .then(data => this.setState({ userInfo: data }))
      .catch(() =>
        this.setState({ userInfo: { externalId: 'Test USER(Not logged)' } })
      );
  }
  setSearchInputRef(element) {
    this.searchInput = element;
  }
  setMobileSearchButtonRef(element) {
    this.mobileSearchButton = element;
  }
  setMobileSearchInputRef(element) {
    this.mobileSearchInput = element;
  }
  clear() {
    this.setState({ querySearch: '' });
  }
  clearAndFocus() {
    const isMobile =
    this.mobileSearchInput &&
    this.mobileSearchInput.parentElement.className.match(/animated-input/g);
    this.clear();

    if (isMobile) {
      this.mobileSearchInput.focus();
    } else {
      this.searchInput.focus();
    }
  }
  displayAndFocus() {
    this.animateInputField(
      this.searchInput.parentElement.parentElement,
      this.searchInput
    );
  }
  displayAndFocusMobile() {
    const element = this.mobileSearchInput;
    const parent = element.parentElement;
    this.clear();
    this.mobileSearchButton.classList.toggle('is-hidden-mobile');
    this.animateInputField(parent, element);
  }
  handleChange(query) {
    this.setState({ querySearch: query });
  }
  animateInputField(nodeToAnimate, inputField) {
    if (nodeToAnimate) {
      if (nodeToAnimate.className.match(/animated-input/g)) {
        nodeToAnimate.classList.remove('animated-input');
      } else {
        nodeToAnimate.classList.add('animated-input');
        inputField.focus();
      }
    }
  }
  submitSSO() {
    document.forms['sso'].submit();
    return false;
  }
  render() {
    const { querySearch, userInfo, renderSSOForm } = this.state;
    let displayName = `${userInfo.firstName || ''} ${userInfo.lastName || ''}`;
    displayName = displayName === ' ' ? userInfo.externalId || '' : displayName;
    const isIssuerServices = window.location.pathname.match(prefix);
    const userIsAdmin = userInfo.isAdmin;
    const userIsAllSeeing = userInfo.isAllSeeing;
    // only show search if user has more than one DR
    const userHasMoreThanOneDr = userInfo.totalDrs > 1 || userInfo.totalWSODIssues > 1;
    return (
      <header>
        <div className="header-content">
          <h2 className="is-hidden-mobile">
            DEPOSITARY RECEIPTS
          </h2>
          <h2 className="is-hidden-tablet" style={{paddingRight: '20px'}}>
            DR
          </h2>
          <div className="searchAndUserName">
            {(userIsAdmin || userIsAllSeeing || userHasMoreThanOneDr) && (
              <Fragment>
                <SearchBox
                  classModifier="header-desktop-search is-hidden-mobile"
                  placeholder="Find DR (Name, Ticker or CUSIP)"
                  clickable
                  colorIcon="white"
                  onClick={this.displayAndFocus}
                  onChange={query => {
                    this.handleChange(query);
                  }}
                  onSubmit={query => {
                    this.setState({ querySearch: query });
                  }}
                  inputRef={this.setSearchInputRef}
                  styles="search-input is-hidden-mobile"
                  input={querySearch}
                />
                <div className="resultsContainer">
                  <ApiConsumer query={querySearch} customApiSearch={makeSearch}>
                    {({ data }) => {
                      if (Object.keys(data).length === 0 && querySearch !== '') {
                        return (
                          <NoResultsSearch
                            styles="results"
                            onClick={this.clearAndFocus}
                          />
                        );
                      }
                      if (querySearch === '') {
                        return '';
                      }
                      return (
                        <div>
                          <SearchResults
                            data={data}
                            path="/issuerservices/summary/"
                            styles="results"
                            stringBold={`${querySearch}`}
                            clear={this.clearAndFocus}
                          />
                        </div>
                      );
                    }}
                  </ApiConsumer>
                </div>
              </Fragment>
            )}
            <div className="header-content__user-info-container">
              {userIsAdmin && (
                <OutlineHandler>
                  <Link
                    to={`/issuerservices/${
                      navigationPaths[0]
                    }/${userInfo.defaultDr || ''}`}
                  >
                    <Button
                      customClass={`btn ${isIssuerServices ? 'current' : ''}`}
                      onClick={() => {}}
                    >
                      ISSUER SERVICES
                    </Button>
                  </Link>
                </OutlineHandler>
              )}
              {userIsAdmin ? (
                <Fragment>
                  {renderSSOForm &&  (
                  <SsoForm formAction="/issuerservices/SSO?..nocache..=on" accessToken={AccessTokenService.retrieve()} />
                  )}
                  <OutlineHandler>
                    <Button onClick={
                      () => {
                        this.setState({renderSSOForm: true}, this.submitSSO)
                      }
                   }>
                      {`Admin ${displayName}`}
                    </Button>
                  </OutlineHandler>
                </Fragment>
              ) : (
                displayName && (
                  <h2 className="header-content__user-info">{`Welcome ${displayName}`}</h2>
                )
              )}
            </div>
            {(userIsAdmin || userIsAllSeeing || userHasMoreThanOneDr) && (
              <button
                aria-label="Search Button"
                className="header-mobile-search-button is-hidden-tablet"
                onClick={this.displayAndFocusMobile}
                ref={this.setMobileSearchButtonRef}
                type="button"
              >
                {MobileSearchIcon}
              </button>
            )}
          </div>
        </div>
        <SearchBox
          classModifier="header-mobile-search is-hidden-tablet"
          clickable
          colorIcon="white"
          customIcon={closeIcon}
          input={querySearch}
          inputRef={this.setMobileSearchInputRef}
          onChange={query => {
            this.handleChange(query);
          }}
          onClick={this.displayAndFocusMobile}
          onSubmit={query => {
            this.setState({ querySearch: query });
          }}
          placeholder="Find DR (Name, Ticker or CUSIP)"
          styles="input-mobile is-hidden-tablet"
        />
      </header>
    );
  }
}

Header.defaultProps = {
  data: {
    amountFiled: null,
    available: null,
  },
};

Header.propTypes = {
  data: PropTypes.shape({
    amountFiled: PropTypes.string,
    available: PropTypes.string,
  }),
};

export default Header;
