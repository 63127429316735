import PropTypes from 'prop-types';
import React from 'react';
import { Tabs } from '@jpm-adr/pattern-library';

import '../../../styles/IssuerServices/components/SubSections.scss';

const SubSections = props => {
  const { config } = props;

  if (!config) {
    return '';
  }

  const { sections = [], rightElements = [] } = config;

  const subSectionsComponents = sections.subNavigations.map((item = {}) => (
    <div tabId={item.id} label={item.sectionName}>
      <React.Fragment>{item.element}</React.Fragment>
    </div>
  ));

  return (
    <div className="subsections">
      <Tabs
        rightContent={rightElements.map(Element => (
          <Element />
        ))}
      >
        {subSectionsComponents}
      </Tabs>
    </div>
  );
};

SubSections.defaultProps = {
  config: {},
};
// Here we will recieve as props "element" that will show into the section
SubSections.propTypes = {
  config: PropTypes.shape({
    rightElements: PropTypes.arrayOf(
      PropTypes.node
    ),
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        sectionName: PropTypes.string,
        element: PropTypes.element,
      })
    ),
  }),
};

export default SubSections;
