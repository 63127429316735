import PropTypes from 'prop-types';
import React from 'react';
import { NoDataAvailable, Table } from '@jpm-adr/pattern-library';
import { executeDataRequest } from '../../utils/dataRequestMethods';
import { numberComma } from '../../utils/format';
import { withApiRequest } from '../../utils/apiWithHOC';
import { withErrorBoundary } from '../../utils/errorBoundary';

import '../../styles/components/TradingVolume.scss';

const tradingVolumeTableHeaders = () => {
  return [
    {
      id: 1,
      title: 'Month',
      customCSSClass: 'margin-table-volumes text-cell',
    },
    {
      id: 2,
      title: 'Month-End Price (USD)',
      customCSSClass: 'margin-table-volumes number-cell',
    },
    {
      id: 3,
      title: 'Trading Volume',
      customCSSClass: 'margin-table-volumes number-cell',
    },
    {
      id: 4,
      title: 'Avg. Closing Price (USD)',
      customCSSClass: 'margin-table-volumes number-cell',
    },
    {
      id: 5,
      title: 'Trading Value (USD)',
      customCSSClass: 'margin-table-volumes number-cell',
    },
    {
      id: 6,
      title: 'Avg/Day Trading Volume',
      customCSSClass: 'margin-table-volumes number-cell',
    },
    {
      id: 7,
      title: 'Avg/Day Trading Value (USD)',
      customCSSClass: 'margin-table-volumes number-cell',
    },
    {
      id: 8,
      title: '',
      customCSSClass: 'margin-table-volumes number-cell',
    },
  ];
};

const tradingVolumeTableData = data => {
  return data.map(monthVolume => {
    return [
      {
        title: 'Month',
        data: monthVolume.month,
        customCSSClass: 'volume-type-margin text-cell',
        hasComponent: false,
      },
      {
        title: 'Month-End Price (USD)',
        data: numberComma(monthVolume.monthEndPrice, 2),
        customCSSClass: 'volume-type-margin number-cell',
        hasComponent: false,
      },
      {
        title: 'Trading Volume',
        data: numberComma(monthVolume.tradingVolume, 0),
        customCSSClass: 'volume-type-margin number-cell',
        hasComponent: false,
      },
      {
        title: 'Avg. Closing Price (USD)',
        data: numberComma(monthVolume.avgClosingPrice, 2),
        customCSSClass: 'volume-type-margin number-cell',
        hasComponent: false,
      },
      {
        title: 'Trading Value (USD)',
        data: numberComma(monthVolume.tradingValue, 0),
        customCSSClass: 'volume-type-margin number-cell',
        hasComponent: false,
      },
      {
        title: 'Avg/Day Trading Volume',
        data: numberComma(monthVolume.avgDayTradingVolume, 0),
        customCSSClass: 'volume-type-margin number-cell',
        hasComponent: false,
      },
      {
        title: 'Avg/Day Trading Value (USD)',
        data: numberComma(monthVolume.avgDayTradingValue, 0),
        customCSSClass: 'volume-type-margin number-cell',
        hasComponent: false,
      },
      {
        title: '',
        data: '',
        customCSSClass: 'volume-type-margin number-cell',
        hasComponent: false,
      },
    ];
  });
};

const TradingVolume = props => {
  const { data, isLoading } = props;

  if (!isLoading && (!data || data.length === 0)) {
    return (
      <NoDataAvailable
        messageTitle="No data available"
        customMessageTitleClass="Data__Not__Found-Title"
        customWrapperCSS="No__Data__Available-Custom-Wrapper Header__Component"
        message="There is no data available for this specific program."
        messageStyles="Data__Not__Found-Message"
        wrapperClass="Data__Not__Found"
      />
    );
  }

  return (
    <Table
      isLoading={isLoading}
      values={tradingVolumeTableData(data || [])}
      tableId="volumes-table"
      tableCustomCss="volumes-table table-component table is-striped is-narrow is-fullwidth"
      tableWrapperClass="table-wrapper-dividends"
      tableTitles={tradingVolumeTableHeaders()}
    />
  );
};

TradingVolume.propTypes = {
  isLoading: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      month: PropTypes.string,
      monthEndPrice: PropTypes.number,
      tradingVolume: PropTypes.number,
      avgClosingPrice: PropTypes.number,
      tradingValue: PropTypes.number,
      avgDayTradingVolume: PropTypes.number,
      avgDayTradingValue: PropTypes.number,
    })
  ).isRequired,
};

export default withErrorBoundary(
  withApiRequest(
    TradingVolume,
    ({ cusip }) =>
      cusip ? executeDataRequest(`dr/tradingVolume/${cusip}`, 'GET') : null
  )
);
