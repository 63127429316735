import React from 'react';

import CountryForms from '../../components/brokerInformation/CountryForms';
import GenericForms from '../../components/brokerInformation/GenericForms';
import ProgramSpecificForms from '../../components/brokerInformation/ProgramSpecificForms';

import '../../styles/components/BrokerForms.scss';

const BrokerForms = () => {
  return (
    <div className="BrokerForms__main-wrapper">
      <section className="BrokerForms__no-mobile-data">
        <div className="BrokerForms__no-mobile-data-message">
          <h2>No data available</h2>
          <p>Broker forms are not available on mobile devices</p>
        </div>
      </section>
      <section className="BrokerForms__table-block">
        <header className="BrokerForms__title-container">
          <h2 className="BrokerForms__title">Generic Forms</h2>
        </header>
        <div className="BrokerForms__table-container">
          <GenericForms />
        </div>
      </section>

      <section className="BrokerForms__table-block">
        <header className="BrokerForms__title-container">
          <h2 className="BrokerForms__title">Country Specific Forms</h2>
        </header>
        <div className="BrokerForms__table-container">
          <CountryForms />
        </div>
      </section>

      <section className="BrokerForms__table-block">
        <header className="BrokerForms__title-container">
          <h2 className="BrokerForms__title">Program Specific Forms</h2>
        </header>
        <div className="BrokerForms__table-container">
          <ProgramSpecificForms />
        </div>
      </section>

    </div>
  )
}

export default BrokerForms;
