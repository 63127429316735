import React from 'react';
import propTypes from 'prop-types';
import { NoticeLinks, withLoadingText } from '@jpm-adr/pattern-library';
import { executeDataRequest } from '../../utils/dataRequestMethods';
import { withApiRequest } from '../../utils/apiWithHOC';
import { withErrorBoundary } from '../../utils/errorBoundary';

import '../../styles/components/RelatedSecurities.scss';

const RelatedSecurities = props => {
  const { data } = props;

  if (!data || data.length === 0) {
    return <span />;
  }

  const relatedSecuritiesData = data.map((security, index) => {
    const currentPosition = security.position || index;
    const tickerLabel = security.ticker ? `(${security.ticker})`:''
    return {
      href: `/drprofile/${security.cusip}`,
      text: `${security.name || ''} ${tickerLabel}`,
      position: currentPosition,
    };
  });

  return (
    <NoticeLinks
      label="Related Securities: "
      noticeClass="notice-links column is-vcentered is-desktop"
      anchors={relatedSecuritiesData}
    />
  );
};

RelatedSecurities.propTypes = {
  data: propTypes.arrayOf(
    propTypes.shape({
      xid: propTypes.number,
      cusip: propTypes.string,
      name: propTypes.string,
      ticker: propTypes.string,
    })
  ).isRequired,
};

export default withErrorBoundary(
  withApiRequest(
    withLoadingText(RelatedSecurities),
    ({ cusip }) =>
      cusip ? executeDataRequest(`dr/relatedSecurities/${cusip}`, 'GET') : null
  )
);
