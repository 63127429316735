import '../../styles/components/Dividends.scss';
import React, { Component } from 'react';
import propTypes from 'prop-types';
import {
  LoadMoreButton,
  NoDataAvailable,
  Table,
} from '@jpm-adr/pattern-library';

import { DECIMAL_DIGITS, URLbyDomain } from '../../utils/constants';
import { dateFullShortMonthNoTime, number } from '../../utils/format';

class Dividends extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dividendsTable: [],
      loadMore: true,
      isLoading: true,
    };
    this.getDividendsData = this.getDividendsData.bind(this);
    this.adapter = this.adapter.bind(this);
    this.getDividendsData({ currentPosition: 0, pageSize: 25 });
    this.LoadingWithState = (
      <LoadMoreButton
        className="dr-loading-button"
        fetchFunction={this.getDividendsData}
        position={0}
        pageSize={25}
      />
    );
 

    this.dividendTitles = [
      {
        id: 0,
        title: ' ',
        customCSSClass: 'title-underlying-arrow',
      },
      {
        id: 1,
        title: 'Announcement Date',
        customCSSClass: '',
      },
      {
        id: 2,
        title: 'DR Record Date',
        customCSSClass: '',
      },
      {
        id: 3,
        title: 'DR Payment Date',
        customCSSClass: '',
      },
      {
        id: 4,
        title: 'Rate Per DR (USD)',
        customCSSClass: '',
      },
      {
        id: 5,
        title: 'Withholding Amount (USD)',
        customCSSClass: '',
      },
      {
        id: 6,
        title: 'Tax Reclaim Fee (USD)',
        customCSSClass: '',
      },
      {
        id: 7,
        title: 'Dividend Fee (USD)',
        customCSSClass: '',
      },
      {
        id: 8,
        title: 'Div Rate Per DR (USD)',
        customCSSClass: '',
      },
      {
        id: 9,
        title: 'Status',
        customCSSClass: '',
      },
      {
        id: 10,
        title: 'Notices',
        customCSSClass: '',
      },
    ];
  }

  getDividendsData({ currentPosition, pageSize }) {
    const { cusip, endpoint } = this.props;
    const { dividendsTable = [] } = this.state;
    return endpoint(cusip, currentPosition, pageSize)
      .then(response => {
        const { items = [] } = response;
        const parsedItems = items.map(item => ({
          ...item,
          ratePerDr: number(item.ratePerDr, DECIMAL_DIGITS),
          withHoldingAmount: number(item.withHoldingAmount, DECIMAL_DIGITS),
          taxReclaimFee: number(item.taxReclaimFee, DECIMAL_DIGITS),
          dividendFee: number(item.dividendFee, DECIMAL_DIGITS),
          finalDivRatePerDr: number(item.finalDivRatePerDr, DECIMAL_DIGITS),
        }));
        this.setState({
          dividendsTable: dividendsTable.concat(parsedItems),
          loadMore: response.pagination.hasMore,
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          isLoading: false,
        });
      });
  }

  adapter(apiData) {
    const mapObject = (obj, field) => {
      let newObj = {};
      newObj.customCSSClass = obj.isLastWorkIdMember ?
        'isLastWorkIdMember' : '';

      if (field in obj && obj[field] !== '') {
        if (field === 'underlying') {
          const underlyingData = obj[field];
          newObj.title = '';
          newObj.hasUnderlying = true;
          newObj.data = '^';
          newObj.customCSSClass = `${newObj.customCSSClass} underlying-arrow-data`;
          newObj.underlying = {
            recordDate: underlyingData.recordDate,
            paymentDate: underlyingData.paymentDate,
            currency: underlyingData.currency,
            currencyPerDr: number(underlyingData.currencyPerDr, DECIMAL_DIGITS),
            exchangeDate: underlyingData.exchangeDate,
            finalExchangeRate: number(underlyingData.finalExchangeRate, DECIMAL_DIGITS),
          };
        } else if (field === 'notices') {
          const noticesData = obj[field];
          const url = URLbyDomain + '/cms/document?cmsId=';
          const sequenceNoParam = obj.sequenceNo ? `&sequenceNo=${obj.sequenceNo}` : '';
          newObj.hasComponents = true;
          newObj.hasNotices = true;
          newObj.pdf = noticesData.pdf || '';
          newObj.xbrl = noticesData.xbrl || '';
          newObj.pdfLink = `${url}${newObj.pdf}${sequenceNoParam}`;
          newObj.xbrlLink = `${url}${newObj.xbrl}${sequenceNoParam}`;
        } else if (
          field === 'announcementDate' ||
          field === 'recordDate' ||
          field === 'paymentDate'
        ) {
          newObj.data = dateFullShortMonthNoTime(obj[field]);
        } else {
          newObj.data = String(obj[field]);
        }
      } else {
        newObj.data = '--';
      }
      return newObj;
    }

    let newDataStructure = [];
    const workIdGroups = {};
    apiData
      .map(current => {
        const { sortOrder, workId } = current;
        const isLastValue = workIdGroups[workId] ? true : false;
        workIdGroups[workId] = {
          isLastValue,
          sortOrder
        };
        return current;
      })
      .map(obj => {
        obj.isLastWorkIdMember = !obj.workId ||
          workIdGroups[obj.workId] &&
          obj.sortOrder === workIdGroups[obj.workId].sortOrder;
        let newArr = [],
          underlying = mapObject(obj, 'underlying'),
          announcement = mapObject(obj, 'announcementDate'),
          recordDate = mapObject(obj, 'recordDate'),
          paymentDate = mapObject(obj, 'paymentDate'),
          ratePerDr = mapObject(obj, 'ratePerDr'),
          withHoldingAmount = mapObject(obj, 'withHoldingAmount'),
          taxReclaimFee = mapObject(obj, 'taxReclaimFee'),
          dividendFee = mapObject(obj, 'dividendFee'),
          finalDivRatePerDr = mapObject(obj, 'finalDivRatePerDr'),
          status = mapObject(obj, 'status'),
          notices = mapObject(obj, 'notices');
        newArr.push(underlying);
        newArr.push(announcement);
        newArr.push(recordDate);
        newArr.push(paymentDate);
        newArr.push(ratePerDr);
        newArr.push(withHoldingAmount);
        newArr.push(taxReclaimFee);
        newArr.push(dividendFee);
        newArr.push(finalDivRatePerDr);
        newArr.push(status);
        newArr.push(notices);
        newDataStructure.push(newArr);
      })

    return newDataStructure;
  }

  render() {
    const { isLoading, dividendsTable, loadMore } = this.state;
    const apiData = this.adapter(dividendsTable || []);

    if (!isLoading && dividendsTable.length === 0) {
      return (
        <section className="Dividends__Table">
          <NoDataAvailable
            messageTitle="No data available"
            customMessageTitleClass="Data__Not__Found-Title"
            customWrapperCSS="No__Data__Available-Custom-Wrapper"
            message="There is no data available for this specific program."
            messageStyles="Data__Not__Found-Message"
            wrapperClass="Data__Not__Found"
          />
        </section>
      );
    }

    return (
      <React.Fragment>
        <Table
          isLoading={isLoading}
          customStyles={{ height: '440px' }}
          values={apiData}
          tableId="dividends-table"
          tableCustomCss="dividends-table table-component table is-striped is-narrow is-fullwidth"
          tableTitles={this.dividendTitles}
          tableWrapperClass="table-wrapper-dividends"
        />
        {isLoading || !loadMore ? null : this.LoadingWithState}
      </React.Fragment>
    );
  }
}

Dividends.defaultProps = {
  cusip: null,
};

Dividends.propTypes = {
  cusip: propTypes.oneOfType([propTypes.string, propTypes.number]),
  endpoint: propTypes.func.isRequired,
};

export default Dividends;
