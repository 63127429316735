/* eslint-disable sort-imports-es6-autofix/sort-imports-es6 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../../styles/components/BooksClosures.scss';
import exportData from '../../utils/exportData';
import { getBookClosure, getExportData } from '../../utils/api';
import bookClosuresFilters from './bookClosuresFilters';
import { optionsBooksDropdown, URLbyDomain } from '../../utils/constants';
import { createUrlQuery, reduceToValues } from '../../utils/urlMethods';
import bookClosuresTableAdapter, {
  createBookClosureTitles,
  SORT_OPTIONS_MAP,
} from './bookClosuresTableAdapter';
import TableViewPageContainer from '../../components/common/TableViewPage/TableViewPageContainer';
/* eslint-enable sort-imports-es6-autofix/sort-imports-es6 */

export default class BookClosures extends Component {
  constructor() {
    super();
    this.tableRef = React.createRef();
    this.fetchFilteredData = this.fetchFilteredData.bind(this);
    this.getExportUrl = this.getExportUrl.bind(this);
    this.extraExportFunction = null;
    this.setExtraExportFunction = this.setExtraExportFunction.bind(this);
  }

  getExportUrl(params) {
    const query = createUrlQuery({
      ...reduceToValues(params),
      offset: 0,
      limit: params.limit,
    });

    return `${URLbyDomain}/bookClosures/exportTable?${query}`;
  }

  setExtraExportFunction (extraFunction) {
    this.extraExportFunction = extraFunction;
  }

  fetchFilteredData(filters) {
    const { q, bookStatus, ...otherFilters } = filters;
    let params = {
      q: q,
      ...otherFilters,
    };
    if (bookStatus) {
      if(optionsBooksDropdown[bookStatus.label])
      {
        params.bookStatus = optionsBooksDropdown[bookStatus.label].map(value => ({
          ...bookStatus,
          value,
        }));
      }
      else
      {
        params = { ...params, bookStatus };
      }
    }
    return getBookClosure(params).then(response => {
      const { items = [], pagination } = response;

      return {
        data: bookClosuresTableAdapter(items),
        pagination,
      };
    });
  }

  handleExportClickOption (isFullDownload = false) {
    const infoFromChild = this.extraExportFunction && this.extraExportFunction();
    let { data, 
          filtersValues, 
          offset,
          sortBy,
          sortOrder,
       } = infoFromChild;
    delete infoFromChild.data;

    let limit
    let params = { ...filtersValues };
    let { bookStatus } = params;
    if(isFullDownload) {
      limit = 25000;
      // Get Full universe, api defaults to 'Closed' books
      params.bookStatus = optionsBooksDropdown['All Statuses'].map(value => ({
        ...bookStatus,
        value,
      }));
      if(params.country) {
        delete params.data;
      }
      if(params.bookClosedType) {
        delete params.bookClosedType;
      } 
      if(params.bookClosedReason) {
        delete params.bookClosedReason;
      }
    }
    else {
      limit = data.length;
      if (bookStatus) { // check bookstatus, as it wont be avaiable for default UI filter 
        if(optionsBooksDropdown[bookStatus.label])
        {
          params.bookStatus = optionsBooksDropdown[bookStatus.label].map(value => ({
            ...bookStatus,
            value,
          }));
        }
        else
        {
          params = { ...params, bookStatus };
        }
      }
  }

  const args = { offset, sortBy,sortOrder, limit, ...reduceToValues(params)};

  return getExportData('bookClosures', args).then(response => {
      const fileName = 'book_closures.xlsx';
      exportData(fileName, response.data);
  });
  }

  canResetFilters(filtersValues) {
    return (
      (filtersValues.bookStatus &&
        filtersValues.bookStatus.label !== 'Closed') ||
      (filtersValues.bookClosedReason &&
        filtersValues.bookClosedReason.length > 0) ||
      (filtersValues.bookClosedType &&
        filtersValues.bookClosedType.length > 0) ||
      (filtersValues.country && filtersValues.country.length > 0)
    );
  }

  render() {
     const { customButton } = this.props
    return (
      <TableViewPageContainer
        classModifier="book-closure-table-view"
        ref={this.tableRef}
        customExportButton={customButton}
        defaultSortBy={SORT_OPTIONS_MAP.closeDate}
        defaultSortOrder="desc"
        extraExportFunction={this.setExtraExportFunction}
        fields={bookClosuresFilters}
        heading="JPM Book Closures"
        headerColumns={createBookClosureTitles}
        onFetchData={this.fetchFilteredData}
        canResetFunc={this.canResetFilters}
        searchInputPlaceholder="Filter Results (Name, Ticker, or CUSIP)"
      />
    );
  }
}

BookClosures.defaultProps = {
  customButton: null
}

BookClosures.propTypes = {
  customButton: PropTypes.func
};
