import PropTypes from 'prop-types';
import React from 'react';
import { ActionableText, OutlineHandler } from '@jpm-adr/pattern-library';
import { orderBy } from 'lodash';

import DeleteAlertsComponent from '../../components/alerts/DeleteAlerts';
import NewAlertsComponent from '../../components/alerts/NewAlerts';

import TableLoader from '../../components/tables/TableLoader';
import utils from '../../utils/utils';
import { dateFullShortMonthNoTime } from '../../../utils/format';
import {
  getAlertsConfigurations,
  getAlertsProfile,
  getAlertsSubscriptions,
} from '../../utils/apiCalls';

class ManageRecipients extends React.Component {
  constructor() {
    super();
    this.state = { checking: true };
    this.onSuccess = this.onSuccess.bind(this);
    this.getProfile = this.getProfile.bind(this);
    this.prepareConfigTable = this.prepareConfigTable.bind(this);
    this.prepareApiRequest = this.prepareApiRequest.bind(this);
  }

  onSuccess(data) {
    let [configs, profile, subscriptions] = data;
    let items = subscriptions.items.map((info, i, arr) => ({
      email: profile.addresses.find(
        address => address.destinationId === info.destinationId
      ).address,
      date: info.insertDate,
      sameEmail: arr.filter(susc => susc.destinationId === info.destinationId),
      description: utils.getAlertDescription(
        info.item,
        info.criteria.period,
        configs.items.find(config => config.item === info.item).description
      ),
      destinationId: info.destinationId,
    }));
    items = orderBy(items, ['email']);
    let lastEmail = '';
    items = items.map(item => {
      if (item.email === lastEmail) {
        return { ...item, tdStyle: 'repeated-item' };
      }
      lastEmail = item.email;
      return { ...item, tdStyle: 'afterRepeat-item' };
    });
    return Promise.resolve({ items });
  }

  getProfile() {
    const { xid } = this.props;
    return Promise.all([
      getAlertsConfigurations(),
      getAlertsProfile(),
      getAlertsSubscriptions(xid),
    ]).then(this.onSuccess);
  }
  prepareConfigTable() {
    return {
      columns: [
        {
          text: 'Actions',
        },
        {
          text: 'Recipient',
        },
        {
          text: 'Date Set',
        },
        {
          text: 'Alert Description',
        },
      ],
      rows: [
        {
          text: item =>
            item.repeated ? null : (
              <div className="manage-actionables">
                <NewAlertsComponent
                  data={{
                    email: item.email,
                    sameEmail: item.sameEmail,
                    title: 'Edit Alerts',
                  }}
                  actionItem={({ onClick }) => (
                    <ActionableText
                      ariaLabel="pressing ENTER or SPACEBAR key will open edit modal"
                      onClick={onClick}
                    >
                      Edit{' '}
                    </ActionableText>
                  )}
                  onSave={() => {
                    const { checking } = this.state;
                    this.getProfile();
                    this.setState({ checking: !checking });
                  }}
                  {...this.props}
                />
                <DeleteAlertsComponent
                  actionItem={({ onClick }) => (
                    <ActionableText
                      ariaLabel="pressing ENTER or SPACEBAR key will open delete modal"
                      onClick={onClick}
                    >
                      Delete
                    </ActionableText>
                  )}
                  destinationId={item.destinationId}
                  data={{
                    email: item.email,
                    sameEmail: item.sameEmail,
                    title: 'Delete recipient?',
                  }}
                  onSave={() => {
                    const { checking } = this.state;
                    this.getProfile();
                    this.setState({ checking: !checking });
                  }}
                />
              </div>
            ),
        },
        {
          text: item =>
            item.repeated ? null : (
              <OutlineHandler>
                <div tabIndex="0" className="email-truncated">{/*eslint-disable-line*/}
                  {item.email}
                </div>
              </OutlineHandler>
            ),
        },
        {
          text: itemData => dateFullShortMonthNoTime(itemData.date),
        },
        {
          text: itemData => itemData.description,
        },
      ],
    };
  }

  prepareApiRequest() {
    return {
      requestApi: this.getProfile,
      requestApiConfig: {},
    };
  }

  render() {
    const {
      props,
      prepareApiRequest,
      prepareConfigTable,
      getFocusableElement,
      getFirstFocusableElement,
    } = this;
    const configs = {
      configTable: prepareConfigTable(),
      immediateRequest: true,
      getFocusableElement,
      getFirstFocusableElement,

      bottomFilters: [
        {
          id: 'loadMore',
          fetchFunction: () => {},
        },
      ],
    };
    const tableLoaderConfig = { ...configs, ...props, ...prepareApiRequest() };
    return (
      <div aria-live="off">
        <TableLoader {...tableLoaderConfig} />
      </div>
    );
  }
}

ManageRecipients.propTypes = {
  match: PropTypes.shape({}).isRequired,
};

export default ManageRecipients;
