import PropTypes from 'prop-types';
import React from 'react';

import DailyTable from './DailyTable';
import MonthlyTable from './MonthlyTable';
import SubSectionsComponent from '../../components/subSections/SubSections';
import { subSections } from '../../utils/constants';

import '../../../styles/IssuerServices/pages/Library.scss';

let sections = subSections['library'];

const Library = props => {
  sections.subNavigations = sections.subNavigations.map(section => {
    if (section.id === 'monthlyReports') {
      section.element = <MonthlyTable {...props} />;
    }

    if (section.id === 'dailyReports') {
      section.element = <DailyTable {...props} />;
    }

    return section;
  });

  const subSectionsComponentConfig = {
    config: { sections },
  };

  return (
    <div className="library library__content">
      <SubSectionsComponent {...subSectionsComponentConfig} />
    </div>
  );
};

Library.propTypes = {
  xid: PropTypes.string.isRequired,
  headerInfo: PropTypes.objectOf.isRequired,
};

export default Library;
