import PropTypes from 'prop-types';
import React from 'react';
import { NoDataAvailable, Table } from '@jpm-adr/pattern-library';
import { sumBy } from 'lodash';

import { dateFullShortMonthNoTime, numberComma } from '../../utils/format';
import { executeDataRequest } from '../../utils/dataRequestMethods';
import { withApiRequest } from '../../utils/apiWithHOC';
import { withErrorBoundary } from '../../utils/errorBoundary';

import '../../styles/components/Ownerships.scss';

const addPlusSign = number => {
  if (number === '0') {
    return number;
  } else if (number.indexOf('-') === -1) {
    return '+' + number;
  }

  return number;
};
const tableTitles = () => {
  return [
    {
      id: 1,
      title: '',
      customCSSClass: 'margin-table-ownerships',
    },
    {
      id: 2,
      title: 'Holder Name',
      customCSSClass: 'margin-table-ownerships text-cell',
    },
    {
      id: 3,
      title: 'Position',
      customCSSClass: 'margin-table-ownerships number-cell',
    },
    {
      id: 4,
      title: 'Position Change',
      customCSSClass: 'margin-table-ownerships number-cell',
    },
    {
      id: 5,
      title: 'Market Value (USD)',
      customCSSClass: 'margin-table-ownerships number-cell',
    },
    {
      id: 6,
      title: 'Report Date',
      customCSSClass: 'margin-table-ownerships text-cell',
    },
  ];
};
const additions = data => {
  return [
    {
      title: '',
      data: '',
      customCSSClass: 'ownership-type-margin text-cell',
      hasComponent: false,
    },
    {
      title: '',
      data: 'Total',
      customCSSClass: 'ownership-type-margin text-cell total-cell',
      hasComponent: false,
    },
    {
      title: '',
      data: numberComma(sumBy(data, 'position')),
      customCSSClass: 'ownership-type-margin number-cell',
      hasComponent: true,
    },
    {
      title: '',
      data: numberComma(sumBy(data, 'positionChange')),
      customCSSClass: 'ownership-type-margin number-cell',
      hasComponent: false,
    },
    {
      title: '',
      data: numberComma(sumBy(data, 'marketValue')),
      customCSSClass: 'ownership-type-margin number-cell',
      hasComponent: false,
    },
    {
      title: '',
      data: '',
      customCSSClass: 'ownership-type-margin text-cell',
      hasComponent: false,
    },
  ];
};
const tableData = data => {
  let mappedData = data.map((holder, index) => {
    return [
      {
        title: '',
        data: `${index + 1}. `,
        customCSSClass: 'ownership-type-margin text-cell',
        hasComponent: false,
      },
      {
        title: 'Holder Name',
        data: holder.holderName ? <span>{holder.holderName}</span> : '--',
        customCSSClass: 'ownership-type-margin text-cell',
        hasComponent: false,
      },
      {
        title: 'Position',
        data: numberComma(holder.position),
        customCSSClass: 'ownership-type-margin number-cell',
        hasComponent: false,
      },
      {
        title: 'Position Change',
        data: addPlusSign(numberComma(holder.positionChange)),
        customCSSClass: 'ownership-type-margin number-cell',
        hasComponent: false,
      },
      {
        title: 'Market Value (USD)',
        data: numberComma(holder.marketValue),
        customCSSClass: 'ownership-type-margin number-cell',
        hasComponent: false,
      },
      {
        title: 'Report Date',
        data: dateFullShortMonthNoTime(holder.reportDate),
        customCSSClass: 'ownership-type-margin text-cell',
        hasComponent: false,
      },
    ];
  });

  mappedData.push(additions(data || []));

  return mappedData;
};
const Ownerships = props => {
  const { data, customClass, isLoading } = props;
  if (!isLoading && (!data || data.length === 0)) {
    return (
      <section className="Dividends__Table">
        <NoDataAvailable
          messageTitle="No data available"
          customMessageTitleClass="Data__Not__Found-Title"
          customWrapperCSS={customClass + 'No__Data__Available-Custom-Wrapper'}
          message="There is no data available for this specific program."
          messageStyles="Data__Not__Found-Message"
          wrapperClass="Data__Not__Found"
        />
      </section>
    );
  }

  return (
    <React.Fragment>
      <h3>Top 10 Institutional Holders</h3>
      <Table
        isLoading={isLoading}
        tableId="ownerships-table"
        tableCustomCss="ownerships-table table-component table is-striped is-narrow is-fullwidth"
        tableWrapperClass="table-wrapper-dividends"
        values={tableData(data || [])}
        tableTitles={tableTitles()}
      />
      <p className="tranding-volumne-disclaimer is-hidden-mobile">
        Ownership Data provided by FactSet
      </p>
    </React.Fragment>
  );
};
Ownerships.defaultProps = {
  customClass: '',
};

Ownerships.propTypes = {
  isLoading: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      holderName: PropTypes.string,
      position: PropTypes.number,
      positionChang: PropTypes.number,
      marketvalue: PropTypes.number,
      reportDate: PropTypes.string,
    })
  ).isRequired,
  customClass: PropTypes.string,
};

export default withErrorBoundary(
  withApiRequest(
    Ownerships,
    ({ cusip }) =>
      cusip ? executeDataRequest(`dr/ownership/${cusip}`, 'GET') : null
  )
);
