import React from 'react';

import BaseComponentWrapper from './BaseComponentHOC';

class BaseComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { render: null };
  }

  render() {
    const { render } = this.state;
    return render;
  }
}

export default BaseComponentWrapper(BaseComponent);
