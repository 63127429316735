import '../../../styles/components/TermsConditions.scss';
import React from 'react';

const TermConditions = () => (
  <div className="jpmtermsconditions">
    <h3>J.P. Morgan Depositary Receipts Team</h3>
    <p>
      This website is a project of JPMorgan Chase &amp; Co. (&quot;J.P.
      Morgan&quot;). Unauthorized use of this website, including but not limited
      to unauthorized entry into J.P. Morgan‎&apos;s systems, or misuse of any
      information posted by J.P. Morgan to a site, is strictly prohibited.
    </p>
    <p>
      This Website is not intended for distribution to, or use by, any person or
      entity in any jurisdiction or country where such distribution or use would
      be contrary to local law or regulation. J.P. Morgan has not reviewed and
      hereby disclaim responsibility for any information or materials posted at
      any of the sites linked to this website, which links are provided for
      information purposes only.
    </p>
    <p>
      Additional information is available upon request. Information herein is
      believed to be reliable but J.P. Morgan and its information suppliers do
      not warrant its completeness or accuracy. Opinions and estimates
      constitute the judgment of certain individuals at J.P. Morgan and are
      subject to change without notice. Past performance is not indicative of
      future results. This material is not intended as an offer or solicitation
      for the purchase or sale of any financial instrument. J.P. Morgan may hold
      a position or act as market maker in the financial instruments of any
      issuer discussed herein or act as advisor or lender to such issuer.
    </p>
    <p>
      The conduct of services contemplated by the terms and conditions
      (&quot;Terms and Conditions&quot;) of each of the Dividend Reinvestment
      Plan (&quot;DRP Plan&quot;) and Direct Stock Purchase Plan (&quot;DSP
      Plan&quot;), including the processing of buy and sell orders for
      securities via this Website (collectively, the &quot;Services&quot;) is
      for your convenience only and neither J.P. Morgan, JPMorgan Chase &amp;
      Co., their respective service providers nor any of their affiliates shall
      have any obligation to maintain the Services through this website or any
      other internet facility. Access to Services via this or any other website
      established by J.P. Morgan for such purposes may be terminated by J.P.
      Morgan at any time, for any reason or no reason, without notice to you. No
      warranty or guarantee concerning the availability of this website for
      Services, Information or otherwise is made by any person or entity. You
      may incur line or usage charges from your ISP in connecting to this
      website. Website design Copyright © 2009 JPMorgan Chase &amp; Co. All
      rights reserved worldwide: Website content Copyright © 2009 JPMorgan Chase
      &amp; Co. All rights reserved worldwide. adr.com is a service mark of
      JPMorgan Chase &amp; Co. J.P. Morgan is a marketing name for JPMorgan
      Chase &amp; Co. and its subsidiaries worldwide. J.P. Morgan Securities
      Inc. is a member of SIPC. JPMorgan Chase Bank, N.A. is a member of the
      FDIC. This material has been approved for issue in the United Kingdom by
      JPMorgan Chase Bank, N.A. (London Branch) which is regulated by the FSA.
      Clients should contact analysts at and execute transactions through a J.P.
      Morgan entity in their home jurisdiction unless governing law permits
      otherwise.
    </p>
    <p>
      <strong>
        PART I AS USED HEREIN, THE TERMS &quot;YOU&quot; AND &quot;USER&quot;
        SHALL MEAN ANY VISITOR TO THE WEBSITE. PLEASE READ THESE TERMS AND
        CONDITIONS CAREFULLY. BY ACCESSING THIS WEBSITE YOU AGREE TO BE BOUND BY
        THE TERMS AND CONDITIONS BELOW. THESE TERMS AND CONDITIONS ARE SUBJECT
        TO CHANGE WITHOUT NOTICE, FROM TIME TO TIME IN OUR SOLE DISCRETION. WE
        WILL NOTIFY YOU OF AMENDMENTS TO THESE TERMS AND CONDITIONS BY POSTING
        THEM TO THIS WEBSITE. IF YOU DO NOT AGREE WITH THESE TERMS AND
        CONDITIONS, PLEASE DO NOT ACCESS THIS WEBSITE.
      </strong>
    </p>
    <p>
      You are strictly prohibited from unauthorized use of our systems or this
      website, including but not limited to unauthorized entry into our systems,
      misuse of passwords, or misuse of any information posted to this website.
    </p>
    <p>
      You acknowledge that we may disclose and transfer any information that you
      provide through this Website to (i) any company within the J.P. Morgan,
      its affiliates, agents or information providers; (ii) to any other person
      or entity with your consent; or (iii) if we have a right or duty to
      disclose or are permitted or compelled to so disclose such information by
      law. You consent to the transmission, transfer or processing of such
      information to, or through, any country in the world, as we deem necessary
      or appropriate, and by using and providing information through this
      Website you agree to such transfers.
    </p>
    <p>
      Use of this Website may be monitored, tracked and recorded. Anyone using
      this Website expressly consents to such monitoring, tracking and
      recording. You are responsible for being familiar with the current version
      of these Terms and Conditions posted on the Website during each session.
    </p>

    <h4>Copyright Notices</h4>
    <p>
      The works of authorship contained in this Website, including but not
      limited to all design, text, sound recordings, and images, are owned,
      except as otherwise expressly stated, by J.P. Morgan or one of its
      subsidiaries. Except as otherwise expressly stated herein, they may not be
      copied, transmitted, displayed, performed, distributed (for compensation
      or otherwise), licensed, altered, framed, stored for subsequent use, or
      otherwise used in whole or in part in any manner without the owner&apos;s
      prior written consent, except to the extent permitted by the Copyright Act
      of 1976 (17 U.S.C. § 107), as amended, and then, only with notices of the
      owner&apos;s proprietary rights. Except for any third party exchange data,
      you may download information and print out a copy for your personal use,
      so long as you do not remove any copyright or other notice as may be
      contained in information, as downloaded.
    </p>
    <h4>Trademark Notices</h4>
    <p>
      &quot;J.P. Morgan&quot;, &quot;JPMorgan Chase &amp; Co.&quot;,
      &quot;Chase&quot;, &quot;WaMu&quot;, the JPMorgan Chase &amp; Co. logo and
      the Octagon Symbol are trademarks of JPMorgan Chase &amp; Co. Other
      featured words or symbols, used to identify the source of goods and
      services, may be the trademarks of their respective owners.
    </p>
    <h4>Website Content and Materials</h4>
    <p>
      The information on this Website is for information purposes only. It is
      believed to be reliable, but we do not warrant its completeness,
      timeliness or accuracy. User accesses, uses, and relies upon such content
      at User&apos;s own risk. Please seek the advice of professionals as
      necessary regarding the evaluation of any content on this Website. The
      information on this Website is not intended as an offer or solicitation
      for the purchase of any stock, any other security or any financial
      instrument or to provide any investment service or investment advice in
      any jurisdiction.
    </p>
  </div>
);

export default TermConditions;
