import { alertDescriptions } from './constants';

const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const integerRegex = /^\d+$/;

export default {
  debounce: (fn, time) => {
    let timeout;

    return function() {
      const functionCall = () => fn.apply(this, arguments);

      clearTimeout(timeout);
      timeout = setTimeout(functionCall, time);
    };
  },
  emailHasError: (emailToValidate = '') => !emailRegex.test(emailToValidate),
  getAlertDescription: (alertItem, period, defaultDescription) => {
    if (alertItem === 574) return alertDescriptions[period];
    // alertItem 599 does not have period sent back because the alert is only for daily
    else if (alertItem === 599) return alertDescriptions[32];
    return defaultDescription;
  },
  isChecked: arg => !arg,
  numberHasError: (number, checkboxToValidate) => {
    return checkboxToValidate ? !integerRegex.test(number) || parseInt(number) === 0 : false
  },
  shallowEqualObjects: (objA, objB) => {
    if (objA === objB) {
      return true;
    }
    const aKeys = Object.keys(objA);
    const len = aKeys.length;
    for (let i = 0; i < len; i++) {
      const key = aKeys[i];
      if (objA[key] !== objB[key]) {
      return false;
      }
    }
    return true;
  },
  urlParamsParser: params =>
    Object.keys(params).reduce(
      (previous, item) => `${previous}&${item}=${params[item]}`,
      ''
    ),
};
