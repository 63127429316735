const numberErrorMessage = 'Please enter a number to save this alert.';
const emailErrorMessage = 'Please enter an email to save this alert.';

export default {
  type: 'newAlert',
  setFor: {
    type: 'setFor',
    labelText: 'Email Address',
    placeholder: 'Enter Email Address',
    errorMessage: emailErrorMessage,
  },
  fields: [
    {
      type: 'drIssuance',
      labelText: 'DR Issuance',
      firstText: 'or more.',
      secondText: 'Delivered after trading day',
      inputProps: {
        placeholder: 'Enter Limit',
      },
      errorMessage: numberErrorMessage,
    },
    {
      type: 'drCancellation',
      labelText: 'DR Cancellation',
      firstText: 'or more.',
      secondText: 'Delivered after trading day',
      inputProps: {
        placeholder: 'Enter Limit',
      },
      errorMessage: numberErrorMessage,
    },
    {
      type: 'drLowLimit',
      labelText: 'DR Outstanding Low limit',
      firstText: 'or less.',
      secondText: 'Delivered after trading day',
      inputProps: {
        placeholder: 'Enter Limit',
      },
      errorMessage: numberErrorMessage,
    },
    {
      type: 'drHighLimit',
      labelText: 'DR Outstanding High limit',
      firstText: 'or more.',
      secondText: 'Delivered after trading day',
      inputProps: {
        placeholder: 'Enter Limit',
      },
      errorMessage: numberErrorMessage,
    },
    {
      disabledInput: true,
      type: 'endOfDaySummary',
      labelText: 'End of Day Summary',
      secondText:
        'Delivered after trading day. Includes daily Issuance, Cancellation, DR Outstanding',
    },
    {
      disabledInput: true,
      type: 'endOfWeekSummary',
      labelText: 'End of Week Summary',
      secondText:
        'Delivered after trading week. Includes weekly Issuance, Cancellation, DR Outstanding',
    },
    {
      disabledInput: true,
      type: 'endOfMonthSummary',
      labelText: 'End of Month Summary',
      secondText:
        'Delivered after trading month. Includes monthly Issuance, Cancellation, DR Outstanding',
    },
  ],
};
