import '../../styles/components/Login.scss';
import React, { Component } from 'react';
import propTypes from 'prop-types';

import DataLogin from '../common/DataLogin';

let clickStyle =
  'navbar-item menu-principal column is-one-fifth is-12-touch has-dropdown is-hoverable';

class Login extends Component {
  constructor(props) {
    super(props);

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleEnterKey = this.handleEnterKey.bind(this);

    this.KeyMap = {
      Enter: 'handleEnterKey',
      Escape: 'handleEsc',
      ArrowDown: 'handleEnterKey',
      ArrowUp: 'handleEnterKey',
    };

    this.state = {
      mouseFocus: false,
      currentFocus: false,
    };
  }

  handleKeyDown(event) {
    this.KeyMap[event.key] && this[this.KeyMap[event.key]](event);
  }

  handleEnterKey(event) {
    this.closeDropDown();
    let $target = event.target.parentElement;
    $target.classList.contains('has-dropdown') ? null : this.closeDropDown();
    $target.classList.add('is-active');
  }

  handleEsc() {
    this.closeDropDown();
  }

  closeDropDown() {
    let $target = document.querySelectorAll('.menu-principal.is-active');
    [...$target].forEach($el => $el.classList.remove('is-active'));

    let $targetMobile = document.querySelector('.navbar-menu.is-active');
    const $opacity = document.getElementsByClassName('container-sticky');
    const $menuClose = document.getElementsByClassName('.burguer');
    if ($targetMobile) {
      $targetMobile.classList.remove('is-active');
      $opacity[0].classList.remove('opacity');
      $menuClose[0].remove('close-burger');
    }
  }

  render() {
    const { mouseFocus = false, currentFocus = false } = this.state;
    const { env } = this.props;
    const svgPath = [
      'M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481',
      '1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 ',
      '0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 ',
      '0-1.615z',
    ].join();
    return (
      <div id="login" aria-hidden="true" className={`menu-bar ${clickStyle}`}>
        <button
          id="loginsMenuButton"
          aria-label="Logins list menu, pressing enter key a dropdown should be open"
          aria-haspopup="true"
          type="button"
          data-toggle="dropdown"
          className={`navbar-item item-principal ${
            mouseFocus ? 'menu-item-mouse-focused' : ''
          }`}
          onKeyDown={this.handleKeyDown}
          onBlur={() =>
            this.setState({ mouseFocus: false, currentFocus: false })
          }
          onFocus={() => this.setState({ currentFocus: true })}
          onMouseDown={() =>
            currentFocus ? null : this.setState({ mouseFocus: true })
          }
        >
          <h2>Logins</h2>
          <svg
            height="20"
            width="20"
            viewBox="0 0 20 20"
            aria-hidden="true"
            focusable="false"
            className="css-19bqh2r"
          >
            <path d={svgPath} />
          </svg>
        </button>
        <ul className="navbar-dropdown" aria-labelledby="loginsMenuButton" role="menu">
          {DataLogin(env).submenu.map((submenu, index) => (
            <li key={`${submenu.id}`}>
              <a
                role="menuitem"
                href={submenu.path}
                className={`navbar-item index-${index}`}
                aria-label={`Login ${submenu.name}. Link to an external site`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {submenu.name}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

Login.propTypes = {
  env: propTypes.string.isRequired,
};

export default Login;
