import React, { Component } from 'react';
import propTypes from 'prop-types';

import { FaExclamationCircle, FaExclamationTriangle } from 'react-icons/fa';

import { FileButtons } from '@jpm-adr/pattern-library';

import { TEXT_NULL_VALUE, URLbyDomain } from '../../utils/constants';
import { formatDate } from '../../utils/format';
import { getDrNotifications } from '../../utils/api';

import Notification from './Notification';

const composedUrl = `${URLbyDomain}/cms/document?cmsId=`;

const notificationByType = {
  corporateAction: FaExclamationCircle,
  bookStatus: FaExclamationTriangle,
};

const getTitlesByType = {
  bookStatus: dataTitle => {
    return {
      title: dataTitle.title,
      subtitle: dataTitle.subtitle,
    };
  },
  corporateAction: dataTitle => {
    return {
      title: dataTitle.title,
      subtitle: dataTitle.subtitle,
    };
  },
};

const showContentByType = {
  bookStatus: function bookStatus(dataDates = {}) {
    const { dates = {} } = dataDates;
    const {
      difference = TEXT_NULL_VALUE,
      endDate = TEXT_NULL_VALUE,
      endText = TEXT_NULL_VALUE,
      startDate = TEXT_NULL_VALUE,
      startText = TEXT_NULL_VALUE,
    } = dates;
    const differenceText =
      difference === '--'
        ? difference
        : `${difference} ${difference > 1 ? 'Days' : 'Day'}`;
    const endDateText =
      endDate === 'TBD'
        ? endDate
        : formatDate(endDate, 'MMMM DD, YYYY', 'MMM DD');
    return (
      <div className="dates">
        <h3 className={`text__${startText}`}>
          <span>{startText}</span>
          <span>{formatDate(startDate, 'MMMM DD, YYYY', 'MMM DD')}</span>
        </h3>
        <p>--- {differenceText} ---</p>
        <h3 className={`text__${endText}`}>
          <span>{endText}</span>
          <span>{endDateText}</span>
        </h3>
      </div>
    );
  },
  corporateAction: function corporateAction(dataDates = {}) {
    const { notices = {} } = dataDates;
    const { pdf, xbrl } = notices;
    if (!pdf && !xbrl) return '';

    let filesToShow = Object.keys(notices);
    filesToShow = filesToShow.map(typeFile => {
      return {
        [typeFile]: notices[typeFile],
        [`${typeFile}Link`]: `${composedUrl}${notices[typeFile]}`,
      };
    });

    return (
      <div className="files">
        {filesToShow.map(file => (
          <FileButtons buttonsInfo={file} />
        ))}
      </div>
    );
  },
};

const getTitles = dataTitle => {
  const titles = getTitlesByType[dataTitle.type]
    ? getTitlesByType[dataTitle.type](dataTitle)
    : {};
  const NotificationIcon = notificationByType[dataTitle.type];
  return (
    <div className="textContainer">
      {NotificationIcon ? <NotificationIcon className="exclamationIcon" /> : ''}
      <div className="text">
        <h3 className="text__title">{titles.title || TEXT_NULL_VALUE}</h3>
        <h3 className="text__subtitle">{titles.subtitle || TEXT_NULL_VALUE}</h3>
      </div>
    </div>
  );
};

const getContent = dataContent => {
  let contentElements = showContentByType[dataContent.type];
  contentElements = contentElements ? contentElements(dataContent) : '';
  return contentElements;
};

const displayNotifications = items => {
  return (
    <div className="notificationsContainer">
      {items.map(notification => {
        return (
          <Notification className={notification.type}>
            {getTitles(notification)}
            {getContent(notification)}
          </Notification>
        );
      })}
    </div>
  );
};

class NotificationsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      notNotifications: '',
    };
    this.getDrNotifications = this.getDrNotifications.bind(this);
  }

  componentDidMount() {
    this.getDrNotifications();
  }

  componentDidUpdate(prevProps) {
    const { cusip: previousCusip } = prevProps;
    const { cusip: currentCusip } = this.props;
    if (currentCusip && currentCusip !== previousCusip) {
      this.getDrNotifications();
    }
  }

  getDrNotifications() {
    const { cusip } = this.props;
    if (cusip) {
      getDrNotifications(cusip).then(response => {
        const { items = [] } = response;
        this.setState({ items });
      });
    }
  }

  render() {
    const { items, notNotifications } = this.state;
    return items.length > 0 ? displayNotifications(items) : notNotifications;
  }
}

NotificationsContainer.defaultProps = {
  cusip: null,
};

NotificationsContainer.propTypes = {
  cusip: propTypes.oneOfType([propTypes.string, propTypes.number]),
};

export default NotificationsContainer;
