import PropTypes from 'prop-types';
import React from 'react';

import { DatePicker, OutlineHandler } from '@jpm-adr/pattern-library';

import ModalExport from '../modalExport/ModalExport';
import Select from '../../../components/common/SelectTest/Select';

import '../../../styles/IssuerServices/components/ModalExportContainer.scss';

import {
  endOf,
  formatDate,
  getMonth,
  isBefore,
  isSameOrBefore,
  startOf,
  substract,
} from '../../../utils/format';

import utils from '../../utils/utils';

const DAILY_TITLE = 'Export Daily Data';
const MONTHLY_TITLE = 'Export Monthly Data';

const { debounce } = utils;
const dateReg = /^\d{2}[.//]\d{2}[.//]\d{4}$/;

const validDateMessage = 'Please choose a valid date range.';

const yearOptions = [0, 1, 2, 3, 4, 5].map(year => {
  const yearConfig = substract({ year }, 'YYYY');
  return { label: yearConfig, value: yearConfig };
});
const [defaultYear, defaultFromYear] = yearOptions;

const monthOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(monthNumber => {
  return {
    label: getMonth.name.fromNumber(monthNumber),
    value: monthNumber,
  };
});
const defaultMonth = {
  label: formatDate(undefined, undefined, 'MMMM'),
  value: formatDate(undefined, undefined, 'M') - 1,
};
const defaultDateFrom = substract({ year: 1 }, 'MM/DD/YYYY');
const defaultDateTo = formatDate(undefined, undefined, 'MM/DD/YYYY');

class ModalExportContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dailyState: {
        endDate: defaultDateTo,
        startDate: defaultDateFrom,
      },
      monthlyState: {
        'from.year': {
          options: yearOptions,
          value: defaultFromYear,
          onChange: this.onChangeHelper('from.year'),
          label: 'Select year with up and down key, ',
          isSearchable: false,
        },
        'from.month': {
          options: monthOptions,
          value: defaultMonth,
          onChange: this.onChangeHelper('from.month'),
          label: 'Select month with up and down key, ',
          isSearchable: false,
        },
        'to.year': {
          options: yearOptions,
          value: defaultYear,
          onChange: this.onChangeHelper('to.year'),
          label: 'Select year with up and down key, ',
          isSearchable: false,
        },
        'to.month': {
          options: monthOptions,
          value: defaultMonth,
          onChange: this.onChangeHelper('to.month'),
          label: 'Select month with up and down key, ',
          isSearchable: false,
        },
      },
    };
    this.errorRef = React.createRef();
    this.getDailyMarkup = this.getDailyMarkup.bind(this);
    this.getMonthlyMarkup = this.getMonthlyMarkup.bind(this);
    this.onChangeHelper = this.onChangeHelper.bind(this);
    this.onChangeDatePicker = this.onChangeDatePicker.bind(this);
    this.setStateMonthlyParam = this.setStateMonthlyParam.bind(this);
    this.buttonFocus = this.buttonFocus.bind(this);
    this.getDailyValues = this.getDailyValues.bind(this);
    this.getMonthlyValues = this.getMonthlyValues.bind(this);
  }

  componentDidUpdate() {
    const { type = 'monthly' } = this.props;
    const showError = this.showError(type);

    showError && this.buttonFocus();
  }

  onChangeHelper(type) {
    const actions = {
      'from.month': (err, newSelectedMonth) =>
        this.setStateMonthlyParam('from.month', newSelectedMonth),
      'from.year': (err, newSelectedYear) =>
        this.setStateMonthlyParam('from.year', newSelectedYear),
      'to.month': (err, newSelectedMonth) =>
        this.setStateMonthlyParam('to.month', newSelectedMonth),
      'to.year': (err, newSelectedYear) =>
        this.setStateMonthlyParam('to.year', newSelectedYear),
    };

    return actions[type] || null;
  }

  onChangeDatePicker(selectedField, date, refs) {
    debounce(() => {
      const { dayPickerStart, dayPickerEnd } = refs;
      let startDate = dayPickerStart.current.state.value;
      let endDate = dayPickerEnd.current.state.value;
      const parsedStartDate = startDate;
      const parsedEndDate = endDate;
      const isAnInvalidDate = dateReg.test(startDate) && dateReg.test(endDate);
      const updatedDailyState = {
        endDate: parsedEndDate,
        startDate: parsedStartDate,
      };
      // Define new state
      const newState = {
        dailyState: {
          ...updatedDailyState,
          wrongSelectedDate: false,
          hasDefaultValues: false,
          hasSelectedDate: true,
        },
      };
      // Valid format
      if (!isAnInvalidDate) {
        newState.dailyState.hasDefaultValues = false;
        newState.dailyState.wrongSelectedDate = true;
        newState.dailyState.disabledLoading = false;
        newState.dailyState.disabledErrorBoundary = false;
      }
      // Compare dates are correctly
      const isSameOrBefore = isBefore(
        updatedDailyState.startDate,
        updatedDailyState.endDate,
        'days'
      );
      if (!isSameOrBefore) {
        newState.dailyState.hasDefaultValues = false;
        newState.dailyState.wrongSelectedDate = true;
      }

      return this.setState({ ...newState });
    }, 200)();
  }

  setStateMonthlyParam(param, value) {
    const { monthlyState } = this.state;
     monthlyState[param].value = value;
    this.setState({ monthlyState });
  }

  getDailyMarkup() {
    const { dailyState } = this.state;

    return (
      <div className="daily-controls">
        <DatePicker
          showClear={false}
          showClearLabel={false}
          onChange={this.onChangeDatePicker}
          {...dailyState}
          updateEveryChange
        />
      </div>
    );
  }

  getMonthlyMarkup() {
    const {
      monthlyState: {
        'from.year': yearSelectorConfigFrom,
        'from.month': monthSelectorConfigFrom,
        'to.year': yearSelectorConfigTo,
        'to.month': monthSelectorConfigTo,
      },
    } = this.state;
    return (
      <div>
        <div className="yearAndMonthSelector From">
          <span>From</span>
          <Select id="monthSelect" {...monthSelectorConfigFrom} />
          <Select id="yearhSelect" {...yearSelectorConfigFrom} />
        </div>

        <div className="yearAndMonthSelector To">
          <span>To</span>
          <Select id="monthSelect" {...monthSelectorConfigTo} />
          <Select id="yearhSelect" {...yearSelectorConfigTo} />
        </div>
      </div>
    );
  }

  getUrlDownload(type) {
    const isDaily = type === 'daily';
    const { cusip } = this.props;
    const {
      dateSelectorConfigFrom,
      dateSelectorConfigTo,
    } = this.getDailyValues();
    const {
      yearSelectorConfigFrom,
      monthSelectorConfigFrom,
      yearSelectorConfigTo,
      monthSelectorConfigTo,
    } = this.getMonthlyValues();

    let params = {
      activityType: type,
      startDate: isDaily
        ? formatDate(dateSelectorConfigFrom, 'MM/DD/YYYY', 'YYYY-MM-DD')
        : startOf({
            date: `${
              yearSelectorConfigFrom.value
            }-${monthSelectorConfigFrom.value + 1}`,
          format: 'YYYY-MM',
          messure: 'month',
          formatOutput: 'YYYY-MM-DD',
        }),
      endDate: isDaily
        ? formatDate(dateSelectorConfigTo, 'MM/DD/YYYY', 'YYYY-MM-DD')
        : endOf({
            date: `${yearSelectorConfigTo.value}-${monthSelectorConfigTo.value +
              1}`,
            format: 'YYYY-MM',
            messure: 'month',
            formatOutput: 'YYYY-MM-DD',
          }),
    };
    params = utils.urlParamsParser(params);
    const urls = {
      daily: `/activity/${cusip}/exportTable?${params}`,
      monthly: `/activity/${cusip}/exportTable?${params}`,
    };

    return urls[type];
  }

  getDailyFileName() {
    const {
      dateSelectorConfigFrom,
      dateSelectorConfigTo,
    } = this.getDailyValues();

    return `Activity Daily Table from ${formatDate(
      dateSelectorConfigFrom,
      'MM/DD/YYYY',
      'YYYY-MM-DD'
    )} to ${formatDate(dateSelectorConfigTo, 'MM/DD/YYYY', 'YYYY-MM-DD')}`;
  }

  getMonthlyFileName() {
    const {
      yearSelectorConfigFrom,
      monthSelectorConfigFrom,
      yearSelectorConfigTo,
      monthSelectorConfigTo,
    } = this.getMonthlyValues();

    const fromDate = `${monthSelectorConfigFrom.value + 1}/01/${yearSelectorConfigFrom.value}`;
    const formattedFromDate = formatDate(fromDate, 'MM/DD/YYYY', 'MM-YYYY');
    const toDate = `${monthSelectorConfigTo.value + 1}/01/${yearSelectorConfigTo.value}`;
    const formattedToDate = formatDate(toDate, 'MM/DD/YYYY', 'MM-YYYY');

    if (fromDate === toDate) {
      return `Activity Monthly Table for ${formattedFromDate}`;
    }

    return `Activity Monthly Table from ${formattedFromDate} to ${formattedToDate}`;
  }

  getDailyValues() {
    const {
      dailyState: {
        endDate: dateSelectorConfigTo,
        startDate: dateSelectorConfigFrom,
      },
    } = this.state;

    return {
      dateSelectorConfigFrom,
      dateSelectorConfigTo,
    };
  }

  getMonthlyValues() {
    const {
      monthlyState: {
        'from.year': { value: yearSelectorConfigFrom },
        'from.month': { value: monthSelectorConfigFrom },
        'to.year': { value: yearSelectorConfigTo },
        'to.month': { value: monthSelectorConfigTo },
      },
    } = this.state;

    return {
      yearSelectorConfigFrom,
      monthSelectorConfigFrom,
      yearSelectorConfigTo,
      monthSelectorConfigTo,
    };
  }

  buttonFocus() {
    this.errorRef.current && this.errorRef.current.focus();
  }

  validateDailyDatesError() {
    const {
      dateSelectorConfigFrom,
      dateSelectorConfigTo,
    } = this.getDailyValues();

    const isAValidDate =
      dateReg.test(dateSelectorConfigFrom) &&
      dateReg.test(dateSelectorConfigTo);
    // Evaluates correct inputs dates.
    const datesAreCorrect =
      isAValidDate &&
      isSameOrBefore(
      dateSelectorConfigFrom,
      dateSelectorConfigTo,
      'YYYY-MM-DD'
    );

    if (datesAreCorrect) {
      return { error: false };
    }

    return { error: true, message: validDateMessage };
  }

  validateMonthlyDatesError() {
    const {
      yearSelectorConfigFrom,
      monthSelectorConfigFrom,
      yearSelectorConfigTo,
      monthSelectorConfigTo,
    } = this.getMonthlyValues();

    const compositedFromDate = `${monthSelectorConfigFrom.value + 1}/01/${yearSelectorConfigFrom.value}`;
    const compositedToDate = `${monthSelectorConfigTo.value + 1}/01/${yearSelectorConfigTo.value}`;
    // Evaluates correct inputs dates.
    const datesAreCorrect = isSameOrBefore(
      compositedFromDate,
      compositedToDate,
      'MMM-YYYY'
    );

    if (datesAreCorrect) {
      return { error: false };
    }

    return { error: true, message: validDateMessage };
  }

  showError(type) {
    const validationFor = {
      daily: this.validateDailyDatesError(),
      monthly: this.validateMonthlyDatesError(),
    };

    return validationFor[type] || null;
  }

  render() {
    const { type = 'monthly', handleCloseModal } = this.props;
    const showError = this.showError(type);
    const fileName = {
      daily: this.getDailyFileName(),
      monthly: this.getMonthlyFileName(),
    };
    const markupByType = {
      daily: this.getDailyMarkup(),
      monthly: this.getMonthlyMarkup(),
    };
    const title = {
      daily: DAILY_TITLE,
      monthly: MONTHLY_TITLE,
    };

    return (
      <ModalExport
        handleCloseModal={handleCloseModal}
        exportDisabled={showError.error}
        urlDownload={this.getUrlDownload(type)}
        customClassName={`modalExportContainer ${
          showError.error ? 'validationError' : ''
        }`}
        fileName={fileName[type]}
        title={title[type]}
      >
        {markupByType[type]}
        {showError.error ? (
          <OutlineHandler>
            <div tabIndex="0" role="presentation" ref={this.errorRef} className="validationErrorMessage" >{/*eslint-disable-line*/}
              <span>{showError.message}</span>
            </div>
          </OutlineHandler>
        ) : (
          ''
        )}
      </ModalExport>
    );
  }
}

ModalExportContainer.propTypes = {
  type: PropTypes.string.isRequired,
  cusip: PropTypes.string.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};

export default ModalExportContainer;
