import React from 'react';

const noCusipMessageStyles = {
  textAlign: 'center'
};

const NoCusipError = () => {
  return(
    <section style={noCusipMessageStyles}>
      <h1>Error</h1>
      <p>
        There are no CUSIPs associated with the current user. Please contact your administrator to access DRs.
      </p>
    </section>
  )
}

export default NoCusipError;
