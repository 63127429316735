import React from 'react';

import {
  Button,
  DatePicker,
  DownloadButton,
  LoadMoreButton,
  OutlineHandler,
} from '@jpm-adr/pattern-library';

import ApiStates from '../../../utils/apiStatesWrapper';

import Select from '../../../components/common/SelectTest/Select';

import { ExportModalWrapper } from '../exportModalWrapper/ExportModalWrapper';

import { formatDate } from '../../../utils/format';

import { downloadFile } from '../../utils/apiCalls';

const SingleSelectorComponent = props => {
  const { title = 'Choose Year', typeSelector = 'year', className = '' } = props;
  return (
    <div className={`${typeSelector}-selector ${className}`}>
      <span>{title}</span>
      <Select id={`${typeSelector}-select`} {...props} />
    </div>
  );
};

const singleSelectorConfig = {
  WrappedComponent: SingleSelectorComponent,
};

export const SingleSelectorFilter = ApiStates(singleSelectorConfig);

// Year and Month Selector
const YearAndMonthSelectorComponent = props => {
  const { yearConfig, monthConfig } = props;
  return (
    <div className="yearAndMonthSelector">
      <span>{`Choose Month ${'&'} Year`}</span>
      <Select id="monthSelect" {...monthConfig} />
      <Select id="yearhSelect" {...yearConfig} />
    </div>
  );
};
const yearAndMonthSelectorConfig = {
  WrappedComponent: YearAndMonthSelectorComponent,
};

export const YearAndMonthSelectorFilter = ApiStates(yearAndMonthSelectorConfig);

// Load More
const LoadMoreComponent = props => {
  const { isLoading, error, fetchFunction, pageSize } = props;
  const loadMoreProps = { isLoading, error, fetchFunction, pageSize };
  return <LoadMoreButton {...loadMoreProps} />;
};

export const LoadMore = LoadMoreComponent;
// Date picker
const DatePickerComp = props => {
  let {
    startDate,
    endDate,
    showClearLabel,
    showClear,
    onChange,
    onResetValues,
    clearValue,
    updateEveryChange,
    wrongSelectedDate,
    hasDefaultValues,
  } = props;
  let formatedDates = {};
  if (!wrongSelectedDate) {
    formatedDates = {
      startDate: formatDate(startDate, '', 'MM/DD/YYYY'),
      endDate: formatDate(endDate, '', 'MM/DD/YYYY'),
    };
  }

  const datePickerProps = {
    showClearLabel,
    showClear,
    onChange,
    updateEveryChange,
    clearValue,
    ...formatedDates,
  };

  let buttonAttrs = {};
  if (hasDefaultValues) {
    buttonAttrs.disabled = true;
  }

  return (
    <div className="daily-controls">
      <DatePicker
        className={wrongSelectedDate ? 'DatePicker--error' : ''}
        {...datePickerProps}
      />
      {wrongSelectedDate && (
        <span aria-live="assertive" className="daily-controls--error">
          Please select a &quot;From&quot; date that occurs before your
          &quot;To&quot; date.
        </span>
      )}
      <button
        title="Pressing enter or spacebar key to reset date filters."
        {...buttonAttrs}
        type="button"
        onClick={() => onResetValues && onResetValues()}
      >
        <span>Reset Filter</span>
      </button>
    </div>
  );
};

const datePickerConfig = {
  WrappedComponent: DatePickerComp,
};

export const DatePickerComponent = ApiStates(datePickerConfig);

export const SimpleCallbackProps = props => {
  const { callbackProps } = props;
  if (callbackProps) {
    return callbackProps(props);
  }
  return '';
};

export const SimpleButton = props => (
  <OutlineHandler>
    <Button {...props} />
  </OutlineHandler>
);

const DownloadButtonFilter = ({
  btnAttr,
  endpoint,
  fileName,
  fileExt,
  sharedState,
  showModal,
  hasModal,
}) => {
  const ownCallback = () => ({
    url: endpoint,
    title: fileName,
    type: fileExt,
    newFetch: downloadFile,
  });

  const onClickCallback = hasModal ? showModal : ownCallback;
  const ariaLabelMessage = hasModal
    ? 'Pop up will be launched by pressing ENTER key'
    : 'Pressing enter key a file will be download.';

  return (
    <DownloadButton
      aria-label={`Export Table. ${ariaLabelMessage}`}
      disabled={sharedState.error !== false || sharedState.isEmpty}
      customClass="btn btn-export"
      label="Export Table"
      icon="Download"
      onClick={onClickCallback}
      {...btnAttr}
    >
      Export Table
    </DownloadButton>
  );
};

export const DownloadButtonComponent = ExportModalWrapper({
  WrappedComponent: DownloadButtonFilter,
});
