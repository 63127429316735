import PropTypes from 'prop-types';
import React from 'react';

import TableLoader from '../../components/tables/TableLoader';
import utils from '../../utils/utils';
import { date } from '../../../utils/format';
import { getHistoryAlerts } from '../../utils/apiCalls';

class HistoryTable extends React.Component {
  constructor() {
    super();

    this.state = {
      disabledLoading: false,
      disabledErrorBoundary: false,
      disabledEmptyHandler: false,
      hasDefaultValues: true,
      pageSize: 25,
      limit: 25,
      offset: 0,
    };

    this.prepareConfigTable = this.prepareConfigTable.bind(this);
    this.prepareApiRequest = this.prepareApiRequest.bind(this);
    this.getFocusableElement = this.getFocusableElement.bind(this);
    this.getFirstFocusableElement = this.getFirstFocusableElement.bind(this);
    this.onLoadMore = this.onLoadMore.bind(this);
    this.focusableItem = null;
    this.firstFocusableItem = null;
  }

  onLoadMore() {
    return ({ currentPosition }) => {
      const { limit } = this.state;
      this.setState({
        disabledLoading: true,
        disabledErrorBoundary: true,
        disabledEmptyHandler: true,
        limit,
        offset: currentPosition,
      });
    };
  }

  getFocusableElement() {
    if (this.focusableItem) {
      this.focusableItem.focus();
    }
  }

  getFirstFocusableElement() {
    const { hasDefaultValues } = this.state;
    hasDefaultValues &&
      this.firstFocusableItem &&
      this.firstFocusableItem.focus();
  }

  prepareConfigTable() {
    return {
      columns: [
        {
          text: 'Time/Date Sent (EST)',
        },
        {
          text: 'Recipient',
        },
        {
          text: 'Alert Description',
        },
      ],
      rows: [
        {
          text: data =>
            date(data.deliveries[0].sentDate, 'dayMonthYearZoneEST'),
        },
        {
          text: data => data.deliveries[0].address,
        },
        {
          text: data => utils.getAlertDescription(data.item, data.criteria.period, data.description),
        },
      ],
    };
  }

  prepareApiRequest() {
    const { xid } = this.props;
    if (!xid) return null;

    const { limit, offset } = this.state;

    return {
      requestApi: getHistoryAlerts,
      requestApiConfig: {
        venueXid: xid,
        limit: offset > 0 ? limit : 10,
        offset,
      },
    };
  }

  render() {
    const {
      props,
      prepareApiRequest,
      prepareConfigTable,
      getFocusableElement,
      getFirstFocusableElement,
      state: {
        disabledLoading,
        disabledErrorBoundary,
        disabledEmptyHandler,
        pageSize,
        offset,
      },
      onLoadMore,
    } = this;
    const configs = {
      configTable: prepareConfigTable(),
      disabledLoading,
      disabledErrorBoundary,
      disabledEmptyHandler,
      immediateRequest: true,
      getFocusableElement,
      getFirstFocusableElement,
      filters: [],
      bottomFilters: [
        {
          id: 'loadMore',
          fetchFunction: onLoadMore(),
          position: offset,
          pageSize: offset > 0 ? pageSize : 10,
        },
      ],
    };
    const tableLoaderConfig = { ...configs, ...props, ...prepareApiRequest() };
    return (
      <div aria-live="off">
        <TableLoader {...tableLoaderConfig} />
      </div>
    );
  }
}

HistoryTable.propTypes = {
  xid: PropTypes.string.isRequired,
  headerInfo: PropTypes.objectOf.isRequired,
};

export default HistoryTable;
