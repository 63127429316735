import PropTypes from 'prop-types';
import React from 'react';
import debounce from 'lodash/debounce';

import TableViewPage from './TableViewPage';
import { reduceToValues } from '../../../utils/urlMethods';
import { stringForCancelAPI } from '../../../utils/constants';

const initialState = {
  data: [],
  pagination: {
    hasMore: false,
    limit: 25,
    offset: 0,
  },
  isError: false,
};

export default class TableViewPageContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...initialState,
      isLoading: true,
      filtersValues: {
        ...props.defaultValues,
        q: '',
        startDate: '',
        endDate: '',
      },
      sortBy: props.defaultSortBy,
      sortOrder: props.defaultSortOrder,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleExport = this.handleExport.bind(this);
    this.handleFetchDataNotDebounce = this.handleFetchData.bind(this);
    this.handleFetchData = debounce(this.handleFetchDataNotDebounce, 300);
    this.handleSort = this.handleSort.bind(this);
    this.handleResetFilters = this.handleResetFilters.bind(this);
  }

  componentDidMount() {
    this.handleFetchData();
  }

  handleChange(name, value) {
    this.setState(currentState => {
      return {
        ...currentState,
        ...initialState,
        isLoading: true,
        filtersValues: {
          ...currentState.filtersValues,
          [name]: value,
        },
      };
    }, this.handleFetchData);
  }

  handleFetchData(options = {}) {
    const {
      filtersValues,
      sortBy,
      sortOrder,
      limit,
      offset,
    } = this.state;
    const { onFetchData } = this.props;
    const { currentPosition } = options;
    return onFetchData({
      ...filtersValues,
      ...options,
      sortBy,
      sortOrder,
      limit,
      offset: currentPosition || offset,
    })
    .then(({ data, pagination }) => {
        const { extraExportFunction } = this.props;
        const {
          data: currentData,
        } = this.state;
        const concatData =
          pagination && pagination.offset !== 0
            ? [...currentData, ...data]
            : [...data];
        extraExportFunction &&
          extraExportFunction(() => {
            return {
              offset: 0,
              sortBy,
              sortOrder,
              data: concatData,
              filtersValues
            };
          });

        this.setState({
          data: concatData,
          pagination: pagination,
          isLoading: false
        });
      })
      .catch(err => {
        if (err && err.message && err.message === stringForCancelAPI) {
          return;
        }
        this.setState({
          isLoading: false,
          isError: true,
        });
      });
  }

  handleExport() {
    const { data, filtersValues, sortBy, sortOrder } = this.state;
    const { getExportUrl, customExportHandler } = this.props;
    if (customExportHandler) {
      customExportHandler(data, filtersValues, sortBy, sortOrder);
    } else {
      const url = getExportUrl({
        ...filtersValues,
        offset: 0,
        limit: data.length,
        sortBy,
        sortOrder,
      });

      window.location = url;
    }
  }

  handleSort(sortBy, sortOrder) {
    this.setState(
      {
        ...initialState,
        sortBy,
        sortOrder,
        isLoading: true,
      },
      this.handleFetchData
    );
  }

  handleResetFilters(name) {
    const { fields } = this.props;

    if (name) {
      this.setState(currentState => {
        let field = fields.filter(field => field.name === name);
        field = field.length > 0 ? field[0] : {};

        const filtersValues = {
          ...currentState.filtersValues,
          [name]: field.defaultValue,
        };

        return { ...initialState, filtersValues };
      }, this.handleFetchData);

      return;
    }

    const filtersValues = fields.reduce((acc, field) => {
      acc[field.name] = field.defaultValue;

      return acc;
    }, {});

    this.setState(
      {
        ...initialState,
        filtersValues,
      },
      this.handleFetchData
    );
  }

  render() {
    const {
      data,
      filtersValues,
      isLoading,
      sortBy,
      sortOrder,
      pagination,
      isError,
    } = this.state;

    const {
      headerColumns,
      canResetFunc,
      classModifier,
      customExportButton,
      fields,
      heading,
      searchInputPlaceholder
    } = this.props;

    const isEmpty = data.length === 0;
    const displayNoDataAvailable = isEmpty && !isLoading;
    return (
      <TableViewPage
        classModifier={classModifier}
        data={data}
        fields={fields}
        pagination={pagination}
        heading={heading}
        headerColumns={headerColumns(sortBy, sortOrder, this.handleSort)}
        isLoading={isLoading}
        customExportButton={customExportButton}
        onFilterChange={this.handleChange}
        onExport={this.handleExport}
        onSelectExport={this.selectHandleExport}
        onFetchData={this.handleFetchData}
        onLoadMore={this.handleFetchDataNotDebounce}
        onSort={this.handleSort}
        onResetFilters={this.handleResetFilters}
        canReset={canResetFunc ? canResetFunc(filtersValues) : false}
        isEmpty={isEmpty}
        isError={isError}
        displayNoDataAvailable={displayNoDataAvailable}
        searchInputProps={{
          placeholder: searchInputPlaceholder,
          onChange: e => this.handleChange('q', e.target.value),
          value: filtersValues.q,
          'aria-label': searchInputPlaceholder,
        }}
        values={filtersValues}
      />
    );
  }
}

TableViewPageContainer.defaultProps = {
  classModifier: '',
  customExportButton: null,
  customExportHandler: null,
  defaultSortBy: '',
  defaultSortOrder: '',
  defaultValues: {},
  extraExportFunction: null,
  getExportUrl: () => '',
  headerColumns: () => [],
  onFetchData: () => Promise.resolve([])
};

TableViewPageContainer.propTypes = {
  canResetFunc: PropTypes.func.isRequired,
  classModifier: PropTypes.string,
  customExportButton: PropTypes.func,
  customExportHandler: PropTypes.func,
  defaultSortBy: PropTypes.string,
  defaultSortOrder: PropTypes.string,
  defaultValues: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    'aria-label': PropTypes.string,
  }),
  extraExportFunction: PropTypes.func,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      defaultValue: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.bool,
        PropTypes.number,
        PropTypes.string,
        PropTypes.object
      ]).isRequired,
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['select']).isRequired,
    })
  ).isRequired,
  getExportUrl: PropTypes.func,
  headerColumns: PropTypes.func,
  heading: PropTypes.string.isRequired,
  onFetchData: PropTypes.func,
  searchInputPlaceholder: PropTypes.string.isRequired,
};
