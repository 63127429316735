import '../../styles/components/AllEvents.scss';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  LoadMoreButton,
  NoDataAvailable,
  Table,
} from '@jpm-adr/pattern-library';
import { URLbyDomain } from '../../utils/constants';
import {
  dateFullShortMonthNoTime,
  emptyStringIfNull,
} from '../../utils/format';
import { getAllEvents } from '../../utils/api';

const values = [
  {
    name: 'announcementDate',
    displayText: 'Announcement Date',
    cellStyle: 'announcement-date',
    sorteable: true,
    type: 'shordate',
  },
  {
    name: 'type',
    displayText: 'Event',
    cellStyle: ' announcement-date',
    sorteable: true,
    type: 'eventType',
  },
  {
    name: 'firstColumn',
    displayText: '',
    cellStyle: '',
    sorteable: true,
    type: 'eventColumn',
  },
  {
    name: 'secondColumn',
    displayText: '',
    cellStyle: '',
    sorteable: true,
    type: 'eventColumn',
  },
  {
    name: 'thirdColumn',
    displayText: ' ',
    cellStyle: 'is-hidden-mobile is-hidden-touch',
    sorteable: true,
    type: 'eventColumn',
  },
  {
    name: 'notices',
    displayText: 'Notices',
    cellStyle: '',
    sorteable: false,
    type: 'files',
  },
];
const separateWithColon = item =>
  item &&
  item.split(':') &&
  item.split(':')[1] && (
    <span>
      <strong>{item.split(':')[0] + ': '}</strong>
      {item.split(':')[1]}
    </span>
  );

const addParameter = value => (value ? `&sequenceNo=` + value : '');
const TableHeaders = values.map((obj, id) => {
  return {
    id,
    title: obj.displayText,
    customCSSClass: obj.cellStyle,
  };
});

const TableData = data => {
  return data.map(obj => {
    const toRealData = value => {
      const field = value.name;
      if (!obj[field]) {
        value.data = value.type === 'eventColumn' ? '' : '--';
        value.customCSSClass = value.cellStyle;
      } else {
        value.customCSSClass = value.cellStyle;
        value.data = String(obj[field]);
        value.hasComponents = false;
        switch (value.type) {
          case 'shordate':
            value.data = dateFullShortMonthNoTime(obj[field]);
            break;
          case 'status':
            value.hasComponents = true;
            value.isOpen = obj.isOpen;
            break;
          case 'files':
            if (obj['notices']) {
              const noticesData = obj['notices'];
              const url = `${URLbyDomain}/cms/document?cmsId=`;
              value.hasComponents = true;
              value.hasNotices = true;
              value.pdf = emptyStringIfNull(noticesData.pdf);
              value.xbrl = emptyStringIfNull(noticesData.xbrl);
              value.sequenceNo = addParameter(noticesData.sequenceNo);
              value.pdfLink = `${url}${value.pdf}${value.sequenceNo}`;
              value.xbrlLink = `${url}${value.xbrl}${value.sequenceNo}`;
              value.emptyValue = ' ';
            }
            break;
          case 'link':
            value.hasLink = true;
            value.contentLink = '/drprofile/' + obj.cusip;
            break;
          case 'eventType':
            if (
              value.data.indexOf('Open') > -1 ||
              value.data.indexOf('Close') > -1
            ) {
              value.hasComponents = true;
              value.isOpen = value.data.indexOf('Open') > -1;
            }
            break;
          case 'eventColumn':
            value.data = separateWithColon(value.data);
            break;
        }
      }
      return { ...value };
    };
    return values.map(toRealData);
  });
};

class AllEvents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loadMore: true,
      isLoading: true,
    };
    this.getAllEventsData = this.getAllEventsData.bind(this);
    this.LoadingWithState = (
      <LoadMoreButton
        className="dr-loading-button"
        fetchFunction={this.getAllEventsData}
        position={0}
        pageSize={25}
      />
    );
  }
  componentWillMount() {
    this.getAllEventsData({ currentPosition: 0, pageSize: 25 });
  }
  componentWillReceiveProps(nextprops) {
    const { cusip } = this.props;
    const { cusip: newCusip } = nextprops;
    if (newCusip !== cusip)
      this.getAllEventsData({ currentPosition: 0, pageSize: 25, newCusip });
  }

  getAllEventsData({ currentPosition, pageSize, newCusip }) {
    const { cusip } = this.props;
    this.setState({ isLoading: true });
    return getAllEvents(newCusip || cusip, currentPosition, pageSize)
      .then(response => {
        const { items = [] } = response;
        const { data } = this.state;
        const composedData =
          currentPosition === 0 ? items : [...data, ...items];
        this.setState({
          data: composedData,
          loadMore: response.pagination.hasMore,
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          isLoading: false,
        });
      });
  }

  render() {
    const { data, isLoading, loadMore } = this.state;

    if (
      !isLoading &&
      (data === null || (data && Object.keys(data).length === 0))
    ) {
      return (
        <section className="All__Events__Table">
          <NoDataAvailable
            messageTitle="No data available"
            customMessageTitleClass="Data__Not__Found-Title"
            customWrapperCSS="No__Data__Available-Custom-Wrapper"
            message="There is no data available for this specific program."
            messageStyles="Data__Not__Found-Message"
            wrapperClass="Data__Not__Found"
          />
        </section>
      );
    }

    return (
      <React.Fragment>
        <Table
          isLoading={isLoading}
          customStyles={{ height: '440px' }}
          values={TableData(data || [])}
          tableId="event-table"
          tableCustomCss="event-table table-component table is-striped is-narrow"
          tableWrapperClass="table-wrapper-all-events"
          tableTitles={TableHeaders}
          statusTitleOpen="Open"
          statusTitleClosed="Closed"
        />
        {isLoading || !loadMore ? null : this.LoadingWithState}
      </React.Fragment>
    );
  }
}

AllEvents.propTypes = {
  cusip: PropTypes.string.isRequired,
};

export default AllEvents;
