import { feesYear, numberMagnitude } from '../../utils/format';

export function adapter(bookClosures) {
  return bookClosures.map(obj => {
    let name = mapObjectHasLink(
        obj,
        'name',
        'books-name ellipsis regular is-bold'
      ),
      cusip = mapObject(obj, 'cusip', 'is-hidden-touch'),
      ticker = mapObject(obj, 'ticker', 'is-bold is-hidden-touch'),
      globalMarketCapital = mapObject(
        obj,
        'globalMarketCap',
        'is-hidden-mobile'
      ),
      country = mapObject(obj, 'country', 'is-hidden-mobile country-column'),
      annualAdminFee = mapObject(obj, 'annualAdminFee', 'is-hidden-mobile'),
      annualDividendFee = mapObject(obj, 'annualDividend', 'is-hidden-mobile'),
      totalAnnualFee = mapObject(obj, 'totalAdminFee', '');

    return [
      name,
      cusip,
      ticker,
      globalMarketCapital,
      country,
      annualAdminFee,
      annualDividendFee,
      totalAnnualFee,
    ];
  });
}

function mapObject(obj, field, customCSS = '') {
  let newObj = {};

  if (field in obj && obj[field] !== null) {
    newObj.data =
      field === 'globalMarketCap'
        ? numberMagnitude(obj[field],2)
        : String(obj[field]);
    newObj.customCSSClass = customCSS;
  } else {
    newObj.data = '--';
    newObj.customCSSClass = customCSS;
  }

  return newObj;
}

function mapObjectHasLink(obj, field, customCSS) {
  let newObj = {};

  if (field in obj) {
    newObj.data = String(obj[field]);
    newObj.hasLink = true;
    newObj.contentLink = '/drprofile/' + obj.cusip;
    newObj.customCSSClass = customCSS;
  }

  return newObj;
}

export const POSSIBLE_SORT_VALUES = {
  asc: 'asc', // Ascending
  desc: 'desc', // Descending
  unsorted: 'unsorted',
};

export const SORT_OPTIONS_MAP = {
  name: 'name',
  cusip: 'cusip',
  ticker: 'ticker',
  globalMarketCap: 'globalMarketCap',
  country: 'country',
  annualAdminFee: 'annualAdminFee',
  annualDividendFee: 'annualDividend',
  totalAnnualFee: 'totalAdminFee',
};

export const getSortDirectionForName = (
  name,
  currentlySortedName,
  sortedDirection
) => {
  if (name === currentlySortedName) {
    return POSSIBLE_SORT_VALUES[sortedDirection];
  }

  return POSSIBLE_SORT_VALUES.unsorted;
};

export const createTitles = (
  currentlySortedName,
  sortedDirection,
  handleSort
) => {
  let index = 1;
  const createHeader = (title, customCSSClass, sortName, width) => {
    index++;

    return {
      id: index,
      title,
      customCSSClass,
      sortable: true,
      handleSort,
      sortName,
      sortDirection: getSortDirectionForName(
        sortName,
        currentlySortedName,
        sortedDirection
      ),
      width,
      minWidth: width,
    };
  };

  return [
    createHeader('DR Name', '', SORT_OPTIONS_MAP.name),
    createHeader('CUSIP', 'is-hidden-touch', SORT_OPTIONS_MAP.cusip),
    createHeader('Ticker', 'is-hidden-touch', SORT_OPTIONS_MAP.ticker, '90px'),
    createHeader(
      'Company Market Cap (USD)',
      'is-hidden-mobile',
      SORT_OPTIONS_MAP.globalMarketCap,
      '120px'
    ),
    createHeader(
      'Country',
      'is-hidden-mobile country-column',
      SORT_OPTIONS_MAP.country,
      '96px'
    ),
    createHeader(
      `Annual Admin Fee ${feesYear} USD per DR`,
      'is-hidden-mobile',
      SORT_OPTIONS_MAP.annualAdminFee,
      '150px'
    ),
    createHeader(
      `Annual Dividend Fee ${feesYear} USD per DR`,
      'is-hidden-mobile',
      SORT_OPTIONS_MAP.annualDividendFee,
      '160px'
    ),
    createHeader(
      `Total Annual Fee ${feesYear} USD per DR`,
      '',
      SORT_OPTIONS_MAP.totalAnnualFee
    ),
  ];
};
