const retrieve = localAccessToken => {
  const accessTokenMeta = document.querySelector("meta[name='access_token']");
  const accessTokenFromMeta = accessTokenMeta
    ? accessTokenMeta.getAttribute('value')
    : null;
  if (accessTokenFromMeta) {
    localStorage.setItem('access_token', accessTokenFromMeta);
    return accessTokenFromMeta;
  }

  const accessTokenFromLocalStorage = localStorage.getItem('access_token');
  if (accessTokenFromLocalStorage) return accessTokenFromLocalStorage;

  return localAccessToken || null;
};

export default { retrieve };
