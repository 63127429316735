import React from 'react';

import propTypes from 'prop-types';
import {
  NoDataAvailable,
  StatusBook,
  withLoadingText,
} from '@jpm-adr/pattern-library';

import { executeDataRequest } from '../../utils/dataRequestMethods';
import { withApiRequest } from '../../utils/apiWithHOC';
import { withErrorBoundary } from '../../utils/errorBoundary';

import '../../styles/components/HeaderProfile.scss';

const HeaderProfileData = props => {
  const { isLoading, data } = props;
  const {
    bookStatus,
    bookStatusIsOverriden,
    bookStatusOverrideText,
    daysUntilClose,
    daysUntilOpen,
    isOpen,
    name,
    openCloseDate,
    symbol
  } = data || {};

  const Content = () =>
    !data || Object.keys(data).length === 0 ? (
      <NoDataAvailable
        messageTitle="No data available"
        customMessageTitleClass="Data__Not__Found-Title"
        customWrapperCSS="No__Data__Available-Custom-Wrapper Header__Component"
        message="There is no data available for this specific program."
        messageStyles="Data__Not__Found-Message"
        wrapperClass="Data__Not__Found"
      />
    ) : (
      <React.Fragment>
        <div className="Header_Profile__Component column padding-left column-data has-text-centered-mobile ticker-cell status-header">
          <div className="header-code">{name}</div>
          <div className="header-detail">
            <span>Ticker: </span>
            <span className="header-detail-value">{symbol}</span>
          </div>
          {bookStatus && (
            <StatusBook
              bookStatusIsOverriden={bookStatusIsOverriden}
              daysUntilClose={daysUntilClose}
              daysUntilOpen={daysUntilOpen}
              isOpen={isOpen}
              openDate={openCloseDate}
              titleClosed="Books Closed"
              titleOpen="Books Open"
              titleOverride={bookStatusOverrideText}
            />
          )}
          <div className="header-date is-hidden">&nbsp;</div>
        </div>
      </React.Fragment>
    );

  const ContentWithLoadingText = withLoadingText(Content);

  return (
    <ContentWithLoadingText isLoading={isLoading} withGreyBackground={false} />
  );
};

HeaderProfileData.defaultProps = {
  isLoading: true,
  data: {
    name: 'BNP PARIBAS',
    symbol: 'BNPQY',
    isOpen: false,
  },
};
HeaderProfileData.propTypes = {
  isLoading: propTypes.bool,
  data: propTypes.shape({
    name: propTypes.string,
    symbol: propTypes.string,
    isOpen: propTypes.bool,
  }),
};

export default withErrorBoundary(
  withApiRequest(
    HeaderProfileData,
    ({ cusip }) =>
      cusip ? executeDataRequest(`dr/openClose/${cusip}`, 'GET') : null
  )
);
