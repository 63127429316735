import React from 'react';

import '../../styles/components/About.scss';
import Timeline from './Timeline';
import timelineContent from './timelineContent.json';

const WhyJpMorgan = () => {
  return (
    <section className="hero is-halfheight">
      <div className="hero__about">
        <div className="hero__about--container">
          <div
            className="hero__about--background"
            alt="hero background"
            src="/static/media/why-jpm-header-image-desktop-tablet.png"
          />
        </div>
        <div className="column column-hidden side-margins" />
        <div className="hero__about--main main-content-wrapper ">
          <div>
            <div className="hero__about--header">90 Years of Innovation</div>
            <div className="hero__about--content">
              Since 1927, J.P. Morgan has been a leading provider of customized
            </div>
            <div className="hero__about--content">
              Depositary Receipts solutions to the world’s largest issuers.
            </div>
          </div>
        </div>
        <div className="column column-hidden side-margins" />
      </div>
      <main>
        <article className="content__brief">
          <div>
            <div className="column column-hidden side-margins" />
            <div className="main-content-wrapper">
              {`With our strength as a global equities leader, we help our clients navigate the capital markets and attract high-quality investors.
              Guided by J.P. Morgan's core principle of "first-class business in a first-class way,” DR issuers have relied on us for 90 years for
              our advice and long-term partnership.`}
            </div>
            <div className="column column-hidden side-margins" />
          </div>
        </article>
        <div className="quarters">
          <div className="column column-hidden side-margins" />
          <div className=" main-content-wrapper">
            <div className="quarters--content ">
              <div className="quarters--row">
                <article className="quarters__section">
                  <div className="quarters__content--header">
                    Focused client base ensures an exceptional client experience
                  </div>
                  <div className="quarters__content--text">
                    {`At J.P. Morgan, we focus our attention on the quality—not quantity—of clients and programs we serve.
                    This allows us to deliver a client experience centered on individualized attention and service.`}
                  </div>
                </article>
                <article className="quarters__section">
                  <div className="quarters__content--header">
                    Integrated, firmwide approach to your IR and capital markets needs
                  </div>
                  <div className="quarters__content--text">
                    {`As one of the world’s leading investment and depositary banks, J.P. Morgan is uniquely positioned to
                    provide clients with a truly integrated equity solution—connecting you with our extensive network of buy–and
                    sell–side market participants, along with giving you access to IR and capital markets professionals who guide
                    you step-by-step in growing your DR program.`}
                  </div>
                </article>
              </div>
              <div className="quarters--row">
                <article className="quarters__section">
                  <div className="quarters__content--header">
                    Excellence and innovation
                  </div>
                  <div className="quarters__content--text">
                    {`With a record of "firsts" in the industry—from creating the first ADR in 1927, to launching the first ADRs in Latin America
                    and Asia, to more recently structuring the first Hong Kong DR in 2010—J.P. Morgan exemplifies a heritage of innovation,
                    leadership and commitment to the DR business.`}
                  </div>
                </article>
                <article className="quarters__section">
                  <div className="quarters__content--header">
                    A partner and close ally
                  </div>
                  <div className="quarters__content--text">
                    {`Everything we do is with our clients' best interests in mind. Since most of our clients, and their investors, have broader
                    relationships with J.P. Morgan, we always act in their long-term best interest and uphold the highest standards of integrity
                    in all of our actions.`}
                  </div>
                </article>
              </div>
            </div>
          </div>
          <div className="column column-hidden side-margins" />
        </div>
        <Timeline timeline={timelineContent} />
      </main>
    </section>
  );
};

export default WhyJpMorgan;
