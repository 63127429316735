import AccessTokenService from './access-token-service';
import ApiBase from '../../utils/apiBase';
import { SAMLURL, defaultApiURL } from './constants';

class ApiAuth extends ApiBase {
  constructor() {
    if (!ApiAuth.instance) {
      super();
      this.logOffCallBack = null;
      this.gettingDomain = false;
      this.callbacksQueue = [];
      this.domain = null;
      this.token = AccessTokenService.retrieve();
      ApiAuth.instance = this;
    }
    return ApiAuth.instance;
  }

  setDomain(data = {}) {
    const { jpmAdrIS, SAMLSSO } = data;
    this.domain = jpmAdrIS || defaultApiURL;
    this.SAMLSSODomain = SAMLSSO || SAMLURL;
  }

  getSAMLSSODomain() {
    return Promise.resolve(this.SAMLSSODomain);
  }
}

export default new ApiAuth();
