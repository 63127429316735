import '../../../styles/IssuerServices/components/ModalExport.scss';
import React, { Component } from 'react';
import propTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import { DownloadWrapper } from '@jpm-adr/pattern-library';

import { downloadFile } from '../../utils/apiCalls';

class ModalExport extends Component {
  constructor(props) {
    super(props);
    this.refModal = React.createRef();
    this.acceptButton = React.createRef();
    this.closeButton = React.createRef();
    this.closeModal = this.closeModal.bind(this);
    this.updateState = this.updateState.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.downloadClick = this.downloadClick.bind(this);

    this.state = {
      isAccept: false,
    };
  }

  componentDidMount() {
    this.buttonFocus();
  }

  componentDidUpdate() {
    if (this.acceptButton.current) this.buttonFocus();
  }

  onKeyDown() {
    return event => {
      if (event.key === 'Escape') {
        this.closeModal();
      }
    };
  }

  downloadClick() {
    const { downloadFunction, fileName, urlDownload } = this.props;

    return downloadFunction(urlDownload, fileName, 'xlsx', downloadFile);
  }

  buttonFocus() {
    if (this.acceptButton.current) this.acceptButton.current.focus();
    else if (this.closeButton.current) this.closeButton.current.focus();
  }

  closeModal() {
    const { handleCloseModal } = this.props;
    handleCloseModal && handleCloseModal();
    const $modal = this.refModal.current;
    $modal.classList.remove('is-active');
  }

  updateState() {
    const { isAccept } = this.state;
    const { onClickBtn } = this.props;
    this.setState({ isAccept: true });
    onClickBtn(isAccept);
  }

  render() {
    const {
      children,
      title,
      footer: CustomFooter,
      customClassName,
      exportDisabled,
    } = this.props;
    const downloadButtonAttrs = {
      [exportDisabled ? 'disabled' : '']: true,
    };
    return (
      <div
        className={`modal modalExport is-active ${customClassName}`}
        ref={this.refModal}
        aria-live="assertive"
      >
        <div className="modal-background" />
        <div
          className="modal-card"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-title"
        >
          <header className="modal-card-head">
            {title ? (
              <p className="modal-card-title" id="modal-title">
                {title}
              </p>
            ) : (
              ''
            )}
            <button
              title="Pressing ESC, modal will be closed."
              className="btn-modal"
              aria-label="Close modal"
              type="button"
              onClick={this.closeModal}
              onKeyDown={this.onKeyDown()}
              ref={this.closeButton}
            >
              <p className="modal-card-title has-text-right is-hidden-touch">
                Close X
              </p>
            </button>
          </header>
          <div className={customClassName === "newAlerts" ? "alerts-overflow" : null}>
            <section
              role="presentation"
              onKeyDown={this.onKeyDown()}
              className="modal-card-body"
            >
              {children}
            </section>
            <footer className="modal-card-foot">
              {CustomFooter ? (
                <CustomFooter closeModal={this.closeModal} />
              ) : (
                <div className="button-actions">
                  <button
                    onClick={this.closeModal}
                    id="modalExportContainer__button--cancel"
                    type="button"
                  >
                    Cancel
                  </button>
                  <button
                    id="modalExportContainer__button--download"
                    type="button"
                    ref={this.acceptButton}
                    onClick={this.downloadClick}
                    {...downloadButtonAttrs}
                  >
                    Download Data
                  </button>
                </div>
                )}
            </footer>
          </div>
        </div>
      </div>
    );
  }
}

ModalExport.defaultProps = {
  footer: null,
  customClassName: '',
  exportDisabled: false,
};

ModalExport.propTypes = {
  customClassName: propTypes.string,
  footer: propTypes.shape(),
  onClickBtn: propTypes.func.isRequired,
  downloadFunction: propTypes.func.isRequired,
  handleCloseModal: propTypes.func.isRequired,
  fileName: propTypes.string.isRequired,
  children: propTypes.node.isRequired,
  title: propTypes.string.isRequired,
  exportDisabled: propTypes.bool,
  urlDownload: propTypes.string.isRequired,
};

const ModalExportComponent = withRouter(ModalExport);

export default DownloadWrapper({ WrappedComponent: ModalExportComponent });
