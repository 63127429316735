import '../../styles/IssuerServices/Layout.scss';
import '../../styles/IssuerServices/components/TableList.scss';

import PropTypes from 'prop-types';
import React from 'react';

import { Route, Switch } from 'react-router-dom';
import { getHeader, getXID } from '../utils/apiCalls';
import { prefix, subSections } from '../utils/constants';

import ActivityPage from './Activity/Activity';
import AlertsPage from './Alerts/Alerts';
import DividendsPage from './Dividends/Dividends';
import Header from '../components/header/Header';
import LibraryPage from './Library/Library';
import NavigationBar from '../components/navigation/NavigationBar';
import OwnershipPage from './Ownership/Ownership';
import ProfileHeader from '../components/profileHeader/ProfileHeader';
import SummaryPage from './Summary/Summary';

const navigationPaths = Object.keys(subSections);

class Layout extends React.Component {
  constructor() {
    super();
    this.prefix = prefix;
    this.navigationPaths = navigationPaths;
    this.state = {
      error: false,
      loading: true,
      xid: null,
    };
    this.getInitialData = this.getInitialData.bind(this);
  }

  componentDidMount() {
    this.getInitialData();
  }

  componentDidUpdate(prevProps = {}) {
    const { props: currentProps = {} } = this;
    if (
      currentProps.match &&
      currentProps.match.params.cusip !== prevProps.match.params.cusip
    ) {
      this.getInitialData();
    }
  }

  getInitialData() {
    const { match } = this.props;
    const { params = {} } = match;
    const { cusip } = params;
    this.setState({ loading: true });
    return Promise.all([getXID(cusip), getHeader(cusip)])
      .then(([xid, headerInfo]) =>
        this.setState({
          headerInfo,
          xid,
          loading: false,
        })
      )
      .catch(() => this.setState({ error: true }));
  }

  render() {
    const { match } = this.props;
    const { params = {} } = match;
    const { cusip } = params;
    const { xid, loading, error, headerInfo } = this.state;

    const componentProps = {
      cusip,
      headerInfo,
      loading,
      match,
      xid,
    };

    if ((!xid && !loading) || error) {
      componentProps.error = true;
    }

    return (
      <div className="issuer-services__layout">
        <Header />
        <div aria-hidden className="background-padding" />
        <section>
          <ProfileHeader {...componentProps} />
          <NavigationBar match={match} />

          <div className="issuer-services__body">
            <div className="issuer-services__body-content">
              <Switch>
                <Route
                  exact
                  path={`${prefix}/summary/:cusip`}
                  component={() => <SummaryPage {...componentProps} />}
                />
                <Route
                  exact
                  path={`${prefix}/ownership/:cusip`}
                  component={() => <OwnershipPage {...componentProps} />}
                />
                <Route
                  exact
                  path={`${prefix}/activity/:cusip`}
                  component={() => <ActivityPage {...componentProps} />}
                />
                <Route
                  exact
                  path={`${prefix}/dividends/:cusip`}
                  component={() => <DividendsPage {...componentProps} />}
                />
                <Route
                  exact
                  path={`${prefix}/library/:cusip`}
                  component={() => <LibraryPage {...componentProps} />}
                />
                <Route
                  exact
                  path={`${prefix}/alerts/:cusip`}
                  component={() => <AlertsPage {...componentProps} />}
                />
              </Switch>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

Layout.propTypes = {
  match: PropTypes.shape({}).isRequired,
};

export default Layout;
