import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment-timezone';

import {
  dateFullShortMonthNoTime,
  numberComma,
  numberCommaSigned,
  numberSignedName,
  substract,
} from '../../../utils/format';

import ProgressBar from '../../components/progressBar/ProgressBar';
import TableLoader from '../../components/tables/TableLoader';
import { getOwnershipTop } from '../../utils/apiCalls';

class TopFiftyTable extends React.Component {
  constructor() {
    super();
    this.currentDate = {
      year: moment()
        .year()
        .toString(),
      quarter: moment().quarter(),
    };
    this.initialState = ['year', 'quarter'].reduce((acc, element) => {
      acc[element] = this.getOptionsValues(element)[0];
      return acc;
    }, {});

    this.state = {
      filterBy: { ...this.initialState },
      disabledDropdowns: true,
    };

    this.prepareConfigTable = this.prepareConfigTable.bind(this);
    this.prepareApiRequest = this.prepareApiRequest.bind(this);
    this.getTheHighestValue = this.getTheHighestValue.bind(this);
    this.calculateProgressBarPercentagePerRow = this.calculateProgressBarPercentagePerRow.bind(
      this
    );
    this.onChangeFilters = this.onChangeFilters.bind(this);
    this.getOptionsValues = this.getOptionsValues.bind(this);
    this.enableDropdows = this.enableDropdows.bind(this);
    this.disableDropdows = this.disableDropdows.bind(this);
    this.getQueryString = this.getQueryString.bind(this);
  }

  onChangeFilters(type) {
    return (error, selectedOption) => {
      const { filterBy } = this.state;
      filterBy[type] = selectedOption;
      const { year, quarter } = filterBy;
      const { year: currentYear, quarter: currentQuarter } = this.currentDate;

      if (year.value === currentYear && quarter.value >= currentQuarter)
        filterBy['quarter'] = this.initialState.quarter;

      this.setState({
        filterBy,
      });
    };
  }

  getOptionsValues(type) {
    const { state = {}, currentDate } = this;
    const { filterBy = {} } = state;
    const { year = {} } = filterBy;
    const maxQuater =
      year.value === currentDate.year ? currentDate.quarter - 1 : 4;
    const options = {
      year: [0, 1, 2, 3, 4].map(year => {
        const yearConfig = substract({ year }, 'YYYY');
        return { label: yearConfig, value: yearConfig };
      }),
      quarter: [1, 2, 3, 4]
        .filter(quarter => quarter <= maxQuater || quarter === 1)
        .map(quarter => {
          return { label: `Q${quarter}`, value: quarter };
        }),
    };

    return options[type];
  }

  getTheHighestValue(allItems, key) {
    return allItems.reduce(
      (previousValue, currentValue) =>
        currentValue[key] > previousValue[key] ? currentValue : previousValue
    )[key];
  }

  getFooterTableLetter() {
    return props => {
      const {
        sharedState: { error: sharedError, isLoading },
        error,
        loading,
      } = props;
      if (!error && !loading && !sharedError && !isLoading) {
        return (
          <div className="factset-label">
            <span>Ownership Data provided by FactSet</span>
          </div>
        );
      }

      return null;
    };
  }
  getQueryString() {
    const {
      state: { filterBy, disabledDropdowns },
    } = this;

    if (disabledDropdowns) return '';

    const newFilterBy = { ...filterBy };
    return Object.keys(newFilterBy).reduce(
      (previous, item) => `${previous}${item}=${newFilterBy[item].value}&`,
      '?'
    );
  }

  disableDropdows() {
    this.setState({
      disabledDropdowns: true,
      filterBy: { ...this.initialState },
    });
  }

  enableDropdows() {
    this.setState({ disabledDropdowns: false });
  }
  calculateProgressBarPercentagePerRow() {
    return allItems => {
      const hihgestValue = this.getTheHighestValue(allItems, 'currentPosition');
      return allItems.map(item => {
        let percentage = (item.currentPosition / hihgestValue) * 100;
        if (percentage < 1) percentage = 1;
        return { ...item, percentage };
      });
    };
  }

  prepareConfigTable() {
    const { calculateProgressBarPercentagePerRow } = this;
    return {
      mappers: [calculateProgressBarPercentagePerRow()],
      columns: [
        {
          text: <div className="rank">Rank</div>,
        },
        {
          text: <div className="holder-name">Holder Name</div>,
        },
        {
          text: <div className="city">City</div>,
        },
        {
          text: <div className="current-position">Current Position</div>,
        },
        {
          text: '', // Progress bar
        },
        {
          text: 'Position Change',
        },
        {
          text: <div className="previous-position">Previous Position</div>,
        },
        {
          text: 'Report Date',
        },
      ],
      rows: [
        {
          text: (item, index) => <div className="rank">{index + 1}</div>,
        },
        {
          text: itemData => (
            <div className="holder-name">{itemData.holderName}</div>
          ),
        },
        {
          text: itemData => <div className="city">{itemData.city}</div>,
        },
        {
          text: itemData => (
            <div className="current-position">
              {numberComma(itemData.currentPosition)}
            </div>
          ),
        },
        {
          className: 'progressbar-cell',
          text: itemData => (
            <ProgressBar percentage={itemData.percentage} width="120px" />
          ),
        },
        {
          text: itemData => (
            <div
              className={`position-change-${numberSignedName(
                itemData.positionChange
              )}`}
            >
              {numberCommaSigned(itemData.positionChange)}
            </div>
          ),
        },
        {
          text: itemData => (
            <div className="previous-position">
              {numberComma(itemData.previousPosition)}
            </div>
          ),
        },
        {
          text: itemData => dateFullShortMonthNoTime(itemData.reportDate),
        },
      ],
    };
  }

  prepareApiRequest() {
    const { cusip } = this.props;
    const { disabledDropdowns, filterBy } = this.state;
    if (!cusip) return null;
    let newFilterBy = { ...filterBy };
    Object.keys(newFilterBy).map(
      key => (newFilterBy[key] = newFilterBy[key].value)
    );
    if (disabledDropdowns) newFilterBy = {};
    return {
      requestApi: getOwnershipTop(cusip, newFilterBy),
    };
  }

  render() {
    const {
      props,
      prepareApiRequest,
      prepareConfigTable,
      getFooterTableLetter,
      state: {
        filterBy: { year, quarter },
        disabledDropdowns,
      },
      getOptionsValues,
      onChangeFilters,
    } = this;
    const configs = {
      callbackProps: getFooterTableLetter(),
      configTable: prepareConfigTable(),
      ...props,
      ...prepareApiRequest(),
      filters: [
        {
          id: 'simpleButton',
          children: <span>ALL MOST RECENT FILINGS</span>,
          'aria-label':
            'Pressing Enter/Spacebar, "All Most Recent Filings" details will be launched',
          onClick: this.disableDropdows,
          customClass: `btn switch state__${disabledDropdowns}`,
        },
        {
          id: 'simpleButton',
          children: <span>QUARTERLY FILINGS</span>,
          'aria-label':
            'Pressing Enter/Spacebar, "Quarterly Filings" details will be launched',
          onClick: this.enableDropdows,
          customClass: `btn switch state__${!disabledDropdowns}`,
        },
        {
          id: 'singleSelect',
          options: getOptionsValues('quarter'),
          value: quarter,
          onChange: onChangeFilters('quarter'),
          label:
            'pressing Up/Down arrow key, the Quarter drop down will be open',
          isSearchable: false,
          title: '',
          isDisabled: disabledDropdowns,
          typeSelector: 'quarter',
          className: 'quartersSelector',
        },
        {
          id: 'singleSelect',
          options: getOptionsValues('year'),
          value: year,
          onChange: onChangeFilters('year'),
          label: 'pressing Up/Down arrow key, the Year drop down will be open',
          isSearchable: false,
          title: '',
          isDisabled: disabledDropdowns,
          className: 'quartersSelector',
        },
        {
          id: 'exportButton',
          endpoint: `/ownership/top/${
            props.cusip
          }/exportTable${this.getQueryString()}`,
          btnAttr: {
            role: 'button',
            title:
              'Export Table: Pressing ENTER or SPACEBAR key, top 50 Institutional holders table will get exported',
          },
          fileExt: 'xlsx',
          fileName: props.headerInfo
            ? `${props.headerInfo.name}_Ownership_Export`
            : 'Ownership_Export',
        },
      ],
      bottomFilters: [
        {
          id: 'simpleCallbackProps',
        },
      ],
      immediateRequest: true,
      filtersClassName: 'table_top_filters_container',
    };
    return <TableLoader {...configs} />;
  }
}

TopFiftyTable.propTypes = {
  xid: PropTypes.string.isRequired,
  cusip: PropTypes.string.isRequired,
  headerInfo: PropTypes.objectOf.isRequired,
};

export default TopFiftyTable;
