import DataLogin from './DataLogin';

const dataMenu = env => [
  {
    name: 'About',
    id: 'about',
    submenu: [
      {
        name: 'Why J.P. Morgan?',
        id: 'about-whyjpmorgan',
        path: '/about/whyjpmorgan',
      },
      {
        name: 'Client Advisory',
        id: 'about-clientadvisory',
        path: '/about/clientadvisory',
      },
      { name: 'Glossary', id: 'about-glossary', path: '/about/glossary' },
    ],
  },
  {
    name: 'Dr Directory',
    id: 'drdirectory',
    submenu: [
      {
        name: 'DR Universe',
        id: 'drdirectory-druniverse',
        path: '/dr/drdirectory/drUniverse',
      },
      {
        name: 'Corporate Actions',
        id: 'drdirectory-corporateactions',
        path: '/dr/drdirectory/corporateActions',
      },
      {
        name: 'Book Closures',
        id: 'drdirectory-bookclosures',
        path: '/dr/drdirectory/bookClosures',
      },
      {
        name: 'Fees',
        id: 'drdirectory-feebrowser',
        path: '/dr/drdirectory/fees',
      },
    ],
  },
  {
    name: '',
    id: 'brokerinformation',
    submenu: [
      {
        name: 'Broker Forms',
        id: 'brokerinformation-brokerforms',
        path: '/broker/brokerforms',
      },
      {
        name: 'Headroom',
        id: 'brokerinformation-headroom',
        path: '/broker/headroom',
      },
    ],
  },
  {
    name: 'Contact',
    id: 'contact',
    submenu: [
      {
        name: 'Contact J.P. Morgan',
        id: 'contact-contactjpmorgan',
        path: '/contact/jpmorgan',
      },
    ],
  },
  {
    name: 'Logins',
    id: DataLogin(env).id,
    submenu: DataLogin(env).submenu.map(submenu => {
      const { name, id, path } = submenu;
      return { name, id, path };
    }),
  },
];

export default dataMenu;
