import * as React from 'react';
import { dateFullNoTime } from '../../utils/format';

export default function adapter(bookClosures) {
  return bookClosures.map(obj => {
    let name = mapObjectHasLink(
        obj,
        'name',
        'books-name ellipsis regular is-bold'
      ),
      cusip = mapObject(obj, 'cusip', 'is-hidden-touch'),
      ticker = mapObject(obj, 'ticker', 'is-hidden-touch is-bold'),
      country = mapObject(obj, 'country', 'is-hidden-touch'),
      isOpen = mapObjectHasComponent(
        obj,
        'isOpen',
        'is-flex-mobile is-flex-start'
      ),
      closeDate = mapObject(obj, 'closeDate', 'is-hidden-mobile'),
      openDate = mapObject(obj, 'openDate', 'is-hidden-mobile'),
      type = mapObject(obj, 'type', 'is-hidden-mobile'),
      reason = mapObject(obj, 'reason', 'is-hidden-mobile reason ellipsis'),
      announcement = mapObject(
        obj,
        'announcementDate',
        'is-hidden-mobile min-width-160'
      );

    return [
      name,
      cusip,
      ticker,
      country,
      isOpen,
      closeDate,
      openDate,
      type,
      reason,
      announcement,
    ];
  });
}

function mapObject(obj, field, customCSS) {
  let newObj = {};

  if (field in obj) {
    if (
      field === 'announcementDate' ||
      field === 'closeDate' ||
      field === 'openDate'
    ) {
      newObj.data = dateFullNoTime(obj[field]);
    } else {
      newObj.data = String(obj[field]);
    }
    newObj.customCSSClass = customCSS;
  } else {
    newObj.data = '--';
    newObj.customCSSClass = customCSS;
  }
  return newObj;
}

function mapObjectHasLink(obj, field, customCSS) {
  let newObj = {};

  if (field in obj) {
    newObj.data = String(obj[field]);
    newObj.hasLink = true;
    newObj.contentLink = '/drprofile/' + obj.cusip;
    newObj.customCSSClass = customCSS;
  }

  return newObj;
}

function mapObjectHasComponent(obj, field, customCSS) {
  let newObj = {};

  if (field in obj) {
    newObj.data = String(obj[field]);
    newObj.hasComponents = true;
    newObj.isOpen = obj.isOpen;
    newObj.customCSSClass = customCSS;
    newObj.bookStatusOverrideText = obj.bookStatusOverrideText
  }

  return newObj;
}

export const POSSIBLE_SORT_VALUES = {
  asc: 'asc', // Ascending
  desc: 'desc', // Descending
  unsorted: 'unsorted',
};

export const SORT_OPTIONS_MAP = {
  name: 'name',
  cusip: 'cusip',
  ticker: 'ticker',
  country: 'country',
  isOpen: 'isOpen',
  closeDate: 'closeDate',
  openDate: 'openDate',
  type: 'type',
  reason: 'reason',
  announcementDate: 'announcementDate',
};

export const getSortDirectionForName = (
  name,
  currentlySortedName,
  sortedDirection
) => {
  if (name === currentlySortedName) {
    return POSSIBLE_SORT_VALUES[sortedDirection];
  }

  return POSSIBLE_SORT_VALUES.unsorted;
};

export const createBookClosureTitles = (
  currentlySortedName,
  sortedDirection,
  handleSort
) => {
  let index = 1;

  const createHeader = (title, customCSSClass, sortName, width) => {
    index++;

    return {
      id: index,
      title:title.component||title,
      customCSSClass,
      sortable: true,
      handleSort,
      sortName,
      sortDirection: getSortDirectionForName(
        sortName,
        currentlySortedName,
        sortedDirection
      ),
      width,
      minWidth: width,
      ariaLabel:title.ariaLabel
    };
  };

  return [
    createHeader('DR Name', '', SORT_OPTIONS_MAP.name),
    createHeader('CUSIP', 'is-hidden-touch', SORT_OPTIONS_MAP.cusip),
    createHeader('Ticker', 'is-hidden-touch', SORT_OPTIONS_MAP.ticker, '90px'),
    createHeader(
      'Country',
      'is-hidden-touch',
      SORT_OPTIONS_MAP.country,
      '96px'
    ),
    createHeader(
      {
        component: (
          <React.Fragment>
            <div style={{ paddingRight: '3px' }}>Current</div>
            <div>Books Status</div>
          </React.Fragment>
        ),
        ariaLabel: 'Current Books Status',
      },
      'current-status-header',
      SORT_OPTIONS_MAP.isOpen,
      '218px'
    ),
    createHeader(
      'Close Date',
      'is-hidden-mobile',
      SORT_OPTIONS_MAP.closeDate,
      '130px'
    ),
    createHeader(
      'Open Date',
      'is-hidden-mobile',
      SORT_OPTIONS_MAP.openDate,
      '130px'
    ),
    createHeader('Type', 'is-hidden-mobile', SORT_OPTIONS_MAP.type),
    createHeader('Reason', 'is-hidden-mobile', SORT_OPTIONS_MAP.reason),
    createHeader(
      'Announcement Date',
      'is-hidden-mobile',
      SORT_OPTIONS_MAP.announcementDate,
      '220px'
    ),
  ];
};
