import React from 'react';
import propTypes from 'prop-types';

import CustodianData from './CustodianData';

import '../../styles/components/Custodian.scss';

const Custodian = props => {
  const{custodianCusip} = props;
  return (
    <section className="custodian column is-paddingless is-multiline">
      <h2 className="custodian-title column is-12">Custodian</h2>
      <CustodianData cusip={custodianCusip} />
    </section>
  );
}

Custodian.propTypes = {
  custodianCusip: propTypes.string.isRequired,
}

export default Custodian;

