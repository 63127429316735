import Axios from '../components/common/AxiosWrapper';
import * as UrlMethods from './urlMethods';
import {
  apiRetries,
  notAuthenticatedStatus,
  stringForCancelAPI,
} from './constants';

class ApiBase {
  constructor () {
    this.logOffCallBack = null;
    this.gettingDomain = false;
    this.callbacksQueue = [];
    this.authHeader = {};
    this.isDebugging = UrlMethods.isDebugOn();
    this.mockParameters = UrlMethods.getMockParameters();
  }

  setLogOffCallBack (fn) {
    this.logOffCallBack = fn;
  }

  getDomain () {
    let SAMLSSODomain = window.MD && window.MD.SAMLSSO || '';
    // if it exists we need to replace any variables in [] before setting
    if (SAMLSSODomain) {
      const referrer = document.referrer.toLowerCase();
      const hostDomain = referrer.split('/')[2] || '';
      let authConnection = 'external-SP%3ASAML2%3ACitrix';
      const appHref = window.location.href.toLowerCase();
      const appDomain = appHref.split('/')[2] || '';
      // P2 aka dark prod aka jpm-adr.* will use UAT auth connection
      if (appDomain === 'jpm-adr.markitondemand.com') {
        authConnection = 'uat-external-IDP%3ASAML2%3ACitrix'
      }
      SAMLSSODomain = SAMLSSODomain.replace('[domain]', `${hostDomain}`);
      SAMLSSODomain = SAMLSSODomain.replace('[auth]', `${authConnection}`);
    }


    this.setDomain({
      jpmAdr: window.MD && window.MD.jpmAdr || '',
      jpmAdrIS: window.MD && window.MD.jpmAdrIS || '',
      jpmAlerts: window.MD && window.MD.jpmAlerts || '',
      SAMLSSO: SAMLSSODomain,
      env: window.MD && window.MD.env || ''
    });

    return Promise.resolve();
  }

  transformResponse (resp) {
    const {
      data: { data = {} },
    } = resp;
    if (resp.data instanceof Blob) return resp;
    if (data) return Promise.resolve(data);
    return Promise.reject('No data available or incorrect Format');
  }

  transformError (err, config) {
    const { response = { status: 200 } } = err;
    const { status } = response;
    if (notAuthenticatedStatus.indexOf(status) > -1) {
      this.logOffCallBack && this.logOffCallBack();
      return Promise.reject('User session is invalid');
    } else {
      const { attempts } = config;
      if (err && err.message && err.message === stringForCancelAPI) {
        return Promise.reject(new Error(stringForCancelAPI));
      } else if (attempts > 0) {
        config.attempts--;
        return this.tryCall(config);
      } else {
        return Promise.reject('Many attempts failed to get data');
      }
    }
  }

  tryCall (config) {
    let authHeader = {};
    const {
      attempts = apiRetries,
      method = 'get',
      endpoint = '',
      data,
      params = {},
      responseType,
    } = config;

    const newParams = { ...params,...this.mockParameters };
    if (params.cancelToken) {
      params.cancelToken[0] = Axios.CancelToken.source();
      delete newParams.cancelToken;
    }
    if (this.isDebugging) {
      newParams['..showdebugdata..'] = 'on';
    }

    if (attempts > 0) {
      if (this.token) {
        authHeader = { Accept: '*/*', Authorization: `Bearer ${this.token}` };
      }

      return Axios({
        method,
        url: `${this.domain}${endpoint}`,
        data,
        headers: authHeader,
        params: newParams,
        cancelToken:
          params.cancelToken && params.cancelToken[0]
            ? params.cancelToken[0].token
            : null,
        responseType,
      })
        .then(this.transformResponse)
        .catch(error => this.transformError(error, { attempts, ...config }));
    } else {
      return Promise.reject('Many attempts failed to get data');
    }
  }
  makeCall (data) {
    return this.getDomain().then(() => {
      return this.tryCall(data);
    });
  }
  getAPIEndpoint () {
    return this.getDomain().then(() => this.tryGetAPI());
  }
  tryGetAPI () {
    return Promise.resolve(this.domain);
  }
  getEnv () {
    return this.getDomain().then(() => this.tryGetEnv());
  }
  tryGetEnv () {
    return Promise.resolve(this.env);
  }
  getSSODomain () {
    return this.getDomain().then(() => this.getSAMLSSODomain());
  }
}

export default ApiBase;
