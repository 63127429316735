import React, { Component } from 'react';

import ModalExportContainer from '../modalExportContainer/ModalExportContainer';

export const ExportModalWrapper = ({ WrappedComponent }) => {
  return class ExportModalComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        showModal: false,
      };
      this.shouldShowModal = this.shouldShowModal.bind(this);
      this.showModal = this.showModal.bind(this);
      this.handleCloseModal = this.handleCloseModal.bind(this);
    }

    showModal() {
      return (
        <ModalExportContainer
          handleCloseModal={this.handleCloseModal}
          {...this.props}
        />
      );
    }

    handleCloseModal() {
      this.setState({ showModal: false });
    }

    shouldShowModal() {
      const { hasModal } = this.props;
      if (hasModal) {
        this.setState({ showModal: true });
      }
    }

    render() {
      const { showModal } = this.state;
      return (
        <React.Fragment>
          {showModal && this.showModal()}
          <WrappedComponent {...this.props} showModal={this.shouldShowModal} />
        </React.Fragment>
      );
    }
  };
};

export default ExportModalWrapper;
