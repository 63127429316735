import React from 'react';
import { LinksSection } from '@jpm-adr/pattern-library';

const links = [
  {
    description: 'U.S. Securities and Exchange Commission',
    label: 'Search Filings',
    anchor: 'https://www.sec.gov/edgar/searchedgar/companysearch.html',
  },
  {
    description: 'Globe Tax',
    label: 'Service Description',
    anchor: 'https://www.globetax.com/contact-us/'
  },
  {
    description: 'Goal Group',
    label: 'Service Description',
    anchor: 'http://www.goalgroup.com/our-services/'
  },
  {
    description: 'The Depository Trust & Clearing Corporation',
    label: 'Contact Information',
    anchor: 'http://www.dtcc.com/'
  }
];

const UsefulLinks = () => {
  return (
    <React.Fragment>
      <LinksSection links={links} sectionTitle="HELPFUL LINKS" />
    </React.Fragment>
  );
};

export default UsefulLinks;
