import ApiBase from './apiBase';
import { defaultApiURL } from './constants';

class ApiPublic extends ApiBase {
  constructor() {
    if (!ApiPublic.instance) {
      super();
      this.logOffCallBack = null;
      this.gettingDomain = false;
      this.callbacksQueue = [];
      this.domain = null;
      this.env = 'production'
      ApiPublic.instance = this;
    }
    return ApiPublic.instance;
  }

  setDomain(data = {}) {
    const { jpmAdr , env } = data;
    this.domain = jpmAdr || defaultApiURL;
    this.env = env || this.env;
  }
}

export default new ApiPublic();
