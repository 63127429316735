import PropTypes from 'prop-types';
import React from 'react';
import { TabularData } from '@jpm-adr/pattern-library';

import ApiStates from '../../../utils/apiStatesWrapper';
import { getRelationshipManager } from '../../utils/apiCalls';

import Address from '../../../components/custodian/Address';
import Contact from '../../../components/custodian/Contact';

import '../../../styles/IssuerServices/components/RelationshipManager.scss';

const containerClass = 'relationship-manager';
const containerTitle = 'RELATIONSHIP MANAGER';

const RelationshipManager = props => {
  const showManager = manager => {
    const { address = {}, email, phone, lastName, firstName } = manager;
    const data = [];
    if (firstName || lastName)
      data.push({
        title: 'Name',
        value: (
          <Contact
            data={{ name: `${firstName} ${lastName}`, email }}
            ariaLabel="Manager contact"
          />
        ),
      });
    if (phone) data.push({ title: 'Telephone', value: `${phone}` });
    if (address && address.line1)
      data.push({
        title: 'Address',
        value: <Address data={address} />,
      });
    return (
      <TabularData
        data={data.map((item, i) => {
          return { id: i, ...item };
        })}
      />
    );
  };

  const showManagersInfo = (data = {}) => {
    const { items = [] } = data;
    return (
      <React.Fragment>
        {items.map(manager => showManager(manager))}
      </React.Fragment>
    );
  };
  const { data } = props;
  return (
    <div className={containerClass}>
      <span className="title">{containerTitle}</span>
      {showManagersInfo(data)}
    </div>
  );
};

RelationshipManager.defaultProps = {
  data: [],
};

RelationshipManager.propTypes = {
  data: PropTypes.arrayOf(),
};

const config = {
  WrappedComponent: RelationshipManager,
  requestApi: ({ xid }) => getRelationshipManager(xid),
  custom: { containerClass, containerTitle },
};

export default ApiStates(config);
