import React from 'react';

const headerStyles = {
  display: 'flex',
  justifyContent: 'center',
  height: '41px',
  lineHeight: '40px',
  border: '0',
  backgroundColor: '#333',
  color: '#ddd',
  padding: '0',
  margin: '0',
};

const iframeContainerStyles = {
  height: 'calc(100vh - 68px)',
  backgroundColor: '#333',
};

const footerStyles = {
  backgroundColor: '#333',
  bottom: '0',
  height: '27px',
};

const inputStyles = {
  outline: '0',
  backgroundColor: 'white',
  cursor: 'auto',
  lineHeight: '22px',
  height: '25px',
  margin: 'auto',
  width: '397px',
  color: '#666',
  left: '0',
  textAlign: 'center',
};

class IframeForTests extends React.Component {
  constructor() {
    super();
    this.state = {
      inputValue: '',
      url: '/issuerservices/alerts/66987V109',
    };
    this.onChange = this.onChange.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
  }

  onChange(event) {
    const {
      target: { value },
    } = event;
    this.setState({ inputValue: value });
  }

  onKeyPress(event) {
    const {
      target: { value },
    } = event;
    if (event.key === 'Enter') {
      this.setState({ url: value });
    }
  }

  render() {
    const { inputValue, url } = this.state;
    return (
      <div>
        <header style={headerStyles}>
          <input
            value={inputValue}
            placeholder="Navigate into the iframe"
            style={inputStyles}
            onChange={this.onChange}
            onKeyPress={this.onKeyPress}
          />
        </header>
        <div style={iframeContainerStyles}>
          <iframe
            title="iframe-for-tests"
            src={url}
            frameBorder="0"
            allowFullScreen=""
            style={{ width: '100%', height: '100%', overflow: 'hidden' }}
          />
        </div>
        <footer style={footerStyles} />
      </div>
    );
  }
}

export default IframeForTests;
