import React, { Fragment } from 'react';

import Hero from '../hero/Hero';
import TopTradingVolumes from "../topTradingVolumes/TopTradingVolumes";
import { getLadingStats } from '../../utils/api';

class Home extends React.Component {
  constructor() {
    super();

    this.state = {
      heroData: {
        error: null,
        loading: true,
        totalPreviousDayDrsTraded: 0,
        totalPreviousDayVolume: 0,
        lastTradeDate: ''
      }
    };
  }

  componentDidMount() {
    getLadingStats()
      .then(({ totalPreviousDayDrsTraded, totalPreviousDayVolume, lastTradeDate }) => {
        this.setState({
          heroData: {
            loading: false,
            totalPreviousDayDrsTraded,
            totalPreviousDayVolume,
            lastTradeDate
          }
        })
      })
      .catch(() => {
        this.setState({
          heroData: {
            error: true,
            loading: false
          }
        })
      })
  }

  render() {
    const { heroData } = this.state;

    return (
      <Fragment>
        <Hero {...heroData} />
        <TopTradingVolumes lastTradeDate={heroData.lastTradeDate} />
      </Fragment>
    );
  }
}

export default Home;
