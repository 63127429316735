import React from 'react';
import ReactDOM from 'react-dom';

import './index.scss';

import App from './App';
import {unregister as unregisterServiceWorker} from './registerServiceWorker';

ReactDOM.render(<App />, document.getElementById('app'));
unregisterServiceWorker();
