import React from 'react';
import jsPDF from 'jspdf';

import 'jspdf-autotable';
import _ from 'lodash';

import { OutlineHandler } from '@jpm-adr/pattern-library';

let x = 0;
let y = 0;

const tables = {
  one: () => document.getElementById('quotes-1'),
  two: () => document.getElementById('quotes-2'),
  three: () => document.getElementById('details-1'),
  fourth: () => document.getElementById('details-2'),
};
const hasQuotes = () =>
  document.querySelectorAll('.quote-detail-level-root div p');
const hasBothQoutes = () => tables.one() && tables.two();
const hasBothDetails = () => tables.three() && tables.fourth();

const getRGBColors = className => {
  const classes = {
    'quote-detail-number-green': [0, 136, 52],
    'quote-detail-number-red': [218, 41, 28],
    default: [0,0,0],
  };
  return classes[className] || classes.default;
};

const getQuotes = pdf => {
  // DR Quotes
  if (!hasQuotes()) {
    return null;
  }
  y += 10;
  const item1 = [...hasQuotes()];
  const xCoords = [15, 56, 87, 130, 165];
  return item1.map((label, index) => {
    const [R, G, B] = getRGBColors(label.className);
    pdf.setTextColor(R, G, B);
    if (label.children.length > 0) {
      let text = '';

      [...label.childNodes].map(
        innerLabel => (text += innerLabel.innerHTML || innerLabel.nodeValue)
      );
      pdf.text(x, index % 2 === 0 ? y : y + 5, _.unescape(text));
    } else {
      pdf.text(x, index % 2 === 0 ? y : y + 5, _.unescape(label.innerHTML));
    }
    if (index % 2 !== 0) {
      x = xCoords[(index + 1) / 2];
      pdf.setFontSize(11);
      pdf.setTextColor(0, 0, 0);
    } else {
      pdf.setFontSize(8);
      pdf.setTextColor(108, 110, 110);
    }
    return label;
  });
};

const tabularsToOne = (
  id1,
  id2,
  { y, w0 = 45, w1 = 45, w2 = 45, w3 = 45 },
  pdf
) => {
  const t1 = pdf.autoTableHtmlToJson(id1);
  t1.columns =['', '']

  const t2 = pdf.autoTableHtmlToJson(id2);
  t2.columns = ['', '']

  const newT = {
    columns: [...t1.columns, ...t2.columns],
    data: [[...t1.columns, ...t2.columns]],
  };

  t1.data.shift()
  t2.data.shift()

  t1.data.map(
    (item, index) =>
      (newT.data[index + 1] = [...t1.data[index], ...t2.data[index]])
    );

    return {
    body: newT.data,
    startY: y,
    bodyStyles: {
      overflow: 'normal',
      fontSize: 9,
      cellPadding: { right: 1, left: 1 },
    },
    columnStyles: {
      0: { cellWidth: w0, minCellHeight: 7, valign: 'middle' },
      1: { halign: 'right', overflow: 'ellipsize', cellWidth: w1, minCellHeight: 7, valign: 'middle'},
      2: { cellWidth: w2, minCellHeight: 7, valign: 'middle' },
      3: { halign: 'right', overflow: 'ellipsize', cellWidth: w3, minCellHeight: 7, valign: 'middle' },
    },
  };
};

const getTables = pdf => {
  if (hasBothQoutes()) {
    // Table 1
    y += 10;
    pdf.autoTable(
      tabularsToOne(
        tables.one(),
        tables.two(),
        { y, w0: 45, w1: 40, w2: 33, w3: 62 },
        pdf
      )
    );
    //program Details
    y += 45;
    pdf.setFontSize(12);
    pdf.setTextColor(0, 0, 0);
    pdf.text(
      15,
      y,
      _.unescape(document.getElementsByClassName('program-detail-title')[0].innerHTML)
    );
  }

  if (hasBothDetails()) {
    // Table 2
    y += 5;
    pdf.autoTable(
      tabularsToOne(
        tables.three(),
        tables.fourth(),
        { y, w0: 20, w1: 65, w2: 33, w3: 62 },
        pdf
      )
    );
  }
};

const pdfClick = () => {
  const pdf = new jsPDF();
  pdf.setFont('Helvetica');
  y = 45;
  x = 15;
  //DR Name
  pdf.setFontSize(30);
  pdf.text(x, y, _.unescape(document.getElementsByClassName('header-code')[0].innerHTML));
  // DR Ticker
  y += 10;
  pdf.setFontSize(12);
  pdf.text(
    x,
    y,
    [...document.querySelectorAll('.header-detail span')].reduce(
      (acc, element) => _.unescape(acc + element.innerHTML),
      ''
    )
  );

  getQuotes(pdf);
  getTables(pdf);
  pdf.save(`${'DR-Profile'}.pdf`);
};

const PrintPDFButton = props => {
  const { xid } = props;
  const disabledButton = !xid;
  const action = disabledButton ? () => '' : pdfClick;
  return (
    <OutlineHandler>
      <button
        type='button'
        tabIndex={disabledButton ? '-1' : '0'}
        aria-label="By clicking this button or pressing ENTER the pdf will be downloaded"
        onKeyPress={e => {
          e.key === 'Enter' ? action : null;
        }}
        onClick={action}
        className={`header-pdf is-hidden-mobile ${
          disabledButton ? 'disabledButton' : ''
        }`}
        title={disabledButton ? 'No data available' : ''}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="header-pdf-svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z" />
          <path d="M0 0h24v24H0z" fill="none" />
        </svg>
        <span>DOWNLOAD PDF</span>
      </button>
    </OutlineHandler>
  );
};

export default PrintPDFButton;
