import React from 'react';
import propTypes from 'prop-types';
import {NoDataAvailable , Table } from '@jpm-adr/pattern-library';
import {dateFullShortMonthNoTime } from '../../utils/format';
import {executeDataRequest} from '../../utils/dataRequestMethods'
import {withApiRequest} from '../../utils/apiWithHOC'
import {withErrorBoundary} from '../../utils/errorBoundary'

import '../../styles/components/Fees.scss';
import '../../styles/components/OpenClosed.scss';

const OpenClosed = props => {
  const { data, isLoading, customClass } = props;

  if(!isLoading && (data === null || (data && Object.keys(data).length === 0))){
    return (
      <section className="Dividends__Table">
        <NoDataAvailable
          messageTitle="No data available"
          customMessageTitleClass="Data__Not__Found-Title"
          customWrapperCSS={customClass + "No__Data__Available-Custom-Wrapper"}
          message="There is no data available for this specific program."
          messageStyles="Data__Not__Found-Message"
          wrapperClass="Data__Not__Found"
        />
      </section>
    );
  }
  function mapObject(obj, field, customCSS) {
    let newObj = {};
    if (field in obj) {
      if (field === 'announcementDate' || field === 'openCloseDate') {
        newObj.data = dateFullShortMonthNoTime(obj[field]);
      } else {
        newObj.data = String(obj[field]);
      }
      newObj.customCSSClass = customCSS;
    } else {
      newObj.data = '--';
      newObj.customCSSClass = customCSS;
    }
    return newObj;
  }

  function mapObjectHasComponent(obj, field, customCSS) {
    let newObj = {};
    if (field in obj) {
      newObj.data = String(obj[field]);
      newObj.hasComponents = true;
      newObj.isOpen = obj.isOpen;
      newObj.customCSSClass = customCSS;
    }
    return newObj;
  }

  function adapter(apiData) {
    let newDataStructure = [];
    apiData.map(obj => {
      let openCloseDate = mapObject(obj, 'openCloseDate', 'open-close-margin'),
        isOpen = mapObjectHasComponent(obj, 'isOpen', 'status-book-margin'),
        type = mapObject(obj, 'type', 'open-close-margin'),
        announcement = mapObject(obj, 'announcementDate', 'open-close-margin'),
        reason = mapObject(obj, 'reason', 'open-close-margin');

      newDataStructure.push([
        openCloseDate,
        isOpen,
        type,
        announcement,
        reason,
      ]);
    });
    return newDataStructure;
  }

  const openClosedTitles = [
    {
      id: 1,
      title: 'Open/Close Date',
      customCSSClass: 'margin-table-fees',
    },
    {
      id: 2,
      title: 'Status',
      customCSSClass: 'margin-table-fees',
    },
    {
      id: 3,
      title: 'Type',
      customCSSClass: 'margin-table-fees',
    },
    {
      id: 4,
      title: 'Announcement Date',
      customCSSClass: 'margin-table-fees',
    },
    {
      id: 5,
      title: 'Reason',
      customCSSClass: 'margin-table-fees',
    },
  ];

  const apiData = adapter( !Array.isArray(data) ? [] : data );
  return (
    <div>
      <Table
        values={apiData}
        customStyles={{ height: '440px' }}
        isLoading={isLoading}
        tableId="fees-table"
        tableCustomCss="fees-table open-close-tables table-component table is-striped is-narrow"
        tableTitles={openClosedTitles}
        statusTitleClosed="Closed"
        statusTitleOpen="Open"
        tableWrapperClass="table-wrapper-dividends"
      />
    </div>
  );
};

OpenClosed.defaultProps = {
  customClass: '',
  isLoading: true,
  data: [
    {
      announcementDate: '--',
      isOpen: '--',
      openCloseDate: '--',
      type: '--',
      reason: '--',
    },
  ],
};

OpenClosed.propTypes = {
  customClass: propTypes.string,
  isLoading: propTypes.bool,
  data: propTypes.arrayOf(
    propTypes.shape({
      announcementDate: propTypes.string,
      isOpen: propTypes.bool,
      openCloseDate: propTypes.string,
      type: propTypes.string,
      reason: propTypes.string,
    })
  ),
};

export default withErrorBoundary(
  withApiRequest(OpenClosed,
    ({cusip})=>(cusip)? executeDataRequest(`dr/booksOpenClosed/${cusip}`, 'GET'): null));
