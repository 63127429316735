import React from 'react';
import propTypes from 'prop-types';

import AccessTokenService from '../../utils/access-token-service';


const SsoForm = props => {

    const {formAction, accessToken} = props;
    const referrer = document.referrer.toLowerCase();
    const hostDomain = referrer.split('/')[2] || '';

    return (
      <form name="sso" id="sso" action={formAction} method="POST" style={{"width": "0", "height": "0", "visibility": "hidden"}}>
        <input type="hidden" name="access_token" value={`${accessToken}`} />
        <input type="hidden" name="referrer" value={hostDomain} />
      </form>
    )
}

SsoForm.defaultProps = {
    formAction: "/issuerservices/SSO?..nocache..=on",
    accessToken: AccessTokenService.retrieve()
  };

SsoForm.propTypes = {
    formAction: propTypes.string,
    accessToken: propTypes.string
  };

export default SsoForm;
