import { Component } from 'react';

import propTypes from 'prop-types';
import { getSearch } from '../../utils/api';

class ApiConsumer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      results: {},
    };
  }

  componentDidUpdate(nextProps) {
    const { query, customApiSearch } = this.props;
    const apiToUse = customApiSearch || getSearch;
    if (nextProps !== this.props && query) {
      apiToUse(query).then(response => {
        this.setState({ results: {[query]: response.items }});
      });
    }
  }

  render() {
    const { children, query } = this.props;    
    const { results } = this.state;
    const { [query]: data } = results;
    return data ? children({ data }) : '';
  }
}

ApiConsumer.propTypes = {
  children: propTypes.func.isRequired,
  query: propTypes.string.isRequired,
  customApiSearch: propTypes.func,
};

ApiConsumer.defaultProps = {
  customApiSearch: null,
};

export default ApiConsumer;
