const dataLogin = (env = 'production') => {
  switch (env) {
    case 'acc':
    case 'dev':
      return {
        id: 'Login',
        name: 'Login',
        submenu: [
          {
            id: 'login-corporate',
            name: 'Corporate',
            path: 'https://markets-uat.jpmorgan.com/',
          },
          {
            id: 'login-registered',
            name: 'Registered Holder',
            path: 'https://www.shareowneronline.com',
          },
          {
            id: 'login-global-invest',
            name: 'Global Invest Direct',
            path: 'https://www.shareowneronline.com/transactional/anonymous/dspp/company-plans',
          },
          {
            id: 'login-jpmorgan-markets',
            name: 'J.P. Morgan Markets',
            path: 'https://markets-uat.jpmorgan.com/',
          },
        ],
      };
    default:
      return {
        id: 'Login',
        name: 'Login',
        submenu: [
          {
            id: 'login-corporate',
            name: 'Corporate',
            path: 'https://markets.jpmorgan.com',
          },
          {
            id: 'login-registered',
            name: 'Registered Holder',
            path: 'https://www.shareowneronline.com',
          },
          {
            id: 'login-global-invest',
            name: 'Global Invest Direct',
            path: 'https://www.shareowneronline.com/transactional/anonymous/dspp/company-plans',
          },
          {
            id: 'login-jpmorgan-markets',
            name: 'J.P. Morgan Markets',
            path: 'https://markets.jpmorgan.com',
          },
        ],
      };
  }
};

export default dataLogin;
