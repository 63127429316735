import moment from 'moment-timezone';
import numeral from 'numeral';
import { TEXT_NULL_VALUE } from './constants';

export const isNull = val =>
  typeof val === 'undefined' ||
  val === null ||
  val === '-32768' ||
  val === -32768;

const numberFormatter = (val, formatString) =>
  isNull(val) ? TEXT_NULL_VALUE : numeral(val).format(formatString);

export const date = (value, format) => {
  if (isNull(value)) return TEXT_NULL_VALUE;

  const time = moment(value);

  if (!time.isValid()) return TEXT_NULL_VALUE;

  const now = moment();
  const timeUtc = moment.utc(value);
  const isDst = moment(timeUtc).isDST;
  const abbreviation = isDst ? 'EDT' : 'EST';

  // parseZone allows you to keep the zone the timestamp has on it
  // moment will automatically parse into your local
  switch (format) {
    case 'zoneTime':
      return time.parseZone().format('HH:mm');
    case 'localTime':
      return time.format('HH:mm');

    case 'roadshow':
      if (now.isSame(time, 'day')) {
        return `Today ${time.parseZone().format('DD MMM')}`;
      }
      return time.parseZone().format('ddd DD MMM');
    case 'dayMonth':
      if (now.isSame(time, 'day')) {
        return time.format('HH:mm');
      }
      return time.format('DD MMM');
    case 'dayMonthYearZone': {
      const zone = moment.tz.zone(moment.tz.guess());
      const zoneTime = moment.tz(time, zone.name);
      let timeAbbreviation = zone.abbr(time.format('X'));
      timeAbbreviation =
        (timeAbbreviation[0] === '+' || timeAbbreviation === '-' ? 'GMT' : '') +
        timeAbbreviation;
      return zoneTime.format('DD MMMM, YYYY HH:mm') + ' ' + timeAbbreviation;
    }
    case 'monthYear':
      return timeUtc.format('MM/YYYY');
    case 'monthDayYear':
      return timeUtc.format('MM/DD/YY');
    case 'monthDayYearFull':
      return timeUtc.format('MM/DD/YYYY');
    case 'yearMonthDayAPI':
      return timeUtc.format('YYYY-MM-DD');
    case 'shortMonthDayYearFull':
      return timeUtc.format('MMM DD, YYYY');
    case 'shortMonthFullDayYearFull':
      return timeUtc.format('MMMM DD, YYYY');
    case 'dayMonthYearZoneEST':
      return timeUtc.utcOffset(-5).format('MM/DD/YYYY hh:mma') + ' EST';
    // quote date returned is in eastern time already
    case 'monthDayTimeZoneEasternQuote':
      return timeUtc.format('MMM DD, h:mma') + ` ${abbreviation}`;
    // quote date for datascope does not have time
    case 'shortMonthDay':
      return timeUtc.format('MMM DD');
    case 'year':
      return timeUtc.format('YYYY');
    case 'monthYearSpacing':
      return timeUtc.format('MMMM YYYY');
    case 'monthYearSpacingWithComma':
      return timeUtc.format('MMM DD, YYYY');
    case 'yearMonthWithUnderscore':
      return timeUtc.format('YYYY_MM');
    case 'yearMonthWithoutSpaces':
      return timeUtc.format('YYYYMMDD');
    case 'shortMonthYear':
      return timeUtc.format('MMM YYYY');
    default:
      if (now.isSame(time, 'day')) {
        return time.format('HH:mm');
      }
      return time.format('DD MMM, YYYY');
  }
};

export const dateQuoteET = value => date(value, 'monthDayTimeZoneEasternQuote');

export const dateAsOfQuoteET = value => 'as of ' + dateQuoteET(value);

// used for quotes that return Datascope data because quote server does not return a time with the date
// time is assumed to be end of day / market close
export const dateAsOfQuoteEndOfDay = value => 'as of ' + date(value, 'shortMonthDay') + ', End of Day';

export const dateNoTime = value => date(value, 'monthDayYear');

export const dateNoTimeAsOf = value => 'as of ' + dateNoTime(value);

export const dateNoTimeAsOfClose = value => 'as of close ' + dateNoTime(value);

export const dateFullNoTime = value => date(value, 'monthDayYearFull');

export const dateFullShortMonthNoTime = value =>
  date(value, 'shortMonthDayYearFull');

export const dateFullShortMontFullhNoTime = value =>
  date(value, 'shortMonthFullDayYearFull');

export const yearMonthDayAPI = value => date(value, 'yearMonthDayAPI');

export const dateNoTimeMonthYear = value => date(value, 'monthYear');

export const dateCountdown = value => date(value, 'countdown');

export const dateUntilMarketsChange = (value, isOpen) => {
  const countdown = date(value, 'countdown') || {};
  let countdownHours = countdown.hours;
  const openClose = isOpen ? 'close' : 'open';
  const timeframe = countdown.hours === 0 ? 'minutes' : 'hours';

  if (countdown.hours > 0 && countdown.hours < 10)
    countdownHours = `0${countdown.hours}`;
  if (countdown.hours < 0 && countdown.hours > -10)
    countdownHours = `-0${Math.abs(countdown.hours)}`;

  return `${countdownHours}:${
    countdown.minutes
  } ${timeframe} until stock market ${openClose}`;
};

export const dateYear = value => date(value, 'year');

export const formatDate = (value = moment(), format = '', targetFotmat = '') =>
  moment(value, format).format(targetFotmat);

export const dateMonthYearSpacing = value =>
  date(formatDate(value, 'YYYYMM'), 'monthYearSpacing');

export const dateMonthYearSpacingWithComma = value =>
  date(value, 'monthYearSpacingWithComma');

export const yearMonthWithUnderscore = value =>
  date(value, 'yearMonthWithUnderscore');

export const yearMonthWithoutSpaces = value =>
  date(value, 'yearMonthWithoutSpaces');

export const shortMonthYear = value => date(value, 'shortMonthYear');

export const number = (val, precision = 0) => {
  const formatString = `0.${Array(precision + 1).join('0')}`;
  return numberFormatter(val, formatString);
};

export const numeralize = val => numeral(val).value();

export const numberComma = (val, precision = 0) => {
  const formatString = `0,0.${Array(precision + 1).join('0')}`;
  return numberFormatter(val, formatString);
};

export const numberCommaSigned = (val, precision = 0) => {
  const formatString = `0,0.${Array(precision + 1).join('0')}`;
  const formatted = numberFormatter(val, formatString);
  const startChar = numeralize(formatted) > 0 ? '+' : '';
  return startChar + formatted;
};

export const numberMagnitude = (val, precision = 0) => {
  const formatString = `0.${Array(precision + 1).join('0')}a`;
  return numberFormatter(val, formatString).toUpperCase();
};

export const numberSigned = (val, precision = 0) => {
  const formatString = `0.${Array(precision + 1).join('0')}`;
  const formatted = numberFormatter(val, formatString);
  const startChar = numeralize(formatted) > 0 ? '+' : '';
  return startChar + formatted;
};

export const percent = (val, precision = 0) => {
  const formatString = `0.${Array(precision + 1).join('0')}%`;
  return numberFormatter(val, formatString);
};

export const percentComma = (val, precision = 0) => {
  const formatString = `0,0.${Array(precision + 1).join('0')}%`;
  return numberFormatter(val, formatString);
};

export const percentCommaSigned = (val, precision) => {
  const formatString = `0,0.${Array(precision + 1).join('0')}%`;
  const formatted = numberFormatter(val, formatString);
  const startChar = numeralize(formatted) > 0 ? '+' : '';
  return startChar + formatted;
};

export const percentNoPercentSign = (val, precision = 0) => {
  const formatString = `0.${Array(precision + 1).join('0')}%`;
  const formattedValue = numberFormatter(val, formatString);
  return !formattedValue || formattedValue === TEXT_NULL_VALUE
    ? formattedValue
    : formattedValue.slice(0, -1);
};

export const percentSigned = (val, precision) => {
  const formatted = percent(val, precision);
  const startChar = numeralize(formatted) > 0 ? '+' : '';

  return startChar + formatted;
};

export const numberSignedColor = (val, precision) => {
  const formatString = `0.${Array(precision + 1).join('0')}`;
  const formatted = numberFormatter(val, formatString);
  const startChar = numeralize(formatted);
  let styleColor = '';

  if (startChar < 0) {
    styleColor = 'quote-detail-number-red';
  } else if (startChar > 0) {
    styleColor = 'quote-detail-number-green';
  } else {
    styleColor = 'quote-detail-number-black';
  }
  return styleColor;
};

export const numberSignedName = (val, precision = 0) => {
  const formatString = `0.${Array(precision + 1).join('0')}`;
  const formatted = numberFormatter(val, formatString);
  const startChar = numeralize(formatted);
  let styleColor = '';

  if (startChar < 0) {
    styleColor = 'negative';
  } else if (startChar > 0) {
    styleColor = 'positive';
  } else {
    styleColor = 'neutral';
  }
  return styleColor;
};

export const numberSignedColorIssuerServices = (val, precision) => {
  const formatString = `0.${Array(precision + 1).join('0')}`;
  const formatted = numberFormatter(val, formatString);
  const startChar = numeralize(formatted);
  let styleColor = '';

  if (startChar < 0) {
    styleColor = 'red__value';
  } else if (startChar > 0) {
    styleColor = 'green__value';
  } else {
    styleColor = '';
  }
  return styleColor;
};

export const price = (val, precision = 2) =>
  isNull(val) ? TEXT_NULL_VALUE : '$' + number(val, precision);

export const priceComma = (val, precision = 2) =>
  isNull(val) ? TEXT_NULL_VALUE : '$' + numberComma(val, precision);

export const priceMagnitude = (val, precision = 0) =>
  isNull(val) ? TEXT_NULL_VALUE : '$' + numberMagnitude(val, precision);

export const numberMagnitudeSigned = (val, precision = 0) => {
  const formatString = `0.${Array(precision + 1).join('0')}a`;
  const formatted = numberFormatter(val, formatString).toUpperCase();
  const startChar = numeralize(formatted) > 0 ? '+' : '';
  return `${startChar}${formatted}`;
};

export const text = val => (isNull(val) ? TEXT_NULL_VALUE : val);

export const textCustomNullValue = (val, customNullValue = TEXT_NULL_VALUE) =>
  isNull(val) ? customNullValue : val;

export const textPluralize = (val, count, pluralCharacters) => {
  if (typeof count !== 'number' || count <= 0 || count > 1) {
    return val + (pluralCharacters || 's');
  }

  return val;
};

export const capitalize = word =>
  word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();

export const showSymbol = (value, symbol) =>
  value !== undefined && value !== '--' ? symbol : '';

export const removeProtocol = webURL => {
  if (webURL) {
    return webURL.replace(/(^\w+:|^)\/\//, '');
  }
  return '--';
};

export const removeHTTP = webURL => {
  if (webURL) {
    return webURL.replace(/(^\w+:|^)\/\/www./, '');
  }
  return '--';
};

export const endOfCurrentMonth = () =>
  moment()
    .subtract({ month: 1 })
    .endOf('month')
    .format('MMM DD, YYYY');

export const lastMonthOfCurrentMonth = (format = 'MMM YYYY') =>
  moment()
    .subtract({ month: 1 })
    .endOf('month')
    .format(format);

export const lastYearOfCurrent = () =>
  moment()
    .subtract({ year: 1 })
    .format('YYYY');

export const substract = (options, format) =>
  moment()
    .subtract(options)
    .format(format);

export const feesYear = (() => {
  const startOfCurrentDate = moment().startOf('year');
  const diffBtDays = moment().diff(startOfCurrentDate, 'days');
  let yearsToSubstract = 1;
  if (diffBtDays === 0) {
    yearsToSubstract = 2;
  }
  return moment()
    .subtract({ years: yearsToSubstract })
    .format('YYYY');
})();

export const getDiff = (firstDate, secondDate, differenceIn) =>
  moment(firstDate).diff(moment(secondDate), differenceIn);

export const isBefore = (
  firstDate,
  secondDate,
  differenceIn = 'days',
  format = null
) =>
  moment(firstDate, format).isBefore(moment(secondDate, format), differenceIn);

export const isSameOrBefore = (
  firstDate,
  secondDate,
  differenceIn = 'days',
  format = null
) =>
  moment(firstDate, format).isSameOrBefore(
    moment(secondDate, format),
    differenceIn
  );

export const isValidDate = (date, format = '') =>
  moment(date, format).isValid();

export const getMonth = {
  name: {
    fromNumber: (monthNumber, format = 'MMMM') =>
      moment()
        .month(monthNumber)
        .format(format),
  },
  number: {
    fromNow: (date = moment()) => moment(date).month(),
  },
};

export const startOf = options => {
  const { date = '', format = '', formatOutput = format, messure } = options;
  return moment(date, format)
    .startOf(messure)
    .format(formatOutput);
};

export const endOf = options => {
  const { date = '', format = '', formatOutput = format, messure } = options;
  return moment(date, format)
    .endOf(messure)
    .format(formatOutput);
};

export const emptyStringIfNull = value => value || '';
