import React from 'react';

import PropTypes from 'prop-types';

const Email = ({ email }) => {
  return email ? (
    <div className="card__email">
      <a
        href={`mailto:${email}`}
        aria-label={`pressing ENTER or SPACEBAR key will send an email to ${email}`}
      >
        <svg
          className="custodian-email-icon"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            className="core-blue-icon"
            d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"
          />
          <path d="M0 0h24v24H0z" fill="none" />
        </svg>
        {email}
      </a>
    </div>
  ) : null;
};

Email.propTypes = {
  email: PropTypes.string.isRequired,
};

const Phone = ({ type, number }) =>
  type && number ? (
    <div className="core__t16">
      {`${type}: `}
      {number}
    </div>
  ) : null;

Phone.propTypes = {
  type: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
};

const Link = ({ link, text }) => {
  return link ? (
    <div className="card__link">
      <a
        href={link}
        aria-label={`${text} (pressing ENTER or SPACEBAR key will open the next link in a new window)`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {text || 'WebSite'}
      </a>
    </div>
  ) : null;
};

Link.propTypes = {
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

const ContactCard = props => {
  const { name, jobTitle, phones, emails, links } = props;

  const phonesRender = phones && phones.map(phone => Phone(phone));
  const emailsRender = emails && emails.map(email => Email(email));
  const linksRender = links && links.map(link => Link(link));
  const jobReader = jobTitle.map(jobTitle => (
    <div className="core__t16">{jobTitle}</div>
  ));

  return (
    <div className="card__container">
      <div className="card__container-contact">
        {name && <div className="core__t15 name">{name}</div>}
        {jobReader}
        {phonesRender}
      </div>
      {emailsRender}
      {linksRender}
    </div>
  );
};

ContactCard.defaultProps = {
  name: null,
  jobTitle: null,
  phones: [{}],
  emails: [{}],
  links: [{}],
};

ContactCard.propTypes = {
  name: PropTypes.string,
  jobTitle: PropTypes.string,
  phones: PropTypes.arrayOf(PropTypes.object),
  emails: PropTypes.arrayOf(PropTypes.object),
  links: PropTypes.arrayOf(PropTypes.object),
};

export default ContactCard;
