import PropTypes from 'prop-types';
import React from 'react';
/* eslint-disable sort-imports-es6-autofix/sort-imports-es6 */
import '../../styles/components/Timeline.scss';
import { Tabs } from '@jpm-adr/pattern-library';
import Icons from './timelineIcons';
/* eslint-enable sort-imports-es6-autofix/sort-imports-es6 */

const Timeline = ({ timeline }) => {
  return (
    <div className="timeline">
      <div className="column column-hidden side-margins" />
      <div className="main-content-wrapper">
        <Tabs tabsId="TimeLine-tabs" customClass="timeline-tabs">
          {timeline.map((decade, index) => {
            return (
              <div
                tabId={`Timeline-tab-${index + 1}`}
                key={decade.title}
                label={decade.title}
              >
                <div className="tabline-content">
                  {decade.content.map(({ items, title }) => {
                    return (
                      <div className="timeline-wrapper" key={title}>
                        <span className="timeline-title">{title}</span>
                        <ul className="timeline-description">
                          {items.map(text => (
                            <li key={text}>
                              {text}
                            </li>
                          ))}
                        </ul>
                      </div>
                    );
                  })}
                </div>
                <div className="timeline-footer">
                  <div className="timeline-footerIconWrapper">
                    {Icons[decade.footer.icon]}
                  </div>
                  <span className="timeline-footerMessage">
                    {decade.footer.copy}
                  </span>
                </div>
              </div>
            );
          })}
        </Tabs>
      </div>
      <div className="column column-hidden side-margins" />
    </div>
  );
};

Timeline.propTypes = {
  timeline: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.arrayOf(PropTypes.shape({})),
      footer: PropTypes.shape({
        copy: PropTypes.string,
        icon: PropTypes.string,
      }),
    })
  ).isRequired,
};

export default Timeline;
