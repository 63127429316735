/* eslint-disable sort-imports-es6-autofix/sort-imports-es6 */
import { ActionableText } from '@jpm-adr/pattern-library';
import React from 'react';
import { components } from 'react-select';
/* eslint-enable sort-imports-es6-autofix/sort-imports-es6 */

const NoOptionsMessage = props => {
  return (
    components.NoOptionsMessage && (
      <components.NoOptionsMessage {...props}>
        <div className="Select__no-options-container">
          <p className="Select__no-results-text">No Results</p>

          <ActionableText
            onClick={() => {
              props.selectProps.clearInputValue();
            }}
          >
            <p>Clear Entry</p>
          </ActionableText>
        </div>
      </components.NoOptionsMessage>
    )
  );
};

export default NoOptionsMessage;
