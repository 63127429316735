import PropTypes from 'prop-types';
import React from 'react';

import F6Data from '../../components/f6/F6Data';
import QuoteBar from '../../components/quoteBar/QuoteBar';
import RelatedSecurities from '../../components/relatedSecurities/RelatedSecurities';
import Reports from '../../components/reports/Reports';
import SecurityManager from '../../components/relationshipManager/RelationshipManager';
import SummaryTables from './SummaryTables';

import '../../../styles/IssuerServices/pages/Summary.scss';

const Summary = props => {
  const {xid} =props;
  return (
    <div className="summary summary__content">
      <div className="summary__left">
        <QuoteBar
          {...props}
          customStyles={{
            maxHeight: '121px',
          }}
        />
        <RelatedSecurities {...props} immediateRequest={xid} />
        <SummaryTables {...props} />
      </div>

      <div className="summary__right">
        <Reports {...props} />
        <SecurityManager {...props} immediateRequest={xid} />
        <F6Data {...props} immediateRequest={xid} />
      </div>
    </div>
  );
}

Summary.propTypes = {
  xid: PropTypes.number.isRequired,
  cusip: PropTypes.number.isRequired,
};

export default Summary;
