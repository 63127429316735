import PropTypes from 'prop-types';
import React from 'react';
import { StatusBook } from '@jpm-adr/pattern-library';

import '../../../styles/IssuerServices/components/ProfileHeader.scss';
import ApiStates from '../../../utils/apiStatesWrapper';

const containerClass = 'issuer-services__subheader';

const ProfileHeader = props => {
  const { headerInfo } = props;
  const {
    name = '',
    symbol = '',
    bookStatus,
    isOpen,
    openCloseDate,
    daysUntilClose,
    daysUntilOpen,
  } = headerInfo;
  return (
    <div className={containerClass}>
      <div className="issuer-services__subheader-content">
        <h1>{name}</h1>
        <div className="issuer-services__details">
          <div>Ticker: </div>
          <div className="issuer-services__details-value">{symbol}</div>
        </div>
        {bookStatus && (
          <StatusBook
            isOpen={isOpen}
            openDate={openCloseDate}
            titleOpen="Books Open"
            titleClosed="Books Closed"
            daysUntilClose={daysUntilClose}
            daysUntilOpen={daysUntilOpen}
          />
        )}
      </div>
    </div>
  );
};

ProfileHeader.defaultProps = {
  headerInfo: {
    name: '',
    symbol: '',
    bookStatus: '',
    isOpen: '',
    openCloseDate: '',
    daysUntilClose: '',
    daysUntilOpen: '',
  },
};

ProfileHeader.propTypes = {
  headerInfo: PropTypes.shape({
    name: PropTypes.string,
    symbol: PropTypes.string,
    bookStatus: PropTypes.string,
    isOpen: PropTypes.string,
    openCloseDate: PropTypes.string,
    daysUntilClose: PropTypes.string,
    daysUntilOpen: PropTypes.string,
  }),
};

const config = {
  WrappedComponent: ProfileHeader,
  custom: { containerClass },
};

export default ApiStates(config);
