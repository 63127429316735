import React from 'react';
import { NoDataAvailable } from '@jpm-adr/pattern-library';

import utils from '../issuerservices/utils/utils';

export const withApiRequest = (WrappedComponent, requestApi) => {
  return class ApiRequest extends React.Component {
    constructor () {
      super();
      this.state = {
        data: null,
        requestError: null
      };
    }

    componentDidMount () {
        requestApi && this.callRequest();
    }

    shouldComponentUpdate (nextProps, nextState) {
      const hasStateChanged = !utils.shallowEqualObjects(nextState, this.state);
      const hasPropsChanged = !utils.shallowEqualObjects(nextProps, this.props);
      return hasPropsChanged || hasStateChanged;
    }

    componentDidUpdate (prevProps) {
      if (!utils.shallowEqualObjects(prevProps, this.props)) {
        requestApi && this.callRequest();
      }
    }

    callRequest () {
      const callback = ()  => {
          const promise = requestApi(this.props);
          if (promise) {
              requestApi(this.props)
              .then(data => {
                  this.setState({
                  data: (data && data.items) || data || [],
                  quoteDataIsEmpty: data && data.quoteDataIsEmpty
                  });
              })
              .catch(requestError => {
                  this.setState({
                  requestError
                  });
              });
          }
      }

      this.setState(
        {
          data: null,
          quoteDataIsEmpty: null,
          requestError: null
        },
        callback
      );
    }

    render () {
      const {
        data,
        quoteDataIsEmpty,
        requestError
      } = this.state;

      if (requestError) {
        return (
          <NoDataAvailable
            customMessageTitleClass="Data__Not__Found-Title"
            customWrapperCSS="No__Data__Available-Custom-Wrapper"
            message="Either something went wrong or the data doesn’t exist."
            messageStyles="Data__Not__Found-Message"
            messageTitle="Data Not Found error"
            wrapperClass="Data__Not__Found"
          />
        );
      }
      return (
        <WrappedComponent
          data={data}
          isLoading={data === null}
          quoteDataIsEmpty={quoteDataIsEmpty}
          {...this.props}
        />
      );
    }
  };
};

export default withApiRequest;
