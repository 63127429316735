import PropTypes from 'prop-types';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AccessTokenService from './utils/access-token-service';
import ApiAuth from './utils/apiAuth';
import NoCusip from './components/nocusip/NoCusip';
import NoCusipError from './pages/NoCusipError';
import Page404 from './pages/Page404';
import ScrollToTop from '../ScrollToTop';
import Section from './pages/SectionDisplay';
import { isLocalHost } from '../utils/urlMethods';
import { prefix, subSections } from './utils/constants';

const iframeStylesNeeded = 'html { overflow: hidden; }';
const navigationPaths = Object.keys(subSections);

class AppAuthenticated extends React.Component {
  constructor () {
    super();
    this.redirectToLoginPage = this.redirectToLoginPage.bind(this);
  }

  componentDidMount () {
    ApiAuth.setLogOffCallBack(this.redirectToLoginPage);
  }

  componentDidUpdate () {
    const pathname = window &&
      window.location &&
      window.location.pathname;
    
    if (!pathname) return
    
    this.sendGoogleAnalyticsData(pathname);
  }

  redirectToLoginPage () {
    return ApiAuth.getSSODomain().then(
      SAMLSSODomain =>
        (location.href = `${SAMLSSODomain}${encodeURIComponent(location.href)}`)
    );
  }

  sendGoogleAnalyticsData (pathname) {
    // We can send custom data through this object
    let gtmData = {
      event: 'pageview',
      page: {
        url: pathname
      }
    };

    window.dataLayer.push(gtmData);
  }

  render () {
    // no token for local development, so set to true if localhost
    const hasAccessToken = AccessTokenService.retrieve() !== null || isLocalHost;

    if (hasAccessToken) {
      return (
        <ScrollToTop>
          <div>
            <style>{iframeStylesNeeded}</style>
            <Switch>
              <Route
                exact
                path={`${prefix}/:section(${navigationPaths.join('|')})?`}
                component={NoCusip}
              />
              <Route
                exact
                path={`${prefix}/:section(${navigationPaths.join(
                  '|'
                )})/:cusip([0-9a-zA-Z]{1,9})`}
                component={Section}
              />
              <Route
                exact
                path={`${prefix}/noCusipError`}
                component={NoCusipError}
              />
              <Route component={Page404} />
            </Switch>
          </div>
        </ScrollToTop>
      )
    }
    // redirect to login
    return this.redirectToLoginPage();
  }
}

AppAuthenticated.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
};
export default AppAuthenticated;
