import React, { Component } from 'react';
import propTypes from 'prop-types';

import {
  FileButtons,
  LoadMoreButton,
  LoadingText,
  NoDataAvailable,
} from '@jpm-adr/pattern-library';
import { dateFullShortMonthNoTime } from '../../utils/format';
import { getOtherCorporateActions } from '../../utils/api';
import { mapObjectHasFiles } from '../../utils/adapterHelperFunctions';
import { withErrorBoundary } from '../../utils/errorBoundary';

import '../../styles/components/OtherCorporateActionsFullHistory.scss';

let workIdGroups = {};

class OtherCorporateActionsFullHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loadMore: true,
      isLoading: true,
    };
    this.getData = this.getData.bind(this);
    this.getData({ currentPosition: 0, pageSize: 25 });
    this.LoadingWithState = (
      <LoadMoreButton
        className="dr-loading-button"
        fetchFunction={this.getData}
        position={0}
        pageSize={25}
      />
    );
  }
  getData({ currentPosition, pageSize }) {
    const { cusip } = this.props;
    const { data } = this.state;
    return getOtherCorporateActions(cusip, currentPosition, pageSize)
      .then(response => {
        const { items = [] } = response;
        this.setState({
          data: data.concat(items),
          loadMore: response.pagination.hasMore,
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          isLoading: false,
        });
      });
  }

  render() {
    const { data, isLoading, loadMore } = this.state;

    if (!isLoading && data.length === 0) {
      return (
        <section className="Fees__Table">
          <NoDataAvailable
            messageTitle="No data available"
            customMessageTitleClass="Data__Not__Found-Title"
            customWrapperCSS="No__Data__Available-Custom-Wrapper"
            message="There is no data available for this specific program."
            messageStyles="Data__Not__Found-Message"
            wrapperClass="Data__Not__Found"
          />
        </section>
      );
    }

    if (isLoading)
      return (
        <LoadingText withGreyBackground customStyles={{ height: '440px' }} />
      );

    workIdGroups = {};
    const items = data
      .map(current => {
        const { sortOrder, workId } = current;
        const isLastValue = workIdGroups[workId] ? true : false;
        workIdGroups[workId] = {
          isLastValue,
          sortOrder,
        };
        return current;
      })
      .map(item => {
        const createKey = 'oaf';
        const isLastWorkIdMember =
          !item.workId ||
          (workIdGroups[item.workId] &&
            item.sortOrder === workIdGroups[item.workId].sortOrder);
        return (
          <tr
            key={createKey}
            className={`otherCorporateActions
            ${isLastWorkIdMember ? 'isLastWorkIdMember' : ''}`}
          >
            <td>{dateFullShortMonthNoTime(item.eventDate)}</td>
            <td className="corpotate-action-full-type-margin text-cell action">
              {item.action || '--'}
            </td>
            <td className="corpotate-action-full-type-margin text-cell">
              {dateFullShortMonthNoTime(item.announcementDate) || '--'}
            </td>
            <td className="corpotate-action-full-type-margin text-cell status">
              {item.status || '--'}
            </td>
            <td className="corpotate-action-full-type-margin text-cell">
              <FileButtons buttonsInfo={mapObjectHasFiles(item, 'notices')} />
            </td>
          </tr>
        );
      });

    return (
      <div className="table-wrapper-dividends">
        <table
          id="corpotate-actions-full-table"
          className="corpotate-actions-full-table table-component table is-striped is-narrow is-fullwidth"
        >
          <thead>
            <tr>
              <th className="margin-table-corpotate-actions-full text-cell">
                Event Date
              </th>
              <th className="margin-table-corpotate-actions-full text-cell">
                Action
              </th>
              <th className="margin-table-corpotate-actions-full text-cell no-whitespace-wrap">
                Announcement Date
              </th>
              <th className="margin-table-corpotate-actions-full text-cell">
                Status
              </th>
              <th className="margin-table-corpotate-actions-full text-cell">
                Notices
              </th>
            </tr>
          </thead>
          <tbody>{items}</tbody>
        </table>
        {isLoading || !loadMore ? null : this.LoadingWithState}
      </div>
    );
  }
}

OtherCorporateActionsFullHistory.propTypes = {
  cusip: propTypes.string.isRequired,
};
export default withErrorBoundary(OtherCorporateActionsFullHistory);
