import PropTypes from 'prop-types';
import React from 'react';

import { Button } from '@jpm-adr/pattern-library';

import ModalExport from '../modalExport/ModalExport';

import { deleteAlertsDestination } from '../../utils/apiCalls';

import '../../../styles/IssuerServices/components/DeleteAlerts.scss';

const defaultState = {
  showApiError: false,
  openModal: false,
  foundError: true,
};

class DeleteAlerts extends React.Component {
  constructor(props) {
    super(props);
    this.getDefaultState = this.getDefaultState.bind(this);
    this.state = this.getDefaultState();
    this.getFooter = this.getFooter.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.getModal = this.getModal.bind(this);
  }

  getDefaultState() {
    return {
      ...defaultState,
    };
  }

  getFooter() {
    const { destinationId } = this.props;
    return () => (
      <div className="actionButtonContainer">
        <Button
          aria-label="Pressing enter or spacebar key to cancel."
          onClick={() => this.closeModal()}
          customClass="btn cancelButton"
        >
          <p>Cancel</p>
        </Button>
        <Button
          aria-label="Pressing enter or spacebar key to delete it."
          customClass="btn delteButton"
          onClick={() =>
            deleteAlertsDestination(destinationId).then(() => {
              this.setState({ openModal: false }, this.closeModal());
            })
          }
        >
          <p>DELETE RECIPIENT</p>
        </Button>
      </div>
    );
  }

  getModal() {
    const { data = {} } = this.props;
    return (
      <ModalExport
        title={data.title || 'Alerts'}
        customClassName="deleteAlerts"
        footer={this.getFooter()}
        handleCloseModal={this.closeModal}
      >
        <div>Are you sure you want to delete this recipient?</div>
        <div>This action cannot be undone</div>
      </ModalExport>
    );
  }

  openModal(e) {
    if (e) {
      if (e.type === 'keydown' && e.key !== 'Enter') return null;
      e.preventDefault();
    }
    return this.setState({ openModal: true });
  }

  closeModal() {
    const draftState = {
      openModal: false,
      ...this.getDefaultState(),
    };
    const { onSave } = this.props;
    if (onSave) onSave();
    this.setState(draftState);
  }

  render() {
    const { openModal } = this.state;
    const { actionItem } = this.props;
    return (
      <React.Fragment>
        {openModal && this.getModal()}
        {actionItem ? (
          actionItem({ onClick: this.openModal })
        ) : (
          <Button
            onClick={() => this.openModal()}
            customClass="btn saveButton openModalButton"
          >
            <p>Delete</p>
          </Button>
        )}
      </React.Fragment>
    );
  }
}

DeleteAlerts.propTypes = {
  destinationId: PropTypes.string.isRequired,
  data: PropTypes.shape.isRequired,
  actionItem: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default DeleteAlerts;
