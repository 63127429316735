import '../../styles/components/DividendsFinalAnnouncements.scss';
import React from 'react';
import propTypes from 'prop-types';
import {
  LoadMoreButton,
  NoDataAvailable,
  Table,
} from '@jpm-adr/pattern-library';
import { dateFullShortMonthNoTime, number } from '../../utils/format';
import { URLbyDomain, DECIMAL_DIGITS } from '../../utils/constants'; //eslint-disable-line sort-imports-es6-autofix/sort-imports-es6

let workIdGroups = {};

const stampGroups = obj => {
  obj.isLastWorkIdMember = !obj.workId ||
    workIdGroups[obj.workId] &&
    obj.sortOrder === workIdGroups[obj.workId].sortOrder;
  obj.customCSSClass =
    `${obj.isLastWorkIdMember ? `${obj.customCSSClass} isLastWorkIdMember` : obj.customCSSClass}`;
  return obj;
};
const underlying = underlyingData => ({
  title: '',
  hasUnderlying: true,
  data: '^',
  customCSSClass: 'underlying-arrow-data',
  underlying: {
    recordDate: underlyingData.recordDate,
    paymentDate: underlyingData.paymentDate,
    currency: underlyingData.currency,
    currencyPerDr: number(underlyingData.currencyPerDr,DECIMAL_DIGITS),
    exchangeDate: underlyingData.exchangeDate,
    finalExchangeRate: number(underlyingData.finalExchangeRate,DECIMAL_DIGITS),
  },
});
const notices = (noticesData, sequenceNo) => {
  const url = URLbyDomain + '/cms/document?cmsId=';
  const pdf = noticesData.pdf || '';
  const xbrl = noticesData.xbrl || '';
  const sequenceNoParam = sequenceNo ? `&sequenceNo=${sequenceNo}` : '';
  return {
    hasComponents: true,
    hasNotices: true,
    pdf,
    xbrl,
    pdfLink: `${url}${pdf}${sequenceNoParam}`,
    xbrlLink: `${url}${xbrl}${sequenceNoParam}`,
  };
};
const datesNotices = datesData => ({
  data: dateFullShortMonthNoTime(datesData),
});

const emptyField = obj => ({
  data: String(obj),
});

const undefinedField = () => ({
  data: '--',
});

const objectTransormtionsMappedFunctions = new Map();
objectTransormtionsMappedFunctions.set(undefined, () => '--');
objectTransormtionsMappedFunctions.set(null, () => '--');
objectTransormtionsMappedFunctions.set('underlying', underlying);
objectTransormtionsMappedFunctions.set('notices', notices);
objectTransormtionsMappedFunctions.set('announcementDate', datesNotices);
objectTransormtionsMappedFunctions.set('recordDate', datesNotices);
objectTransormtionsMappedFunctions.set('paymentDate', datesNotices);
objectTransormtionsMappedFunctions.set('paymentDate', datesNotices);


const objectTransormation = obj => field => {
  const sequenceNo = obj.sequenceNo;
  if (obj[field] === undefined) return undefinedField();
  let newObject = {};
  newObject = objectTransormtionsMappedFunctions.has(field)
    ? objectTransormtionsMappedFunctions.get(field)(obj[field], sequenceNo)
    : emptyField(obj[field]);
  newObject = stampGroups(newObject);

  return newObject;
};

class DividendsFinalAnnouncements extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loadMore: true,
      isLoading: true,
    };

    this.getDividensData = this.getDividensData.bind(this);
    this.LoadingWithState = (
      <LoadMoreButton
        className="dr-loading-button"
        fetchFunction={this.getDividensData}
        position={0}
        pageSize={25}
      />
    );
  }
  componentWillMount() {
    this.getDividensData({ currentPosition: 0, pageSize: 25 });
  }
  componentWillReceiveProps(nextprops) {
    const { cusip } = this.props;
    const { cusip: newCusip } = nextprops;
    if (newCusip !== cusip)
      this.getDividensData({ currentPosition: 0, pageSize: 25, newCusip });
  }

  getDividensData({ currentPosition, pageSize }) {
    const { cusip, endpoint } = this.props;
    return endpoint(cusip, currentPosition, pageSize)
      .then(response => {
        const { items = [] } = response;
        const parsedItems = items.map(item => ({
          ...item,
          ratePerDr: number(item.ratePerDr,DECIMAL_DIGITS),
          withHoldingAmount: number(item.withHoldingAmount,DECIMAL_DIGITS),
          taxReclaimFee: number(item.taxReclaimFee,DECIMAL_DIGITS),
          dividendFee: number(item.dividendFee,DECIMAL_DIGITS),
          finalDivRatePerDr: number(item.finalDivRatePerDr,DECIMAL_DIGITS)
        }));
        const { data } = this.state;
        const composedData =
          currentPosition === 0 ? parsedItems : [...data, ...parsedItems];
        this.setState({
          data: composedData,
          loadMore: response.pagination.hasMore,
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          isLoading: false,
        });
      });
  }

  render() {
    const { customClass } = this.props;
    const { isLoading, data, loadMore } = this.state;
    function adapter(apiData = []) {
      workIdGroups = {};
      return apiData
        .map(current => {
          const { sortOrder, workId } = current;
          const isLastValue = workIdGroups[workId] ? true : false;
          workIdGroups[workId] = {
            isLastValue,
            sortOrder
          };
          return current;
        })
        .map(obj => {
          const fieldTransformation = objectTransormation(obj);
          return [
            fieldTransformation('underlying'),
            fieldTransformation('announcementDate'),
            fieldTransformation('recordDate'),
            fieldTransformation('paymentDate'),
            fieldTransformation('ratePerDr'),
            fieldTransformation('withHoldingAmount'),
            fieldTransformation('taxReclaimFee'),
            fieldTransformation('dividendFee'),
            fieldTransformation('finalDivRatePerDr'),
            fieldTransformation('status'),
            fieldTransformation('notices'),
          ];
        });
    }

    const dividendFullHistoryTitles = [
      {
        id: 0,
        title: ' ',
        customCSSClass: 'title-underlying-arrow',
      },
      {
        id: 1,
        title: 'Announcement Date',
        customCSSClass: '',
      },
      {
        id: 2,
        title: 'DR Record Date',
        customCSSClass: '',
      },
      {
        id: 3,
        title: 'DR Payment Date',
        customCSSClass: '',
      },

      {
        id: 4,
        title: 'Rate Per DR (USD)',
        customCSSClass: '',
      },
      {
        id: 5,
        title: 'Withholding Amount (USD)',
        customCSSClass: '',
      },
      {
        id: 6,
        title: 'Tax Reclaim Fee (USD)',
        customCSSClass: '',
      },
      {
        id: 7,
        title: 'Dividend Fee (USD)',
        customCSSClass: '',
      },
      {
        id: 8,
        title: 'Div Rate Per DR (USD)',
        customCSSClass: '',
      },
      {
        id: 9,
        title: 'Status',
        customCSSClass: '',
      },
      {
        id: 10,
        title: 'Notices',
        customCSSClass: '',
      },
    ];
    if (
      !isLoading &&
      (data === null || (data && Object.keys(data).length === 0))
    ) {
      return (
        <section className={customClass + ' table-no-data-available'}>
          <NoDataAvailable
            messageTitle="No data available"
            customMessageTitleClass="Data__Not__Found-Title"
            customWrapperCSS="No__Data__Available-Custom-Wrapper"
            message="There is no data available for this specific program."
            messageStyles="Data__Not__Found-Message"
            wrapperClass="Data__Not__Found"
          />
        </section>
      );
    }

    const dividendFullHistoryData = adapter(data ? data : []);
    return (
      <React.Fragment>
        <Table
          isLoading={isLoading}
          customStyles={{ height: '440px' }}
          values={dividendFullHistoryData || []}
          tableId="dividends-table-final-announcements"
          tableCustomCss="dividends-table-final-announcements table-component table is-striped is-narrow is-fullwidth"
          tableTitles={dividendFullHistoryTitles}
          tableWrapperClass="table-wrapper-dividends"
        />
        {isLoading || !loadMore ? null : this.LoadingWithState}
      </React.Fragment>
    );
  }
}

DividendsFinalAnnouncements.defaultProps = {
  customClass: 'columns margin-mobile',
  cusip: ''
};

DividendsFinalAnnouncements.propTypes = {
  cusip: propTypes.string,
  customClass: propTypes.string,
  endpoint: propTypes.func.isRequired
};

export default DividendsFinalAnnouncements;
