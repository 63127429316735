import React from 'react';
import { LoadingText, NoDataAvailable } from '@jpm-adr/pattern-library';

export const apiStatesWrapper = ({
  WrappedComponent,
  requestApi,
  custom = {},
  statesToDispatch,
}) => {
  return class ApiRequest extends React.Component {
    // eslint-disable-line react/no-multi-comp
    constructor(props) {
      super(props);
      this.state = {
        isLoading: true,
        error: false,
        data: {},
      };
      props.immediateRequest && requestApi && this.callRequest();
      this.dataToAppendParser = this.dataToAppendParser.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState) {
      const hasStateChanged = !this.shallowEqualObjects(nextState, this.state);
      const hasPropsChanged = !this.shallowEqualObjects(nextProps, this.props);
      return hasPropsChanged || hasStateChanged;
    }

    componentDidUpdate(prevProps = {}) {
      const { props } = this;
      if (!this.shallowEqualObjects(prevProps, props)) {
        this.callRequest();
      }
    }

    shallowEqualObjects(objA, objB) {
      if (objA === objB) {
        return true;
      }
      const aKeys = Object.keys(objA);
      const len = aKeys.length;
      for (let i = 0; i < len; i++) {
        const key = aKeys[i];
        if (objA[key] !== objB[key]) {
          return false;
        }
      }
      return true;
    }

    callRequest() {
      if (!requestApi) return;
      const promise = requestApi(this.props);
      if (promise) {
        this.setState({ isLoading: true });
        promise
          .then(data => {
            const dataToAppent = this.dataToAppendParser(data);
            this.setState({
              data: dataToAppent,
              isLoading: false,
              error: false,
            });
          })
          .catch(error => {
            this.setState({ error, isLoading: false });
          });
      }
    }

    dataToAppendParser(data) {
      let dataToAppend = data;
      const hasPagination = data.pagination && data.pagination.offset > 0;
      if (hasPagination) {
        const { data: currentData } = this.state;
        dataToAppend.items = [...currentData.items, ...dataToAppend.items];
      }
      return dataToAppend;
    }

    wrapState(element) {
      const { containerClass, containerTitle } = custom;
      return (
        <div className={`${containerClass} state-api`}>
          {containerTitle && <span className="title">{containerTitle}</span>}
          <div className="state-api-body">{element}</div>
        </div>
      );
    }

    showError() {
      return this.wrapState(
        <NoDataAvailable
          messageTitle="Data not found"
          customMessageTitleClass="Data__Not__Found-Title"
          customWrapperCSS="No__Data__Available-Custom-Wrapper"
          message="Either something went wrong or the data doesn't exist."
          messageStyles="Data__Not__Found-Message"
          wrapperClass="Data__Not__Found"
        />
      );
    }

    showNoData() {
      return this.wrapState(
        <NoDataAvailable
          messageTitle="No data available"
          customMessageTitleClass="Data__Not__Found-Title"
          customWrapperCSS="No__Data__Available-Custom-Wrapper"
          message="There is no data available for this specific program."
          messageStyles="Data__Not__Found-Message"
          wrapperClass="Data__Not__Found"
        />
      );
    }

    showLoading() {
      return this.wrapState(
        <LoadingText aria-live="polite" aria-busy="true" />
      );
    }

    render() {
      const { state, props } = this;
      let { data = {}, isLoading, error } = state;
      const {
        error: forceError,
        loading,
        disabledLoading,
        disabledErrorBoundary,
        disabledEmptyHandler,
      } = props;
      const { items } = data;
      const isEmpty =
        !data ||
        (items && items.length < 1) ||
        (Object.keys(data).length === 0 && data.constructor === Object);

      const { updateSharedState } = this.props;
      updateSharedState &&
        updateSharedState({
          error,
          isEmpty,
          pagination: data.pagination || {},
        });

      if (!disabledErrorBoundary && (error || forceError)) {
        return this.showError();
      }

      if (!disabledLoading && ((requestApi && isLoading) || loading)) {
        return this.showLoading();
      }

      if (!disabledEmptyHandler && (requestApi && isEmpty)) {
        return this.showNoData();
      }

      let dataToDispatch = { ...{}, ...props };
      if (statesToDispatch) {
        // Set requires states to Dispatch
        statesToDispatch.map(attr => (dataToDispatch[attr] = state[attr]));
      }
      return <WrappedComponent data={data} {...dataToDispatch} />;
    }
  };
};

export default apiStatesWrapper;
