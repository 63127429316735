import PropTypes from 'prop-types';
import React from 'react';

import { TableList } from '@jpm-adr/pattern-library';

import ApiStates from '../../../utils/apiStatesWrapper';

import { tableLoaderParser } from './formater';

import '../../../styles/IssuerServices/components/MonthlyReportsList.scss';

const TableLoader = props => {
  const { configTable, data, error, isLoading, updateSharedState } = props;
  const { items = [], pagination = {} } = data || {};
  const config = configTable;
  const tableData = tableLoaderParser(items, config);
  // Use sharedState
  updateSharedState && updateSharedState({ error, isLoading, pagination });

  return (
    <TableList tableData={tableData} customClassName="monthly-reports-list" />
  );
};

TableLoader.defaultProps = {
  data: {},
  updateSharedState: null,
  error: null,
  isLoading: null,
};

TableLoader.propTypes = {
  data: PropTypes.objectOf({}),
  configTable: PropTypes.objectOf({}).isRequired,
  updateSharedState: PropTypes.func,
  error: PropTypes.bool,
  isLoading: PropTypes.bool,
};

const config = {
  WrappedComponent: TableLoader,
  requestApi: ({ requestApi, requestApiConfig }) => {
    if (!requestApi) return null;
    return requestApiConfig ? requestApi(requestApiConfig) : requestApi;
  },
  statesToDispatch: ['error', 'isLoading', 'pagination'],
};

export default ApiStates(config);
