/* eslint-disable sort-imports-es6-autofix/sort-imports-es6 */
import { ActionableText } from '@jpm-adr/pattern-library';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import LibrarySelect, { components } from 'react-select';
import MultiselectDropdownIndicator from './SelectComponents/MultiselectDropdownIndicator';
import NoOptionsMessage from './SelectComponents/NoOptionsMessage';
import Placeholder from './SelectComponents/Placeholder';
import { getFilterData } from '../../../utils/api';
import {
  labelBooksDropdown,
  labelSponsorshipDropdown,
  labelListingsDropdown,
} from '../../../utils/constants';
/* eslint-enable sort-imports-es6-autofix/sort-imports-es6 */

const clearLabelValid = (value, showClearLabel) =>
  showClearLabel && Array.isArray(value) && value.length > 0;

class Select extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: '',
      remoteOptions: undefined,
    };

    this.clearInputValue = this.clearInputValue.bind(this);
    this.handleInputValue = this.handleInputValue.bind(this);
    this.clearAllValues = this.clearAllValues.bind(this);
    this.onChangeFilter = this.onChangeFilter.bind(this);
    this.updateAriaLabel = this.updateAriaLabel.bind(this);
    this.librarySelectRef = React.createRef();
  }

  componentDidMount() {
    const { type, page, label } = this.props;
    let { remoteOptions } = this.state;
    const { librarySelectRef } = this;
    if (
      librarySelectRef &&
      librarySelectRef.current &&
      librarySelectRef.current.select &&
      librarySelectRef.current.select.inputRef
    ) {
      this.librarySelectRef.current.select.inputRef.setAttribute(
        'aria-hidden',
        true
      );
      this.updateAriaLabel(
        this.librarySelectRef.current.select.inputRef,
        label
      );
    }

    if (typeof type !== 'undefined') {
      const remoteDataPromise = getFilterData(type);

      if (page === 'corporateActions') {
        remoteDataPromise.then(data => {
          remoteOptions = data.items.map(item => {
            return {
              label: item.label,
              value: item.label,
              'aria-label': item.label,
            };
          });

          this.setState({
            remoteOptions: remoteOptions,
          });
        });
      } else {
        remoteDataPromise.then(data => {
          if (type === 'depositaries') {
            const notOthers = data.items.filter(item => item.label !== 'Other');
            const withOthers = data.items
              .filter(item => item.label === 'Other')
              .map(item => item.value)
              .join('|');
            data.items = [...notOthers, { value: withOthers, label: 'Other' }];
          }
          remoteOptions = data.items.map(item => {
            return {
              label: item.label,
              value: item.value,
              'aria-label': item.label,
            };
          });
          switch (type) {
            case 'bookStatuses':
              remoteOptions = [
                {
                  label: labelBooksDropdown.AllStatuses,
                  value: data.items.length + 1,
                  'aria-label': labelBooksDropdown.AllStatuses,
                },
                ...remoteOptions,
              ];
              remoteOptions = remoteOptions.map((item = {}) => {
                item.label = labelBooksDropdown[item.label] || item.label;
                item['aria-label'] = item.label;
                return item;
              });
              break;
            case 'sponsorship':
              remoteOptions = [
                {
                  label: labelSponsorshipDropdown.All,
                  value: data.items.length + 1,
                  'aria-label': labelSponsorshipDropdown.All,
                },
                ...remoteOptions,
              ];
              break;
            case 'listings':
              remoteOptions = [
                {
                  label: labelListingsDropdown.All,
                  value: data.items.length + 1,
                  'aria-label': labelListingsDropdown.All,
                },
                ...remoteOptions,
              ];
              break;
            default:
              break;
          }

          this.setState({
            remoteOptions: remoteOptions,
          });
        });
      }
    }
  }

  onChangeFilter(value) {
    const { onChange } = this.props;

    onChange(null, value);
  }

  updateAriaLabel(element, label) {
    element.setAttribute('aria-label', label || element['aria-label']);
  }

  clearInputValue() {
    this.setState({
      inputValue: '',
    });
  }

  handleInputValue(inputValue) {
    this.setState({
      inputValue,
    });
  }

  clearAllValues(e) {
    if (e.keyCode) {
      if (e.keyCode !== 13) {
        return;
      }
      e.stopPropagation();
    }

    const { clearValue } = this.props;
    clearValue();
    this.clearInputValue();
    this.librarySelectRef.current.focus();
  }

  render() {
    const {
      isClearable,
      isMulti,
      showClearLabel,
      value,
      options,
      label,
      name,
      ...props
    } = this.props;
    const { inputValue, remoteOptions } = this.state;

    let selectTopClassname = 'Select';

    // We add a "is-multi" modifier if the isMulti prop is true
    if (isMulti) selectTopClassname += ' Select--is-multi';

    return (
      <div className={selectTopClassname}>
        {clearLabelValid(value, showClearLabel) && (
          <div className="Select__clear-label">
            <ActionableText
              ariaLabel={
                `Pressing ENTER or SPACEBAR key, the filter of ${name}
                 will reset to default and the results will be reloaded.`
              }
              onClick={this.clearAllValues}
            >
              Clear
            </ActionableText>
          </div>
        )}

        <LibrarySelect
          ref={this.librarySelectRef}
          aria-label={label + ' filter selection'}
          classNamePrefix="Select"
          isClearable={isClearable}
          isMulti={isMulti}
          inputValue={inputValue}
          onInputChange={this.handleInputValue}
          clearInputValue={this.clearInputValue}
          components={{
            NoOptionsMessage,
            Placeholder,
            DropdownIndicator: isMulti
              ? MultiselectDropdownIndicator
              : components.DropdownIndicator,
          }}
          value={value}
          options={remoteOptions || options}
          {...props}
          onChange={this.onChangeFilter}
        />
      </div>
    );
  }
}

const valueShape = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.string,
});

Select.propTypes = {
  isClearable: PropTypes.bool,
  isMulti: PropTypes.bool,
  showClearLabel: PropTypes.bool,
  type: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  options: PropTypes.shape.isRequired,
  // We disable this so that we can still use the component uncontrolled if needed,
  // however, if you include the showClearLabel prop, and don't include a clearValue prop
  // the component will crash once you click the label.
  /* eslint-disable react/require-default-props */
  clearValue: PropTypes.func,
  onChange: PropTypes.func,
  page: PropTypes.string,
  value: PropTypes.oneOfType([valueShape, PropTypes.arrayOf(valueShape)]),
  /* eslint-enable react/require-default-props */
};

Select.defaultProps = {
  isClearable: false,
  isMulti: false,
  name: '',
  showClearLabel: false,
  type: undefined,
  page: undefined,
};

export default Select;
