import React from 'react';
import propTypes from 'prop-types';


const Address = props => {

    const { data: { line1, line2, line3, country } } = props;

    return (
      <address className="custodian-address">
        <div>{line1}</div>
        <div>{line2}</div>
        <div>{line3}</div>
        <div>{country}</div>
      </address>
    );
}

Address.defaultProps = {
    data: {
        line1: "--",
        line2: "--",
        line3: "--",
        country: "--"
    }
}

Address.propTypes = {
    data: propTypes.shape({
        line1: propTypes.string,
        line2: propTypes.string,
        line3: propTypes.string,
        country: propTypes.string,
    })
}


export default Address;
