import '../../styles/components/Headroom.scss';
import React from 'react';

import HeadingLayout from '../../components/common/HeadingLayout';
import HeadroomFile from '../../components/headroomFile/HeadroomFile';

const Headroom = () => (
  <HeadingLayout title={<h1 className="headroom-heading-title">Headroom</h1>}>
    <div className="headroom">
      <div className="headroom-text">
        <div className="headroom-content">
          <p className="core__t13">
            In certain markets the availability of DRs permitted to be
            outstanding may be limited due to foreign ownership limits, local
            legislation, regulatory actions or limits set by an issuer. The
            maximum number of DRs permitted to be issued is referred to as
            headroom. Described below are examples of markets where headroom
            limits may apply. For availability of DRs in these markets see the
            document to the right under Headroom Availability. For questions
            about headroom availability in other markets contact us at the
            number on the right side of the page.
          </p>
        </div>
        <div className="headroom-content">
          <div className="headroom-title core__t5">INDIA</div>
          <p className="core__t15">
            In India, DR headroom is calculated as the difference between the
            total number of DRs originally issued less the number of DRs
            currently outstanding, adjusted for DRs redeemed into underlying
            shares and registered in the name of non-resident investors. The
            local custodian monitors and reports the extent up to which DR
            reissuance would be permissible.
          </p>
        </div>
        <div className="headroom-content">
          <div className="headroom-title core__t5">SOUTH KOREA</div>
          <p className="core__t15">
            In South Korea, headroom is the total number of DRs issued at the
            time of an initial offering plus any subsequent offering, stock
            dividend, rights issue, or any other corporate action that resulted
            in a distribution of DRs, less the number of DRs outstanding and
            less the number of shares formerly represented by DRs that were
            cancelled but have not been sold in the local market.
          </p>
        </div>
        <div className="headroom-content">
          <div className="headroom-title core__t5">TAIWAN</div>
          <p className="core__t15">
            In Taiwan, the number of DRs permitted to be outstanding is referred
            to as the Maximum Allowable Limit or MAL. The MAL is the total
            number of DRs issued at the time of an initial offering, plus any
            subsequent offering, stock dividend, rights issue, or any other
            corporate action that resulted in a distribution of DRs. The number
            of DRs available for issuance is the MAL less the number of DRs
            outstanding.
          </p>
        </div>
      </div>
      <div className="headroom-info">
        <div className="headroom-availability">
          <div className="headroom-card-title core__t5">
            headroom availability
          </div>
          <p className="headroom-latest-copy core__t16">
            Read the latest information on DR headroom.
          </p>
          <div className="headroom-availability-section">
            <HeadroomFile />
          </div>
        </div>
        <div className="headroom-contact">
          <div className="headroom-card-title core__t5">
            contact us for more information
          </div>
          <p className="headroom-card-copy core__t15">
            Broker and Institutional Investor Clients
          </p>
          <p className="headroom-card-copy headroom-phone core__t16">
            Phone: +1 212 552 8926
          </p>
        </div>
      </div>
    </div>
  </HeadingLayout>
);

export default Headroom;
