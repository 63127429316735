import { dateFullShortMonthNoTime } from '../../utils/format';
import { mapObjectHasFiles } from '../../utils/adapterHelperFunctions';

const values = [
  {
    name: 'eventDate',
    displayText: 'Event Date',
    cellStyle: null,
    headerStyle: null,
    sorteable: true,
    type: 'shordate',
  },
  {
    name: 'name',
    displayText: 'DR Name',
    cellStyle: 'corporate-name ellipsis regular',
    sorteable: true,
    type: 'link',
  },
  {
    name: 'ticker',
    displayText: 'Ticker',
    cellStyle: 'is-hidden-touch is-hidden-mobile  is-bold',
    sorteable: true,
  },
  {
    name: 'action',
    displayText: 'Action',
    cellStyle: 'is-hidden-mobile',
    sorteable: true,
  },
  {
    name: 'announcementDate',
    displayText: 'Announcement Date',
    cellStyle: 'is-hidden-mobile announcement-date',
    sorteable: true,
    type: 'shordate',
  },
  {
    name: 'status',
    displayText: 'Status',
    cellStyle: 'is-hidden-mobile',
    sorteable: true,
  },
  {
    name: 'notices',
    displayText: 'Notices',
    cellStyle: 'is-hidden-mobile',
    sorteable: false,
    type: 'files',
  },
  {
    name: 'country',
    displayText: 'Country',
    cellStyle: 'is-hidden-mobile is-hidden-touch',
    sorteable: true,
  },
  {
    name: 'bookStatus',
    displayText: 'Book Status',
    cellStyle: 'is-hidden-mobile is-hidden-touch',
    type: 'component',
  },
];

export default function adapter(CorporateAction) {
  return CorporateAction.map(obj => {
    const toRealData = value => {
      const field = value.name;
      if (!obj[field]) {
        value.data = '--';
        value.customCSSClass = value.cellStyle + ' Corporate-Actions__No-Wrap';
      } else {
        value.customCSSClass =
          value.cellStyle + ' Corporate-Actions Corporate-Actions__No-Wrap';
        value.data = String(obj[field]);
        switch (value.type) {
          case 'shordate':
            value.data = dateFullShortMonthNoTime(obj[field]);
            value.customCSSClass = `${
              value.customCSSClass
              } Corporate-Actions-Event-Date Corporate-Actions__No-Wrap`;
            break;
          case 'status':
            value.hasComponents = true;
            value.isOpen = obj.isOpen;
            break;
          case 'files':
            value =
              obj[field] &&
              mapObjectHasFiles(
                { ...obj, ...{ customCSSClass: value.customCSSClass } },
                field
              );
            break;
          case 'link':
            value.hasLink = true;
            value.contentLink = '/drprofile/' + obj.cusip;
            break;

          case 'component':
            if (String(obj[field]) === 'Unknown') {
              value.data = '--';
              value.hasComponents = false;
              value.customCSSClass = value.cellStyle;
              break;
            }
            else {
              value.data = String(obj[field]);
              value.hasComponents = true;
              value.isOpen = value.data === 'Open';
              value.customCSSClass = value.cellStyle;
              break;
            }
        }
      }
      return { ...value };
    };
    return values.map(toRealData);
  });
}

export const POSSIBLE_SORT_VALUES = {
  asc: 'asc', // Ascending
  desc: 'desc', // Descending
  unsorted: 'unsorted',
};

export const SORT_OPTIONS_MAP = values
  .filter(value => value.sorteable)
  .reduce((obj, value) => {
    obj[value.name] = value.name;
    return obj;
  }, {});

export const getSortDirectionForName = (
  name,
  currentlySortedName,
  sortedDirection
) => {
  if (name === currentlySortedName) {
    return POSSIBLE_SORT_VALUES[sortedDirection];
  }
  return POSSIBLE_SORT_VALUES.unsorted;
};

export const createCorporateActionsTitles = (
  currentlySortedName,
  sortedDirection,
  handleSort
) => {
  return values.map((value, index) => {
    return {
      id: index,
      title: value.displayText,
      customCSSClass: value.headerStyle ? value.headerStyle : value.cellStyle,
      sortable: value.sorteable,
      handleSort,
      sortName: value.name,
      sortDirection: getSortDirectionForName(
        value.name,
        currentlySortedName,
        sortedDirection
      ),
    };
  });
};
