import PropTypes from 'prop-types';
import React from 'react';

import {
  dateMonthYearSpacingWithComma,
  getMonth,
  numberComma,
  numberCommaSigned,
  numberSignedName,
  percent,
  price,
  substract,
} from '../../../utils/format';

import TableLoader from '../../components/tables/TableLoader';
import { getActivityDailyReports } from '../../utils/apiCalls';

class DailyTable extends React.Component {
  constructor() {
    super();
    const [currentYear] = this.getYearsFilter();
    const currentMonthNumber = getMonth.number.fromNow();
    const currentMonth = {
      label: getMonth.name.fromNumber(currentMonthNumber),
      value: currentMonthNumber,
    };
    this.defaultDateFilter = {
      year: currentYear,
      month: currentMonth,
    };
    this.state = {
      filterBy: this.defaultDateFilter,
    };
    this.prepareConfigTable = this.prepareConfigTable.bind(this);
    this.prepareApiRequest = this.prepareApiRequest.bind(this);
    this.onSelectYear = this.onSelectYear.bind(this);
    this.onSelectMonth = this.onSelectMonth.bind(this);
  }

  onSelectYear() {
    return (error, yearSelected) => {
      let { filterBy } = this.state;
      const newYearSelected = {
        year: yearSelected,
      };
      filterBy = { ...filterBy, ...newYearSelected };
      this.setState({ filterBy });
    };
  }

  onSelectMonth() {
    return (error, monthSelected) => {
      let { filterBy } = this.state;
      const newMonthSelected = {
        month: monthSelected,
      };
      filterBy = { ...filterBy, ...newMonthSelected };
      this.setState({ filterBy });
    };
  }

  getYearsFilter() {
    return [0, 1, 2, 3, 4, 5].map(year => {
      const yearConfig = substract({ year }, 'YYYY');
      return { label: yearConfig, value: yearConfig };
    });
  }

  getMonthsFilter() {
    const months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    return months.map(monthNumber => {
      return {
        label: getMonth.name.fromNumber(monthNumber),
        value: monthNumber,
      };
    });
  }

  prepareConfigTable() {
    return {
      columns: [
        {
          text: <div>Day</div>,
        },
        {
          text: (
            <React.Fragment>
              <div>DRs</div> <div>Outstanding</div>
            </React.Fragment>
          ),
        },
        {
          text: <div>Issuance</div>,
        },
        {
          text: <div>Cancellation</div>,
        },
        {
          text: <div>Net Change</div>,
        },
        {
          text: <div>Total Activity</div>,
        },
        {
          text: <div>Volume</div>,
        },
        {
          text: (
            <React.Fragment>
              <div>DR Price at </div>
              <div>Day&apos;s Close</div>
              <div>(USD)</div>{' '}
            </React.Fragment>
          ),
        },
        {
          text: <div>DR Value (USD)</div>,
        },
        {
          text: (
            <React.Fragment>
              <div>Underlying </div>
              <div>Value (USD)</div>
            </React.Fragment>
          ),
        },
        {
          text: (
            <React.Fragment>
              <div>DR % of</div>
              <div> Market Cap</div>
            </React.Fragment>
          ),
        },
      ],
      rows: [
        {
          text: data => dateMonthYearSpacingWithComma(data.date),
        },
        {
          text: data => numberComma(data.drsOutstanding),
        },
        {
          text: data => numberComma(data.issuance),
        },
        {
          text: data => numberComma(data.cancellation),
        },
        {
          text: data => (
            <div
              className={`position-change-${numberSignedName(data.netChange)}`}
            >
              {numberCommaSigned(data.netChange)}
            </div>
          ),
        },
        {
          text: data => numberComma(data.totalActivity),
        },
        {
          text: data => numberComma(data.volume),
        },

        {
          text: data => price(data.drPrice),
        },
        {
          text: data => numberComma(data.drValue),
        },
        {
          text: data => numberComma(data.underlyingValue),
        },
        {
          text: data => percent(data.drPercentMarketCap, 1),
        },
      ],
    };
  }

  prepareApiRequest() {
    const { cusip } = this.props;
    const { filterBy } = this.state;
    const {
      year: { value: yearValue },
      month: { value: monthValue },
    } = filterBy;

    return {
      requestApi: cusip ? getActivityDailyReports : null,
      requestApiConfig: {
        cusip,
        year: yearValue,
        month: monthValue + 1,
      },
    };
  }

  render() {
    const {
      props,
      prepareApiRequest,
      prepareConfigTable,
      state: { filterBy },
      getMonthsFilter,
      getYearsFilter,
      onSelectMonth,
      onSelectYear,
    } = this;
    const configs = {
      configTable: prepareConfigTable(),
      filters: [
        {
          id: 'selectYearAndMonth',
          yearConfig: {
            options: getYearsFilter(),
            value: filterBy.year,
            onChange: onSelectYear(),
            label: 'Select year with up and down key, ',
            isSearchable: false,
          },
          monthConfig: {
            options: getMonthsFilter(),
            value: filterBy.month,
            onChange: onSelectMonth(),
            label: 'Select month with up and down key, ',
            isSearchable: false,
          },
        },
        {
          id: 'exportButton',
          hasModal: true,
          type: 'daily'
        },
      ],
      immediateRequest: true,
      filtersClassName:'daily'
    };
    const tableLoaderConfig = { ...configs, ...props, ...prepareApiRequest() };
    return <TableLoader {...tableLoaderConfig} />;
  }
}

DailyTable.propTypes = {
  cusip: PropTypes.string.isRequired,
  headerInfo: PropTypes.objectOf.isRequired,
};

export default DailyTable;
