/* eslint-disable sort-imports-es6-autofix/sort-imports-es6 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../../styles/components/CorporateActions.scss';
import exportData from '../../utils/exportData';
import { getCorporateActions, getExportData } from '../../utils/api';
import { date } from '../../utils/format';
import corporateActionsFilters from './CorporateActionsFilters';
import { URLbyDomain } from '../../utils/constants';
import { createUrlQuery, reduceToValues } from '../../utils/urlMethods';
import corporateActionsTableAdapter, {
  createCorporateActionsTitles,
  SORT_OPTIONS_MAP,
} from './CorporateActionsTableAdapter';

import TableViewPageContainer from '../../components/common/TableViewPage/TableViewPageContainer';
/* eslint-enable sort-imports-es6-autofix/sort-imports-es6 */

export default class CorporateActions extends Component {
  constructor() {
    super();
    this.tableRef = React.createRef();
    this.fetchFilteredData = this.fetchFilteredData.bind(this);
    this.getExportUrl = this.getExportUrl.bind(this);
    this.extraExportFunction = null;
    this.setExtraExportFunction = this.setExtraExportFunction.bind(this);
  }

  getExportUrl(params) {
    const query = createUrlQuery({
      ...reduceToValues(params),
      offset: 0,
      limit: params.limit,
    });

    return `${URLbyDomain}/corporateActions/exportTable?${query}`;
  }
  
  setExtraExportFunction (extraFunction) {
    this.extraExportFunction = extraFunction;
  }

  fetchFilteredData(filters) {
    const { q, startDate, endDate, ...otherFilters } = filters;
    let params = {
      q: q,
      ...otherFilters,
    };
    if (startDate) {
      params.startDate = date(startDate, 'yearMonthDayAPI');
    }
    if (endDate) {
      params.endDate = date(endDate, 'yearMonthDayAPI');
    }
    return getCorporateActions(params).then(response => {
      const { items = [], pagination } = response;

      return {
        data: corporateActionsTableAdapter(items),
        pagination,
      };
    });
  }

  handleExportClickOption (isFullDownload = false) {
    const infoFromChild = this.extraExportFunction && this.extraExportFunction();
    const { 
      data, 
      filtersValues,
      offset,
      sortBy,
      sortOrder, 
    } = infoFromChild;
    delete infoFromChild.data;
    delete infoFromChild.filtersValues;
    
    let args 
    if(isFullDownload) {
      args = {
        offset,
        sortBy,
        sortOrder,
      };
      args.limit = 25000 
    }
    else {
      args = {
        ...reduceToValues(filtersValues),
        ...infoFromChild
      };
      args.limit = data.length;
      if (args.startDate) {
        args.startDate = date(args.startDate, 'yearMonthDayAPI');
      }
      if (args.endDate) {
        args.endDate = date(args.endDate, 'yearMonthDayAPI');
      }
    }
    

    return getExportData('corporateActions', args).then(response => {
        const fileName = 'corporate_actions.xlsx';
        exportData(fileName, response.data);
    });
  }


  canResetFilters(filtersValues) {
    return (
      (filtersValues.type && filtersValues.type.length > 0) ||
      (filtersValues.country && filtersValues.country.length > 0) ||
      (filtersValues.sector && filtersValues.sector.length > 0) ||
      (filtersValues.startDate && filtersValues.startDate !== '') ||
      (filtersValues.endDate && filtersValues.endDate !== '')
    );
  }

  render() {
    const { customButton } = this.props
    return (
      <TableViewPageContainer
        classModifier="corporate-actions-table-view"
        ref={this.tableRef}
        customExportButton={customButton}
        defaultSortBy={SORT_OPTIONS_MAP.announcementDate}
        defaultSortOrder="desc"
        extraExportFunction={this.setExtraExportFunction}
        fields={corporateActionsFilters}
        getExportUrl={this.getExportUrl}
        heading="JPM CORPORATE ACTIONS"
        headerColumns={createCorporateActionsTitles}
        onFetchData={this.fetchFilteredData}
        canResetFunc={this.canResetFilters}
        searchInputPlaceholder="Filter Results (Name, Ticker, or CUSIP)"
      />
    );
  }
}

CorporateActions.defaultProps = {
  customButton: null
}

CorporateActions.propTypes = {
  customButton: PropTypes.func
};
