import React, { Component } from 'react';

const SharedStateContext = React.createContext();

export class SharedStateProvider extends Component {
  constructor(props) {
    super(props);
    this.updateSharedState = this.updateSharedState.bind(this);
    this.state = {
      isLoading: false,
      error: false,
      isEmpty: false,
      pagination: {},
    };
  }

  updateSharedState(newState = {}) {
    this.setState(newState);
  }

  render() {
    const { state, props } = this;
    const { children } = props;
    const { error, isLoading, isEmpty, pagination } = state;

    return (
      <SharedStateContext.Provider
        value={{ error, isLoading, isEmpty, pagination, updateSharedState: this.updateSharedState }}
      >
        {children}
      </SharedStateContext.Provider>
    );
  }
}

export const SharedStateConsumer = SharedStateContext.Consumer;
