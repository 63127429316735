import React from 'react';
import { Table } from '@jpm-adr/pattern-library';

import Tables from './FxDisclosureTables1.json';

const { Table1, Table2, Table3 } = Tables

const Appendix = (
  <div id="appendixA">
    {' '}
    <p className="normalUnderline">Appendix A</p>
    <h3>WMR Rate Currencies</h3>
    <Table
      tableId="table-1"
      values={Table1}
      tableTitles={Table1[0].map((element, index) => {
        return {
          index,
          superscript: element.titleSuperscript,
          title: element.title
        };
      })}
    />
    <ol className="special-numbers">
      <li>
        The basis point spread is added to the WM/Refinitiv Intra-Day Spot Rate if the WM/Refinitiv
        Intra-Day Spot Rate is quoted as the amount of the WMR Rate Currency per one United States
        Dollar. For example, if the WM/Refinitiv Intra-Day Spot Rate is 106.26 JPY per 1.0 USD, then
        the basis point spread would be added to the WM/Refinitiv Intra-Day Spot Rate and the
        Applicable FX Rate would be 106.4725 JPY per 1.0 USD (equal to 106.26 x (1.00 + 0.0020)).
        The basis point spread is subtracted from the WM/Refinitiv Intra-Day Spot Rate if the
        WM/Refinitiv Intra-Day Spot Rate is quoted as the amount of United States Dollars per one
        unit of WMR Rate Currency. For example, if the WM/Refinitiv Intra-Day Spot Rate is 1.2329
        USD per 1.0 EUR, then the basis point spread would be subtracted from the WM/Refinitiv Intra-Day
        Spot Rate and the Applicable FX Rate would be 1.230434 (equal to 1.2329 x (1.00 – 0.0020)).
      </li>
      <li>
        The USD-equivalent notional amount of an FX Transaction is determined by JPMorgan
        in its sole discretion. The Maximum Amount is subject to change.
      </li>
      <li>
        Execution of FX Transactions using the WM/Refinitiv Intra-Day Spot Rate
        is available hourly from 1 a.m. to 9 p.m. (London time) where 9 p.m.
        (London time) coincides with 4 p.m. (New York time) on any Business Day.
        When the time difference between London and New York changes to four hours,
        the 9 p.m. execution (London time) will revert automatically to 8 p.m.
        (London time) to coincide with 4 p.m. (New York time) on any Business Day.
      </li>
      <li>
        Not applicable for the Santander Mexico (BSMX) depositary receipt program.
        For such program, the Bank is not party to any foreign exchange transaction
        and does not determine an Applicable FX Rate.
      </li>
    </ol>
    <h3>Alternative Benchmark Rate Currencies</h3>
    <Table
      tableId="table-2"
      values={Table2}
      tableTitles={Table2[0].map((element, index) => {
          return {
            index,
            superscript: element.titleSuperscript,
            title: element.title
          };
      })}
    />
    <ol className="special-numbers">
      <li>
        The basis point spread is added to the Alternative Benchmark Rate if the Alternative
        Benchmark Rate is quoted as the amount of the Alternative Benchmark Rate Currency per
        one United States Dollar. For example, if the Alternative Benchmark Rate is 29.1742
        TWD per 1.0 USD, then the basis point spread would be added to the Alternative
        Benchmark Rate and the Applicable FX Rate would be 29.2325 per 1.0 USD (equal to
        29.1742 x (1.00 + 0.0020)).
      </li>
      <li>
        The USD-equivalent notional amount of an FX Transaction is determined by JPMorgan in
        its sole discretion. The Maximum Amount is subject to change.
      </li>
      <li>
        Applicable when JPMorgan is the local custodian.
      </li>
      <li>
        Not applicable for the Banco De Chile (BCH) depositary receipt program. For such
        program, the Bank is not party to any foreign exchange transaction and does not determine
        an Applicable FX Rate.
      </li>
    </ol>
    <h3>Local Liquidity Provider Rate Currencies</h3>
    <Table
      tableId="table-3"
      values={Table3}
      tableTitles={Table3[0].map((element, index) => {
          return {
            index,
            superscript: element.titleSuperscript,
            title: element.title
          };
      })}
    />
    <ol className="special-numbers">
      <li>
        Applicable when JPMorgan is not the local custodian
      </li>
    </ol>
    {' '}
  </div>
);

const FxDisclosure = () => {
  return (
    <div className="fx-disclosure">
      <p>
        To facilitate the administration of various depositary receipt transactions,
        including disbursement of dividends or other cash distributions and other corporate
        actions, JPMorgan Chase Bank, N.A. or an affiliate, in its capacity as depositary bank
        (the &quot;<b>Depositary</b>&quot;), may engage the foreign exchange desk
        (the &quot;<b>FX Desk</b>&quot;) within JPMorgan Chase Bank, N.A. (the &quot;<b>Bank</b>&quot;)
        and/or its affiliates in order to enter into spot foreign exchange transactions to convert
        foreign currency into U.S. dollars (&quot;<b>FX Transactions</b>&quot;). For WMR Rate Currencies and Alternative
        Benchmark Rate Currencies (each as defined below), FX Transactions are entered into with the
        Bank or an affiliate, as the case may be, acting in a principal capacity. For Local Liquidity
        Provider Rate Currencies (as defined below), FX Transactions are routed directly to and managed
        by an unaffiliated local custodian (or other third-party local liquidity provider), and neither
        the Bank nor any of its affiliates is a party to such FX Transactions.
      </p>
      <p>
        The foreign exchange rate applied to an FX Transaction will be the foreign exchange rate specified
        in <span className="normalUnderline">Appendix A</span> for the applicable currency
        (the &quot;<b>Applicable FX Rate</b>&quot;), which may be (a) the WM/Refinitiv Intra-Day Spot Rate
        (as defined below), (b) the Alternative Benchmark Rate (as defined below), or (c) the Local
        Liquidity Provider Rate (as defined below), in each case plus or minus the basis point spread
        specified for the applicable currency in <span className="normalUnderline">Appendix A</span>.
        In certain circumstances, as described below, the applicable foreign exchange rate will be the
        Externally Sourced Spot Rate (as defined below), plus or minus the applicable basis point
        spread specified for the applicable currency in <span className="normalUnderline">Appendix A</span>,
        rather than the Applicable FX Rate for the relevant currency. The applicable foreign exchange
        rate and spread may (and neither the Depositary, the Bank nor any of its affiliates is
        under any obligation to ensure that such rate does not) differ from rates and spreads at
        which comparable transactions are entered into with other customers or the range of foreign
        exchange rates and spreads at which the Bank or any of its affiliates enters into foreign
        exchange transactions in the relevant currency pair on the date of the FX Transaction.
        Additionally, the timing of execution of an FX Transaction varies according to local market
        dynamics, which may include regulatory requirements, market hours and liquidity in the foreign
        exchange market or other factors. Furthermore, the Bank and its affiliates may manage the
        associated risks of their position in the market in a manner they deem appropriate without
        regard to the impact of such activities on the issuer whose securities are represented/evidenced
        by such depositary receipts (each an  &quot;<b>Issuer</b>&quot;) or registered holders or
        beneficial owners of depositary receipts. The spread applied does not reflect any gains or losses
        that may be earned or incurred by the Bank or its affiliates as a result of risk management or
        other hedging related activity.
      </p>
      <p>
        Notwithstanding the foregoing, registered holders and beneficial owners of unsponsored depositary
        receipts are advised that the Depositary may be required, or otherwise elect, to utilize a foreign
        exchange rate determined by another depositary bank that established an unsponsored depositary
        receipt program for securities of the same Issuer prior to the date the Depositary established its
        unsponsored depositary receipt program. Under such circumstances, the foreign exchange rate that
        the Depositary applies for determining the amount of the U.S. dollar payment to the relevant
        registered holders and beneficial owners will be the foreign exchange rate set by such other
        depositary bank. The Depositary may, for its own purposes, convert any foreign currency that
        it receives under any such program into U.S. dollars at foreign exchange rate(s) that it determines
        in its own discretion, and such conversion will have no effect on any payment made to registered
        holders (for their own account or for forwarding to beneficial owners of unsponsored depositary
        receipts).
      </p>
      <p>
        Additionally, to the extent an Issuer provides U.S. dollars to the Depositary, neither the Bank
        nor any of its affiliates will execute an FX Transaction as set forth herein. In such case, the
        Depositary will distribute the U.S. dollars received from such Issuer.
      </p>
      <p>
        &quot;<b>WM/Refinitiv Intra-Day Spot Rate</b>&quot; means the bid or ask rate, as
        applicable, published by Refinitiv Benchmark Services Limited for the currencies specified
        as subject to the WM/Refinitiv Intra-Day Spot Rate (&quot;<b>WMR Rate Currencies</b>&quot;)
        in <span className="normalUnderline">Appendix A.</span> Execution of FX Transactions using
        the WM/ Refinitiv Intra-Day Spot Rate is available hourly from 1 a.m. to 9 p.m. (London time)
        where 9 p.m. (London time) coincides with 4 p.m. (New York time) on any Business Day.
        When the time difference between London and New York changes to four hours, the 9 p.m.
        execution (London time) will revert automatically to 8 p.m. (London time) to coincide
        with 4 p.m. (New York time) on any Business Day. The Depositary will provide instructions
        to the relevant FX Desk to execute an FX Transaction. Except as otherwise provided below,
        if such instructions are received prior to the cut-off time specified in <span className="normalUnderline">Appendix A</span> for
        the applicable WMR Rate Currency, the FX Transaction will be executed at the relevant
        WM/Refinitiv Intra-Day Spot Rate for the hour immediately following receipt of such
        instructions and at the next available WM/Refinitiv Intra-Day Spot Rate thereafter. If
        the WM/Refinitiv Intra-Day Spot Rate is unavailable due to a holiday or other reason,
        the relevant FX Desk will hold the FX Transaction until the WM/Refinitiv Intra-Day Spot
        Rate becomes available, unless the FX Desk determines in its sole discretion that the
        Force Majeure Provisions apply. Notwithstanding the foregoing, where the notional amount
        of foreign currency to be converted is greater than or equal to the equivalent of the maximum amount
        (the &quot;<b>Maximum Amount</b>&quot;) indicated on <span className="normalUnderline">Appendix A</span> for
        the applicable WMR Rate Currency (as determined by the relevant FX Desk in its sole discretion),
        the relevant rate may be the WM/Refinitiv Intra-Day Spot Rate or the Externally Sourced Spot Rate, as
        determined by the relevant FX Desk in its sole discretion, in each case plus or minus the basis
        point spread specified for the applicable currency in <span className="normalUnderline">Appendix A</span>.
        The relevant FX Desk may, in its sole discretion, execute such conversion in a series of FX
        Transactions over one or more Business Days and the foreign exchange rate applied to each
        such FX Transaction will be the WM/Refinitiv Intra-Day Spot Rate(s) and/or the Externally
        Sourced Spot Rate(s), in each case plus or minus the basis point spread(s), for the applicable
        Business Day(s) and at such times as determined by the relevant FX Desk in its sole discretion.
        In such cases, the foreign exchange rate used to calculate the U.S. dollar amount payable to
        registered holders (for their own account or for forwarding to beneficial owners of depositary
        receipts) will be a weighted average rate based on the series of FX Transactions.
      </p>
      <p>
        &quot;<b>Alternative Benchmark Rate</b>&quot; means an alternative benchmark rate
        published at the time specified for such alternative benchmark rate
        in <span className="normalUnderline">Appendix A</span> for the currencies specified
        as subject to the Alternative Benchmark Rate (&quot;<b>Alternative Benchmark Rate Currencies</b>&quot;)
        in <span className="normalUnderline">Appendix A</span>. Execution of FX
        Transactions using the Alternative Benchmark Rate for a Business Day is subject
        to the relevant FX Desk having received instructions from the Depositary to enter
        into such FX Transactions on such Business Day by the cut-off time specified in <span className="normalUnderline">Appendix A</span> for
        the applicable Alternative Benchmark Rate Currency. Instructions to enter into FX Transactions using the Alternative
        Benchmark Rate for a Business Day that are received by the relevant FX Desk on such
        Business Day but after the time specified in <span className="normalUnderline">Appendix A</span> for
        the applicable Alternative Benchmark Rate Currency will be executed on the
        following Business Day at the Alternative Benchmark Rate for such following Business Day.
        If the Alternative Benchmark Rate is unavailable due to a holiday or other reason, the relevant
        FX Desk will hold the FX Transaction until the Alternative Benchmark Rate becomes available,
        unless the FX Desk determines in its sole discretion that the Force Majeure Provisions apply.
        Notwithstanding the foregoing, where the notional amount of foreign currency to be converted
        is greater than or equal to the equivalent of the Maximum Amount indicated on <span className="normalUnderline">Appendix A</span> for
        the applicable Alternative Benchmark Rate Currency (as determined by the relevant FX Desk in its sole discretion), the relevant
        FX Desk may, in its sole discretion, execute such conversion in a series of FX Transactions over
        one or more Business Days and the foreign exchange rate applied to each such FX Transaction will
        be the Alternative Benchmark Rate(s), plus or minus the basis point spread(s) specified for the
        applicable currency in <span className="normalUnderline">Appendix A</span>, for the applicable
        Business Day(s) as determined by the relevant FX Desk in its sole discretion. In such cases,
        the foreign exchange rate used to calculate the U.S. dollar amount payable to registered holders
        (for their own account or for forwarding to beneficial owners of depositary receipts)
        will be a weighted average rate based on the series of FX Transactions.
      </p>
      <p>
        &quot;<b>Local Liquidity Provider Rate</b>&quot; means the rate applied by a third-party local
        liquidity provider for the currencies specified as subject to the Local Liquidity Provider Rate
        (&quot;<b>Local Liquidity Provider Rate Currencies</b>&quot;) in <span className="normalUnderline">Appendix A</span> during
        such third party local liquidity provider’s local market hours. The timing for execution
        of an FX Transaction subject to the Local Liquidity Provider Rate will be determined by the third-party
        local liquidity provider. If the Local Liquidity Provider Rate is unavailable due to a holiday
        or for any other reason, execution of the FX Transaction will be held until the Local Liquidity
        Provider Rate becomes available, unless the FX Desk determines in its sole discretion that the
        Force Majeure Provisions apply. The third party local liquidity provider may execute a conversion
        of foreign currency in a series of FX Transactions over one or more Business Days in its discretion
        and, in such cases, the foreign exchange rate used to calculate the U.S. dollar amount payable
        to registered holders (for their own account or for forwarding to beneficial owners of) will
        be the rate applied by the third party local liquidity provider based on the series of FX
        Transactions. The Local Liquidity Provider Rate may include a profit earned by the third-party
        local liquidity provider in converting the currency.
      </p>
      <p>
        &quot;<b>Externally Sourced Spot Rate</b>&quot; is the rate determined by J.P. Morgan in its
        sole discretion using an FX rate sourced from third-party liquidity providers. Such FX Transactions
        will be entered into with the Bank or an affiliate, as the case may be, acting in a principal
        capacity. If there is no third-party liquidity provider available, the FX Desk will execute at
        the next execution point at which third-party liquidity becomes available. The FX Desk may, in its
        sole discretion, execute FX Transactions in one or more batches on the same day or over one or more
        days, and a particular batch may cover the transactions of multiple clients. In such cases, the foreign
        exchange rate used to calculate the U.S. dollar amount payable to registered holders (for their own
        account or for forwarding to beneficial owners of depositary receipts) will be a weighted average rate
        based on the series of FX Transactions. The FX Desk may, it its sole discretion, source FX rates from
        multiple third-party liquidity sources.
      </p>
      <p>
        &quot;<b>Business Day</b>&quot; means (i) with respect to the WM/Refinitiv Intra-Day Spot Rate, a day
        on which the WM/Refinitiv Intra-Day Spot Rate is published, (ii) with respect to an Alternative
        Benchmark Rate, a day on which the Alternative Benchmark Rate is published, (iii) with respect
        to the Local Liquidity Provider Rate, a day on which the third party local liquidity provider provides
        quotations for the Local Liquidity Provider Rate, and (iv) with respect to the Externally Sourced Spot
        Rate, a day on which a third-party local liquidity provider provides quotations for the Externally
        Sourced Spot Rate.
      </p>
      <p>
        <h3>Force Majeure Provisions</h3>
        The FX Desk’s execution of FX Transactions may be affected by causes beyond the reasonable control of
        the FX Desk (e.g., war, terrorism, act of any governmental/regulatory authority, extraordinary market
        conditions, legal constraint, or malfunction or failure of equipment, software, information or database
        system). In such cases, the FX Desk will, in its discretion, execute the FX Transactions based on the
        Externally Sourced Spot Rate then available to the FX Desk, plus or minus the applicable basis point
        spread specified for the applicable currency in <span className="normalUnderline">Appendix A</span>,
        and such FX Transactions will be entered into with the Bank or an affiliate, as the case may be, acting
        in a principal capacity. As soon as commercially practicable after such cause has been eliminated or
        resolved or otherwise no longer constrains the FX Desk’s trade execution, the FX Desk will resume using
        the Applicable FX Rate as described above.
      </p>
      <p />
      {Appendix}
    </div>
  );
};

export default FxDisclosure;
