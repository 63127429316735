import React from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';

import DataLogin from '../common/DataLogin';
import DataMenu from '../common/DataMenu';
import { isPathAbsolute } from '../../utils/urlMethods';

import '../../styles/components/Footer.scss';

const Login = env =>
  [DataLogin(env)].map(menu => (
    <div className="column link-footer" key={menu.id}>
      <div className="login-name">{menu.name}</div>
      <ul>
        {menu.submenu.map(data => (
          <li key={data.id}>
            <a href={data.path} id={`${data.id}-footer`} aria-label={data.name}>
              {data.name}
            </a>
          </li>
        ))}
      </ul>
    </div>
  ));

const Footer = ({ env }) => (
  <footer className="columns footer is-multiline margin-mobile is-hidden-touch">
    <div className="column is-12">
      <div className="columns">
        <div className="column side-margis">&nbsp;</div>
        <div className="morgan-logo-footer column is-four-fifths is-paddingless main-content-wrapper" />
        <div className="column side-margis">&nbsp;</div>
      </div>
    </div>
    <div className="column is-12">
      <div className="columns">
        <div className="column side-margis">&nbsp;</div>
        <div className="main-content-wrapper">
          <div
            className="menu-footer is-flex-tablet column is-four-fifths "
            role="navigation"
          >
            {DataMenu(env).map(menu => (
              <div className="column link-footer" key={menu.id}>
                <div className="h3-footer">{menu.name}</div>
                <ul>
                  {menu.submenu.map(
                    data =>
                      isPathAbsolute(data.path) ? (
                        <a
                          href={data.path}
                          aria-label={data.name}
                          target="_blank"
                          rel="noopener noreferrer"
                          key={data.id}
                        >
                          {data.name}
                        </a>
                      ) : (
                        <Link
                          id={data.id}
                          to={data.path}
                          aria-label={data.name}
                          key={data.id}
                        >
                          {data.name}
                        </Link>
                      )
                  )}
                </ul>
              </div>
            ))}
            {Login(env)}
          </div>
        </div>
        <div className="column side-margis">&nbsp;</div>
      </div>
    </div>
  </footer>
);
Footer.propTypes = {
  env: propTypes.string.isRequired,
};
export default Footer;
