import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import { detectEnvByDomain } from './constants';

/**
 * Method used to get query string parameters
 * @param {A query string to parse} query
 */
export const getQueryStringParameters = query => {
  if (!query) return {};

  return (/^[?#]/.test(query) ? query.slice(1) : query)
    .split('&')
    .reduce((params, param) => {
      const [key, value] = param.split('=');
      params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
      return params;
    }, {});
};

/**
 *
 *
 * Method used to get a query string parameter value
 * @param {A parameter key} key
 */
export const getUrlParameter = key => {
  if (!window.location.search) return undefined;

  const params = getQueryStringParameters(window.location.search);

  if (!params || Object.keys(params).length < 1) return undefined;

  return params[key];
};

/**
 * Method used to determine whether debug is on
 */
export const isDebugOn = () => getUrlParameter('..showdebuginfo..') === 'on';

export const getMockParameters = () => {
  if (detectEnvByDomain === 'production') {
    return {};
  }
  const parameters = ['..mockmode..', '..mockid..'];
  return parameters.reduce((acc, newValue) => {
    acc[newValue] = getUrlParameter(newValue);
    return acc;
  }, {});
};

export const isLocalHost = () =>
  window.location.host.indexOf('localhost') > -1 ||
  window.location.host === '127.0.0.1';

export const isLocalHost3000 = () => window.location.port === '3000';

export const encodeString = key => value =>
  `${key}=${encodeURIComponent(value)}`;

export const createUrlQuery = object => {
  return Object.keys(object)
    .reduce((acc, k) => {
      let entry = get(object, k, '');

      if (isNumber(entry)) {
        entry += '';
      }

      if (!entry || entry.length === 0) return acc;

      if (Array.isArray(entry)) {
        const encodeKeyString = encodeString(k);

        return [...acc, entry.map(encodeKeyString).join('&')];
      }

      return [...acc, encodeString(k)(entry)];
    }, [])
    .join('&');
};

const getValue = entry => {
  // If we have an array, we call get value with each entry of the array
  if (Array.isArray(entry)) {
    return entry.map(getValue);
  }

  // If we already have a string, we just return it
  if (isString(entry)) {
    return entry;
  }

  // Otherwise, we must have an object with a label, and a value. We return the value
  return entry.value;
};

export const reduceToValues = object =>
  Object.keys(object).reduce(
    (acc, k) => ({
      ...acc,
      [k]: getValue(object[k]),
    }),
    {}
  );

export const isPathAbsolute = path => /(^\w+:|^)\/\//.test(path);
