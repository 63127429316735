import PropTypes from 'prop-types';
import React from 'react';
import { TabularData } from '@jpm-adr/pattern-library';

import ApiStates from '../../../utils/apiStatesWrapper';
import { getF6 } from '../../utils/apiCalls';

import '../../../styles/IssuerServices/components/F6Data.scss';

import { TEXT_NULL_VALUE } from '../../../utils/constants';
import { 
  dateFullShortMonthNoTime,
  numberComma 
} from '../../../utils/format';


const searchFilingLink =
  'https://www.sec.gov/edgar/searchedgar/companysearch.html';

const containerClass = 'f6';
const containerTitle = 'F-6 DATA';

const F6Data = props => {

  const showManagersInfo = (data = {}) => {
    let { amountFiled, available, dateEndOfDay } = data;
    const f6Data = [
      {
        id: 'amountFiled',
        title: 'Amount Filed',
        value: numberComma(amountFiled),
      },
      {
        id: 'available',
        title: 'Available to Be Issued',
        value: numberComma(available),
      },
      {
        id: 'searchFillings',
        className: 'search-filings',
        title: 'U.S. Securities and Exchange Comission EDGAR',
        value: (
          <a
            id="search-filing"
            aria-label="Search Filings (pressing ENTER or SPACEBAR key will be open the next link in a new window)"
            target="_blank"
            rel="noopener noreferrer"
            href={searchFilingLink}
          >
            Search Filings
          </a>
        ),
      },
      {
        id: 'f6-as-Of',
        className: 'f6-end-of-day',
        title: 'As of',
        value: dateEndOfDay ? dateFullShortMonthNoTime(dateEndOfDay) : TEXT_NULL_VALUE
      }
    ];
    return (
      <React.Fragment>
        <TabularData data={f6Data} />
      </React.Fragment>
    );
  };
  const { data } = props;
  return (
    <div className={containerClass}>
      <span className="title">{containerTitle}</span>
      {showManagersInfo(data)}
    </div>
  );
};

F6Data.defaultProps = {
  data: {
    amountFiled: null,
    available: null,
  },
};

F6Data.propTypes = {
  data: PropTypes.shape({
    amountFiled: PropTypes.number,
    available: PropTypes.number,
  }),
};

const config = {
  WrappedComponent: F6Data,
  requestApi: ({ xid }) => getF6(xid),
  custom: { containerClass, containerTitle }
};

export default ApiStates(config);
