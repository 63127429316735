import PropTypes from 'prop-types';
import React from 'react';

import '../../../styles/components/Field.scss';

export default function Field({ children, htmlFor, label }) {
  /* eslint-disable jsx-a11y/label-has-for */
  return (
    <div className="Field">
      <label className="Field__label" htmlFor={htmlFor}>
        {label}
      </label>
      <div className="Field__component">{children}</div>
    </div>
  );
}

Field.propTypes = {
  children: PropTypes.node.isRequired,
  htmlFor: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};
