import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Submenu from './Submenu';

let clickStyle =
  'navbar-item menu-principal column is-one-fifth is-12-touch has-dropdown is-hoverable';

class Menu extends Component {
  constructor(props) {
    super(props);

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleEnterKey = this.handleEnterKey.bind(this);

    this.KeyMap = {
      13: 'handleEnterKey',
      27: 'handleEsc',
    };

    this.state = {
      mouseFocus: false,
      currentFocus: false,
    };
  }

  handleKeyDown(event) {
    this.KeyMap[event.keyCode] && this[this.KeyMap[event.keyCode]](event);
  }

  handleEnterKey(event) {
    this.closeDropDown();
    let $target = event.target.parentElement;
    $target.classList.contains('has-dropdown') ? null : this.closeDropDown();
    $target.classList.add('is-active');
  }

  handleEsc() {
    this.closeDropDown();
  }

  closeDropDown() {
    let $target = document.querySelectorAll('.menu-principal.is-active');
    [...$target].forEach($el => $el.classList.remove('is-active'));

    let $targetMobile = document.querySelector('.navbar-menu.is-active');
    if ($targetMobile) {
      document.querySelector('.navbar-burger, .burguer').click();
    }
  }

  render() {
    let { name, id, items } = this.props;
    const { mouseFocus = false, currentFocus = false } = this.state;
    return (
      <li className={'menu-bar ' + clickStyle}>
        <div
          role="link"
          tabIndex="0"
          className={`navbar-item item-principal ${
            mouseFocus ? 'menu-item-mouse-focused' : ''
          }`}
          onKeyDown={this.handleKeyDown}
          onBlur={() =>
            this.setState({ mouseFocus: false, currentFocus: false })
          }
          onFocus={() => this.setState({ currentFocus: true })}
          onMouseDown={() =>
            currentFocus ? null : this.setState({ mouseFocus: true })
          }
        >
          <div id={id}>
            <h2>{name}</h2>
          </div>
          <div className="navbar-dropdown">
            {items.map(item => (
              <Submenu
                name={item.name}
                id={item.id}
                key={item.id}
                path={item.path}
                onCloseDropDown={this.closeDropDown}
              />
            ))}
          </div>
        </div>
      </li>
    );
  }
}

Menu.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Menu;
