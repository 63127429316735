import '../../styles/components/BrokerForms.scss';
import React, { Component } from 'react';
import { NoDataAvailable, Table } from '@jpm-adr/pattern-library';

import ProgramSpecificFormAdapter, {
  SORT_OPTIONS_MAP,
  createProgramSpecificTitles,
} from './ProgramSpecificFormAdapter';

import { getBrockerForms } from '../../utils/api';

const DEFAULT_FILTER = {
  sortBy: SORT_OPTIONS_MAP.name,
  sortOrder: 'asc',
};

class ProgramSpecificForms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      programSpecificFormsTable: [],
      isLoading: true,
      filters: {
        ...DEFAULT_FILTER,
      },
    };
    this.fetchData = this.fetchData.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.getBrockerFormsData = this.getBrockerFormsData.bind(this);
  }

  componentDidMount() {
    this.getBrockerFormsData('program');
  }

  getBrockerFormsData(query) {
    const { programSpecificFormsTable, filters } = this.state;
    return getBrockerForms(query, filters).then(response => {
      const { items = [] } = response;
      this.setState({
        programSpecificFormsTable: programSpecificFormsTable.concat(items),
        isLoading: false,
      });
    });
  }

  handleSort(sortBy, sortOrder) {
    const { filters } = this.state;
    this.setState(
      {
        isLoading: true,
        filters: {
          ...filters,
          sortBy,
          sortOrder,
        },
      },
      this.fetchData
    );
  }

  fetchData() {
    const { filters } = this.state;
    return getBrockerForms('program', filters).then(response => {
      const { items = [] } = response;
      this.setState({
        isLoading: false,
        programSpecificFormsTable: items,
      });
    });
  }

  render() {
    const { filters, isLoading, programSpecificFormsTable } = this.state;
    const { sortBy, sortOrder } = filters;
    if (!isLoading && programSpecificFormsTable.length === 0) {
      return (
        <section>
          <NoDataAvailable />
        </section>
      );
    }
    return (
      <React.Fragment>
        <Table
          isLoading={isLoading}
          values={ProgramSpecificFormAdapter(programSpecificFormsTable)}
          tableId="BrokerForms__Program-Specific-Forms"
          tableCustomCss="BrokerForms__table table-component table is-striped is-narrow is-fullwidth"
          theadCustomCss="BrokerForms__table-titles"
          tbodyCustomCss="BrokerForms__table-body"
          tableWrapperClass="BrokerForms__table"
          tableTitles={createProgramSpecificTitles(
            sortBy,
            sortOrder,
            this.handleSort
          )}
        />
        {isLoading ? null : this.LoadingWithState}
      </React.Fragment>
    );
  }
}

export default ProgramSpecificForms;
