import React from 'react';
import propTypes from 'prop-types';
import {NoDataAvailable, TabularData, withLoadingText } from '@jpm-adr/pattern-library';
import {capitalize, dateNoTime, removeHTTP } from '../../utils/format';
import {executeDataRequest} from '../../utils/dataRequestMethods';
import {withApiRequest} from '../../utils/apiWithHOC';
import {withErrorBoundary} from '../../utils/errorBoundary';

import '../../styles/components/ProgramDetail.scss';

const ProgramDetail = props => {

  const {isLoading,  data, xid } = props;
  const { issuer, cusip, ticker, isin, depositary,
    sector, drsTraded, sponsorship, website, ratio,
    underlying, underlyingIsin, custodian, country, underlyingTraded,
    inceptionDate }= data || {};
  const listLeft = [
    { id: 1, title: "Issuer", value: issuer },
    { id: 2, title: "CUSIP", value: cusip },
    { id: 3, title: "DR Ticker", value: ticker },
    { id: 4, title: "DR ISIN", value: isin },
    { id: 5, title: "Depositary", value: depositary },
    { id: 6, title: "Sector", value: sector },
    { id: 7, title: "DRs Traded", value: drsTraded },
    { id: 8, title: "Sponsorship", value: sponsorship },
  ];
  const listRight = [
    { id: 1, title: "Company Website", value: website ? <a href={website} target='_blank' rel='noopener noreferrer'>{removeHTTP(website)}</a> : "--" },
    { id: 2, title: "Ratio (DR/underlying)", value: ratio },
    { id: 3, title: "Underlying Ticker", value: underlying },
    { id: 4, title: "Underlying ISIN", value: underlyingIsin },
    { id: 5, title: "Custodian", value: custodian },
    { id: 6, title: "Country", value: country },
    { id: 7, title: "Underlying Traded", value: underlyingTraded ? capitalize(underlyingTraded) : "--" },
    { id: 8, title: "DR Inception Date", value: inceptionDate ? dateNoTime(inceptionDate) : "--" },
  ];

  const Content =()=>{
    if (!data || Object.keys(data).length === 0) {
      return (
        <NoDataAvailable
          messageTitle="No data available"
          customMessageTitleClass="Data__Not__Found-Title"
          customWrapperCSS="No__Data__Available-Custom-Wrapper"
          message="There is no data available for this specific program."
          messageStyles="Data__Not__Found-Message"
          wrapperClass="Data__Not__Found Program__Detail"
        />
      )
    }

    return (
      <React.Fragment>
        <div className="column container-tabulardata-padding column-margin">
          <TabularData data={listLeft} id='details-1' />
        </div>
        <div className="column container-tabulardata-padding">
          <TabularData data={listRight} id='details-2' />
        </div>
      </React.Fragment>
      )
  }

  const ContentWithLoadingData = withLoadingText(Content)


  return (
    <section className="columns is-multiline marginless program-detail-section ">
      <h3 className="program-detail-title program-title-margin is-paddingless column is-12">Program Details</h3>
      <ContentWithLoadingData isLoading={isLoading && xid} customStyles={{height:"310px"}} />
    </section>
  );
}

ProgramDetail.defaultProps = {
  isLoading:true,
  data: {
    issuer: "--",
    cusip: "--",
    ticker: "--",
    isin: "--",
    depositary: "--",
    sector: "--",
    drsTraded: "--",
    sponsorship: "--",
    website: "--",
    ratio: "--",
    underlying: "--",
    underlyingIsin: "--",
    custodian: "--",
    country: "--",
    underlyingTraded: "--",
    inceptionDate: "--"
  }
  ,xid:null,
}

ProgramDetail.propTypes = {
  isLoading: propTypes.bool,
  data: propTypes.shape({
    issuer: propTypes.oneOfType([propTypes.string, propTypes.number]),
    cusip: propTypes.oneOfType([propTypes.string, propTypes.number]),
    ticker: propTypes.oneOfType([propTypes.string, propTypes.number]),
    isin: propTypes.oneOfType([propTypes.string, propTypes.number]),
    depositary: propTypes.oneOfType([propTypes.string, propTypes.number]),
    sector: propTypes.oneOfType([propTypes.string, propTypes.number]),
    drsTraded: propTypes.oneOfType([propTypes.string, propTypes.number]),
    sponsorship: propTypes.oneOfType([propTypes.string, propTypes.number]),
    website: propTypes.oneOfType([propTypes.string, propTypes.number]),
    ratio: propTypes.oneOfType([propTypes.string, propTypes.number]),
    underlying: propTypes.oneOfType([propTypes.string, propTypes.number]),
    underlyingIsin: propTypes.oneOfType([propTypes.string, propTypes.number]),
    custodian: propTypes.oneOfType([propTypes.string, propTypes.number]),
    country: propTypes.oneOfType([propTypes.string, propTypes.number]),
    underlyingTraded: propTypes.oneOfType([propTypes.string, propTypes.number]),
    inceptionDate: propTypes.oneOfType([propTypes.string, propTypes.number])
  }),
  xid: propTypes.string,
}

export default withErrorBoundary(
  withApiRequest(ProgramDetail,
    ({xid})=>(xid)? executeDataRequest(`dr/programDetails/${xid}`, 'GET') : null));
