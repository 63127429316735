import React from 'react';
import propTypes from 'prop-types';

const Contact = props => {
  const {
    data: { name, email, phone, fax },
    hasPhoneAndFax,
    ariaLabel = 'custodian contact',
  } = props;

  return (
    <div className="custodian-contact">
      <p className="custodian-contact-element mail">
        <a href={`mailto:${email}`} aria-label={ariaLabel}>
          <svg
            className="custodian-email-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              className="core-blue-icon"
              d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
          {name}
        </a>
      </p>
      {hasPhoneAndFax && (
        <p className="custodian-contact-element phone">
          Phone: {phone || '--'}
        </p>
      )}
      {hasPhoneAndFax && (
        <p className="custodian-contact-element fax">Fax: {fax || '--'}</p>
      )}
    </div>
  );
};

Contact.defaultProps = {
  data: {
    name: '--',
    email: '--',
    phone: '--',
    fax: '--',
  },
  hasPhoneAndFax: false,
  ariaLabel: 'custodian contact',
};

Contact.propTypes = {
  data: propTypes.shape({
    name: propTypes.string,
    email: propTypes.string,
    phone: propTypes.string,
    fax: propTypes.string,
  }),
  hasPhoneAndFax: propTypes.bool,
  ariaLabel: propTypes.string,
};

export default Contact;
