// Formater for tables
import {
  dateNoTime,
  lastYearOfCurrent,
  number,
  numberComma,
  numberCommaSigned,
  numberMagnitude,
  numberSignedColorIssuerServices,
  percent,
} from '../../../utils/format';

export const programDetailsParser = data => {
  const fields = {
    'Ratio (DR/Underlying)': 'ratio',
    CUSIP: 'cusip',
    'DR Ticker': 'drTicker',
    'DRs Traded': 'drsTraded',
    'DR Inception Date': 'drInceptionDate',
    Country: 'country',
    Sector: 'sector',
    Custodian: 'custodian',
    SEDOL: 'sedol',
    'DR ISIN': 'drIsin',
    Type: 'type',
    Sponsorship: 'sponsorship',
    [`Annual Div/Admin Fee ${lastYearOfCurrent()}`]: 'annualFees',
  };

  return Object.keys(fields).map((key, id) => {
    let value = data[fields[key]] || '--';
    // Verify if the field is a date
    if (fields[key] === 'drInceptionDate') {
      value = dateNoTime(value);
    }

    if (fields[key] === 'annualFees') {
      value = `${number(value, 7)} USD`;
    }

    return {
      className: 'color__red',
      id,
      title: key,
      value,
    };
  });
};

export const monthlyParser = data => {
  const fields = {
    'DRs Outstanding': 'drsOutstanding',
    'DRs Issued': 'drsIssued',
    'DRs Cancelled': 'drsCancelled',
    'Net Change': 'netChange',
    'Total Activity': 'totalActivity',
    'Trading Volume': 'tradingVolume',
    'Trading Value': 'tradingValue',
    'Last Price  (Month End)': 'lastPrice',
    'DR Market Value  (Month End)': 'drMarketValue',
    'Company Market Cap  (Month End)': 'totalMarketCap',
    'DR % of Market Cap  (Month End)': 'drPercentMarketCap',
    'Registered Holders': 'registeredHolders',
    'Number of DRs Held by Reg. Holders': 'drsHeldByRegHolders',
  };

  return Object.keys(fields).map((key, id) => {
    // Verify netChange value to apply color by rules
    let className = '';
    let value = data[fields[key]];

    switch (fields[key]) {
      case 'netChange':
        value = numberCommaSigned(value, 0);
        className = numberSignedColorIssuerServices(value, 2);
        break;

      case 'drMarketValue':
      case 'lastPrice':
      case 'tradingValue':
      case 'totalMarketCap':
        if (!value) {
          value = `${numberMagnitude(value, 2)}`;
          break;
        }
        value = `${numberMagnitude(value, 2)} USD`;
        break;
      case 'drPercentMarketCap':
        value = percent(value / 100, 1);
        break;
      default:
        value = numberComma(value, 0);
        break;
    }

    return {
      className,
      id,
      title: key,
      value,
    };
  });
};

const tableListParser = (unparsedData = [], config = {}) => {
  const { columns, rows, mappers = [] } = config;
  const parsedRows = [];
  const arraySize = unparsedData.length;
  // Map data from a mapper preconfigurated
  // "mappers"" should be an array of callbacks that returns the same array structure
  let dataToParse = unparsedData;
  if (mappers.length > 0) {
    mappers.filter(mapper => {
      dataToParse = mapper(dataToParse);
      return false;
    });
  }
  dataToParse.map((item, index) => {
    parsedRows.push(
      rows.map(configItem => {
        const { className = '' } = configItem;
        const { tdStyle = '' } = item;
        return {
          className: `${className} ${tdStyle}`,
          text: configItem.text(item, index, arraySize, unparsedData),
        };
      })
    );
  });

  return { columns, rows: parsedRows };
};

export const tableLoaderParser = tableListParser;
