export default [
  {
    label: 'EXCHANGE',
    name: 'exchange',
    type: 'select',
    filter: 'exchanges',
    defaultValue: '',
    options: [],
    placeholder: 'All Exchanges',
    isMulti: true,
    showClearLabel: true,
  },
  {
    label: 'REGION',
    name: 'region',
    type: 'select',
    filter: 'regions',
    defaultValue: '',
    options: [],
    placeholder: 'All Regions',
    isMulti: true,
    showClearLabel: true,
  },
  {
    label: 'COUNTRY',
    name: 'country',
    type: 'select',
    filter: 'countries',
    defaultValue: '',
    options: [],
    placeholder: 'All Countries',
    isMulti: true,
    showClearLabel: true,
  },
  {
    label: 'SECTOR',
    name: 'sector',
    type: 'select',
    filter: 'sectors',
    defaultValue: '',
    options: [],
    placeholder: 'All Sectors',
    isMulti: true,
    showClearLabel: true,
  },
  {
    label: 'DEPOSITARY',
    name: 'depositary',
    type: 'select',
    filter: 'depositaries',
    defaultValue: '',
    options: [],
    placeholder: 'All Depositaries',
    isMulti: true,
    showClearLabel: true,
  },
  {
    label: 'Sponsorship',
    name: 'sponsorship',
    type: 'select',
    filter: 'sponsorship',
    defaultValue: '',
    options: [],
    placeholder: 'All Sponsored / Unsponsored',
    isMulti: false,
    showClearLabel: true,
  },
  {
    label: 'LISTINGS',
    name: 'listings',
    type: 'select',
    filter: 'listings',
    defaultValue: '',
    options: [],
    placeholder: 'All Listings',
    isMulti: false,
    showClearLabel: true,
  },
  {
    name: 'q',
    defaultValue: ''
  }
];
