import React from 'react';
import { getYear } from '../../utils/api';

class Copyright extends React.Component {
  constructor() {
    super();
    this.state = {
      year: null,
    };
    this.setYear = this.setYear.bind(this);
    this.getYearFromService = this.getYearFromService.bind(this);
    this.getYearFromService();
  }
  getYearFromService() {
    getYear()
      .then(this.setYear)
      .catch(this.setYear);
  }
  setYear(response = {}) {
    const { year = '2019' } = response;
    this.setState({ year });
  }
  render() {
    const { year } = this.state;
    if (!year) {
      return <p />;
    }
    return (
      <p className="copyright">
        &copy;{year} JPMorgan Chase & Co. All rights reserved. Use of this site
        implies
        <br />
        having read and agreed to our Terms and Conditions.
      </p>
    );
  }
}

export default Copyright;
