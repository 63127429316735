import PropTypes from 'prop-types';
import React from 'react';

import ManageRecipients from './ManageRecipients';
import NewAlertsComponent from '../../components/alerts/NewAlerts';
import SubSectionsComponent from '../../components/subSections/SubSections';

import HistoryTable from './HistoryTable';
import { subSections } from '../../utils/constants';

class Alerts extends React.Component {
  constructor() {
    super();
    this.sections = subSections['alerts'];
    this.state = {
      hidrate: false,
    };
    this.getSetcionsConfig = this.getSetcionsConfig.bind(this);
    this.hidrateChilds = this.hidrateChilds.bind(this);
  }

  getSetcionsConfig() {
    const { sections, props, state: { hidrate }, hidrateChilds } = this;
    sections.subNavigations = sections.subNavigations.map(section => {
      if (section.id === 'manageRecipients') {
        section.element = <ManageRecipients {...props} {...{ hidrate }} />;
      }

      if (section.id === 'sentAlertsHistory') {
        section.element = <HistoryTable {...props} {...{ hidrate }} />;
      }

      return section;
    });

    const subSectionsComponentConfig = {
      config: { sections },
    };

    subSectionsComponentConfig.config.rightElements = [
      function newAlerts() {
        return (
          <NewAlertsComponent
            {...props}
            onSave={() => { hidrateChilds() }}
          />
        );
      },
    ];

    return subSectionsComponentConfig;
  }

  hidrateChilds() {
    const { hidrateChilds } = this.state;
    this.setState({ hidrateChilds: !hidrateChilds });
  }

  render() {
    return (
      <div className="alerts alerts__content">
        <SubSectionsComponent {...this.getSetcionsConfig()} />
      </div>
    );
  }
}

Alerts.propTypes = {
  xid: PropTypes.string.isRequired,
  headerInfo: PropTypes.objectOf.isRequired,
};

export default Alerts;
