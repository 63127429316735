import React from 'react';
import propTypes from 'prop-types';
import { NoDataAvailable, TabularData, withLoadingText } from '@jpm-adr/pattern-library';
import { executeDataRequest } from '../../utils/dataRequestMethods';
import { withApiRequest } from '../../utils/apiWithHOC';
import { withErrorBoundary } from '../../utils/errorBoundary';

import * as Format from '../../utils/format'; //eslint-disable-line sort-imports-es6-autofix/sort-imports-es6
import { TEXT_NULL_VALUE } from '../../utils/constants';

import '../../styles/components/QuoteSection.scss';

const precision = 2;

const getLeft = (data = {}) => {
    const {
        pricingCurrency,
        underlyingOutstanding,
        companyMarketCap,
        largestHolder,
        largestPosition,
    } = data || {};

// It is assumed that if a money value is returned without a currency, then its value is in 'USD'.

  return [
    {
        id: 1,
        title: 'Underlying Shares Outstanding',
        value: Format.numberComma(underlyingOutstanding, 0),
    },
    {
        id: 2,
        title: 'Company Market Cap',
        value:
            Format.numberMagnitude(companyMarketCap, precision) +
            Format.showSymbol(companyMarketCap, ` ${pricingCurrency ? pricingCurrency:'USD'}`),
    },
    {
      id: 3,
      title: 'Largest DR Holder',
      value: largestHolder || TEXT_NULL_VALUE,
    },
    {
      id: 4,
      title: 'Largest DR Position',
      value: largestPosition
        ? Format.numberComma(largestPosition, 0)
        : '--',
    },
  ];
};
const getRight = (data = {}) => {
    const {
        dividendFeePerYear,
        dividendPaidPerYear,
        dividendYield,
        priorYear
    } = data || {};
    return [
        {
          id: 1,
          title: 'Annual Dividend Yield',
          value: dividendYield
            ? Format.percent(dividendYield / 100, 2)
            : dividendYield,
        },
        {
          id: 2,
          title: `Annual Dividend ${ priorYear || Format.feesYear}`,
          value: dividendPaidPerYear
            ? Format.number(dividendPaidPerYear, 7) + ' USD'
            : '--',
        },
        {
          id: 3,
          title:`Annual Div/Admin Fee ${ priorYear || Format.feesYear}`,
          value: dividendFeePerYear
            ? Format.number(dividendFeePerYear, 7) + ' USD'
            : '--',
        },
    ];
};

const QuoteSection = props => {
    const {
        cusip,
        data = {},
        isLoading,
        quoteDataIsEmpty
    } = props;

    const {
        lastValue,
        quoteCurrency,
        lastUpdate,
        todayChange,
        todayChangePercent,
        volume,
        daily30DAvg,
        high52Week,
        low52Week,
        yearToDateChange,
        yearToDateChangePercent,
    } = data || {};

    // ADR-2789 - if lastUpdate does not have a timestamp, then we show End of Day instead of time
    const lastUpdateDate = lastUpdate && lastUpdate.indexOf("T00:00:00.000") > 0
        ? Format.dateAsOfQuoteEndOfDay(lastUpdate)
        : Format.dateAsOfQuoteET(lastUpdate);

    const quoteDetail = quoteDataIsEmpty
      ? (
        <NoDataAvailable
          customMessageTitleClass="Data__Not__Found-Title"
          customWrapperCSS="No__Data__Available-Custom-Wrapper"
          message="Either something went wrong or the data doesn’t exist."
          messageStyles="Data__Not__Found-Message"
          messageTitle="Data Not Found error"
          wrapperClass="Data__Not__Found"
        />
      )
      : (
        <div
          className="level is-mobile columns is-multiline quote-detail-level-root"
          role="list"
        >
          <div
            className="level-item quote-detail-level-summary column is-12-mobile has-text-left"
            role="listitem"
          >
            <div className="has-text-centered-mobile">
              <p>
                {' '}
                <span className="quote-detail-number-1-a">
                  {Format.number(lastValue, 2)}
                </span>{' '}
                <span className="quote-detail-number-1-b">
                  {Format.showSymbol(lastValue, `${quoteCurrency ? quoteCurrency: 'USD'}`)}
                </span>
              </p>
              <p className="quote-detail-text margin-bottom">
                {lastUpdateDate}
              </p>
            </div>
          </div>
          <div
            className="level-item quote-detail-level-summary  has-text-right"
            role="listitem"
          >
            <div className="has-text-left-mobile">
              <p className={Format.numberSignedColor(todayChange, 2)}>
                <span>{Format.numberSigned(todayChange, 2)}</span>{' '}
                <span>
                  ({Format.number(todayChangePercent, 2)}
                  {Format.showSymbol(todayChangePercent, '%')})
                </span>
              </p>
              <p className="quote-detail-text">Today&apos;s Change</p>
            </div>
          </div>
          <div
            role="listitem"
            className="level-item quote-detail-level-summary has-text-right"
          >
            <div className="has-text-left-mobile">
              <p className="quote-detail-number-black">
                {' '}
                <span>{Format.numberMagnitude(volume, 2)}</span>
                {' / '}
                <span>{Format.numberMagnitude(daily30DAvg, 2)}</span>
              </p>
              <p className="quote-detail-text">
                {' '}
                <span>Volume</span> / <span>30 Day Daily Avg.</span>{' '}
              </p>
            </div>
          </div>
          <div
            className="level-item quote-detail-level-summary has-text-right"
            role="listitem"
          >
            <div className="has-text-left-mobile">
              <p className="quote-detail-number-black">
                {' '}
                <span>{Format.number(high52Week, 2)}</span>
                {' / '}
                <span>{Format.number(low52Week, 2)}</span>
              </p>
              <p className="quote-detail-text">
                {' '}
                <span>52 Week High </span> / <span>Low</span>{' '}
              </p>
            </div>
          </div>
          <div
            className="level-item quote-detail-level-summary has-text-right"
            role="listitem"
          >
            <div className="has-text-left-mobile is-no-wrapping">
              <p className={Format.numberSignedColor(yearToDateChange, 2)}>
                {' '}
                <span>{Format.numberSigned(yearToDateChange, 2)}</span>{' '}
                <span>
                  ({Format.numberSigned(yearToDateChangePercent, 2)}
                  {Format.showSymbol(yearToDateChangePercent, '%')})
                </span>
              </p>
              <p className="quote-detail-text">
                {' '}
                <span>Year-to-date Change</span>{' '}
              </p>
            </div>
          </div>
        </div>
      );

  const listLeft = getLeft(data);
  const listRight = getRight(data);

  const Content = () => (
    <React.Fragment>
      <div className="column is-12 quote-detail-summary">
        {quoteDetail}
      </div>
      <div className="column container-tabulardata-padding column-margin ">
        {' '}
        <TabularData data={listLeft} id='quotes-1' />{' '}
      </div>
      <div className="column container-tabulardata-padding">
        {' '}
        <TabularData data={listRight} id='quotes-2' />{' '}
      </div>
    </React.Fragment>
  );

  const ContentWithLoadingData = withLoadingText(Content);

  return (
    <section className="columns is-multiline marginless quote-section">
      <ContentWithLoadingData
        isLoading={isLoading && cusip}
        customStyles={{ height: '400px', marginBottom: '20px' }}
      />
    </section>
  );
};

QuoteSection.defaultProps = {
  cusip: null,
  data: {
    lastValue: '--',
    lastUpdate: '--',
    todayChange: '--',
    todayChangePercent: '--',
    volume: '--',
    daily30DAvg: '--',
    high52Week: '--',
    low52Week: '--',
    yearToDateChange: '--',
    yearToDateChangePercent: '--',
    marketCap: '--',
    underlyingOutstanding: '--',
    globalMarketCap: '--',
    largestHolder: '--',
    largestPosition: '--',
    dividendYield: '--',
    dividendPaidPerYear: '--',
    dividendFeePerYear: '--',
  },
  isLoading: true,
  quoteDataIsEmpty: null
};

QuoteSection.propTypes = {
  cusip: propTypes.string,
  data: propTypes.shape({
    lastValue: propTypes.oneOfType([propTypes.string, propTypes.number]),
    lastUpdate: propTypes.oneOfType([propTypes.string, propTypes.number]),
    todayChange: propTypes.oneOfType([propTypes.string, propTypes.number]),
    todayChangePercent: propTypes.oneOfType([
      propTypes.string,
      propTypes.number,
    ]),
    volume: propTypes.oneOfType([propTypes.string, propTypes.number]),
    daily30DAvg: propTypes.oneOfType([propTypes.string, propTypes.number]),
    high52Week: propTypes.oneOfType([propTypes.string, propTypes.number]),
    low52Week: propTypes.oneOfType([propTypes.string, propTypes.number]),
    yearToDateChange: propTypes.oneOfType([propTypes.string, propTypes.number]),
    yearToDateChangePercent: propTypes.oneOfType([
      propTypes.string,
      propTypes.number,
    ]),
    marketCap: propTypes.oneOfType([propTypes.string, propTypes.number]),
    underlyingOutstanding: propTypes.oneOfType([
      propTypes.string,
      propTypes.number,
    ]),
    globalMarketCap: propTypes.oneOfType([propTypes.string, propTypes.number]),
    largestHolder: propTypes.oneOfType([propTypes.string, propTypes.number]),
    largestPosition: propTypes.oneOfType([propTypes.string, propTypes.number]),
    dividendYield: propTypes.oneOfType([propTypes.string, propTypes.number]),
    dividendPaidPerYear: propTypes.oneOfType([
      propTypes.string,
      propTypes.number,
    ]),
    dividendFeePerYear: propTypes.oneOfType([
      propTypes.string,
      propTypes.number,
    ]),
  }),
  isLoading: propTypes.bool,
  quoteDataIsEmpty: propTypes.bool
};

export default withErrorBoundary(
  withApiRequest(
    QuoteSection,
    ({ cusip }) =>
      cusip ? executeDataRequest(`dr/quotes/${cusip}`, 'GET') : null
  )
);
