import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { prefix, subSections } from '../../utils/constants';

import '../../../styles/IssuerServices/components/NavigationBar.scss';

const navigationPaths = Object.keys(subSections);

class NavigationBar extends React.Component {
  constructor() {
    super();
    this.prefix = prefix;
    this.navigationPaths = navigationPaths;
  }

  render() {
    const { match } = this.props;
    const { params = {} } = match;
    const { cusip } = params;

    return (
      <nav className='issuer-services__navigation'>
        <ul className='issuer-services__navigation-content'>
          {this.navigationPaths.map((sectionItem, key) => (
            <NavLink
              aria-current={false}
              key={sectionItem}
              activeClassName='active'
              aria-label={sectionItem}
              id={`link-key-${key}`}
              to={`${this.prefix}/${sectionItem}/${cusip}`}
            >
              <span>{sectionItem}</span>
            </NavLink>
          ))}
        </ul>
      </nav>
    );
  }
}

NavigationBar.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      cusip: PropTypes.string
    })
  }).isRequired,
};

export default NavigationBar;
