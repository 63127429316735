import { URLbyDomain } from './constants';

export function mapObject(obj, field, customCSS) {
  let newObj = {};
  if (field in obj) {
    newObj.data = String(obj[field]);
    newObj.customCSSClass = customCSS;
  } else {
    newObj.data = '--';
    newObj.customCSSClass = customCSS + 'has-text-centered';
  }
  return newObj;
}

export function mapObjectHasLink(obj, field, customCSS) {
  let newObj = {};
  if (field in obj) {
    newObj.data = String(obj[field]);
    newObj.hasLink = true;
    newObj.contentLink = '/drprofile/' + obj.cusip;
    newObj.customCSSClass = customCSS;
  }
  return newObj;
}

export function mapObjectHasPDF(obj, field, customCSS) {
  let newObj = {};
  if (field in obj) {
    const PDFData = `${obj[field]}.pdf`;
    const url = URLbyDomain + '/cms/document?cmsId=';
    newObj.hasComponents = true;
    newObj.hasNotices = true;
    newObj.pdf = PDFData || '';
    newObj.xbrl = '';
    newObj.pdfLink = url + obj[field];
    newObj.customCSS = customCSS || '';
  } else {
    newObj.data = '--';
    newObj.customCSSClass = customCSS + 'has-text-centered';
  }
  return newObj;
}

export function mapObjectHasPDFDM(obj, field, customCSS) {
  let newObj = {};
  if (field in obj) {
    const PDFData = `${obj[field]}.pdf`;
    const url = `${URLbyDomain}/dm/document?docTag=`;
    newObj.hasComponents = true;
    newObj.hasNotices = true;
    newObj.pdf = PDFData || '';
    newObj.xbrl = '';
    newObj.pdfLink = `${url}${obj[field]}&feedId=1138&filename=${obj[field]}`;
    newObj.customCSS = customCSS || '';
  } else {
    newObj.data = '--';
    newObj.customCSSClass = customCSS + 'has-text-centered';
  }
  return newObj;
}

export function mapObjectHasFiles(obj, field) {
  let value = {};
  const sequenceNo = obj.sequenceNo;
  const noticesData = obj[field];
  const url = `${URLbyDomain}/cms/document?cmsId=`;
  const sequenceNoParam = sequenceNo ? `&sequenceNo=${sequenceNo}` : '';
  value.hasComponents = true;
  value.hasNotices = true;
  value.pdf = noticesData.pdf || '';
  value.xbrl = noticesData.xbrl || '';
  value.pdfLink = `${url}${value.pdf}${sequenceNoParam}`;
  value.xbrlLink = `${url}${value.xbrl}${sequenceNoParam}`;
  value.emptyValue = ' ';
  value.customCSSClass = obj.customCSSClass;
  return value;
}
