import React, { Component } from 'react';

import PropTypes from 'prop-types';
import TableViewPageContainer from '../../components/common/TableViewPage/TableViewPageContainer';
import exportData from '../../utils/exportData';
import {  DECIMAL_DIGITS, URLbyDomain } from '../../utils/constants';
import {
  SORT_OPTIONS_MAP,
  adapter,
  createTitles,
} from './feeBrowserTableAdapter';
import { createUrlQuery, reduceToValues } from '../../utils/urlMethods';
import { getExportData, getFeesBrowser } from '../../utils/api';
import { number } from '../../utils/format';

const fields = [
  {
    name: 'q',
    defaultValue: '',
  },
];


export default class FeeBrowser extends Component {
  constructor() {
    super();

    this.tableRef = React.createRef();
    this.fetchFilteredData = this.fetchFilteredData.bind(this);
    this.getExportUrl = this.getExportUrl.bind(this);
    this.extraExportFunction = null;
    this.setExtraExportFunction = this.setExtraExportFunction.bind(this);
  }

  setExtraExportFunction (extraFunction) {
    this.extraExportFunction = extraFunction;
  }

  getExportUrl(params) {
    const query = createUrlQuery({
      ...reduceToValues(params),
      offset: 0,
      limit: params.limit,
    });
    return `${URLbyDomain}/fees/exportTable?${query}`;
  }

  handleExportClickOption (isFullDownload = false) {
    const infoFromChild = this.extraExportFunction && this.extraExportFunction();
    const { data, filtersValues } = infoFromChild;
    delete infoFromChild.data;
    delete infoFromChild.filtersValues;

    const args = {
      ...reduceToValues(filtersValues),
      ...infoFromChild
    };

    args.limit = isFullDownload ? 25000 : data.length;

    return getExportData('fees', args).then(response => {
        const fileName = 'fees.xlsx';
        exportData(fileName, response.data);
    });
  }

  fetchFilteredData(filters) {
    return getFeesBrowser(filters).then(response => {
      const { items = [], pagination } = response;
      const parsedItems = items.map(item => ({
        ...item,
        annualDividend: number(item.annualDividend, DECIMAL_DIGITS),
        annualAdminFee: number(item.annualAdminFee, DECIMAL_DIGITS),
        totalAdminFee: number(item.totalAdminFee, DECIMAL_DIGITS)
      }));

      return {
        data: adapter(parsedItems),
        pagination,
      };
    });
  }

  render() {
    const { customButton } = this.props
    return (
      <TableViewPageContainer
        ref={this.tableRef}
        classModifier="fees-table-view"
        customExportButton={customButton}
        defaultSortBy={SORT_OPTIONS_MAP.name}
        defaultSortOrder="asc"
        extraExportFunction={this.setExtraExportFunction}
        fields={fields}
        getExportUrl={this.getExportUrl}
        heading="JPM DR Programs With Fees"
        headerColumns={createTitles}
        isDownloadingExcel={false}
        onFetchData={this.fetchFilteredData}
        searchInputPlaceholder="Filter Results (Name, Ticker, or CUSIP)"
      />
    );
  }
}

FeeBrowser.defaultProps = {
  customButton: null
}

FeeBrowser.propTypes = {
  customButton: PropTypes.func
};
