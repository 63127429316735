import PropTypes from 'prop-types';
import React from 'react';
import {
  FileButtons,
  LoadingText,
  NoDataAvailable,
} from '@jpm-adr/pattern-library';

import { dateFullShortMonthNoTime } from '../../../utils/format';
import { downloadFile, getLatestsReports } from '../../utils/apiCalls';

import '../../../styles/IssuerServices/components/Reports.scss';

class Reports extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      error: false,
      xidCached: '',
      reports: [],
    };
    this.getLatestReports = this.getLatestReports.bind(this);
    this.showReports = this.showReports.bind(this);
  }

  componentDidMount() {
    const { xid } = this.props;
    xid && this.getLatestReports(xid);
  }

  componentDidUpdate() {
    const { xid } = this.props;
    this.getLatestReports(xid);
  }

  getUniqueKey() {
    return Date.now() | (0 * Math.random() * (5 - 1) + 1);
  }

  getLatestReports(xid) {
    const { error, xidCached } = this.state;
    // Check if we already have rendered xid
    if (xidCached === xid || error) return;

    getLatestsReports(xid)
      .then(reports => {
        let reportsByName = [];
        
        const monthly = reports.find(report => report.id === 'monthly');

        if (monthly) {
          reportsByName.push(
            {
              buttonsInfo: {
                labels: {
                  inactive: !monthly.date,
                  label: monthly.title || 'Monthly Board Report',
                  subLabel: !monthly.date
                    ? 'Not Available'
                    : dateFullShortMonthNoTime(monthly.date),
                },
                pdf: !!monthly.date,
                pdfLink: `/dm/document?feedId=${monthly.feedId}&docTag=${
                  monthly.documentKey
                }`,
                newFetch: downloadFile,
              },
            }
          )
        }

        return this.setState({
          isLoading: false,
          reports: reportsByName,
          xidCached: xid,
        });
      })
      .catch(error => this.setState({ error }));
  }

  showError() {
    return (
      <NoDataAvailable
        messageTitle="Data not found"
        customMessageTitleClass="Data__Not__Found-Title"
        customWrapperCSS="No__Data__Available-Custom-Wrapper"
        message="Either something went wrong or the data doesn't exist."
        messageStyles="Data__Not__Found-Message"
        wrapperClass="Data__Not__Found"
      />
    );
  }

  showNoData() {
    return (
      <NoDataAvailable
        messageTitle="No data available"
        customMessageTitleClass="Data__Not__Found-Title"
        customWrapperCSS="No__Data__Available-Custom-Wrapper"
        message="There is no data available for this specific program."
        messageStyles="Data__Not__Found-Message"
        wrapperClass="Data__Not__Found"
      />
    );
  }

  showReports(errorFromProps) {
    let { reports = [], isLoading, error } = this.state;

    if (error || errorFromProps) {
      return this.showError();
    }

    if (isLoading) {
      return (
        <LoadingText aria-live="polite" aria-busy="true" withGreyBackground />
      );
    }

    if (reports.length === 0) {
      return this.showNoData();
    }

    return (
      <React.Fragment>
        {reports.map(report => (
          <FileButtons key={this.getUniqueKey()} {...report} />
        ))}
      </React.Fragment>
    );
  }

  render() {
    const { error } = this.props;
    return (
      <div className="reports">
        <span className="reports__title">LATEST REPORTS</span>
        <div className="reports__links">{this.showReports(error)}</div>
      </div>
    );
  }
}

Reports.defaultProps = {
  xid: null,
  error: false,
};

Reports.propTypes = {
  match: PropTypes.shape({}).isRequired,
  xid: PropTypes.number,
  error: PropTypes.bool,
};

export default Reports;
