import PropTypes from 'prop-types';
import React from 'react';

import FinalAnnouncementTable from './FinalAnnouncementTable';
import FullHistoryTable from './FullHistoryTable';
import SubSectionsComponent from '../../components/subSections/SubSections';

import { subSections } from '../../utils/constants';

import '../../../styles/IssuerServices/pages/Dividends.scss';

const sections = subSections['dividends'];

const Dividends = props => {
  sections.subNavigations = sections.subNavigations.map(section => {
    if (section.id === 'fullHistory') {
      section.element = <FullHistoryTable {...props} />;
    }

    if (section.id === 'finalAnnouncement') {
      section.element = <FinalAnnouncementTable {...props} />;
    }

    return section;
  });

  const subSectionsComponentConfig = {
    config: { sections }
  };

  return (
    <div className="dividends dividends__content">
      <SubSectionsComponent {...subSectionsComponentConfig} />
    </div>
  )
}

Dividends.propTypes = {
  cusip: PropTypes.string.isRequired,
  match: PropTypes.shape({}).isRequired,
};

export default Dividends;
