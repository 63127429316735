import {
  mapObject,
  mapObjectHasPDFDM,
} from '../../utils/adapterHelperFunctions';

export default function adapter(genericForms) {
  return genericForms.map(obj => {
    let forms = mapObject(obj, 'title', 'forms ellipsis regular'),
      program = mapObject(obj, 'program', 'BrokerInformation__program-info'),
      cusip = mapObject(
        obj,
        'cusip',
        'BrokerInformation__cusip-info is-hidden-touch'
      ),
      ticker = mapObject(
        obj,
        'ticker',
        'BrokerInformation__ticker-info is-hidden-touch'
      ),
      country = mapObject(
        obj,
        'country',
        'BrokerInformation__country-info is-hidden-touch'
      ),
      qibWithdrawal = mapObjectHasPDFDM(
        obj,
        'qibWithdrawal',
        'BrokerInformation__country-info'
      ),
      qibDeposit = mapObjectHasPDFDM(
        obj,
        'qibDeposit',
        'BrokerInformation__qibDeposit-info'
      ),
      affiliateClause = mapObjectHasPDFDM(
        obj,
        'affiliateClause',
        'BrokerInformation__affiliateClause-info'
      ),
      procedureLetter = mapObjectHasPDFDM(
        obj,
        'procedureLetter',
        'BrokerInformation__procedureLetter-info'
      ),
      exchangeForm = mapObjectHasPDFDM(
        obj,
        'exchangeForm',
        'BrokerInformation__exchangeForm-info'
      ),
      miscellaneous = mapObjectHasPDFDM(
        obj,
        'miscellaneous',
        'BrokerInformation__miscellaneous-info'
      );
    return [
      forms,
      program,
      cusip,
      ticker,
      country,
      qibWithdrawal,
      qibDeposit,
      affiliateClause,
      procedureLetter,
      exchangeForm,
      miscellaneous,
    ];
  });
}

export const POSSIBLE_SORT_VALUES = {
  asc: 'asc',
  desc: 'desc',
  unsorted: 'unsorted',
};

export const SORT_OPTIONS_MAP = {
  name: 'name',
};

export const getSortedDirectionForName = (
  name,
  currentlySortedName,
  sortedDirection
) => {
  if (name === currentlySortedName) {
    return POSSIBLE_SORT_VALUES[sortedDirection];
  }
  return POSSIBLE_SORT_VALUES.unsorted;
};
export const createProgramSpecificTitles = (
  currentlySortedName,
  sortedDirection,
  handleSort
) => {
  let index = 1;
  const createHeader = (title, customCSSClass, sortable, width, sortName) => {
    index++;
    return {
      id: index,
      title,
      customCSSClass,
      sortable,
      handleSort,
      sortName,
      sortDirection: getSortedDirectionForName(
        sortName,
        currentlySortedName,
        sortedDirection
      ),
      width,
      minwidth: width,
    };
  };

  return [
    createHeader(
      'Forms',
      'BrokerInformation__Title Forms',
      false,
      '300px',
      SORT_OPTIONS_MAP.name
    ),
    createHeader('Program', 'BrokerInformation__Title Program', false),
    createHeader(
      'CUSIP',
      'BrokerInformation__Title CUSIP is-hidden-touch',
      false
    ),
    createHeader(
      'Ticker',
      'BrokerInformation__Title Ticker is-hidden-touch',
      false
    ),
    createHeader(
      'Country',
      'BrokerInformation__Title Country is-hidden-touch',
      false
    ),
    createHeader(
      'Withdrawal',
      'BrokerInformation__Title QIB-Withdrawal',
      false
    ),
    createHeader('Deposit', 'BrokerInformation__Title Deposit', false),
    createHeader(
      'Affiliate Clause',
      'BrokerInformation__Title Affiliate',
      false
    ),
    createHeader(
      'Procedure Letter',
      'BrokerInformation__Title Procedure',
      false
    ),
    createHeader('Exchange Form', 'BrokerInformation__Title Exchange', false),
    createHeader(
      'Miscellaneous',
      'BrokerInformation__Title Miscellaneous',
      false
    ),
  ];
};
