import PropTypes from 'prop-types';
import React from 'react';

import { FileButtons } from '@jpm-adr/pattern-library';

import {
  dateFullShortMontFullhNoTime,
  dateMonthYearSpacingWithComma,
  formatDate,
  isBefore,
  substract,
  yearMonthDayAPI,
  yearMonthWithUnderscore,
  yearMonthWithoutSpaces,
} from '../../../utils/format';

import TableLoader from '../../components/tables/TableLoader';
import utils from '../../utils/utils';
import { downloadFile, getLibraryDailyReports } from '../../utils/apiCalls';

const { debounce } = utils;
const dateReg = /^\d{2}[.//]\d{2}[.//]\d{4}$/;

class DailyTable extends React.Component {
  constructor() {
    super();
    const today = yearMonthDayAPI(Date.now());
    const defaultFilterBy = {
      endDate: yearMonthDayAPI(today),
      startDate: yearMonthDayAPI(substract({ month: 1 })),
    };
    const defaultPaginationValues = {
      pageSize: 10,
      limit: 10,
      offset: 0,
    };
    this.state = {
      ...defaultPaginationValues,
      defaultPaginationValues,
      defaultFilterBy,
      filterBy: { ...defaultFilterBy },
      disabledLoading: false,
      disabledErrorBoundary: false,
      disabledEmptyHandler: false,
      wrongSelectedDate: false,
      hasDefaultValues: true,
      hasSelectedDate: false,
    };
    this.prepareConfigTable = this.prepareConfigTable.bind(this);
    this.prepareApiRequest = this.prepareApiRequest.bind(this);
    this.onLoadMore = this.onLoadMore.bind(this);
    this.getFocusableElement = this.getFocusableElement.bind(this);
    this.onChangeDatePicker = this.onChangeDatePicker.bind(this);
    this.resetDatePickerValues = this.resetDatePickerValues.bind(this);
    this.getFirstFocusableElement = this.getFirstFocusableElement.bind(this);
    this.focusableItem = null;
    this.firstFocusableItem = null;
  }

  onLoadMore() {
    return ({ currentPosition }) => {
      const { limit } = this.state;
      this.setState({
        disabledLoading: true,
        disabledErrorBoundary: true,
        disabledEmptyHandler: true,
        limit,
        offset: currentPosition,
      });
    };
  }

  onChangeDatePicker(selectedField, date, refs) {
    debounce(() => {
      const {
        defaultPaginationValues,
        defaultFilterBy: {
          startDate: defaultStartDate,
          endDate: defaultEndDate,
        },
      } = this.state;
      const { dayPickerStart, dayPickerEnd } = refs;
      let startDate = dayPickerStart.current.state.value;
      let endDate = dayPickerEnd.current.state.value;
      const parsedStartDate = formatDate(startDate, '', 'YYYY-MM-DD');
      const parsedEndDate = formatDate(endDate, '', 'YYYY-MM-DD');
      const isDefaultValueDate =
        parsedStartDate === defaultStartDate &&
        parsedEndDate === defaultEndDate;
      const isAnInvalidDate = dateReg.test(startDate) && dateReg.test(endDate);
      const updatedFilterBy = {
        startDate: parsedStartDate,
        endDate: parsedEndDate,
      };
      // Define new state
      const newState = {
        filterBy: updatedFilterBy,
        wrongSelectedDate: false,
        hasDefaultValues: false,
        hasSelectedDate: true,
      };
      // Was selected a default date
      if (isDefaultValueDate) {
        newState.hasDefaultValues = isDefaultValueDate;
      }
      // Valid format
      if (!isAnInvalidDate) {
        newState.hasDefaultValues = false;
        newState.wrongSelectedDate = true;
        newState.disabledLoading = false;
        newState.disabledErrorBoundary = false;
      }
      // Compare dates are correctly
      const isSameOrBefore = isBefore(
        updatedFilterBy.startDate,
        updatedFilterBy.endDate,
        'days'
      );
      if (!isSameOrBefore) {
        newState.hasDefaultValues = false;
        newState.wrongSelectedDate = true;
      }
      return this.setState({ ...newState, ...defaultPaginationValues });
    }, 200)();
  }

  getFocusableElement() {
    if (this.focusableItem) {
      this.focusableItem.focus();
    }
  }

  getFirstFocusableElement() {
    const { hasDefaultValues } = this.state;
    hasDefaultValues &&
      this.firstFocusableItem &&
      this.firstFocusableItem.focus();
  }

  resetDatePickerValues() {
    const { defaultFilterBy, defaultPaginationValues } = this.state;
    this.setState({
      wrongSelectedDate: false,
      filterBy: defaultFilterBy,
      hasDefaultValues: true,
      ...defaultPaginationValues,
    });
  }

  prepareConfigTable() {
    const { headerInfo = {}, xid } = this.props;
    const { limit, hasSelectedDate } = this.state;
    const { name = '' } = headerInfo;

    return {
      columns: [
        {
          text: 'Report Type',
        },
        {
          text: 'Title',
        },
        {
          text: 'Publish Date',
        },
      ],
      rows: [
        {
          text: () => <div className="report-type">Daily Trade Report</div>,
        },
        {
          className: 'title-with-download-pdf',
          text: (itemData, index, size) => {
            const refs = {};
            if (index === size - limit) {
              refs.label = element => (this.focusableItem = element);
            }
            if (index === 0 && hasSelectedDate) {
              refs.label = element => (this.firstFocusableItem = element);
            }
            return (
              <FileButtons
                id={index}
                buttonsInfo={{
                  refs,
                  labels: {
                    label: `${dateFullShortMontFullhNoTime(
                      `${itemData.date}`
                    )} Daily Trade Report`,
                  },
                  pdf: true,
                  pdfLink: `/dm/document?feedId=${itemData.feedId}&docTag=${
                    itemData.documentTag
                  }&versionTag=${itemData.versionTag}`,
                  title: `${name.replace(
                    ' ',
                    '_'
                  )}_DR_${xid}_DailyTradeReport_${yearMonthWithUnderscore(
                    itemData.date
                  )}_AsOf_${yearMonthWithoutSpaces(itemData.date)}`,
                  newFetch: downloadFile,
                }}
              />
            );
          },
        },
        {
          text: itemData => dateMonthYearSpacingWithComma(itemData.date),
        },
      ],
    };
  }

  prepareApiRequest() {
    const { xid } = this.props;
    const { filterBy, limit, offset, wrongSelectedDate } = this.state;
    if (!xid) return null;

    return {
      requestApi: !wrongSelectedDate ? getLibraryDailyReports : null,
      requestApiConfig: { xid, ...filterBy, limit, offset },
    };
  }

  render() {
    const {
      props,
      prepareApiRequest,
      prepareConfigTable,
      onLoadMore,
      onChangeDatePicker,
      resetDatePickerValues,
      getFocusableElement,
      getFirstFocusableElement,
      state: {
        disabledLoading,
        disabledErrorBoundary,
        filterBy,
        hasDefaultValues,
        limit,
        pageSize,
        offset,
        disabledEmptyHandler,
        wrongSelectedDate,
      },
    } = this;
    const configs = {
      configTable: prepareConfigTable(),
      disabledLoading,
      disabledErrorBoundary,
      disabledEmptyHandler,
      immediateRequest: true,
      getFocusableElement,
      getFirstFocusableElement,
      offset,
      filters: [
        {
          id: 'datePicker',
          ...filterBy,
          onChange: onChangeDatePicker,
          updateEveryChange: true,
          showClear: false,
          showClearLabel: false,
          wrongSelectedDate,
          hasDefaultValues,
          onResetValues: resetDatePickerValues,
        },
      ],
      bottomFilters: [
        {
          id: 'loadMore',
          fetchFunction: onLoadMore(),
          position: limit,
          pageSize,
        },
      ],
    };
    const tableLoaderConfig = { ...configs, ...props, ...prepareApiRequest() };
    return (
      <div aria-live="off">
        <TableLoader {...tableLoaderConfig} />
      </div>
    );
  }
}

DailyTable.propTypes = {
  xid: PropTypes.string.isRequired,
  headerInfo: PropTypes.objectOf.isRequired,
};

export default DailyTable;
