import '../../../styles/IssuerServices/components/QuoteBar.scss';

import { withLoadingText } from '@jpm-adr/pattern-library';

import React from 'react';
import propTypes from 'prop-types';

import formater from './formater';
import { getQuoteBar } from '../../utils/apiCalls';
import { withApiRequest } from '../../../utils/apiWithHOC';
import { withErrorBoundary } from '../../../utils/errorBoundary';

const QuoteBar = props => {
  const { data } = props;

  const {
    drsOutstanding,
    issuedLast1D,
    cancelledLast1D,
    netChangeLast1D,
    netChangeLast1DColor,
    issueAndTotal,
    dateEndOfDay,
  } = formater(data);

  return (
    <div className="quote quote__container">
      <div className="quote__cyphers">
        <div className="quote__details">
          <p className="quote__number">
            <span>{drsOutstanding}</span>
          </p>
          <div className="quote__text">DRs Outstanding</div>
        </div>
        <div className="quote__details">
          <p className="quote__number">
            <span className="quote__cyphers">{issuedLast1D}</span>
          </p>
          <div className="quote__text">Issued Last 1 Day</div>
        </div>
        <div className="quote__details">
          <p className="quote__number">
            <span className="quote__cyphers">{cancelledLast1D}</span>
          </p>
          <div className="quote__text">Cancelled Last 1 Day</div>
        </div>
        <div className="quote__details">
          <p className="quote__number">
            <span
              className={`quote__cyphers quote__cyphers--${netChangeLast1DColor}`}
            >
              {netChangeLast1D}
            </span>
          </p>
          <div className="quote__text">Net Change Last 1 Day</div>
        </div>
        <div className="quote__details">
          <p className="quote__number">
            <span className="quote__cyphers quote__cyphers--comparative">
              {issueAndTotal}
            </span>
          </p>
          <div className="quote__text">Issue Limit / Total Issued</div>
        </div>
      </div>
      <div className="quote__asof">
        <span className="summary__asof">As of {dateEndOfDay}</span>
      </div>
    </div>
  );
};

QuoteBar.propTypes = {
  data: propTypes.objectOf(
    propTypes.shape({
      xid: propTypes.number,
      cusip: propTypes.string,
      name: propTypes.string,
      ticker: propTypes.string,
    })
  ).isRequired,
};

const errorHandler = (xid, error) =>
  new Promise(resolve => {
    // Evaluates if there is an error from xid request
    // then if we got error show dashes as empty data
    if (error) return resolve({ data: {} });
    if (!xid) return null;

    return getQuoteBar(xid)
      .then(resolver => resolve(resolver))
      .catch(() => resolve({ data: {} }));
  });

export default withErrorBoundary(
  withApiRequest(
    withLoadingText(QuoteBar, { height: '121px' }),
    ({ xid, error }) => errorHandler(xid, error)
  )
);
