import '../../styles/components/BrokerForms.scss';
import React, { Component } from 'react';
import { NoDataAvailable, Table } from '@jpm-adr/pattern-library';

import CountryFormsAdapter, {
  SORT_OPTIONS_MAP,
  createCountryTitles,
} from './CountryFormsAdapter';

import { getBrockerForms } from '../../utils/api';

const DEFAULT_FILTER = {
  sortBy: SORT_OPTIONS_MAP.name,
  sortOrder: 'asc',
};

class CountryForms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CountryFormsTable: [],
      isLoading: true,
      filters: {
        ...DEFAULT_FILTER,
      },
    };
    this.fetchData = this.fetchData.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.getBrockerFormsData = this.getBrockerFormsData.bind(this);
  }

  componentDidMount() {
    this.getBrockerFormsData('country');
  }

  getBrockerFormsData(query) {
    const { CountryFormsTable = [], filters } = this.state;
    return getBrockerForms(query, filters).then(response => {
      const { items = [] } = response;
      this.setState({
        CountryFormsTable: CountryFormsTable.concat(items),
        isLoading: false,
      });
    });
  }

  handleSort(sortBy, sortOrder) {
    const { filters } = this.state;
    this.setState(
      {
        isLoading: true,
        filters: {
          ...filters,
          sortBy,
          sortOrder,
        },
      },
      this.fetchData
    );
  }

  fetchData() {
    const { filters } = this.state;
    return getBrockerForms('country', filters).then(response => {
      const { items = [] } = response;
      this.setState({
        isLoading: false,
        CountryFormsTable: items,
      });
    });
  }

  render() {
    const { filters, isLoading, CountryFormsTable } = this.state;
    const { sortBy, sortOrder } = filters;
    if (!isLoading && CountryFormsTable.length === 0) {
      return (
        <section>
          <NoDataAvailable />
        </section>
      );
    }
    return (
      <React.Fragment>
        <Table
          isLoading={isLoading}
          values={CountryFormsAdapter(CountryFormsTable)}
          tableId="BrokerForms__Country-Forms"
          tableCustomCss="BrokerForms__table table-component table is-striped is-narrow is-fullwidth"
          theadCustomCss="BrokerForms__table-titles"
          tbodyCustomCss="BrokerForms__table-body"
          tableWrapperClass="BrokerForms__table"
          tableTitles={createCountryTitles(sortBy, sortOrder, this.handleSort)}
        />
        {isLoading ? null : this.LoadingWithState}
      </React.Fragment>
    );
  }
}

export default CountryForms;
