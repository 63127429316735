import PropTypes from 'prop-types';
import React from 'react';

import {
  Button,
  Icon,
  LoadMoreButton,
  NoDataAvailable,
  Table,
  Toggle,
} from '@jpm-adr/pattern-library';

import Filters from './Filters';

import '../../../styles/components/TableViewPage.scss';

const TableViewPage = props => {
  const {
    classModifier,
    data,
    fields,
    pagination,
    heading,
    headerColumns,
    customExportButton,
    isLoading,
    onExport,
    onFilterChange,
    onLoadMore,
    canReset,
    onResetFilters,
    searchInputProps,
    displayNoDataAvailable,
    isError,
    values,
  } = props;

  const {
    hasMore,
    limit,
    offset,
    totalItems
  } = pagination;

  const counter = offset + limit > totalItems
    ? totalItems
    : offset + limit;

  const button = (
    <Button
      customClass="export-table-tablet btn"
      title="Export Table"
      icon="Download"
      border="0"
      onClick={onExport}
    >
      Export Table
    </Button>
  );

  const hasFilters = fields.length && fields.length > 1;

  return (
    <section className={`columns table-view ${classModifier}`}>
      {hasFilters && (
        <Toggle
          defaultClosed={false}
          resetToDefault={onResetFilters}
          canReset={canReset}
        >
          <Filters
            fields={fields}
            onChange={onFilterChange}
            onClear={onResetFilters}
            values={values}
          />
        </Toggle>
      )}

      <div className="column is-12-desktop is-12-tablet main-wrapper">
        <div className="main-section-header">
          <div className="title-left-row">
            <h1 className="table-title">{heading}</h1>
          </div>

          <div className="tablet-button-wrapper right-container" aria-hidden>
            <div className="SearchComponent" aria-hidden>
              <input className="SearchComponent__input" {...searchInputProps} />

              <span className="SearchComponent__icon">
                <Icon iconName="SearchIcon" />
              </span>
            </div>

            <div className="is-hidden-desktop is-hidden-mobile">
              {customExportButton || button}
            </div>
          </div>
        </div>
        <div>
          {totalItems > 0 && (
            <div className="pagination-indicator">{`Displaying 1 - ${counter} of ${totalItems}`}</div>
          )}
          <Table
            isLoading={isLoading}
            values={data}
            tableId="TableView"
            tableCustomCss="table-view-table table is-striped is-narrow is-hoverable is-fullwidth"
            theadCustomCss=""
            tableTitles={headerColumns}
            statusTitleClosed="Closed"
            statusTitleOpen="Open"
          />
          {hasMore && (
            <div>
              <LoadMoreButton fetchFunction={onLoadMore} />
            </div>
          )}
          {displayNoDataAvailable &&
            !isError && (
              <div>
                <NoDataAvailable
                  message="No Results Found"
                  wrapperClass="no-results-wrapper"
                  action={(
                    <Button
                      aria-label="Pressing ENTER or SPACEBAR key, filters will reset to default and the results will be reloaded."
                      onClick={() => {
                        onResetFilters();
                      }}
                      title="Reset filters"
                    >
                      Reset Filters To Default
                    </Button>
                  )}
                />
              </div>
            )}
          {isError && (
            <NoDataAvailable
              messageTitle="Data not found"
              customMessageTitleClass="Data__Not__Found-Title"
              customWrapperCSS="No__Data__Available-Custom-Wrapper"
              message="Either something went wrong or the data doesn’t exist."
              messageStyles="Data__Not__Found-Message"
              wrapperClass="Data__Not__Found"
            />
          )}
        </div>
      </div>
    </section>
  );
};

TableViewPage.defaultProps = {
  classModifier: '',
  customExportButton: null,
  displayNoDataAvailable: false,
  headerColumns: [],
  isError: false,
  onSelectExport: null
};

TableViewPage.propTypes = {
  classModifier: PropTypes.string,
  customExportButton: PropTypes.func,
  canReset: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  displayNoDataAvailable: PropTypes.bool,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['select']).isRequired,
    })
  ).isRequired,
  headerColumns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
      customCSSClass: PropTypes.string,
      sortable: PropTypes.bool,
      handleSort: PropTypes.func,
      sortName: PropTypes.string,
      sortDirection: PropTypes.string,
    })
  ),
  heading: PropTypes.string.isRequired,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  onExport: PropTypes.func.isRequired,
  onFetchData: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  onResetFilters: PropTypes.func.isRequired,
  onSelectExport: PropTypes.func,
  onSort: PropTypes.func.isRequired,
  pagination: PropTypes.shape({}).isRequired,
  searchInputProps:PropTypes.shape({
  }).isRequired,
  values: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    'aria-label': PropTypes.string,
  }).isRequired
};

export default TableViewPage;
